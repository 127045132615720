import { Plus } from "lucide-react";
import { Button } from "@heffl/ui/components/primitives/button";
import { cn, isMobile } from "@heffl/ui/lib/utils";
import { useEffect } from "react";

// Primary action button in listing page (eg: Add Invoice, Add quotation..)
// In desktop, button with Plus (+) icon and text
// In mobile, floating button with plus (+) icon

interface Props {
  onClick: () => void;
  text: string;
  className?: string;
}

const ResponsiveActionButton = ({ onClick, text, className }: Props) => {
  useEffect(() => {
    if (isMobile()) {
      document.body.style.paddingBottom = "80px";
      return () => {
        document.body.style.paddingBottom = "";
      };
    }
  }, []);

  return (
    <Button
      onClick={onClick}
      variant="primary"
      className={cn(
        "fixed right-4 z-10 w-16 h-16 rounded-full sm:static sm:rounded-md sm:h-9 sm:px-4 sm:py-2 sm:w-auto bottom-[80px]",
        className
      )}
    >
      <Plus className="sm:mr-2 mr-0 sm:h-4 sm:w-4 h-10 !text-white " />
      {!isMobile() && text}
    </Button>
  );
};

export default ResponsiveActionButton;
