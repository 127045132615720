import { Button, buttonVariants } from "@heffl/ui/components/primitives/button";
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@heffl/ui/components/primitives/drawer";
import { cn, isMobile } from "@heffl/ui/lib/utils";
import { VariantProps } from "class-variance-authority";
import { Loader, LucideIcon, MoreVertical } from "lucide-react";
import { useState } from "react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@heffl/ui/components/primitives/popover";

type ResponsiveActionsProps = {
  actions: {
    dropdown?: React.ReactNode;
    hide?: boolean;
    label: string;
    hideLabelOnDesktop?: boolean;
    onClick?: () => void;
    iconClassName?: string;
    buttonClassName?: string;
    icon?: LucideIcon;
    buttonVariant?: VariantProps<typeof buttonVariants>["variant"];
    loading?: boolean;
  }[];
  title?: string;
};

const ResponsiveActions = (props: ResponsiveActionsProps) => {
  const [mobileDrawer, setMobileDrawer] = useState(false);
  const [showDropdown, setShowDropdown] = useState<Record<string, boolean>>({});

  const actions = props.actions.filter((action) => !action.hide);

  const getTextColor = (
    variant: VariantProps<typeof buttonVariants>["variant"]
  ) => {
    switch (variant) {
      case "destructive":
        return "text-red-500";
      case "success":
        return "text-success";
      default:
        return "text-black";
    }
  };

  const handleDropdownChange = (label: string, open: boolean) => {
    setShowDropdown((prev) => ({ ...prev, [label]: open }));
  };

  if (isMobile()) {
    return (
      <>
        <Button
          size="icon"
          onClick={() => setMobileDrawer(true)}
          variant="ghost"
        >
          <MoreVertical />
        </Button>
        <Drawer
          open={mobileDrawer}
          onOpenChange={(v) => !v && setMobileDrawer(v)}
        >
          <DrawerContent className={cn("p-4")}>
            <DrawerHeader className="text-left">
              <DrawerTitle>{props.title || "Actions"}</DrawerTitle>
            </DrawerHeader>
            <DrawerDescription>
              {actions.map((action) => {
                const button = (
                  <div
                    onClick={!action.dropdown ? action.onClick : undefined}
                    key={action.label}
                    className={cn(
                      "flex gap-1 items-center py-2 text-base",
                      getTextColor(action.buttonVariant || "default")
                    )}
                  >
                    {action.icon && !action.loading && (
                      <action.icon className="h-4" />
                    )}
                    {action.loading && <Loader className="h-4 animate-spin" />}
                    {action.label}
                  </div>
                );

                if (action.dropdown) {
                  return (
                    <Drawer
                      open={showDropdown[action.label]}
                      onOpenChange={(open) =>
                        handleDropdownChange(action.label, open)
                      }
                      key={action.label}
                    >
                      <DrawerTrigger>{button}</DrawerTrigger>
                      <DrawerContent
                        onClick={() =>
                          handleDropdownChange(action.label, false)
                        }
                      >
                        {action.dropdown}
                      </DrawerContent>
                    </Drawer>
                  );
                }

                return button;
              })}
            </DrawerDescription>
          </DrawerContent>
        </Drawer>
      </>
    );
  }

  return (
    <div className="flex gap-2">
      {actions.map((action) => {
        const button = (
          <Button
            key={action.label}
            variant={action.buttonVariant || "default"}
            className={action.buttonClassName}
            onClick={!action.dropdown ? action.onClick : undefined}
            loading={action.loading}
            size="md"
          >
            {action.icon && (
              <action.icon className={cn("h-4", action.iconClassName)} />
            )}
            {action.hideLabelOnDesktop ? "" : action.label}
          </Button>
        );
        if (action.dropdown) {
          return (
            <Popover
              key={action.label}
              open={showDropdown[action.label]}
              onOpenChange={(open) => handleDropdownChange(action.label, open)}
            >
              <PopoverTrigger>{button}</PopoverTrigger>
              <PopoverContent
                align="end"
                className="flex flex-col p-2 w-fit"
                onClick={() => handleDropdownChange(action.label, false)}
              >
                {action.dropdown}
              </PopoverContent>
            </Popover>
          );
        }
        return button;
      })}
    </div>
  );
};

export default ResponsiveActions;
