import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
// import * as Sentry from "@sentry/react";
import "@heffl/ui/globals.css";
import "./localStyles.css";

// Sentry.init({
//   dsn: "https://8bf16ff7fd6bd3dde3dd78a859b7ba23@o4507520159776768.ingest.us.sentry.io/4507520162267136",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration({
//       maskAllText: false,
//       blockAllMedia: false,
//     }),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost", /^https:\/\/api\.heffl\.com/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   enabled: import.meta.env.VITE_ENVIRONMENT !== "development",
// });

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
