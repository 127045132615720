import { SearchInput } from "@/components/FormComponents";
import DataGrid from "@/components/dataGrid/DataGrid";
import FilterBar from "@/components/filters";
import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import usePermissions from "@/lib/hooks/usePermissions";
import { calculateLineItems } from "@heffl/server/src/helpers/lineItems/calculateLineItems";
import enums from "@heffl/server/src/schemas/enums";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Card } from "@heffl/ui/components/primitives/card";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import ResponsiveActionButton from "@heffl/ui/components/primitives/responsive-action-button";
import {
  formatCurrency,
  formatName,
  formatValue,
  isMobile,
} from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import {
  CalendarDays,
  CircleDollarSign,
  CircleDollarSignIcon,
  Hammer,
  Plus,
} from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import JobCard from "./components/jobCard";
import { JobStatusBadge } from "./details";

export const JobTypeBadge = ({ type }: { type: "ONEOFF" | "CONTRACT" }) => {
  return (
    <Badge
      variant={type === "ONEOFF" ? "warning" : "success"}
      icon={type === "ONEOFF" ? Hammer : CalendarDays}
    >
      {type === "ONEOFF" ? "One-off" : "Contract"}
    </Badge>
  );
};

export const ScheduleInvoiceBadge = ({
  schedule,
}: {
  schedule: {
    id: number;
    fsJobId: number;
    invoiceRequired: boolean;
    fsJobInvoices?: { invoices: { id: number; number: string } }[];
    status: z.infer<typeof enums.scheduleStatus>;
  };
}) => {
  if (!schedule.invoiceRequired || schedule.status === "CANCELLED") return null;

  if (schedule.status !== "COMPLETED" && !schedule.fsJobInvoices?.length) {
    return (
      <Badge variant="warning" icon={CircleDollarSign}>
        Invoice required
      </Badge>
    );
  }

  if (schedule.status === "COMPLETED" && !schedule.fsJobInvoices?.length) {
    return (
      <Badge
        variant="error"
        icon={CircleDollarSign}
        className="cursor-pointer"
        onClick={() => {
          // used this because datagrid errors out when using navigate
          window.location.href = `/sales/invoices/add?fsJobId=${schedule.fsJobId}&fsScheduleId=${schedule.id}`;
        }}
      >
        To invoice <Plus className="w-4 h-4" />
      </Badge>
    );
  }

  if (schedule.fsJobInvoices?.length) {
    return (
      <Badge
        variant="success"
        icon={CircleDollarSign}
        className="cursor-pointer"
        onClick={() => {
          if (!schedule.fsJobInvoices) return;
          // used this because datagrid errors out when using navigate
          window.location.href = `/sales/invoices/details/${schedule.fsJobInvoices[0].invoices.id}`;
        }}
      >
        #{schedule.fsJobInvoices[0].invoices.number}
      </Badge>
    );
  }

  return null;
};

type Filters = {
  salesPersons: number[];
  tags: number[];
  search: string;
  type: ("ONEOFF" | "CONTRACT")[];
  nextSchedule: [Date, Date] | undefined;
  createdAt: [Date, Date] | undefined;
  statuses: z.infer<typeof enums.jobStatus>[];
  clients: number[];
  invoiced: ("YES" | "NO")[];
  page: number;
  pageSize: number;
  services: number[];
};

export const getJobId = (
  job: {
    contractJobNumber?: number | null;
    oneOffJobNumber?: number | null;
    type: z.infer<typeof enums.jobType>;
  },
  prefix: string = "#"
) => {
  const idPrefix = job.type === "ONEOFF" ? "OF" : "AMC";
  const id =
    job.type === "ONEOFF" ? job.oneOffJobNumber : job.contractJobNumber;
  return `${prefix}${idPrefix}-${id}`;
};

const FsJobsList = () => {
  const navigate = useNavigate();
  const permissions = usePermissions();

  const [clientSearch, setClientSearch] = useState("");
  const [serviceSearch, setServiceSearch] = useState("");

  const [filters, setFilters] = useParamsState<Filters>({
    salesPersons: [],
    tags: [],
    search: "",
    type: [],
    nextSchedule: undefined,
    createdAt: undefined,
    clients: [],
    invoiced: [],
    page: 1,
    pageSize: 10,
    statuses: [],
    services: [],
  });

  const { data: jobData } = trpc.fieldService.jobs.list.useQuery({
    statuses: filters.statuses,
    search: filters.search,
    type: filters.type,
    nextSchedule: {
      startDate: filters.nextSchedule?.[0],
      endDate: filters.nextSchedule?.[1],
    },
    services: filters.services,
    tags: filters.tags,
    clients: filters.clients,
    invoiced: filters.invoiced,
    salesPersons: filters.salesPersons,
    page: filters.page,
    pageSize: filters.pageSize,
    createdAt: {
      startDate: filters.createdAt?.[0],
      endDate: filters.createdAt?.[1],
    },
  });
  const { data: clients } = trpc.clients.list.useQuery({
    search: clientSearch,
    pageNo: 1,
    pageSize: 10,
  });

  const { data: usersSales } = trpc.users.list.useQuery({
    type: ["ADMIN", "STAFF", "OWNER", "SUPER_ADMIN"],
  });

  const { data: services } = trpc.products.list.useQuery({
    search: serviceSearch,
    pageSize: 8,
  });

  const { data: tags } = trpc.tags.list.useQuery({
    type: "JOB",
  });

  return (
    <Page title="Jobs" fullWidth className="sm:p-0">
      <div className="flex justify-between w-full border-b border-gray-200 sm:p-3">
        <SearchInput
          placeholder="Search jobs..."
          className="w-full sm:w-1/3"
          value={filters.search ?? ""}
          onChange={(e) =>
            setFilters({
              search: e,
            })
          }
        />
        {permissions?.CREATE_JOBS.allowed && (
          <ResponsiveActionButton text="Job" onClick={() => navigate("add")} />
        )}
      </div>
      <div className="flex flex-col gap-2 mt-3 w-4/5 sm:mt-0 sm:items-center sm:p-3 sm:flex-row">
        <FilterBar
          onChange={() => {
            setFilters({
              page: 1,
            });
          }}
          filters={[
            {
              key: "createdAt",
              label: "Created at",
              type: "date-range",
              value: filters.createdAt,
              presets: [
                {
                  label: "Today",
                  value: [new Date(), new Date()],
                },
                {
                  label: "This Week",
                  value: [
                    dayjs().startOf("week").toDate(),
                    dayjs().endOf("week").toDate(),
                  ],
                },
                {
                  label: "This Month",
                  value: [
                    dayjs().startOf("month").toDate(),
                    dayjs().endOf("month").toDate(),
                  ],
                },
              ],
              onChange: (e) => {
                setFilters({
                  createdAt: e,
                });
              },
            },
            {
              key: "nextSchedule",
              label: "Next Schedule",
              type: "date-range",
              value: filters.nextSchedule,
              presets: [
                {
                  label: "Today",
                  value: [new Date(), new Date()],
                },
                {
                  label: "This Week",
                  value: [
                    dayjs().startOf("week").toDate(),
                    dayjs().endOf("week").toDate(),
                  ],
                },
                {
                  label: "Next Two Weeks",
                  value: [
                    dayjs().startOf("week").toDate(),
                    dayjs().add(2, "week").endOf("week").toDate(),
                  ],
                },
                {
                  label: "This Month",
                  value: [
                    dayjs().startOf("month").toDate(),
                    dayjs().endOf("month").toDate(),
                  ],
                },
              ],
              onChange: (e) => {
                console.log(e, "date");
                setFilters({
                  nextSchedule: e,
                });
              },
            },
            {
              key: "statuses",
              label: "Status",
              type: "checkbox",
              value: filters.statuses,
              options: [
                {
                  label: "Active",
                  value: "ACTIVE",
                },
                {
                  label: "Completed",
                  value: "COMPLETED",
                },
                {
                  label: "Cancelled",
                  value: "CANCELLED",
                },
              ],
              onChange: (e) => {
                setFilters({
                  statuses: e as z.infer<typeof enums.jobStatus>[],
                });
              },
            },
            {
              key: "type",
              label: "Type",
              type: "checkbox",
              value: filters.type,
              options: [
                { label: "One-off", value: "ONEOFF" },
                { label: "Contract", value: "CONTRACT" },
              ],
              onChange: (e) => {
                setFilters({
                  type: e as ("ONEOFF" | "CONTRACT")[],
                });
              },
            },

            {
              key: "services",
              label: "Services",
              type: "checkbox",
              showSearch: true,
              value: filters.services,
              options: services?.products.map((service) => ({
                label: service.name,
                value: service.id,
              })),
              onSearch: (e) => setServiceSearch(e),
              onClose: () => setServiceSearch(""),
              onChange: (e) => {
                setFilters({
                  services: e as number[],
                });
              },
            },
            {
              key: "tags",
              label: "Tags",
              type: "checkbox",
              value: filters.tags,
              options:
                tags?.map((tag) => ({
                  label: tag.name,
                  value: tag.id,
                })) ?? [],
              onChange: (e) => {
                setFilters({
                  tags: e as number[],
                });
              },
            },

            {
              key: "clients",
              label: "Client",
              type: "checkbox",
              value: filters.clients,
              showSearch: true,
              options:
                clients?.clients.map((client) => ({
                  label: client.name,
                  value: client.id,
                })) ?? [],
              onSearch: (e) => setClientSearch(e),
              onClose: () => setClientSearch(""),
              onChange: (e) => {
                setFilters({
                  clients: e as number[],
                });
              },
            },
            {
              key: "salesPersons",
              label: "Sales Person",
              type: "checkbox",
              value: filters.salesPersons,
              options: usersSales?.map((salesPerson) => ({
                label: formatName(salesPerson),
                value: salesPerson.id,
              })),
              onChange: (e) => {
                setFilters({
                  salesPersons: e as number[],
                });
              },
            },
            {
              key: "invoiced",
              label: "Invoiced",
              type: "checkbox",
              value: filters.invoiced,
              options: [
                { label: "Yes", value: "YES" },
                { label: "No", value: "NO" },
              ],
              onChange: (e) => {
                setFilters({
                  invoiced: e as ("YES" | "NO")[],
                });
              },
            },
          ]}
          suffix={
            <Card parentClassName="p-1">
              <div className="flex gap-2 justify-between">
                <div className="text-gray-500 flex items-center gap-0.5">
                  <CircleDollarSignIcon className="w-4 h-4" />
                  Total:{" "}
                </div>
                <div className="font-medium text-gray-700">
                  {" "}
                  {formatCurrency(jobData?.totalJobAmount ?? 0, "AED")}
                </div>
              </div>
            </Card>
          }
        />
      </div>

      {jobData?.jobs ? (
        isMobile() ? (
          <div className="flex flex-col gap-2 mt-4 mb-[100px]">
            {jobData.jobs.map((job) => (
              <JobCard key={job.id} job={job} />
            ))}
          </div>
        ) : (
          <DataGrid
            rowKey="id"
            name="jobsListMain"
            className="h-[calc(100vh-127px-var(--header-height))]"
            label="Jobs"
            rows={jobData.jobs}
            onCellClick={(row) => {
              navigate(`details/${row.row.id}`);
            }}
            empty={{
              icon: Hammer,
              title: "No jobs",
              description: "Please add some jobs to get started.",
              actionText: "Add job",
              onAction: () => navigate("/field-service/jobs/add"),
              buttonSize: "sm",
            }}
            columns={[
              {
                key: "id",
                name: "Id",
                width: 100,
                renderCell: ({ row }) => (
                  <div className="font-medium text-gray-700">
                    {getJobId(row)}
                  </div>
                ),
              },
              {
                key: "service",
                name: "Service",
                width: 200,
                renderCell: ({ row }) => (
                  <div className="!font-medium relative flex items-center group cursor-pointer gap-2">
                    {row.fsJobServices.length && row.fsJobServices[0].name}
                    {row.fsJobServices.length > 1 &&
                      ` + ${row.fsJobServices.length - 1} more`}
                  </div>
                ),
              },
              {
                key: "type",
                name: "Type",
                width: 120,
                renderCell: ({ row }) => <JobTypeBadge type={row.type} />,
              },
              {
                key: "client",
                name: "Client",
                width: 250,
                renderCell: ({ row }) => (
                  <div className="font-medium text-gray-700">
                    {row.fsProperties.clients.name} -{" "}
                    <span className="text-gray-500">
                      {formatValue.propertyName(row.fsProperties)}
                    </span>
                  </div>
                ),
              },
              {
                key: "lastVisit",
                name: "Last visit",
                width: 120,
                renderCell: ({ row }) => {
                  const completedSchedule = row.fsSchedules.find(
                    (schedule) => schedule.status === "COMPLETED"
                  );
                  return completedSchedule
                    ? dayjs(completedSchedule.startDate).format("MMM DD, YYYY")
                    : "No visit";
                },
              },
              {
                key: "nextVisit",
                name: "Next visit",
                width: 120,
                renderCell: ({ row }) => {
                  const nextSchedule = row.fsSchedules.find(
                    (schedule) =>
                      schedule.status === "SCHEDULED" ||
                      schedule.status === "CONFIRMED"
                  );
                  return nextSchedule
                    ? dayjs(nextSchedule.startDate).format("MMM DD, YYYY")
                    : "No visit";
                },
              },
              {
                key: "status",
                name: "Status",
                width: 120,
                renderCell: ({ row }) => {
                  return <JobStatusBadge status={row.status} />;
                },
              },
              {
                key: "invoices",
                name: "Invoices",
                width: 120,
                renderCell: ({ row }) => {
                  const totalJobValue = calculateLineItems({
                    lineItems: row.fsJobServices.map((service) => ({
                      ...service,
                      viewType: "LINE_ITEM",
                    })),
                    discount: row.discount,
                  }).total;

                  const invoiceRequiredSchedules = row.fsSchedules.filter(
                    (schedule) =>
                      schedule.invoiceRequired &&
                      schedule.status !== "CANCELLED"
                  );
                  const hasInvoicePending =
                    invoiceRequiredSchedules.filter(
                      (schedule) => schedule.fsJobInvoices.length === 0
                    ).length !== 0;

                  return invoiceRequiredSchedules.length ? (
                    <Badge variant={hasInvoicePending ? "warning" : "success"}>
                      {hasInvoicePending ? "To Invoice" : "Invoiced"}
                    </Badge>
                  ) : totalJobValue > 0 && row.status !== "CANCELLED" ? (
                    <Badge variant="error">No invoice</Badge>
                  ) : null;
                },
              },
              {
                key: "amount",
                name: "Amount",
                width: 120,
                renderCell: ({ row }) => (
                  <div className="">
                    {formatCurrency(
                      calculateLineItems({
                        lineItems: row.fsJobServices.map((service) => ({
                          ...service,
                          viewType: "LINE_ITEM",
                        })),
                        discount: row.discount,
                      }).total,
                      "AED"
                    )}
                  </div>
                ),
              },
              {
                key: "salesman",
                name: "Sales person",
                width: 130,
                renderCell: ({ row }) => (
                  <div className="font-medium text-gray-700">
                    {row?.salesPerson
                      ? formatName(row.salesPerson)
                      : "No sales person"}
                  </div>
                ),
              },
              {
                key: "expenses",
                name: "Expenses",
                width: 130,
                renderCell: ({ row }) => (
                  <div className="font-medium text-gray-700">
                    {row?.expenses
                      ? formatCurrency(
                          row.expenses.reduce(
                            (acc, expense) => acc + expense.amount,
                            0
                          ),
                          "AED"
                        )
                      : "No expenses"}
                  </div>
                ),
              },
              {
                key: "aggregatorCharge",
                name: "Aggregator Charge",
                width: 130,
                renderCell: ({ row }) => (
                  <div className="font-medium text-gray-700">
                    {row?.vendorCharge
                      ? formatCurrency(row.vendorCharge, "AED")
                      : "No vendor charge"}
                  </div>
                ),
              },
              {
                key: "tags",
                name: "Tags",
                width: 200,
                renderCell: ({ row }) => (
                  <div className="space-x-2">
                    {row.fsJobTags.map((tag) => (
                      <Badge key={tag.id} background={tag.tags.color}>
                        {tag.tags.name}
                      </Badge>
                    ))}
                  </div>
                ),
              },
            ]}
          />
        )
      ) : (
        <FullScreenSpinner />
      )}
    </Page>
  );
};

export default FsJobsList;
