import {
  ClientInput,
  MultipleUserInput,
  UserInput,
} from "@/components/FormComponents";
import CustomFieldsInputForm from "@/helpers/customFields/custom-fields-input-form";
import validateCustomFields from "@/helpers/customFields/validateCustomFields";
import { trpc } from "@/helpers/trpc";
import { AddClientDrawer } from "@/pages/crm/clients/list";
import { AddContactDrawer } from "@/pages/crm/contacts/list";
import Schemas from "@heffl/server/src/schemas";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import Select from "@heffl/ui/components/primitives/creatable-select";
import { DatePicker } from "@heffl/ui/components/primitives/datepicker";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import { MultiSelect } from "@heffl/ui/components/primitives/multi-select";
// import { Switch } from "@heffl/ui/components/primitives/switch";
import { Textarea } from "@heffl/ui/components/primitives/textarea";
import { zodResolver } from "@hookform/resolvers/zod";
import dayjs from "dayjs";
import { Plus, Tag } from "lucide-react";
import { useEffect, useState } from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

export const ProjectForm = ({
  form,
  edit = false,
  pipelineId,
}: {
  form: UseFormReturn<z.infer<typeof projectSchema>, unknown>;
  edit?: boolean;
  pipelineId: number;
}) => {
  const navigate = useNavigate();

  // const [isRepeat, setIsRepeat] = useState(false);
  const [showAddContact, setShowAddContact] = useState(false);
  const [showAddCompany, setShowAddCompany] = useState(false);

  const assignees = form.watch("projectAssignees");

  const { data: pipelineDetails } =
    trpc.projects.pipelines.details.useQuery(pipelineId);
  const { data: pipelineStages } =
    trpc.projects.pipelines.getPipelineStages.useQuery({
      pipelineId: pipelineId,
    });

  const { data: users } = trpc.users.list.useQuery();
  const { data: templates } = trpc.projects.templates.list.useQuery({
    pipelineId: pipelineId,
  });
  const { data: tags } = trpc.tags.list.useQuery({
    type: "PROJECT",
  });

  useEffect(() => {
    if (
      pipelineStages &&
      !form.getValues("projectStageId") &&
      pipelineStages.length
    ) {
      console.log(pipelineStages);
      form.setValue("projectStageId", pipelineStages[0].id);
    }
  }, [pipelineStages]);

  useEffect(() => {
    if (pipelineId) {
      form.setValue("pipelineId", pipelineId);
    }
  }, [pipelineId]);

  if (!pipelineDetails || !pipelineStages || !users || !templates)
    return <FullScreenSpinner />;

  return (
    <div className="flex flex-col gap-3">
      <AddContactDrawer
        open={showAddContact}
        onClose={(contactId) => {
          setShowAddContact(false);
          if (contactId) {
            form.setValue("contactId", contactId);
          }
        }}
      />
      <AddClientDrawer
        open={showAddCompany}
        onClose={(clientId) => {
          setShowAddCompany(false);
          if (clientId) {
            form.setValue("clientId", clientId);
          }
        }}
      />
      <div className="flex gap-2">
        {!edit && (
          <FormField
            name="projectTemplateId"
            label="Template"
            info="Use a project template to automate similar projects."
            className="w-2/3"
          >
            <Select
              placeholder="Not selected"
              options={
                templates?.map((template) => ({
                  label: template.name,
                  value: template.id,
                })) || []
              }
              createButton={{
                label: "Add template",
                onClick: () => navigate(`/projects/templates`),
              }}
              onSelect={(v) => {
                const selectedTemplate = templates.find(
                  (template) => template.id === v.value
                );
                if (selectedTemplate) {
                  form.setValue(
                    "pipelineId",
                    selectedTemplate.projectPipelineId
                  );
                  form.setValue(
                    "projectStageId",
                    selectedTemplate.projectPipelines.projectPipelineStages[0]
                      .id
                  );
                }
              }}
            />
          </FormField>
        )}

        {!edit && (
          <UserInput
            disabled={!assignees?.length}
            className="w-1/3"
            name="assignTasksTo"
            label="Assign tasks"
            placeholder="Assign all tasks to"
            filter={assignees?.map((assignee) => Number(assignee)) || []}
          />
        )}
      </div>

      <FormField name="title" label="Title">
        <Input placeholder={`${pipelineDetails.singularName} title`} required />
      </FormField>
      <div className="grid grid-cols-2 gap-2">
        <FormField name="projectStageId" label="Stage">
          <Select
            options={
              pipelineStages?.map((stage) => ({
                label: stage.name,
                value: stage.id,
              })) || []
            }
          />
        </FormField>
        <MultipleUserInput
          label="Assignees"
          name="projectAssignees"
          placeholder="Add assignees"
        />
      </div>
      <div className="flex gap-3">
        <FormField name="startDate" label="Start date">
          <DatePicker />
        </FormField>

        <FormField name="endDate" label="First close date">
          <DatePicker />
        </FormField>
        {/* <div className="flex gap-2 justify-center items-center mt-4">
          Repeat
          <Switch checked={isRepeat} onCheckedChange={setIsRepeat} />
        </div> */}
      </div>
      {/* {isRepeat && (
        <div className="grid grid-cols-2 gap-2">
          <FormField name="repeatFrequency" label="Repeat">
            <FrequencySelector />
          </FormField>
          <FormField name="repeatEndDate" label="Repeat until">
            <DatePicker />
          </FormField>
        </div>
      )} */}

      <div className="grid grid-cols-2 gap-2">
        <ClientInput
          name="clientId"
          label="Client"
          allowClear
          onAddModalClose={(id) => id && form.setValue("clientId", id)}
        />
        <FormField name="projectTags" label="Tags">
          <MultiSelect
            icon={Tag}
            placeholder="Add tags"
            options={tags?.map((tag) => ({
              label: tag.name,
              value: tag.id,
              color: tag.color,
            }))}
          />
        </FormField>
      </div>
      <FormField name="description" label="Description">
        <Textarea placeholder="Describe details, notes etc.." />
      </FormField>
      <CustomFieldsInputForm section="PROJECT" projectPipelineId={pipelineId} />
    </div>
  );
};

const projectSchema = Schemas.project.project.extend({
  assignTasksTo: z.number().optional(),
});
const AddProjectDrawer = ({
  open,
  onClose,
  pipelineId,
}: {
  open: boolean;
  onClose: () => void;
  pipelineId: number;
}) => {
  const { data: pipelineDetails } =
    trpc.projects.pipelines.details.useQuery(pipelineId);
  const { data: customFields } = trpc.customizations.customFields.list.useQuery(
    {
      section: "PROJECT",
      projectPipelineId: pipelineId,
    },
    {
      enabled: !!pipelineId,
    }
  );

  const projectAddMutation = trpc.projects.add.useMutation({
    onSuccess() {
      toast.success("Added project");
      onClose();
      form.reset();
    },
  });

  const form = useForm<z.infer<typeof projectSchema>>({
    resolver: zodResolver(projectSchema),
    defaultValues: {
      startDate: new Date(),
      endDate: dayjs().add(2, "weeks").toDate(),
    },
  });

  const onSubmit = (values: z.infer<typeof projectSchema>) => {
    const isValid = validateCustomFields({
      customFields,
      customFieldsValues: values.customFields,
      form,
    });
    if (!isValid) return;
    projectAddMutation.mutate(values);
  };

  return (
    <ModalDrawer
      open={open}
      onClose={() => {
        onClose();
        form.reset();
      }}
      className="w-full sm:w-[450px] h-4/5 sm:h-full "
      title={`Add ${pipelineDetails?.singularName}`}
      footer={
        <Button
          loading={projectAddMutation.isLoading}
          type="submit"
          variant="primary"
          className="mt-4 w-full"
          onClick={() => {
            form.handleSubmit(onSubmit)();
          }}
          icon={Plus}
        >
          Add {pipelineDetails?.singularName}
        </Button>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <ProjectForm form={form} pipelineId={pipelineId} />
      </Form>
    </ModalDrawer>
  );
};

export default AddProjectDrawer;
