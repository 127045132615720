import DataTable from "@/components/DataTable";
import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import { Button } from "@heffl/ui/components/primitives/button";
import { Card } from "@heffl/ui/components/primitives/card";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Separator } from "@heffl/ui/components/primitives/separator";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import { formatCurrency, formatName } from "@heffl/ui/lib/utils";
import { BarChart } from "@tremor/react";
import dayjs from "dayjs";
import {
  Album,
  Calendar,
  DollarSign,
  FileLineChart,
  Trash2,
  User,
} from "lucide-react";
import GaugeComponent from "react-gauge-component";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

const SalesGoalDetails = () => {
  const { id } = useParams();

  const confirm = useConfirm();
  const navigate = useNavigate();

  const { data } = trpc.salesGoals.details.useQuery(Number(id));

  const deleteSalesGoal = trpc.salesGoals.delete.useMutation({
    onSuccess: () => {
      toast.success("Sales goal deleted");
      navigate("/crm/goals");
    },
  });

  if (!data) return <FullScreenSpinner />;
  return (
    <Page
      title="Sales Goal Details"
      breadcrumbs={[{ label: "Sales Goals", path: "/crm/goals" }]}
      showBack
      fullWidth
      suffix={
        <Button
          variant="destructive"
          size="sm"
          icon={Trash2}
          onClick={async () => {
            const confirmed = await confirm({
              title: "Delete Sales Goal",
              body: "Are you sure you want to delete this sales goal?",
            });
            if (confirmed) {
              deleteSalesGoal.mutate(Number(id));
            }
          }}
        >
          Delete
        </Button>
      }
    >
      <div className="flex flex-col gap-4">
        <Card title="Target Details" className="flex flex-col gap-2">
          <div className="flex gap-2 items-center">
            <User className="h-4" />
            <p className="text-sm font-medium">
              {data?.salesGoal?.users
                ? formatName(data?.salesGoal?.users)
                : "Team"}
            </p>
          </div>
          <div className="flex gap-2 items-center">
            <Album className="h-4" />
            <p className="text-sm font-medium">
              {data?.salesGoal?.type === "FIELD_SERVICE_JOB" ? "Job added" : ""}
            </p>
          </div>
          <div className="flex gap-2 items-center">
            <FileLineChart className="h-4" />
            <p className="text-sm font-medium">{data.salesGoal.frequency}</p>
          </div>
          <div className="flex gap-2 items-center">
            <Calendar className="h-4" />
            <p className="text-sm font-medium">
              {dayjs(data.salesGoal.startDate).format("DD MMM YYYY")} -{" "}
              {dayjs(data.salesGoal.endDate).format("DD MMM YYYY")}
            </p>
          </div>
          <div className="flex gap-2 items-center">
            <DollarSign className="h-4" />
            <p className="text-sm font-medium">
              {formatCurrency(data.salesGoal.goal, "AED")}
            </p>
          </div>
        </Card>
        <div className="grid gap-4 sm:grid-cols-3">
          {data?.result?.map((item) => (
            <Card key={item.period}>
              <div className="flex flex-col gap-2">
                <div className="flex justify-between">
                  <h2 className="text-sm font-medium">{item.periodLabel}</h2>
                </div>
                <Separator />
                <GaugeComponent
                  className="w-full sm:w-auto"
                  value={item.percentage}
                  labels={{
                    valueLabel: {
                      formatTextValue: () =>
                        `${formatCurrency(item.difference, "AED")}`,
                    },
                  }}
                  type="semicircle"
                  arc={{
                    colorArray: ["#ff1f1f", "#ffe100", "#39f505"],
                  }}
                />
                <div className="flex justify-between">
                  <p className="text-sm text-gray-800">Target</p>
                  <p className="text-sm font-medium">
                    {formatCurrency(item.target, "AED")}
                  </p>
                </div>
                <div className="flex justify-between">
                  <p className="text-sm text-gray-800">Achieved</p>
                  <p className="text-sm font-medium">
                    {formatCurrency(item.total, "AED")}
                  </p>
                </div>
                <div className="flex justify-between">
                  <p className="text-sm text-gray-800">Difference</p>
                  <p className="text-sm font-medium">
                    {formatCurrency(item.difference, "AED")}
                  </p>
                </div>
              </div>
            </Card>
          ))}
        </div>

        <Card>
          <BarChart
            data={data?.result || []}
            categories={["total", "target"]}
            index="period"
            colors={["blue", "green"]}
            stack={true}
          />
        </Card>

        <Card title="Summary">
          <DataTable
            data={data?.result || []}
            rowKey="period"
            columns={[
              {
                title: "Period",
                render: (value) => value.periodLabel,
              },
              {
                title: "Target",
                render: (value) => formatCurrency(value.target, "AED"),
              },
              {
                title: "Result",
                render: (value) => formatCurrency(value.total, "AED"),
              },
              {
                title: "Difference",
                render: (value) => formatCurrency(value.difference, "AED"),
              },
              {
                title: "Target Progress",
                render: (value) => `${value.percentage}%`,
              },
            ]}
          />
        </Card>
      </div>
    </Page>
  );
};

export default SalesGoalDetails;
