import { AddActivityModal } from "@/components/actionsModals/activities-modals";
import { trpc } from "@/helpers/trpc";
import { activityItems } from "@/lib/constants";
import { capitalize } from "@heffl/ui/lib/utils";
import ActivityTimeline from "@/pages/crm/components/activity-timeline";
import Schemas from "@heffl/server/src/schemas";
import { Button } from "@heffl/ui/components/primitives/button";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import {
  RawSelect,
  RawSelectItem,
} from "@heffl/ui/components/primitives/raw-select";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@radix-ui/react-collapsible";
import dayjs from "dayjs";
import { ChevronDown, ChevronUp } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

const LeadFocus = ({ leadId }: { leadId: number }) => {
  const activityForm = useForm<z.infer<typeof Schemas.crm.activity>>({
    defaultValues: {
      type: "call",
      leadId: leadId,
    },
  });

  const selectedType = activityForm.watch("type");

  const [open, setOpen] = useState(true);
  const [showAddActivity, setShowAddActivity] = useState(false);

  const activityAddMutation = trpc.activities.add.useMutation({
    onSuccess() {
      activityForm.reset();
      setOpen(true);
    },
  });
  const { data: focusActivities } = trpc.activities.focus.useQuery({
    leadId,
  });

  if (!focusActivities) return <FullScreenSpinner />;

  const handleSubmit = async (data: z.infer<typeof Schemas.crm.activity>) => {
    activityAddMutation.mutate(data);
  };

  return (
    <>
      <AddActivityModal
        open={showAddActivity}
        onClose={() => setShowAddActivity(false)}
        leadId={leadId}
      />
      <Collapsible className="mt-4" open={open} onOpenChange={setOpen}>
        <CollapsibleTrigger>
          <div className="flex flex-row gap-1 items-center">
            {open ? (
              <ChevronUp className="w-4 h-4" />
            ) : (
              <ChevronDown className="w-4 h-4" />
            )}
            <h1 className="text-sm font-semibold">Focus</h1>
          </div>
        </CollapsibleTrigger>
        <CollapsibleContent>
          <div className="flex flex-col mt-4">
            {!focusActivities.length && (
              <div className="flex flex-col gap-2 justify-center items-center p-3">
                <p className="text-sm">No focus items yet</p>
                <p className="w-1/2 text-sm text-center text-gray-500">
                  Scheduled activities, pinned notes, email drafts and scheduled
                  emails will appear here.
                </p>
                <div className="flex">
                  <Form {...activityForm} onSubmit={handleSubmit}>
                    <div className="flex gap-1">
                      <FormField name="type" className="w-22">
                        <RawSelect>
                          {activityItems.map((activity) => (
                            <RawSelectItem
                              key={activity.value}
                              value={activity.value}
                            >
                              <div className="flex gap-1 items-center">
                                <activity.icon className="h-3.5" />
                                {activity.label}
                              </div>
                            </RawSelectItem>
                          ))}
                        </RawSelect>
                      </FormField>
                      <FormField name="title">
                        <Input placeholder={capitalize(selectedType)} />
                      </FormField>
                    </div>
                    <div className="flex gap-1">
                      <Button
                        size="xs"
                        onClick={() => {
                          const startDate = dayjs().add(1, "hour");
                          const endDate = startDate.add(20, "minutes");
                          activityForm.setValue(
                            "startDate",
                            startDate.toDate()
                          );
                          activityForm.setValue("endDate", {
                            date: endDate.toDate(),
                            dateType: "CUSTOM_DATE",
                          });

                          if (!activityForm.getValues("title")) {
                            activityForm.setValue(
                              "title",
                              capitalize(selectedType)
                            );
                          }
                          activityForm.handleSubmit(handleSubmit)();
                        }}
                      >
                        In 1h
                      </Button>
                      <Button
                        onClick={() => {
                          const startDate = dayjs().add(3, "hour");
                          const endDate = startDate.add(20, "minutes");
                          activityForm.setValue(
                            "startDate",
                            startDate.toDate()
                          );
                          activityForm.setValue("endDate", {
                            date: endDate.toDate(),
                            dateType: "CUSTOM_DATE",
                          });
                          if (!activityForm.getValues("title")) {
                            activityForm.setValue(
                              "title",
                              capitalize(selectedType)
                            );
                          }
                          activityForm.handleSubmit(handleSubmit)();
                        }}
                        size="xs"
                      >
                        In 3h
                      </Button>
                      <Button
                        onClick={() => {
                          const startDate = dayjs().add(1, "day");
                          const endDate = startDate.add(20, "minutes");
                          activityForm.setValue(
                            "startDate",
                            startDate.toDate()
                          );
                          activityForm.setValue("endDate", {
                            date: endDate.toDate(),
                            dateType: "CUSTOM_DATE",
                          });
                          if (!activityForm.getValues("title")) {
                            activityForm.setValue(
                              "title",
                              capitalize(selectedType)
                            );
                          }
                          activityForm.handleSubmit(handleSubmit)();
                        }}
                        size="xs"
                      >
                        Tommorrow
                      </Button>
                      <Button
                        onClick={() => setShowAddActivity(true)}
                        size="xs"
                        className="text-primary"
                      >
                        Other
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            )}
            <ActivityTimeline activities={focusActivities} />
          </div>
        </CollapsibleContent>
      </Collapsible>
    </>
  );
};

export default LeadFocus;
