import { WorkFlowApp } from "../../types";
import { sendHefflNotification } from "./actions/sendNotification";

export const heffl: WorkFlowApp = {
  name: "heffl",
  label: "Heffl",
  description: "Heffl actions and triggers",
  logo: "heffl",
  actions: [sendHefflNotification],
  triggers: [
    {
      name: "lead.new",
      label: "New lead",
      description: "Triggered when a new lead is created",
      output: [
        {
          name: "id",
          label: "Lead id",
        },
        {
          name: "name",
          label: "Lead name",
        },
        {
          name: "title",
          label: "Lead title",
        },
        {
          name: "description",
          label: "Lead description",
        },
      ],
      inputs: [],
    },
    {
      name: "deal.new",
      label: "New deal",
      description: "Triggered when a new deal is created",
      output: [
        {
          name: "id",
          label: "Deal id",
        },
        {
          name: "name",
          label: "Deal name",
        },
        {
          name: "amount",
          label: "Deal amount",
        },
      ],
      inputs: [],
    },
  ],
};
