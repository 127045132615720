import { trpc } from "@/helpers/trpc";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { useNavigate, useParams } from "react-router-dom";

const ExternalConnect: React.FC = () => {
  const { mobile } = useParams<{ mobile: string }>();
  const navigate = useNavigate();

  const { data, isLoading } = trpc.clients.externalConnectUser.useQuery(
    mobile ?? "",
    {
      enabled: !!mobile,
    }
  );

  if (isLoading) return <FullScreenSpinner />;

  if (!data) {
    navigate(`/crm/leads/table?addLead=true&addLeadMobile=${mobile}`);
    return null;
  }

  const { clientId, contactId, leadId } = data;

  if (clientId) {
    navigate(`/crm/clients/details/${clientId}`);
  } else if (contactId) {
    navigate(`/crm/contacts/details/${contactId}`);
  } else if (leadId) {
    navigate(`/crm/leads/details/${leadId}`);
  }

  return null;
};

export default ExternalConnect;
