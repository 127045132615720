import { RouterOutputs, trpc } from "@/helpers/trpc";

import { AvatarsGroup } from "@/components/UserAvatar";
import DataGrid from "@/components/dataGrid/DataGrid";
import { TaskLinkBadge } from "@/pages/personal-dashboard";
import {
  getProjectTaskStatus,
  taskStatuses,
} from "@/pages/projects/details/components/kanban";
import { taskDateColor } from "@/pages/projects/details/components/kanban/TaskCard";
import {
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
} from "@heffl/ui/components/primitives/popover";
import { cn, dynamicDateFormatting, formatName } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";

const TaskList = ({
  tasks,
  onTaskClick,
  className,
}: {
  tasks: RouterOutputs["tasks"]["list"];
  onTaskClick: (id: number) => void;
  className?: string;
}) => {
  const taskUpdateMutation = trpc.tasks.update.useMutation();

  return (
    <>
      <DataGrid
        rowKey="id"
        className={cn(className)}
        name="crmTasksListMain"
        label="Tasks"
        rows={tasks}
        columns={[
          {
            key: "number",
            name: "Id",
            width: 80,
            renderCell: ({ row }) => <div>{row.id}</div>,
          },
          {
            key: "title",
            name: "Subject",
            width: 300,
            renderCell: ({ row }) => (
              <div
                onClick={() => onTaskClick(row.id)}
                className="truncate rounded-md cursor-pointer hover:text-primary-600"
              >
                {row.title}
              </div>
            ),
          },
          {
            key: "status",
            name: "Status",
            width: 140,
            editable: true,
            cellClass: "px-0",
            renderCell: ({ row: task }) => (
              <Popover>
                <PopoverTrigger className="w-full h-full">
                  <div
                    className={cn(
                      "w-full h-full flex items-center justify-center text-white font-medium",
                      getProjectTaskStatus(task.status).color
                    )}
                  >
                    {getProjectTaskStatus(task.status).label}
                  </div>
                </PopoverTrigger>
                <PopoverContent className="space-y-2 w-56" sideOffset={0}>
                  <PopoverArrow className="fill-white" />
                  {taskStatuses.map((status) => (
                    <div
                      key={status.value}
                      onClick={() => {
                        taskUpdateMutation.mutate({
                          id: task.id,
                          task: {
                            status: status.value,
                          },
                        });
                      }}
                      className={cn(
                        "p-2 cursor-pointer w-full flex items-center justify-center rounded-sm text-white font-medium",
                        status.color
                      )}
                      style={{
                        backgroundColor: status.hex,
                      }}
                    >
                      {status.label}
                    </div>
                  ))}
                </PopoverContent>
              </Popover>
            ),
          },
          {
            key: "dueDate",
            name: "Due Date",
            width: 120,
            renderCell: ({ row }) => (
              <div
                className={cn(
                  taskDateColor(row.date, row.status === "COMPLETED")
                )}
              >
                {row.date ? dayjs(row.date).format("DD MMM YYYY") : ""}
              </div>
            ),
          },
          {
            key: "linked",
            name: "Linked",
            width: 160,
            renderCell: ({ row }) => <TaskLinkBadge task={row} />,
          },
          {
            key: "assignee",
            name: "Assignee",
            width: 120,
            renderCell: ({ row }) => (
              <AvatarsGroup
                size="xs"
                users={row.taskAssignees.map((assignee) => assignee.users)}
              />
            ),
          },
          {
            key: "createdBy",
            name: "Created By",
            width: 120,
            renderCell: ({ row }) => <div>{formatName(row.createdBy)}</div>,
          },
          {
            key: "completedOn",
            name: "Completed On",
            width: 120,
            renderCell: ({ row }) => (
              <div>
                {row.completedOn ? dynamicDateFormatting(row.completedOn) : "-"}
              </div>
            ),
          },
        ]}
      />
    </>
  );
};

export default TaskList;
