import { Button } from "@heffl/ui/components/primitives/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@heffl/ui/components/primitives/command";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@heffl/ui/components/primitives/drawer";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@heffl/ui/components/primitives/popover";
import { cn, isMobile } from "@heffl/ui/lib/utils";
import {
  ChevronDown,
  Loader2,
  LucideIcon,
  PlusCircle,
  XCircle,
} from "lucide-react";
import * as React from "react";
import { ScrollArea, ScrollBar } from "./scroll-area";
import { CommandLoading } from "cmdk";
import { Tooltip } from "./tooltip";

interface Option {
  label: string;
  value: any;
  icon?: LucideIcon;
}

type SelectProps<T extends Option> = {
  popoverClassName?: string;
  previewRender?: (record: T | undefined) => React.ReactNode;
  shouldFilter?: boolean;
  onSearch?: (v: string) => void;
  hideSearch?: boolean;
  className?: string;
  render?: (record: T) => React.ReactNode;
  options: T[] | undefined | null;
  emptyMessage?: string;
  value?: any;
  onChange?: (v: any) => void;
  onSelect?: (v: T) => void;
  isLoading?: boolean;
  disabled?: boolean;
  placeholder?: string;
  icon?: React.ReactNode;
  number?: boolean;
  allowClear?: boolean;
  createButton?: {
    label: string;
    onClick: () => void;
  };
};

// select is breakin if it contains \n or \r
const formatString = (str: string) => {
  return str.trim();
};

const Select = <T extends Option>(props: SelectProps<T>) => {
  const isDesktop = !isMobile();

  const [open, setOpen] = React.useState(false);

  const options = props.options || [];
  const selectedOption = options.find((o) => o.value === props.value);

  const selectPreview = (
    <Tooltip
      content={selectedOption?.label}
      delayDuration={500}
      side="bottom"
      showArrow
      className={cn("w-full", props.className)}
    >
      <Button
        variant="outline"
        className={cn(
          "flex justify-between w-full px-2 sm:h-9 h-11  !font-normal sm:py-2 py-3"
        )}
        disabled={props.disabled || props.isLoading}
        size="md"
      >
        <div className="flex overflow-hidden gap-1 items-center">
          {props.icon}
          <p className="truncate">
            {props.value ? (
              <p>{selectedOption?.label}</p>
            ) : (
              <p className="font-normal text-gray-500">
                {props.placeholder || ""}
              </p>
            )}
          </p>
        </div>
        <div className="flex items-center">
          {!!props.value && props.allowClear && (
            <XCircle
              className="h-4 text-gray-700"
              onClick={(e) => {
                e.stopPropagation();
                setOpen(false);
                props.onChange && props.onChange(null);
              }}
            />
          )}
          {props.isLoading ? (
            <Loader2 className="h-4 animate-spin text-primary" />
          ) : (
            <ChevronDown className="h-4 text-gray-400" />
          )}
        </div>
      </Button>
    </Tooltip>
  );

  const selectOptions = (
    <Command shouldFilter={props.shouldFilter}>
      {!props.hideSearch && (
        <CommandInput
          placeholder="Search options..."
          onValueChange={(v) => props.onSearch?.(v)}
        />
      )}

      <CommandList onClick={(e) => e.stopPropagation()}>
        {!props.isLoading && (
          <CommandEmpty>
            {props.emptyMessage || "No results found."}
          </CommandEmpty>
        )}
        {/* TODO BUG: There is an issue of select breaking if \n or r in label */}
        {props.isLoading ? (
          <CommandLoading>
            <div className="flex gap-1 items-center p-2">
              <Loader2 className="h-5 animate-spin text-primary" />
              <p>Loading...</p>
            </div>
          </CommandLoading>
        ) : (
          <CommandGroup>
            <ScrollArea
              className={cn("max-h-[300px] sm:max-h-[190px] !overflow-auto")}
            >
              {options.map((option) => (
                <CommandItem
                  className="py-2 sm:py-1.5"
                  key={option.value}
                  value={formatString(`${option.label} ${option.value}`)}
                  onSelect={() => {
                    props.onSelect && props.onSelect(option);
                    props.onChange && props.onChange(option.value);
                    setOpen(false);
                  }}
                >
                  {props.render ? (
                    props.render(option)
                  ) : (
                    <div className="flex gap-2 items-center">
                      {option.icon && <option.icon className="h-5" />}
                      {option.label}
                    </div>
                  )}
                </CommandItem>
              ))}
              <ScrollBar orientation="vertical" />
            </ScrollArea>
          </CommandGroup>
        )}
      </CommandList>
      {props.createButton?.onClick && (
        <div
          className="p-2.5 border-t border-gray-200 hover:text-white hover:bg-primary-300 flex items-center gap-1 cursor-pointer"
          key="create"
          onClick={() => {
            props.createButton?.onClick();
            setOpen(false);
          }}
        >
          <PlusCircle className="h-5 text-primary" />
          {props.createButton.label}
        </div>
      )}
    </Command>
  );

  if (isDesktop) {
    return (
      <Popover open={open} onOpenChange={setOpen} modal={true}>
        <PopoverTrigger
          className={cn(props.previewRender ? "" : "w-full")}
          disabled={props.disabled}
          onClick={(e) => e.stopPropagation()}
        >
          {props.previewRender
            ? props.previewRender(selectedOption)
            : selectPreview}
        </PopoverTrigger>
        <PopoverContent
          className={cn(
            "w-[--radix-popover-trigger-width] max-h-[--radix-popover-content-available-height] p-0",
            props.popoverClassName
          )}
          align="start"
          side="bottom"
          asChild
        >
          {selectOptions}
        </PopoverContent>
      </Popover>
    );
  }

  return (
    <Drawer open={open} onOpenChange={setOpen}>
      <DrawerTrigger
        className={cn(props.previewRender ? "" : "w-full")}
        disabled={props.disabled}
      >
        {props.previewRender
          ? props.previewRender(selectedOption)
          : selectPreview}
      </DrawerTrigger>
      <DrawerContent>
        {props.placeholder && (
          <DrawerHeader>
            <DrawerTitle>{props.placeholder}</DrawerTitle>
          </DrawerHeader>
        )}
        <div className="mt-4 border-t">{selectOptions}</div>
      </DrawerContent>
    </Drawer>
  );
};

export default Select;
