import {
  useSortable,
  defaultAnimateLayoutChanges,
  type AnimateLayoutChanges,
} from "@dnd-kit/sortable";
// import { useNavigate } from "react-router-dom";
import { RouterOutputs } from "@/helpers/trpc";
import Projectcard from "./projectCard";
import { useNavigate } from "react-router-dom";

const animateLayoutChanges: AnimateLayoutChanges = (args) => {
  const { isSorting, wasDragging } = args;
  if (isSorting || wasDragging) return defaultAnimateLayoutChanges(args);
  return true;
};

export type TProject = NonNullable<
  RouterOutputs["projects"]["list"]["projects"][number]
>;

export default function SortableProjectCard({
  project,
}: {
  project: TProject;
}) {
  const {
    setNodeRef,
    listeners,
    attributes,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: project.id,
    data: { project: project, type: "project" },
    animateLayoutChanges,
  });

  const navigate = useNavigate();

  const cardStyle = {
    transform: transform
      ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
      : undefined,
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <li
      style={cardStyle}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      className="bg-white w-[300px]"
      onClick={(e) => {
        e.stopPropagation();
        navigate(`/projects/details/${project.id}`);
      }}
    >
      <Projectcard project={project} />
    </li>
  );
}
