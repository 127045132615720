import { z } from "zod";
import enums from "./enums";

export const paymentMethod = z.object({
  name: z.string(),
  isDefault: z.boolean().default(false),
});

export const lineItemProduct = z.object({
  name: z.string(),
  productId: z.coerce.number().nullish(),
  quantity: z.coerce.number().default(0),
  price: z.coerce.number().default(0),
  description: z.string().nullish(),
  tax: z.coerce.number().default(0),
  viewType: enums.lineItemViewType.default("LINE_ITEM"),
});

export const quotation = z.object({
  clientId: z.number(),
  contactId: z.number().nullish(),
  templateId: z.number(),
  date: z.date(),
  quotationProducts: lineItemProduct
    .extend({
      customFields: z.any().default({}),
    })
    .array(),
  title: z.string().default(""),
  subject: z.string().nullish(),
  status: enums.quotationStatus.default("DRAFT"),
  rejectionReason: z.string().nullish(),
  contentHtml: z.string(),
  markedSentOn: z.date().nullish(),
  internalNotes: z.string().nullish().optional(),
  dealId: z.number().nullish(),
  discount: z.coerce.number().default(0),
  currencyId: z.number().default(1),
  quotationTags: z.coerce.number().array().default([]),
  expiryDate: z.date().nullish(),
  salesPersonId: z.number().nullish(),
  lostReasonId: z.number().nullish(),
  lostReasonDescription: z.string().nullish(),
});

export const invoice = z.object({
  projectInvoices: z
    .object({
      projectId: z.number().nullish(),
    })
    .nullish(),
  fsJobInvoices: z
    .object({
      fsJobId: z.number().nullish(),
      fsScheduleId: z.number().nullish(),
    })
    .nullish(),
  externalId: z.string().nullish(),
  importOperationId: z.number().nullish(),
  clientId: z.number(),
  contactId: z.number().nullish(),
  date: z.date(),
  dueDate: z.date(),
  invoiceProducts: lineItemProduct.array(),
  status: enums.invoiceStatus.default("DRAFT"),
  contentHtml: z.string(),
  notes: z.string().nullish().optional(),
  discount: z.coerce.number().default(0),
  currencyId: z.number().default(1),
  templateId: z.number(),
  invoiceTags: z.coerce.number().array().default([]),
  cancelReason: z.string().nullish(),
  salesPersonId: z.number().nullish(),
});

export const proformaInvoice = z.object({
  externalId: z.string().nullish(),
  importOperationId: z.number().nullish(),
  clientId: z.number(),
  contactId: z.number().nullish(),
  date: z.date(),
  dueDate: z.date(),
  proformaInvoiceProducts: lineItemProduct.array(),
  status: enums.invoiceStatus.default("DRAFT"),
  contentHtml: z.string(),
  notes: z.string().nullish().optional(),
  discount: z.coerce.number().default(0),
  currencyId: z.number().default(1),
  templateId: z.number(),
});

export const invoicePayment = z.object({
  clientId: z.number(),
  date: z.date(),
  paymentMethodId: z.number(),
  collectedByUserId: z.number().nullish(),
  amount: z.coerce.number().min(0.01, "Amount is required"),
  refNo: z.string().nullish(),
  notes: z.string().nullish(),
  paymentAllocations: z
    .object({
      invoiceId: z.number().nullish(),
      amount: z.coerce.number(),
      type: enums.paymentAllocationType,
    })
    .array()
    .default([]),
});

export const paymentLink = z.object({
  paymentGateway: enums.paymentGateway,
  amount: z.coerce.number(),
  paymentStatus: enums.paymentStatus.default("PENDING"),
  active: z.boolean().default(false),
  invoiceId: z.number().nullish(),
  metadata: z.record(z.any()).default({}),
  currencyId: z.number().default(1),
  description: z.string().default(""),
});
