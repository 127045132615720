import { getActivityColumnLabels } from "@heffl/server/src/helpers/userActivities/columnLabels";
import {
  Entity,
  UserActivity,
} from "@heffl/server/src/helpers/userActivities/recordActivity";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@heffl/ui/components/primitives/accordion";
import { Timeline } from "@heffl/ui/components/primitives/Timeline";
import dayjs from "dayjs";
import { convert } from "html-to-text";
import { capitalize } from "lodash";
import { ReactNode } from "react";

const actionLabels = {
  ADD: "created",
  UPDATE: "updated",
  DELETE: "deleted",
};

const updateTypeLabels = {
  CREATE: "added",
  REMOVE: "removed",
  CHANGE: "updated",
};

const renderTitle = (userActivity: UserActivity) => {
  return (
    <p>
      <span className="font-medium">{userActivity.users.firstName}</span>{" "}
      {actionLabels[userActivity.action]}{" "}
      <span className="font-medium">{capitalize(userActivity.entity)}</span>
    </p>
  );
};

const hideValueChanged: { [key in Entity]: string[] } = {
  QUOTATION: ["quotationProducts", "dealId"],
  INVOICE: ["invoiceProducts", "paymentsRecieved"],
  PURCHASE_ORDER: ["purchaseOrderProducts"],
  BILL: ["billProducts"],
};

const renderDescription = (userActivity: UserActivity) => {
  if (userActivity.action === "ADD") {
    return (
      <div>
        <span className="text-xs">
          {dayjs(userActivity.createdAt).fromNow()}
        </span>
      </div>
    );
  }
  if (userActivity.action === "UPDATE") {
    return (
      <div>
        <ul>
          {userActivity.updates.length > 2 ? (
            <Accordion type="single" collapsible>
              <AccordionItem value="updates">
                <AccordionTrigger
                  className="text-xs font-medium"
                  iconPosition="end"
                >
                  <div className="flex gap-3 items-center">
                    {userActivity.updates.length} updates done
                  </div>
                </AccordionTrigger>
                <AccordionContent>
                  <ul className="pl-3">
                    {userActivity.updates.map((update, index) => (
                      <li
                        key={index}
                        className="text-xs list-disc marker:text-yellow-500"
                      >
                        {`${capitalize(
                          getActivityColumnLabels(
                            userActivity.entity,
                            update.path[0]
                          )
                        )} ${updateTypeLabels[update.type]}`}
                        {"value" in update &&
                          !hideValueChanged[userActivity.entity].includes(
                            update.path[0].toString()
                          ) && (
                            <span>
                              {" "}
                              to{" "}
                              <span className="font-medium">
                                {["string", "number"].includes(
                                  typeof update?.value
                                )
                                  ? typeof update?.value === "number"
                                    ? update.value
                                    : convert(update?.value)
                                  : ""}
                              </span>
                            </span>
                          )}
                      </li>
                    ))}
                  </ul>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          ) : (
            <ul>
              {userActivity.updates.map((update, index) => (
                <li
                  key={index}
                  className="text-xs list-disc marker:text-yellow-500"
                >
                  {`${capitalize(
                    getActivityColumnLabels(userActivity.entity, update.path[0])
                  )} ${updateTypeLabels[update.type]}`}
                  {"value" in update &&
                    !hideValueChanged[userActivity.entity].includes(
                      update.path[0].toString()
                    ) && (
                      <span>
                        {" "}
                        to{" "}
                        <span className="font-medium">
                          {["string", "number"].includes(typeof update?.value)
                            ? typeof update?.value === "number"
                              ? update.value
                              : convert(update?.value)
                            : ""}
                        </span>
                      </span>
                    )}
                </li>
              ))}
            </ul>
          )}
        </ul>
        <span className="text-xs">
          {dayjs(userActivity.createdAt).fromNow()}
        </span>
      </div>
    );
  }
  return "";
};

const RenderTimeline = ({
  userActivities,
}: {
  userActivities: UserActivity[];
}) => {
  const items: {
    title: ReactNode;
    description: ReactNode;
  }[] = [];

  userActivities.forEach((activity) => {
    items.push({
      title: renderTitle(activity),
      description: renderDescription(activity),
    });
  });
  return <Timeline activeItem={0} items={items} />;
};

export default RenderTimeline;
