import { RouterOutputs, trpc } from "@/helpers/trpc";
import { DATE_TIME_WORD_FORMAT, getLeadStage } from "@/lib/constants";
import DropMenu from "@heffl/ui/components/DropMenu";
import { Button } from "@heffl/ui/components/primitives/button";
import { Card } from "@heffl/ui/components/primitives/card";
import { cn, dynamicDateFormatting, formatName } from "@heffl/ui/lib/utils";
import { format, isBefore, isToday } from "date-fns";
import {
  AlertOctagon,
  Calendar,
  CheckSquare,
  MoreVertical,
} from "lucide-react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

type TLead = RouterOutputs["leads"]["list"]["leads"][number];

const LeadCard = ({ data, onEdit }: { data: TLead; onEdit?: () => void }) => {
  const navigate = useNavigate();

  const leadUpdateMutation = trpc.leads.update.useMutation({
    onError: (err) => {
      toast.error(err.message);
    },
  });
  const leadDeleteMutation = trpc.leads.delete.useMutation({
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const todo = [
    ...data.activities.map((a) => ({
      date: a.startDate,
      type: "activity",
      title: a.title,
    })),
    ...data.tasks.map((t) => ({
      date: t.date,
      type: "task",
      title: t.title,
    })),
  ].sort((a, b) => a.date.getTime() - b.date.getTime());
  const Icon = todo[0]?.type === "activity" ? Calendar : CheckSquare;

  return (
    <Card onClick={() => navigate(`/crm/leads/details/${data.id}`)}>
      <div className="flex flex-col gap-2">
        <div className="flex flex-row justify-between">
          <p className="text-sm font-medium">{data.title}</p>
          <div className="flex flex-row gap-2">
            <div
              className={cn(
                "rounded-full px-3 py-1 text-xs text-white font-medium w-fit flex items-center h-fit",
                `bg-${getLeadStage(data.stage).bg}-100`,
                `text-${getLeadStage(data.stage).bg}-500`
              )}
            >
              {getLeadStage(data.stage).label}
            </div>
            <Button variant={"outline"} size={"sm"} className="px-1">
              <DropMenu
                items={[
                  {
                    label: "Edit",
                    onClick: (e) => {
                      e.stopPropagation();
                      onEdit?.();
                    },
                  },
                  {
                    label: data.archived ? "Unarchive" : "Archive",
                    onClick: (e) => {
                      e.stopPropagation();
                      leadUpdateMutation.mutate({
                        id: data.id,
                        lead: { archived: !data.archived },
                      });
                    },
                  },
                  {
                    label: "Delete",
                    className: "text-red-600",
                    onClick: (e) => {
                      e.stopPropagation();
                      leadDeleteMutation.mutate(data.id);
                    },
                  },
                ]}
              >
                <MoreVertical className="h-4" />
              </DropMenu>
            </Button>
          </div>
        </div>
        <div className="flex flex-row justify-between">
          <p className="text-xs">Created by</p>
          <p className="text-xs text-gray-500">{formatName(data.ownedBy)}</p>
        </div>
        <div className="flex flex-row justify-between">
          <p className="text-xs">Created on</p>
          <p className="text-xs text-gray-500">
            {" "}
            {format(data.createdAt, DATE_TIME_WORD_FORMAT)}
          </p>
        </div>
        <div className="flex flex-row justify-between">
          <p className="text-xs">Next activity</p>
          <div>
            {todo[0] && Icon ? (
              <div
                className={cn(
                  "flex items-center flex-wrap justify-end",
                  isBefore(todo[0].date, new Date()) && "text-red-600",
                  isToday(todo[0].date) && "text-green-600"
                )}
              >
                <Icon className="mr-1 h-4" />
                {todo[0].title} <span className="px-1 text-gray-300">•</span>
                {dynamicDateFormatting(todo[0].date)}
              </div>
            ) : (
              <div className="flex items-center text-yellow-600">
                <AlertOctagon className="mr-1 h-4" /> No task
              </div>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default LeadCard;
