//@ts-nocheck

import { Button } from "@heffl/ui/components/primitives/button";
import { useCallback, useRef, useState } from "react";
import Webcam from "react-webcam";
import { createWorker } from "tesseract.js";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const preprocessImage = (image) => {
  // Convert the image to black and white (grayscale)
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const img = new Image();
  img.src = image;

  canvas.width = img.width;
  canvas.height = img.height;

  ctx.drawImage(img, 0, 0, img.width, img.height);

  const imageData = ctx.getImageData(0, 0, img.width, img.height);
  const data = imageData.data;

  for (let i = 0; i < data.length; i += 4) {
    const grayscaleValue = Math.round(
      0.3 * data[i] + 0.59 * data[i + 1] + 0.11 * data[i + 2]
    );
    data[i] = grayscaleValue;
    data[i + 1] = grayscaleValue;
    data[i + 2] = grayscaleValue;
  }

  ctx.putImageData(imageData, 0, 0);

  return canvas.toDataURL("image/jpeg");
};

const BusinessCardScan = () => {
  const webcamRef = useRef(null);

  const [capturedImage, setCapturedImage] = useState(null);

  const capture = useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    const processedImage = imageSrc;
    setCapturedImage(processedImage);
    const worker = await createWorker("eng");
    const {
      data: { text, confidence },
    } = await worker.recognize(processedImage);
    console.log(text, confidence);
    await worker.terminate();
  }, [webcamRef]);

  return (
    <div className="h-full space-y-3 ">
      <Webcam
        forceScreenshotSourceSize
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/png"
        videoConstraints={{ facingMode: "user", width: 2000, height: 2000 }}
      />
      <Button className="w-full" variant="primary" onClick={capture}>
        Capture card
      </Button>
      {capturedImage && <img src={capturedImage} className="w-full" />}{" "}
    </div>
  );
};
export default BusinessCardScan;
