import { z } from "zod";

export const dataImport = z.object({
  teamId: z.number(),
  userId: z.number(),
  type: z.enum([
    "CLIENTS",
    "CLIENT_ADDRESSES",
    "CLIENT_CONTACTS",
    "ADRESSES",
    "INVOICES",
    "INVOICE_LINE_ITEMS",
    "PRODUCTS",
  ]),
  templateId: z.number().nullish(),
  csvFileName: z.string().optional(),
  csvArray: z.any().array().default([]),
  countryId: z.number().nullish(),
  stateId: z.number().nullish(),
  createProducts: z.boolean().default(false),
});
