import * as React from "react";

import { cn } from "@heffl/ui/lib/utils";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  display: ({ doEdit }: { doEdit: (v: boolean) => void }) => React.ReactNode;
}

const EditableInput = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, value, display, ...props }, ref) => {
    const [isEdit, setIsEdit] = React.useState(false);

    return (
      <>
        {isEdit ? (
          <input
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                setIsEdit(false);
              }
            }}
            onBlur={() => setIsEdit(false)}
            value={value ?? ""}
            type={type}
            className={cn(
              "flex h-10 rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
              className
            )}
            ref={ref}
            {...props}
          />
        ) : (
          display({ doEdit: setIsEdit })
        )}
      </>
    );
  }
);
EditableInput.displayName = "EditableInput";

export default EditableInput;
