import { z } from "zod";
import parsePhone from "../helpers/utils/parsePhone";

const user = z.object({
  active: z.boolean().default(true),
  firstName: z.string(),
  teamId: z.number(),
  email: z.string(),
  lastName: z.string().default(""),
  outlookAccessToken: z.string().nullish(),
  password: z.string(),
  phone: z.string().max(7).nullish(),
  mobile: z.string().transform(parsePhone).nullish(),
  countryCode: z.number().nullish(),
  type: z.enum(["OWNER", "STAFF", "ADMIN", "SUPER_ADMIN", "FIELD_STAFF"]),
  permissionSetId: z.number().nullish(),
  pinnedApps: z.array(z.string()).optional().default([]),
  hourlyRate: z.coerce.number().optional().default(0),
});
export default {
  user,
};
