import { z } from "zod";

export const workflow = z.object({
  name: z.string().min(1),
  description: z.string().optional(),
  triggerApp: z.string().nullish(),
  triggerName: z.string().nullish(),
  triggerData: z.record(z.any()).optional(),
});

export const workFlowItem = z.object({
  app: z.string(),
  action: z.string(),
  data: z.record(z.any()),
});
