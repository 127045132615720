import { cn } from "@heffl/ui/lib/utils";
import "./tiptap.css";

import Placeholder from "@tiptap/extension-placeholder";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import { TextSelection } from "@tiptap/pm/state";
import { Editor, EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { styled } from "goober";
import { Bold, Italic, List, ListOrdered, ListTodo } from "lucide-react";
import { useEffect } from "react";

const MenuBar = ({ editor }: { editor: Editor | null }) => {
  if (!editor) {
    return null;
  }

  return (
    <div className="flex gap-3 tiptap-menu">
      <button
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        className={cn(
          "p-1 px-2 rounded-md hover:bg-primary-50",
          editor.isActive("bold") && "is-active bg-primary-300"
        )}
        type="button"
      >
        <Bold className="w-4 h-4" strokeWidth={3.5} />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        className={editor.isActive("italic") ? "is-active" : ""}
        type="button"
      >
        <Italic className="w-4 h-4" strokeWidth={2} />
      </button>

      <button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={editor.isActive("bulletList") ? "is-active" : ""}
        type="button"
      >
        <List className="w-4 h-4" strokeWidth={2} />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={editor.isActive("orderedList") ? "is-active" : ""}
        type="button"
      >
        <ListOrdered className="w-4 h-4" strokeWidth={2} />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleTaskList().run()}
        className={editor.isActive("taskList") ? "is-active" : ""}
        type="button"
      >
        <ListTodo className="w-4 h-4" strokeWidth={2} />
      </button>
    </div>
  );
};

const EditorStyling = styled("div")<{
  height?: number;
  maxHeight?: number;
  disabled: boolean;
}>`
  .ProseMirror * {
    white-space: pre-wrap !important;
    word-wrap: break-word !important;
  }
  .tiptap {
    height: ${(props) => (props.height ? `${props.height}px` : "auto")};
    max-height: ${(props) =>
      props.maxHeight ? `${props.maxHeight}px` : "none"};
    overflow: auto;
    background-color: ${(props) => props.disabled && "#F9FAFB"};
  }
`;

interface Props {
  onChange?: (value: string) => void;
  value?: string;
  disabled?: boolean;
  height?: number;
  maxHeight?: number;
  placeholder?: string;
  className?: string;
  inlineEdit?: boolean;
  autoFocus?: boolean;
}

const TipTap: React.FC<Props> = ({
  onChange,
  value = "",
  disabled = false,
  height,
  placeholder = "",
  maxHeight,
  className,
  autoFocus = false,
}) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder: placeholder,
      }),
      TaskList,
      TaskItem.configure({
        nested: true,
      }),
    ],
    autofocus: autoFocus ? "end" : false,
    content: value,
    editable: !disabled,
    parseOptions: {
      preserveWhitespace: "full",
    },
    onUpdate: ({ editor }) => {
      const newContent = editor.getHTML();
      if (onChange) {
        onChange(newContent);
      }
    },
  });

  // in modal autofocus doesn't work, this fixes it
  useEffect(() => {
    if (editor && autoFocus) {
      editor.commands.focus("end");
    }
  }, [editor, autoFocus]);

  useEffect(() => {
    if (editor) {
      const { from, to } = editor.state.selection;
      editor.commands.setContent(value, false, {
        preserveWhitespace: "full",
      });
      // Restore cursor position
      const newFrom = Math.min(from, editor.state.doc.content.size);
      const newTo = Math.min(to, editor.state.doc.content.size);
      const textSelection = new TextSelection(
        editor.state.doc.resolve(newFrom),
        editor.state.doc.resolve(newTo)
      );
      editor.view.dispatch(editor.state.tr.setSelection(textSelection));
    }
  }, [value]);

  return (
    <EditorStyling
      height={height}
      maxHeight={maxHeight}
      disabled={disabled}
      className={cn(className, disabled && "pointer-events-none")}
    >
      <EditorContent editor={editor} />
      <MenuBar editor={editor} />
    </EditorStyling>
  );
};
export default TipTap;
