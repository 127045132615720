import { trpc } from "@/helpers/trpc";
import Schemas from "@heffl/server/src/schemas";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import Select from "@heffl/ui/components/primitives/creatable-select";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FormList from "@heffl/ui/components/primitives/form-list";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import MiniRichTextEditor from "@heffl/ui/components/primitives/mini-rich-text-editor";
import { MultiSelect } from "@heffl/ui/components/primitives/multi-select";
import { zodResolver } from "@hookform/resolvers/zod";
import { Trash, Users } from "lucide-react";
import { useEffect } from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";

const defaultSubTask: {
  title: string;
  status: z.infer<typeof Schemas.project.projectSubTask>["status"];
} = {
  title: "",
  status: "OPEN",
};

const ProjectTemplateTaskForm = ({
  projectTemplateId,
}: {
  form: UseFormReturn<
    z.infer<typeof Schemas.project.projectTemplateTask>,
    unknown
  >;
  projectTemplateId: number;
}) => {
  const { data: users } = trpc.users.list.useQuery();
  const { data: sections } = trpc.projects.templates.sections.list.useQuery({
    projectTemplateId,
  });
  return (
    <>
      <div className="flex gap-4">
        <div className="space-y-2 w-3/5">
          <FormField name="title" label="Title">
            <Input placeholder="Task title" />
          </FormField>

          <div className="grid grid-cols-3 gap-2">
            <FormField
              name="dueDateDays"
              label="Due Days"
              info="Days from project start date"
            >
              <Input placeholder="Due days" />
            </FormField>
            <FormField
              label="Section"
              name="projectTemplateSectionId"
              className="col-span-2"
            >
              <Select
                options={
                  sections?.map((section) => ({
                    label: section.title,
                    value: section.id,
                  })) || []
                }
              />
            </FormField>
          </div>
          <FormField label="Assigned to" name="projectTemplateTaskAssignees">
            <MultiSelect
              icon={Users}
              placeholder="Assign team"
              options={
                users?.map((user) => ({
                  label: user.firstName,
                  value: user.id,
                })) || []
              }
            />
          </FormField>
          <FormField label="Description" name="description">
            <MiniRichTextEditor
              height={160}
              placeholder="Describe the task, note down points etc...."
            />
          </FormField>
        </div>
        <div className="p-3 w-2/5 bg-gray-50 rounded-md">
          {/* SUBTASKS */}
          <h1 className="text-lg font-semibold">Subtasks</h1>
          <div>
            <FormList name="projectTemplateSubTasks">
              {({ fields, append, remove, _name }) => {
                return (
                  <div className="flex flex-col gap-2">
                    {fields.map((field, index) => (
                      <div
                        className="flex flex-row gap-1 items-center w-full"
                        key={field.id}
                      >
                        <FormField name={_name(index, "title")}>
                          <Input placeholder="Enter subtask" />
                        </FormField>
                        <Button
                          onClick={() => {
                            remove(index);
                          }}
                          className="ml-1 rounded-sm"
                        >
                          <Trash className="w-4 h-4 text-red-500" />
                        </Button>
                      </div>
                    ))}
                    <div className="mt-2 w-full">
                      <Button
                        onClick={() => {
                          append(defaultSubTask);
                        }}
                        variant="primaryOutline"
                        size="sm"
                      >
                        + Add Subtask
                      </Button>
                    </div>
                  </div>
                );
              }}
            </FormList>
          </div>
        </div>
      </div>
    </>
  );
};

export const AddTemplateTaskModal = ({
  open,
  onClose,
  defaultValues,
  projectTemplateId,
}: {
  open: boolean;
  onClose: () => void;
  defaultValues?: Partial<z.infer<typeof Schemas.project.projectTemplateTask>>;
  projectTemplateId: number;
}) => {
  const form = useForm<z.infer<typeof Schemas.project.projectTemplateTask>>({
    resolver: zodResolver(Schemas.project.projectTemplateTask),
    defaultValues: {
      title: "",
      ...defaultValues,
    },
  });

  useEffect(() => {
    if ((defaultValues || projectTemplateId) && !form.formState.isDirty) {
      form.reset({ ...defaultValues, projectTemplateId });
    }
  }, [defaultValues, form, projectTemplateId]);

  const onCloseModal = () => {
    onClose();
    form.reset();
  };

  const addTaskMutation = trpc.projects.templates.tasks.add.useMutation({
    onSuccess() {
      toast.success("Succesfully added task.");
      onCloseModal();
      form.reset();
    },
  });

  return (
    <ModalDrawer
      open={open}
      onClose={onCloseModal}
      modalClassName="max-w-[900px]"
      title="Add task"
    >
      <Form
        {...form}
        onSubmit={(values) => {
          addTaskMutation.mutate({
            ...values,
          });
        }}
      >
        <ProjectTemplateTaskForm
          form={form}
          projectTemplateId={projectTemplateId}
        />
        <div className="flex gap-2 justify-end w-full">
          <Button onClick={onCloseModal}>Cancel</Button>
          <Button
            loading={addTaskMutation.isLoading}
            type="submit"
            variant="primary"
          >
            Add task
          </Button>
        </div>
      </Form>
    </ModalDrawer>
  );
};

export const EditTemplateTaskModal = ({
  open,
  onClose,
  id,
  projectTemplateId,
}: {
  open: boolean;
  onClose: () => void;
  id: number;
  projectTemplateId: number;
}) => {
  const form = useForm<z.infer<typeof Schemas.project.projectTemplateTask>>({
    resolver: zodResolver(Schemas.project.projectTemplateTask),
  });

  const { data: taskDetails } =
    trpc.projects.templates.tasks.details.useQuery(id);

  useEffect(() => {
    if (taskDetails) {
      form.reset({
        ...taskDetails,
        projectTemplateTaskAssignees:
          taskDetails.projectTemplateTaskAssignees.map((user) => user.userId),
      });
    }
  }, [taskDetails]);

  const onCloseModal = () => {
    onClose();
    form.reset();
  };

  const taskUpdateMutation = trpc.projects.templates.tasks.update.useMutation({
    onSuccess() {
      toast.success("Succesfully updated task.");
      onCloseModal();
      form.reset();
    },
  });

  return (
    <ModalDrawer
      open={open}
      onClose={onCloseModal}
      modalClassName="max-w-[900px]"
      title="Edit task"
    >
      <Form
        {...form}
        onSubmit={(values) =>
          taskUpdateMutation.mutate({
            id: id,
            task: values,
          })
        }
      >
        {taskDetails ? (
          <ProjectTemplateTaskForm
            form={form}
            projectTemplateId={projectTemplateId}
          />
        ) : (
          <FullScreenSpinner />
        )}
        <div className="flex gap-2 justify-end w-full">
          <Button onClick={onCloseModal}>Cancel</Button>
          <Button
            loading={taskUpdateMutation.isLoading}
            type="submit"
            variant="primary"
          >
            Update task
          </Button>
        </div>
      </Form>
    </ModalDrawer>
  );
};
