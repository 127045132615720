import { z } from "zod";
import { getServerUrl } from "../getHostUrl";
import { CustomFieldInput } from "../workflows/types";

type IntegrationProviders = "zoho_books" | "telr";

type Integration = {
  name: IntegrationProviders;
  label: string;
  clientId: string;
  clientSecret: string;
  scope: string[];
  authUrl: string;
  tokenUrl: string;
  redirectUrl: string;
  inputs: CustomFieldInput[];
};

export const integrationSchemas: Record<IntegrationProviders, z.Schema> = {
  zoho_books: z.object({
    organization_id: z.string(),
    tax_id: z.string().optional(),
  }),
  telr: z.object({
    store_id: z.string(),
    auth_key: z.string(),
  }),
};

export const integrationProviders: Record<IntegrationProviders, Integration> = {
  zoho_books: {
    name: "zoho_books",
    label: "Zoho Books",
    clientId: "1000.8IHRVKQWOX4TTCQYMB0FQYMGE1YVXP",
    clientSecret: "dfef29cc0c31900559aaaf9f674f3e4d0fdb675d87",
    scope: ["ZohoBooks.fullaccess.all"],
    authUrl: "https://accounts.zoho.com/oauth/v2/auth",
    tokenUrl: "https://accounts.zoho.com/oauth/v2/token",
    redirectUrl: `${getServerUrl()}/api/integrations/zoho_books`,
    inputs: [
      {
        name: "organization_id",
        label: "Organization ID",
        type: "TEXT",
        required: true,
        placeholder: "Zoho organization id",
      },
      {
        name: "tax_id",
        label: "Tax ID",
        type: "TEXT",
        required: false,
        placeholder: "Tax id for services and products",
      },
    ],
  },
  telr: {
    name: "telr",
    label: "Telr",
    clientId: "",
    clientSecret: "",
    scope: [],
    authUrl: "",
    tokenUrl: "",
    redirectUrl: "",
    inputs: [
      {
        name: "store_id",
        label: "Store ID",
        type: "TEXT",
        required: true,
        placeholder: "Telr store id",
      },
      {
        name: "auth_key",
        label: "Auth Key",
        type: "TEXT",
        required: true,
        placeholder: "Telr auth key",
      },
    ],
  },
};
