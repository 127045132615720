import { z } from "zod";
import { address, contact } from "./crm";
import enums from "./enums";
import { lineItemProduct } from "./sales";

export const vendor = z.object({
  name: z.string(),
  taxNumber: z
    .string()
    .min(15, "Invalid tax number")
    .max(15, "Invalid tax number")
    .nullish()
    .or(z.literal("")),
  description: z.string().nullish(),
  zohoVendorId: z.string().nullish(),
  billingAddress: address.nullish(),
  externalId: z.string().nullish(),
  importOperationId: z.number().nullish(),
  primaryContact: contact
    .partial({
      firstName: true,
    })
    .omit({ clientId: true, vendorId: true })
    .nullish(),
});

export const purchaseOrder = z.object({
  date: z.date(),
  status: enums.purchaseOrderStatus.default("DRAFT"),
  contentHtml: z.string(),
  notes: z.string().nullish(),
  discount: z.coerce.number().default(0),
  templateId: z.number(),
  contactId: z.number().nullish(),
  expectedDeliveryDate: z.date().nullish(),
  markedSentOn: z.date().nullish(),
  vendorId: z.number(),
  purchaseOrderItems: z.array(lineItemProduct),
  purchaseOrderTags: z.coerce.number().array().default([]),
  purchaseOrderJobs: z.coerce.number().array().default([]),
});

export const bill = z.object({
  date: z.date(),
  status: enums.billStatuses.default("DRAFT"),
  contentHtml: z.string(),
  notes: z.string().nullish(),
  discount: z.coerce.number().default(0),
  templateId: z.number(),
  dueDate: z.date(),
  markedSentOn: z.date().nullish(),
  vendorId: z.number(),
  billItems: z.array(lineItemProduct),
  billTags: z.coerce.number().array().default([]),
});
