import { cn } from "@heffl/ui/lib/utils";
import { Link } from "lucide-react";
import { ReactNode } from "react";

type Props = {
  items: {
    label: string;
    value: string | ReactNode;
    hidden?: boolean;
    onClick?: () => void;
  }[];
  className?: string;
  labelClassName?: React.ComponentProps<"dt">["className"];
};

const InfoItemsHZ = ({ items, className, labelClassName }: Props) => {
  return (
    <div className={cn("flow-root", className)}>
      <dl className="-my-3 text-sm divide-y divide-gray-100">
        {items
          .filter((item) => !item.hidden)
          .map((item) => (
            <div
              className="grid grid-cols-1 gap-1 items-center py-3 sm:grid-cols-3 sm:gap-4"
              key={item.label}
            >
              <dt className={cn("font-medium text-gray-900", labelClassName)}>
                {item.label}
              </dt>
              <dd
                className={cn(
                  "text-gray-700 sm:col-span-2 flex items-center",
                  item.onClick && "hover:text-primary cursor-pointer"
                )}
                onClick={item.onClick}
              >
                {item.value}
                {!!item.onClick && <Link className="h-3.5 text-primary" />}
              </dd>
            </div>
          ))}
      </dl>
    </div>
  );
};

export default InfoItemsHZ;
