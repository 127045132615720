import Empty from "@/components/Empty";
import Pagination from "@/components/Pagination";
import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import { cn, formatCurrency } from "@heffl/ui/lib/utils";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import ResponsiveActionButton from "@heffl/ui/components/primitives/responsive-action-button";
import { Box, Package, SearchIcon } from "lucide-react";
import { useState } from "react";
import { useImmer } from "use-immer";
import { AddProductDrawer } from "./components/addProductDrawer";
import { EditProductDrawer } from "./components/editProductDrawer";
import usePermissions from "@/lib/hooks/usePermissions";
import RenderHtml from "@heffl/ui/components/render-html";

const Products = () => {
  const [addProduct, setAddProduct] = useState(false);
  const [editProduct, setEditProduct] = useState<undefined | number>(undefined);
  const [filters, setFilters] = useImmer({
    pageNo: 1,
    pageSize: 20,
    search: "",
  });

  const permissions = usePermissions();
  const { data, isLoading: isLoadingProducts } = trpc.products.list.useQuery({
    ...filters,
  });

  if (!permissions) return <FullScreenSpinner />;
  if (!permissions.VIEW_PRODUCTS.allowed) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <div className="flex flex-col gap-2 justify-center items-center p-3">
          <h1 className="text-3xl font-bold">
            You don&apos;t have permission to view products
          </h1>
          <p className="text-base font-medium">
            Please contact the admin to request access.
          </p>
        </div>
      </div>
    );
  }
  return (
    <Page title="Products" fullWidth>
      {addProduct && (
        <AddProductDrawer open onClose={() => setAddProduct(false)} />
      )}
      {editProduct && (
        <EditProductDrawer
          productId={editProduct}
          onClose={() => setEditProduct(undefined)}
        />
      )}
      <div className="flex justify-between">
        <div className="relative w-full sm:max-w-sm">
          <Input
            className="pl-10"
            placeholder="Search products..."
            type="search"
            value={filters.search}
            onChange={(e) => {
              setFilters((draft) => {
                draft.search = e.target.value;
              });
            }}
          />
          <SearchIcon className="absolute left-3 top-1/2 w-5 h-5 transform -translate-y-1/2" />
        </div>
        {permissions.CREATE_PRODUCTS.allowed && (
          <ResponsiveActionButton
            onClick={() => setAddProduct(true)}
            text="Product"
          />
        )}
      </div>
      <div className="grid grid-cols-1 gap-3 mt-4 sm:grid-cols-4">
        {!data ? (
          <FullScreenSpinner />
        ) : (
          data.products.map((product) => (
            <div
              key={product.id}
              className={cn(
                "sm:max-w-[330px] bg-white border shadow-sm p-2 !rounded-sm cursor-pointer"
              )}
              onClick={() => setEditProduct(product.id)}
            >
              <div className="flex gap-3">
                <div className="flex justify-center items-center w-12 h-12 bg-gray-200 rounded-sm">
                  <Package />
                </div>
                <div className="w-5/6">
                  <p className="font-semibold w-[200px]">{product.name}</p>
                  <p className="h-10 text-xs font-normal text-gray-500 sm:text-sm line-clamp-2">
                    <RenderHtml className="line-clamp-2">
                      {product.description}
                    </RenderHtml>
                  </p>
                </div>
              </div>
              <hr className="-mx-2 mt-3 border-gray-100" />
              <div className="flex justify-between items-center p-1">
                <div className="flex gap-2 items-center pt-1">
                  <p className="text-xs font-normal text-gray-600">
                    Buy: {formatCurrency(product.buyPrice || 0, "AED")}
                  </p>
                </div>
                <span className="text-xs font-medium text-black sm:text-sm">
                  {formatCurrency(product.price, "AED")}
                </span>
              </div>
            </div>
          ))
        )}
      </div>
      <div className="flex justify-end w-full">
        <Pagination
          pageNo={filters.pageNo}
          setPageNo={(pageNo) =>
            setFilters((draft) => {
              draft.pageNo = pageNo;
            })
          }
          totalPages={data?.totalPages}
        />
      </div>

      {!data?.products.length && !isLoadingProducts && (
        <Empty
          className="mt-8"
          title="No products added"
          icon={Box}
          description="Add products and quote, invoice etc.."
          actionText="Add product"
          onAction={() => setAddProduct(true)}
          buttonSize="sm"
          hideAction={!permissions.CREATE_PRODUCTS.allowed}
        />
      )}
    </Page>
  );
};

export default Products;
