import { WorkFlowApp } from "../../types";
import { sendTelegramMessageAction } from "./actions/sendMessage";

export const telegram: WorkFlowApp = {
  name: "telegram",
  label: "Telegram",
  description: "Telegram is a messaging app",
  logo: "telegram",
  actions: [sendTelegramMessageAction],
  triggers: [],
};
