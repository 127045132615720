import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn, generateUniqueColor, isMobile } from "@heffl/ui/lib/utils";
import { LucideIcon, X } from "lucide-react";
import { Tooltip } from "./tooltip";

const lightenHexColor = (hex: string, percent: number): string => {
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, "");
  // Parse the r, g, b values
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  // Increase each channel by the given percentage
  r = Math.min(255, Math.floor(r + (255 - r) * (percent / 100)));
  g = Math.min(255, Math.floor(g + (255 - g) * (percent / 100)));
  b = Math.min(255, Math.floor(b + (255 - b) * (percent / 100)));

  // Convert back to hex and return
  const toHex = (c: number): string => c.toString(16).padStart(2, "0");
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

const darkenHexColor = (hex: string, percent: number): string => {
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, "");
  // Parse the r, g, b values
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  // Decrease each channel by the given percentage
  r = Math.max(0, Math.floor(r * (1 - percent / 100)));
  g = Math.max(0, Math.floor(g * (1 - percent / 100)));
  b = Math.max(0, Math.floor(b * (1 - percent / 100)));

  // Convert back to hex and return
  const toHex = (c: number): string => c.toString(16).padStart(2, "0");
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

const badgeVariants = cva(
  "inline-flex items-center gap-x-1 whitespace-nowrap rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset",
  {
    variants: {
      variant: {
        default: "bg-blue-100 text-blue-900 ring-blue-500/30",
        neutral: "bg-gray-100 text-gray-900 ring-gray-500/30",
        success: "bg-green-100 text-green-900 ring-green-600/30",
        solidSuccess: "bg-green-500 text-white ring-green-600/30",
        error: "bg-red-100 text-red-900 ring-red-600/20",
        warning: "bg-yellow-100 text-yellow-900 ring-yellow-600/30",
        unique: "text-white ring-0",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {
  small?: boolean;
  children?: any;
  icon?: LucideIcon;
  iconClassName?: string;
  uniqueString?: string;
  background?: string;
  tooltip?: {
    content: string;
    side?: "top" | "bottom" | "left" | "right";
  };
  onRemove?: () => void;
}

function Badge({
  className,
  variant,
  children,
  small = isMobile(),
  background,
  onRemove,
  iconClassName,
  tooltip = { content: "", side: "bottom" },
  ...props
}: BadgeProps) {
  const Icon = props.icon;

  const uniqueString = props.uniqueString || children;

  const backgroundColor = () => {
    if (variant === "unique") {
      return generateUniqueColor(uniqueString, 100);
    }
    if (background) {
      return lightenHexColor(background, 70);
    }
    return "";
  };

  const borderColor = () => {
    if (variant === "unique") {
      return generateUniqueColor(uniqueString, 600);
    }
    if (background) {
      return darkenHexColor(background, 70);
    }
    return "";
  };

  const textColor = () => {
    if (variant === "unique") {
      return generateUniqueColor(uniqueString, 700);
    }
    if (background) {
      return darkenHexColor(background, 60);
    }
    return "";
  };

  const boxShadow = () => {
    if (variant === "unique") {
      return `0 0 0 0.6px ${generateUniqueColor(uniqueString, 600)}`;
    }
    if (background) {
      return `0 0 0 0.6px ${darkenHexColor(background, 40)}`;
    }
    return "";
  };

  return (
    <Tooltip content={tooltip.content} side={tooltip.side}>
      <div
        className={cn(
          badgeVariants({ variant }),
          className,
          onRemove && "pr-0 py-0",
          small && "px-1 !py-0 text-[10px] rounded-sm h-[16px]"
        )}
        onClick={(e) => {
          if (props.onClick) {
            e.stopPropagation();
            props.onClick(e);
          }
        }}
        {...props}
        style={{
          backgroundColor: backgroundColor(),
          color: textColor(),
          borderColor: borderColor(),
          boxShadow: boxShadow(),
        }}
      >
        {Icon && <Icon className={cn("w-3.5 h-3.5", iconClassName)} />}
        {children}
        {onRemove && (
          <div
            onClick={(e) => {
              e.stopPropagation();
              onRemove();
            }}
            className={cn(
              "py-1 pr-2 h-full cursor-pointer hover:text-red-500",
              small && "!py-0.5 pr-0"
            )}
          >
            <X
              className={cn("w-3.5 h-3.5", small && "w-3 h-3")}
              strokeWidth={2.5}
            />
          </div>
        )}
      </div>
    </Tooltip>
  );
}

export { Badge, badgeVariants };
