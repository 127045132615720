import { ReactNode } from "react";
import {
  UseFieldArrayAppend,
  UseFieldArrayInsert,
  UseFieldArrayRemove,
  useFieldArray,
  useFormContext,
  UseFieldArrayMove,
} from "react-hook-form";

type FormListProps = {
  name?: string;
  children: ({
    fields,
    append,
    name,
    remove,
    _name,
  }: {
    fields: Record<"id", string>[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    append: UseFieldArrayAppend<any, string>;
    remove: UseFieldArrayRemove;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    insert: UseFieldArrayInsert<any, string>;
    name: string;
    move: UseFieldArrayMove;
    _name: (index: number, fieldName: string) => string;
  }) => ReactNode;
};

const FormList = ({ name, children }: FormListProps) => {
  const { control } = useFormContext();

  const { fields, append, remove, insert, move } = useFieldArray({
    name: name || "",
    control,
  });

  if (typeof children !== "function") {
    throw new Error("Children must be a function");
  }

  const _name = (index: number, fieldName: string) => {
    return `${name}.${index}.${fieldName}`;
  };

  return children({
    fields,
    append,
    name: name || "",
    remove,
    _name,
    insert,
    move,
  });
};

export default FormList;
