import { cn } from "@heffl/ui/lib/utils";
import { ReactNode } from "react";

const TimelineItemDescription = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <p className={cn("text-sm text-muted-foreground", className)}>{children}</p>
  );
};

type TimelineItemProps = {
  title: ReactNode;
  bullet?: ReactNode;
  description?: ReactNode;
  isLast?: boolean;
  isActive: boolean;
  isActiveBullet: boolean;
  className?: string;
  bulletSize: number;
  lineSize: number;
  onClick?: () => void;
  titleSuffix?: ReactNode;
};

const TimelineItemBullet = ({
  children,
  isActive,
  bulletSize,
  lineSize,
}: {
  children?: ReactNode;
  isActive?: boolean;
  bulletSize: number;
  lineSize: number;
}) => {
  return (
    <div
      className={cn(
        "absolute border top-0 rounded-full bg-background flex items-center justify-center",
        isActive && "border-primary"
      )}
      style={{
        width: bulletSize,
        height: bulletSize,
        left: -bulletSize / 2 - lineSize / 2,
        borderWidth: lineSize,
      }}
      aria-hidden="true"
    >
      {children}
    </div>
  );
};

const TimelineItem = ({
  description,
  bullet,
  title,
  isLast,
  isActive,
  isActiveBullet,
  bulletSize,
  className,
  lineSize,
  onClick,
  titleSuffix,
  ...props
}: TimelineItemProps) => {
  return (
    <li
      className={cn(
        "pl-8 pb-4 relative border-l list-none",
        isLast && "border-l-transparent pb-0",
        isActive && !isLast && "border-l-primary",
        className
      )}
      style={{
        borderLeftWidth: lineSize,
      }}
      {...props}
    >
      <TimelineItemBullet
        lineSize={lineSize}
        bulletSize={bulletSize}
        isActive={isActiveBullet}
      >
        {bullet}
      </TimelineItemBullet>
      <div className="flex gap-2 items-center">
        <div
          className={cn(
            "mb-1 text-sm leading-none",
            onClick && "cursor-pointer hover:text-primary"
          )}
          onClick={onClick}
        >
          {title}
        </div>
        {titleSuffix}
      </div>
      <TimelineItemDescription>{description}</TimelineItemDescription>
    </li>
  );
};

type TimelinePropsItem = Omit<
  TimelineItemProps,
  "isActive" | "isActiveBullet" | "bulletSize" | "lineSize"
> & {
  bulletSize?: number;
  description?: ReactNode;
};

type TimelineProps = {
  items: TimelinePropsItem[];
  activeItem: number;
  bulletSize?: number;
  lineSize?: number;
};

/*
  No bullet or line is active when activeItem is -1
  First bullet is active only if activeItem is 0 or more
  First line is active only if activeItem is 1 or more
*/

export const Timeline = ({
  items,
  activeItem,
  bulletSize = 16,
  lineSize = 2,
}: TimelineProps) => {
  return (
    <ul
      style={{
        paddingLeft: bulletSize / 2,
      }}
    >
      {items.map((item, index) => {
        return (
          <TimelineItem
            key={index}
            title={item.title}
            bullet={item.bullet}
            isLast={index === items.length - 1}
            isActive={activeItem === -1 ? false : activeItem >= index + 1}
            isActiveBullet={activeItem === -1 ? false : activeItem >= index}
            bulletSize={bulletSize}
            lineSize={lineSize}
            description={item.description}
            onClick={item.onClick}
            titleSuffix={item.titleSuffix}
          />
        );
      })}
    </ul>
  );
};
