import React, { useState } from "react";
import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import DataGrid from "@/components/dataGrid/DataGrid";
import { Banknote } from "lucide-react";
import { formatCurrency } from "@heffl/ui/lib/utils";
import FilterBar from "@/components/filters";
import { useParamsState } from "@/lib/hooks/useParamsState";
import dayjs from "dayjs";
import { Card } from "@heffl/ui/components/primitives/card";
import { useNavigate } from "react-router-dom";

interface PageFilters {
  dates?: [Date, Date];
  clients: number[];
  paymentMethodId: number[];
}

const PaymentsReceivedReport: React.FC = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useParamsState<PageFilters>({
    dates: [dayjs().startOf("month").toDate(), dayjs().endOf("month").toDate()],
    clients: [],
    paymentMethodId: [],
  });

  const [clientSearch, setClientSearch] = useState("");

  const { data: report, isLoading } =
    trpc.sales.reports.paymentsReceivedReport.useQuery({
      clientId: filters.clients,
      paymentMethodId: filters.paymentMethodId,
      dates: filters.dates,
    });
  const { data: clients } = trpc.clients.list.useQuery({
    search: clientSearch,
    pageNo: 1,
    pageSize: 10,
  });
  const { data: paymentMethods } =
    trpc.invoices.payments.methods.list.useQuery();

  return (
    <Page title="Payments Received Report" className="!p-0" fullWidth>
      <FilterBar
        className="px-3 pt-3"
        filters={[
          {
            key: "date",
            type: "date-range",
            label: "Date",
            value: filters.dates,
            onChange: (value) => setFilters({ dates: value }),
          },
          {
            key: "clients",
            type: "checkbox",
            label: "Clients",
            value: filters.clients,
            showSearch: true,
            onChange: (value) => setFilters({ clients: value as number[] }),
            onSearch: (value) => setClientSearch(value),
            options: clients?.clients.map((client) => ({
              label: client.name,
              value: client.id,
            })),
          },
          {
            key: "paymentMethod",
            type: "checkbox",
            label: "Payment Method",
            value: filters.paymentMethodId,
            onChange: (value) =>
              setFilters({ paymentMethodId: value as number[] }),
            options: paymentMethods?.map((method) => ({
              label: method.name,
              value: method.id,
            })),
          },
        ]}
      />
      {report && (
        <div className="grid grid-cols-2 gap-4 p-3 sm:grid-cols-3 lg:grid-cols-6">
          <Card>
            <h3 className="text-xs font-medium text-gray-500">
              Total Payments Received
            </h3>
            <p className="mt-1 text-xl font-semibold text-gray-900">
              {formatCurrency(report.summary.totalPaymentsReceived, "AED")}
            </p>
          </Card>
          <Card>
            <h3 className="text-xs font-medium text-gray-500">
              Total Payments
            </h3>
            <p className="mt-1 text-xl font-semibold text-gray-900">
              {report.summary.totalPayments}
            </p>
          </Card>
          <Card>
            <h3 className="text-xs font-medium text-gray-500">
              Unique Clients
            </h3>
            <p className="mt-1 text-xl font-semibold text-gray-900">
              {report.summary.uniqueClients}
            </p>
          </Card>
          <Card>
            <h3 className="text-xs font-medium text-gray-500">
              Average Payment Amount
            </h3>
            <p className="mt-1 text-xl font-semibold text-gray-900">
              {formatCurrency(report.summary.averagePaymentAmount, "AED")}
            </p>
          </Card>
        </div>
      )}
      <DataGrid
        rowKey="id"
        name="paymentsReceivedReport"
        label="Payments"
        className="h-[calc(100vh-260px)] mt-12"
        loading={isLoading}
        empty={{
          icon: Banknote,
          title: "No Payments",
          description: "No payments found for the selected filters.",
          buttonSize: "sm",
        }}
        rows={report?.payments || []}
        columns={[
          {
            key: "date",
            name: "Date",
            width: 150,
            renderCell: ({ row }) => dayjs(row.date).format("DD/MM/YYYY"),
          },
          {
            key: "number",
            name: "Number",
            width: 100,
            renderCell: ({ row }) => row.number,
          },
          {
            key: "client",
            name: "Client",
            width: 140,
            renderCell: ({ row }) => row.clients.name,
          },
          {
            key: "invoiceNumber",
            name: "Invoice#",
            width: 100,
            renderCell: ({ row }) => (
              <div className="flex gap-2 items-center">
                {row.paymentAllocations.map((allocation) => (
                  <span
                    key={allocation.id}
                    className="text-blue-500 cursor-pointer hover:underline"
                    onClick={() => {
                      if (!allocation.invoices) return;
                      navigate(
                        `/sales/invoices/details/${allocation.invoices.id}`
                      );
                    }}
                  >
                    {allocation.type === "OPENING_BALANCE"
                      ? "Opening Balance"
                      : allocation.invoices?.number}
                  </span>
                ))}
              </div>
            ),
          },
          {
            key: "paymentMethod",
            name: "Mode",
            width: 100,
            renderCell: ({ row }) => row.paymentMethods.name,
          },
          {
            key: "amount",
            name: "Amount",
            width: 140,
            renderCell: ({ row }) => formatCurrency(row.amount, "AED"),
          },
          {
            key: "referenceNumber",
            name: "Reference Number",
            width: 130,
            renderCell: ({ row }) => row.refNo,
          },
        ]}
      />
    </Page>
  );
};

export default PaymentsReceivedReport;
