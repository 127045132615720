import { create } from "zustand";

type NavState = {
  selectedPage: string;
  setSelectedPage: (tab: string) => void;
};

const useNav = create<NavState>((set) => ({
  selectedPage: "",
  setSelectedPage: (page: string) => set({ selectedPage: page }),
}));

export default useNav;
