import { Prisma } from "@prisma/client";

type FormatCustomFields = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customFields: any | null | undefined;
  customFieldFields: Prisma.customFieldsGetPayload<null>[];
};

export const formatCustomFields = ({
  customFields,
  customFieldFields,
}: FormatCustomFields) => {
  if (!customFields || Object.keys(customFields).length === 0) {
    return {};
  }

  return Object.entries(customFields).reduce((acc, [key, value]) => {
    const field = customFieldFields.find((f) => f.name === key);
    if (!field) {
      acc[key] = undefined;
      return acc;
    }
    if (field.dataType === "DATE") {
      acc[key] = new Date(value as string);
    } else if (field.dataType === "NUMBER") {
      acc[key] = value ? Number(value as string) : undefined;
    } else {
      acc[key] = value as string;
    }
    return acc;
  }, {} as Record<string, unknown>);
};
