import {
  ClientInput,
  ContactInput,
  UserInput,
} from "@/components/FormComponents";
import LineItemSelector from "@/components/line-item-selector";
import { RouterOutputs, trpc } from "@/helpers/trpc";
import usePermissions from "@/lib/hooks/usePermissions";
import { getJobName } from "@/pages/field-service/schedules/calendar";
import { calculateLineItems } from "@heffl/server/src/helpers/lineItems/calculateLineItems";
import { dynamicTags } from "@heffl/server/src/helpers/templates/dynamicTags";
import Schemas from "@heffl/server/src/schemas";
import RichTextEditor from "@heffl/ui/components/primitives/RichTextEditor";
import { Button } from "@heffl/ui/components/primitives/button";
import Select from "@heffl/ui/components/primitives/creatable-select";
import { DatePicker } from "@heffl/ui/components/primitives/datepicker";
import { FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import { MultiSelect } from "@heffl/ui/components/primitives/multi-select";
import { Textarea } from "@heffl/ui/components/primitives/textarea";
import { formatCurrency } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import { Pencil, Plus } from "lucide-react";
import { useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { z } from "zod";

const InvoiceForm = ({
  form,
  edit = false,
  tags,
}: {
  form: UseFormReturn<z.infer<typeof Schemas.sales.invoice>, unknown>;
  edit?: boolean;
  tags?: RouterOutputs["tags"]["list"];
}) => {
  const templateId = form.watch("templateId");
  const clientId = form.watch("clientId");
  const lineItems = form.watch("invoiceProducts");
  const discount = form.watch("discount");
  const fsJobId = form.watch("fsJobInvoices.fsJobId");

  const permissions = usePermissions();

  const [showEditContent, setShowEditContent] = useState(false);

  const { data: templates } = trpc.templates.list.useQuery({
    type: "INVOICE",
  });
  const { data: projects } = trpc.projects.list.useQuery(
    {
      clients: clientId ? [clientId] : [],
    },
    {
      enabled: !!clientId,
    }
  );
  const { data: fsJobs } = trpc.fieldService.jobs.list.useQuery(
    {
      clients: [clientId],
      page: 1,
      pageSize: 100,
    },
    {
      enabled: !!clientId,
    }
  );

  const { data: fsSchedulesData } = trpc.fieldService.schedules.list.useQuery(
    {
      fsJobId: fsJobId || undefined,
      status: [
        "COMPLETED",
        "CONFIRMED",
        "IN_PROGRESS",
        "SCHEDULED",
        "ON_MY_WAY",
        "ARRIVED",
      ],
      orderBy: {
        startDate: "asc",
      },
    },
    {
      enabled: !!fsJobId,
    }
  );

  const fsSchedules = fsSchedulesData?.schedules || [];

  const { data: templateDetails } = trpc.templates.details.useQuery(
    Number(templateId),
    {
      enabled: !!templateId && !edit,
    }
  );

  useEffect(() => {
    if (templates?.length && !edit) {
      form.setValue("templateId", templates[0].id);
    }
  }, [templates]);

  useEffect(() => {
    if (templateDetails && !edit) {
      form.setValue("contentHtml", templateDetails.contentHtml || "");
    }
  }, [templateDetails, form, edit]);

  if (!templates) return <FullScreenSpinner />;

  const lineItemsValues = calculateLineItems({ lineItems, discount });

  const includeItemsVat = () => {
    // decrease vat of each items
    const updatedLineItems = lineItems.map((item) => {
      const vatDeductedAmount: number = (item.price * 100) / (100 + item.tax);
      return {
        ...item,
        price: Number(vatDeductedAmount.toFixed(2)),
      };
    });
    form.setValue("invoiceProducts", updatedLineItems, {
      shouldDirty: true,
    });
  };

  if (!permissions) return <FullScreenSpinner />;

  return (
    <div className="space-y-4">
      {!edit && (
        <FormField name="templateId" label="Template" className="sm:w-64">
          <Select
            placeholder="Select invoice template"
            options={templates.map((template) => ({
              label: template.name,
              value: template.id,
            }))}
          />
        </FormField>
      )}
      <div className="grid grid-cols-1 gap-2 sm:grid-cols-4">
        <FormField name="date" label="Date">
          <DatePicker />
        </FormField>
        <FormField name="dueDate" label="Due date">
          <DatePicker />
        </FormField>
        <FormField name="invoiceTags" label="Tags">
          <MultiSelect
            placeholder="Add tags"
            options={
              tags?.map((tag) => ({
                label: tag.name,
                value: tag.id,
              })) || []
            }
          />
        </FormField>
        <UserInput
          name="salesPersonId"
          label="Sales person"
          defaultCurrentUser={!edit}
          type={["STAFF", "ADMIN", "OWNER", "SUPER_ADMIN"]}
          allowClear
        />
      </div>
      <div className="grid grid-cols-1 gap-2 sm:grid-cols-4">
        <ClientInput
          className="w-full"
          name="clientId"
          label="Client"
          disabled={!permissions.CREATE_INVOICES.allowed}
          onAddModalClose={(id) =>
            id &&
            form.setValue("clientId", id, {
              shouldDirty: true,
            })
          }
        />
        <ContactInput
          name="contactId"
          label="Contact"
          clientId={clientId}
          fetchEnabled={!!clientId}
          onAddModalClose={(id) => id && form.setValue("contactId", id)}
          disabled={!clientId || !permissions.CREATE_INVOICES.allowed}
          setDefault={!edit && !!clientId}
          allowClear
        />
        {!!(projects && projects.projects.length) && (
          <FormField name="projectInvoices.projectId" label="Project">
            <Select
              disabled={!permissions.CREATE_INVOICES.allowed}
              placeholder="Select project"
              options={projects?.projects.map((project) => ({
                label: project.title,
                value: project.id,
              }))}
            />
          </FormField>
        )}

        {!!(fsJobs && fsJobs.jobs.length) && (
          <FormField name="fsJobInvoices.fsJobId" label="Job">
            <Select
              disabled={!permissions.CREATE_INVOICES.allowed}
              allowClear
              placeholder="Select job"
              options={fsJobs?.jobs.map((job) => ({
                label: getJobName(job.fsJobServices),
                value: job.id,
              }))}
            />
          </FormField>
        )}
        {!!(fsSchedules && fsSchedules.length) && (
          <FormField name="fsJobInvoices.fsScheduleId" label="Schedules">
            <Select
              disabled={!permissions.CREATE_INVOICES.allowed}
              allowClear
              placeholder="Select schedule"
              options={fsSchedules?.map((schedule) => ({
                label: `${dayjs(schedule.startDate).format("DD MMM YYYY")} ${
                  !schedule.timeDisabled
                    ? dayjs(schedule.startDate).format(": hh:mm a")
                    : ""
                }`,
                value: schedule.id,
              }))}
            />
          </FormField>
        )}
      </div>
      <div className="mb-6">
        <FormField name="invoiceProducts" label="">
          <LineItemSelector
            required={true}
            isEdit={edit}
            disabled={
              !permissions.CREATE_INVOICES.allowed &&
              permissions.CONVERT_INVOICES.allowed
            }
          />
        </FormField>
      </div>
      <div className="flex justify-end w-full">
        <table className="gap-8 text-base border-separate border-spacing-y-2">
          <tr>
            <td className="w-52">Subtotal</td>
            <td className="text-right">
              {formatCurrency(lineItemsValues.subTotal, "AED")}
            </td>
          </tr>
          <tr>
            <td className="w-52">Discount</td>
            <td className="text-right">
              <FormField name="discount">
                <Input
                  disabled={!permissions.CREATE_INVOICES.allowed}
                  suffix="AED"
                  className="w-[70px]"
                />
              </FormField>
            </td>
          </tr>
          <tr>
            <td className="flex gap-2 items-center w-52">
              VAT
              <Button
                onClick={includeItemsVat}
                size="sm"
                variant="primaryOutline"
                disabled={!permissions.CREATE_INVOICES.allowed}
              >
                <Plus className="h-4" />
                Include
              </Button>
            </td>
            <td className="text-right">
              {formatCurrency(lineItemsValues.totalTax, "AED")}
            </td>
          </tr>
          <tr>
            <td className="w-52">Total</td>
            <td className="font-semibold text-right">
              {formatCurrency(lineItemsValues.total, "AED")}
            </td>
          </tr>
        </table>
      </div>
      <FormField name="notes" label="Notes" className="w-1/2">
        <Textarea />
      </FormField>
      {!showEditContent && (
        <Button
          variant="primaryOutline"
          onClick={() => setShowEditContent(true)}
        >
          <Pencil className="h-4" /> Edit content
        </Button>
      )}
      {showEditContent && (
        <FormField name="contentHtml" label="Content">
          <RichTextEditor
            height="500px"
            dynamicTags={[
              dynamicTags.Client,
              dynamicTags.Contact,
              dynamicTags.Quote,
            ]}
          />
        </FormField>
      )}
    </div>
  );
};

export default InvoiceForm;
