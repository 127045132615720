import enums from "@heffl/server/src/schemas/enums";
import z from "zod";

const VITE_S3_ENDPOINT = "https://heffl.s3.me-central-1.amazonaws.com";

export const getS3URL = (
  folder: z.infer<typeof enums.S3Folders>,
  name: string
) => {
  return `${VITE_S3_ENDPOINT}/${folder}/${name}`;
};
