import Page from "@/components/page";
import {
  Tabs,
  TabsList,
  TabsTrigger,
} from "@heffl/ui/components/primitives/tabs";
import { useState } from "react";
import CRMPersonalDashboard from "./dashboard";
import CRMAdminDashboard from "./admin";

const CRMDashboard = () => {
  const [selectedTab, setSelectedTab] = useState("personal");
  return (
    <Page>
      <Tabs value={selectedTab} className="mb-3 w-fit">
        <TabsList className="grid w-full grid-cols-2">
          <TabsTrigger
            value="personal"
            onClick={() => setSelectedTab("personal")}
          >
            Personal
          </TabsTrigger>
          <TabsTrigger value="admin" onClick={() => setSelectedTab("admin")}>
            Admin
          </TabsTrigger>
        </TabsList>
      </Tabs>
      {selectedTab === "personal" ? (
        <CRMPersonalDashboard />
      ) : (
        <CRMAdminDashboard />
      )}
    </Page>
  );
};

export default CRMDashboard;
