import DataTable from "@/components/DataTable";
import Empty from "@/components/Empty";
import { ClientInput, SearchInput } from "@/components/FormComponents";
import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import usePermissions from "@/lib/hooks/usePermissions";
import { formatName, isMobile } from "@heffl/ui/lib/utils";
import Schemas from "@heffl/server/src/schemas";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import { Label } from "@heffl/ui/components/primitives/label";
import { PhoneInput } from "@heffl/ui/components/primitives/phone-input";
import ResponsiveActionButton from "@heffl/ui/components/primitives/responsive-action-button";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import { zodResolver } from "@hookform/resolvers/zod";
import { Mail, PencilLine, Phone, Trash2Icon, UserCircle2 } from "lucide-react";
import { useEffect, useState } from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useImmer } from "use-immer";
import { z } from "zod";
import MobileContactCard from "./MobileContactCard";

const ContactForm = ({
  form,
}: {
  form: UseFormReturn<z.infer<typeof Schemas.crm.contact>, unknown>;
}) => {
  return (
    <>
      <Label>Name</Label>
      <div className="flex gap-2">
        <FormField name="firstName">
          <Input placeholder="First name" autoFocus />
        </FormField>
        <FormField name="lastName">
          <Input placeholder="Last name" />
        </FormField>
      </div>
      <div className="grid gap-2 sm:grid-cols-2">
        <FormField name="mobile" label="Mobile">
          <PhoneInput placeholder="Mobile" defaultCountry="AE" />
        </FormField>
        <FormField name="email" label="Email">
          <Input placeholder="Email" prefix={<Mail className="h-5" />} />
        </FormField>
      </div>
      <div className="flex flex-col gap-2 sm:flex-row">
        <ClientInput
          className="w-full sm:w-72"
          name="clientId"
          label="Client"
          onAddModalClose={(id) =>
            id &&
            form.setValue("clientId", id, {
              shouldDirty: true,
            })
          }
        />
        <FormField name="jobTitle" label="Job title">
          <Input placeholder="Job title" />
        </FormField>
      </div>
    </>
  );
};

// TODO : add vendorid to form
export const AddContactDrawer = ({
  open,
  onClose,
  defaultValues,
}: {
  open: boolean;
  onClose: (id: number | undefined) => void;
  defaultValues?: Partial<z.infer<typeof Schemas.crm.contact>>;
}) => {
  const createContactMutation = trpc.contacts.add.useMutation({
    onSuccess(newContact) {
      toast.success("Succesfully added contact.");
      form.reset();
      onClose(newContact.id);
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  const form = useForm<z.infer<typeof Schemas.crm.contact>>({
    resolver: zodResolver(Schemas.crm.contact),
    defaultValues: {},
  });

  useEffect(() => {
    if (defaultValues) {
      form.reset(defaultValues);
    }
  }, [defaultValues, form]);

  return (
    <ModalDrawer
      className="max-w-lg"
      open={open}
      onClose={() => {
        form.reset();
        onClose(undefined);
      }}
      title="Add Contact"
    >
      <Form
        {...form}
        onSubmit={(values) => {
          createContactMutation.mutate(values);
        }}
      >
        <ContactForm form={form} />
        <Button
          loading={createContactMutation.isLoading}
          className="mt-2"
          type="submit"
          variant="primary"
          size="md"
        >
          Add contact
        </Button>
      </Form>
    </ModalDrawer>
  );
};

export const EditContactDrawer = ({
  id,
  onClose,
}: {
  id: number;
  onClose: () => void;
}) => {
  const confirm = useConfirm();

  const { data: contact } = trpc.contacts.details.useQuery(id, {
    refetchOnWindowFocus: false,
  });
  const updateContactMutation = trpc.contacts.update.useMutation({
    onSuccess() {
      toast.success("Succesfully updated contact.");
      onClose();
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const deleteContactMutation = trpc.contacts.delete.useMutation({
    onSuccess() {
      toast.success("Succesfully deleted contact.");
      form.reset();
      onClose();
    },
    onError(err) {
      toast.error(
        err.message || "Contact has associated data and cannot be deleted."
      );
    },
  });

  const form = useForm<z.infer<typeof Schemas.crm.contact>>({
    resolver: zodResolver(Schemas.crm.contact),
  });

  useEffect(() => {
    if (contact) {
      form.reset({
        ...contact,
      });
    }
  }, [contact, form]);

  const onSubmit = () => {
    updateContactMutation.mutate({
      id,
      contact: form.getValues(),
    });
  };

  return (
    <ModalDrawer
      open={true}
      className="w-full sm:max-w-lg"
      title="Edit contact"
      onClose={() => {
        form.reset();
        onClose();
      }}
      footer={
        <div className="flex gap-2 pt-4 w-full">
          <Button
            loading={deleteContactMutation.isLoading}
            icon={Trash2Icon}
            variant="destructive"
            onClick={async () => {
              const confirmed = await confirm({
                title: "Are you sure you want to delete this contact?",
                body: "This will delete the contact and all associated data.",
              });
              confirmed && deleteContactMutation.mutate(id);
            }}
          />
          <Button
            loading={updateContactMutation.isLoading}
            className="w-full"
            variant="primary"
            onClick={onSubmit}
            type="submit"
          >
            Update
          </Button>
        </div>
      }
    >
      {!contact ? (
        <FullScreenSpinner />
      ) : (
        <Form {...form} onSubmit={onSubmit}>
          <ContactForm form={form} />
        </Form>
      )}
    </ModalDrawer>
  );
};

const Contacts = () => {
  const [editContact, setEditContact] = useState<undefined | number>(undefined);
  const [addContact, setAddContact] = useState(false);
  const [filters, setFilters] = useImmer({
    pageNo: 1,
    pageSize: 20,
    search: "",
  });

  const { data: contactsData, isLoading: isLoadingContacts } =
    trpc.contacts.list.useQuery({
      ...filters,
    });
  const navigate = useNavigate();
  const permissions = usePermissions();

  if (permissions && !permissions.VIEW_CONTACTS.allowed) {
    return (
      <Page fullWidth title="Contacts">
        <div className="flex flex-col justify-center items-center h-screen">
          <div className="flex flex-col gap-2 justify-center items-center p-3">
            <h1 className="text-3xl font-bold">
              You don&apos;t have permission to view contacts
            </h1>
            <p className="text-base font-medium">
              Please contact the admin to request access.
            </p>
          </div>
        </div>
      </Page>
    );
  }

  return (
    <Page fullWidth title="Contacts">
      {editContact && (
        <EditContactDrawer
          id={editContact}
          onClose={() => setEditContact(undefined)}
        />
      )}
      <AddContactDrawer
        open={addContact}
        onClose={() => setAddContact(false)}
      />

      <div className="flex flex-row justify-between">
        <SearchInput
          placeholder="Search contacts..."
          value={filters.search}
          onChange={(value) =>
            setFilters((f) => {
              f.search = value;
            })
          }
        />
        <div className="space-x-3">
          {permissions && permissions.CREATE_CONTACTS.allowed && (
            <ResponsiveActionButton
              onClick={() => setAddContact(true)}
              text="Contact"
            />
          )}
        </div>
      </div>

      {contactsData ? (
        isMobile() ? (
          <div className="flex flex-col gap-2 mt-4 mb-[100px]">
            {contactsData.contacts.map((contact) => (
              <MobileContactCard data={contact} key={contact.id} />
            ))}
          </div>
        ) : (
          <div>
            <DataTable
              data={contactsData?.contacts || []}
              columns={[
                {
                  title: "Id",
                  render: (row) => `#${row.id}`,
                  className: "w-[40px]",
                },
                {
                  title: "Name",
                  className: "w-[400px]",
                  render: (contact) => (
                    <div
                      onClick={() =>
                        navigate(`/crm/contacts/details/${contact.id}`)
                      }
                      className="!font-medium relative flex items-center group cursor-pointer gap-1"
                    >
                      <UserCircle2 className="object-cover p-1 w-6 h-6 bg-blue-50 rounded-full" />
                      {formatName(contact)}
                      <div className="absolute backdrop-blur-xl flex gap-1.5 right-12 opacity-0 group-hover:opacity-100 bg-white/30">
                        <PencilLine className="h-4 text-gray-600" />
                      </div>
                    </div>
                  ),
                },
                {
                  title: "Phone",
                  icon: Phone,
                  render: (contact) => (
                    <span className="font-medium text-gray-700">
                      {contact.mobile || "No phone"}
                    </span>
                  ),
                },
                {
                  title: "Email",
                  icon: Mail,
                  render: (contact) => (
                    <span className="font-medium text-gray-700">
                      {contact.email || "No email"}
                    </span>
                  ),
                },
                {
                  title: "Tags",
                  render: () => <span></span>,
                },
              ]}
              rowKey="id"
              className="mt-6"
              empty={{
                title: "No contacts added",
                icon: UserCircle2,
                description: "Please add a contact to the system",
              }}
              pagination={{
                pageNo: filters.pageNo,
                totalPages: contactsData?.totalPages,
                setPageNo: (pageNo) =>
                  setFilters((f) => {
                    f.pageNo = pageNo;
                  }),
              }}
            />
          </div>
        )
      ) : (
        <FullScreenSpinner />
      )}

      {!contactsData?.contacts.length && !isLoadingContacts && (
        <Empty
          className="mt-8"
          title="No contacts added"
          icon={UserCircle2}
          description="Please add a contact to the system"
          actionText="Add contact"
          onAction={() => setAddContact(true)}
          buttonSize="sm"
          hideAction={permissions && !permissions.CREATE_CONTACTS.allowed}
        />
      )}
    </Page>
  );
};

export default Contacts;
