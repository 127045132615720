import React, { useMemo, useState } from "react";
import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import DataGrid from "@/components/dataGrid/DataGrid";
import { TargetIcon } from "lucide-react";
import { formatCurrency, formatName } from "@heffl/ui/lib/utils";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import FilterBar from "@/components/filters";
import { useParamsState } from "@/lib/hooks/useParamsState";

type ProjectFilter = {
  pipelineId: number | null;
  clients: number[];
  tags: number[];
  assignees: number[];
  statuses: ("OPEN" | "CLOSED")[];
  startDate: [Date, Date] | undefined;
  endDate: [Date, Date] | undefined;
};

const ProjectSummaryReport: React.FC = () => {
  const [filters, setFilters] = useParamsState<ProjectFilter>({
    pipelineId: null,
    clients: [],
    tags: [],
    assignees: [],
    statuses: [],
    startDate: undefined,
    endDate: undefined,
  });
  const [clientSearch, setClientSearch] = useState("");

  const { data: clients } = trpc.clients.list.useQuery({
    search: clientSearch,
    pageNo: 1,
    pageSize: 10,
  });
  const { data: tags } = trpc.tags.list.useQuery({
    type: "PROJECT",
  });
  const { data: users } = trpc.users.list.useQuery({
    excludeType: ["FIELD_STAFF"],
  });

  const { data: pipelines } = trpc.projects.pipelines.list.useQuery();

  const projectsFilters = useMemo(
    () => ({
      pipelines: filters.pipelineId ? [filters.pipelineId] : [],
      clients: filters.clients,
      tags: filters.tags,
      assignees: filters.assignees,
      statuses: filters.statuses,
      archived: false,
      startDate: filters.startDate,
      endDate: filters.endDate,
    }),
    [filters]
  );

  const { data: project } =
    trpc.projects.reports.projectSummaryReport.useQuery(projectsFilters);
  const { data: currentUser } = trpc.users.currentUser.useQuery();

  const navigate = useNavigate();

  if (currentUser?.type !== "ADMIN" && currentUser?.type !== "SUPER_ADMIN")
    return <div>You are not authorized to view this page.</div>;
  return (
    <Page title="Project Summary Report" fullWidth>
      <h1 className="mb-6 text-2xl font-bold">Project Summary Report</h1>
      <div className="flex flex-wrap items-center py-3 w-full">
        <FilterBar
          className="px-3"
          filters={[
            {
              key: "pipelineId",
              type: "checkbox",
              multiple: false,
              label: "Pipeline",
              value: filters.pipelineId ? [filters.pipelineId] : [],
              onChange: (value) =>
                setFilters({
                  pipelineId: value.length > 0 ? Number(value[0]) : null,
                }),
              options: pipelines?.map((pipeline) => ({
                label: pipeline.name,
                value: pipeline.id,
              })),
            },
            {
              key: "clients",
              type: "checkbox",
              label: "Clients",
              showSearch: true,
              value: filters.clients,
              onChange: (value) => setFilters({ clients: value as number[] }),
              options: clients?.clients.map((client) => ({
                label: client.name,
                value: client.id,
              })),
              onSearch: (e) => setClientSearch(e),
            },
            {
              key: "assignees",
              type: "checkbox",
              label: "Assignees",
              value: filters.assignees,
              onChange: (value) => setFilters({ assignees: value as number[] }),
              options: users?.map((user) => ({
                label: formatName(user),
                value: user.id,
              })),
            },
            {
              key: "statuses",
              type: "checkbox",
              label: "Status",
              value: filters.statuses,
              onChange: (value) =>
                setFilters({ statuses: value as ("OPEN" | "CLOSED")[] }),
              options: ["OPEN", "CLOSED"].map((status) => ({
                label: status,
                value: status,
              })),
            },
            {
              key: "startDate",
              type: "date-range",
              label: "Start Date",
              value: filters.startDate,
              onChange: (value) =>
                setFilters({ startDate: value as [Date, Date] }),
            },
            {
              key: "endDate",
              type: "date-range",
              label: "End Date",
              value: filters.endDate,
              onChange: (value) =>
                setFilters({ endDate: value as [Date, Date] }),
            },
            {
              key: "tags",
              type: "checkbox",
              label: "Tags",
              value: filters.tags,
              onChange: (value) => setFilters({ tags: value as number[] }),
              options: tags?.map((tag) => ({
                label: tag.name,
                value: tag.id,
              })),
            },
          ]}
        />
      </div>
      <DataGrid
        rowKey="id"
        name="projectSummaryReport"
        className="h-[calc(100vh-162px-var(--header-height))]"
        label="Project Summary"
        loading={!project}
        empty={{
          icon: TargetIcon,
          title: "No projects",
          description:
            "There are no projects to display in the summary report.",
        }}
        rows={project}
        columns={[
          {
            key: "title",
            name: "Project Name",
            width: 200,
            renderCell: ({ row }) => (
              <div
                className="truncate rounded-md cursor-pointer hover:text-primary-600"
                onClick={() => navigate(`/projects/details/${row.id}`)}
              >
                {row.title}
              </div>
            ),
          },
          {
            key: "projectPipelines",
            name: "Project Pipelines",
            width: 150,
            renderCell: ({ row }) => row.projectPipelines.name,
          },
          {
            key: "clients",
            name: "Client",
            width: 150,
            renderCell: ({ row }) => row.clients?.name || "N/A",
          },
          {
            key: "projectStatus",
            name: "Project Status",
            width: 150,
            renderCell: ({ row }) => row.status,
          },
          {
            key: "startDate",
            name: "Start Date",
            width: 150,
            renderCell: ({ row }) => dayjs(row.startDate).format("DD/MM/YYYY"),
          },
          {
            key: "endDate",
            name: "End Date",
            width: 150,
            renderCell: ({ row }) =>
              row.endDate ? dayjs(row.endDate).format("DD/MM/YYYY") : "N/A",
          },
          {
            key: "budget",
            name: "Budget",
            width: 150,
            renderCell: ({ row }) => `${formatCurrency(row.budget, "AED")}`,
          },
          {
            key: "totalHours",
            name: "Total Hours",
            width: 120,
            renderCell: ({ row }) =>
              `${Math.floor(row.totalHours)} Hour${
                row.totalHours >= 2 ? "s" : ""
              }${
                Math.round((row.totalHours % 1) * 60) > 0
                  ? ` ${Math.round((row.totalHours % 1) * 60)} Minute${
                      Math.round((row.totalHours % 1) * 60) > 1 ? "s" : ""
                    }`
                  : ""
              }`,
          },
          {
            key: "staffCost",
            name: "Staff Cost",
            width: 150,
            renderCell: ({ row }) => `${formatCurrency(row.staffCost, "AED")}`,
          },
          {
            key: "expenses",
            name: "Expenses",
            width: 150,
            renderCell: ({ row }) => `${formatCurrency(row.expenses, "AED")}`,
          },
          {
            key: "actualCost",
            name: "Actual Cost",
            width: 150,
            renderCell: ({ row }) => `${formatCurrency(row.actualCost, "AED")}`,
          },
          {
            key: "profit",
            name: "Profit",
            width: 150,
            renderCell: ({ row }) => `${formatCurrency(row.profit, "AED")}`,
          },
          {
            key: "profitPercentage",
            name: "Profit Percentage",
            width: 150,
            renderCell: ({ row }) => `${row.profitPercentage}%`,
          },
        ]}
      />
    </Page>
  );
};

export default ProjectSummaryReport;
