import { z } from "zod";

const scheduleStatus = z.enum([
  "SCHEDULED",
  "CONFIRMED",
  "ARRIVED",
  "ON_MY_WAY",
  "IN_PROGRESS",
  "COMPLETED",
  "CANCELLED",
]);

const projectTaskStatusTypes = z.enum([
  "OPEN",
  "IN_PROGRESS",
  "ON_HOLD",
  "COMPLETED",
]);
const notificationTypes = z.enum([
  "REMINDER",
  "TASK_ASSIGNED",
  "TASK_COMPLETED",
  "ACTIVITY_ASSIGNED",
  "ACTIVITY_COMPLETED",
  "PROJECT_TASK_ASSIGNED",
  "PROJECT_ACTIVITY_ASSIGNED",
  "LEAD_ASSIGNED",
  "DEAL_ASSIGNED",
  "WORKFLOW_NOTIFICATION",
  "JOB_ASSIGNED",
  "PAYMENT_RECEIVED",
]);

const projectStatsuses = z.enum(["ACTIVE", "COMPLETED", "CANCELLED"]);

const timeSheetDurationTypes = z.enum(["MINUTES", "DATE"]);

const salesGoalTargetFor = z.enum(["USER", "TEAM"]);
const timeSheetStatusTypes = z.enum(["OPEN", "APPROVED", "REJECTED"]);
const lostReasonTypes = z.enum(["LEAD", "DEAL", "QUOTATION"]);

const dealStageType = z.enum(["ACTIVE", "WON", "LOST"]);

const customFieldTypes = z.enum(["TEXT", "NUMBER", "DATE", "SINGLE_OPTION"]);
const customFieldSections = z.enum([
  "CLIENT",
  "LEAD",
  "DEAL",
  "CONTACT",
  "PROJECT",
  "QUOTATION_LINE_ITEM",
]);

const userActivitiesActionTypes = z.enum(["ADD", "UPDATE", "DELETE"]);

const invoiceStatus = z.enum([
  "DRAFT",
  "SENT",
  "PARTIALLY_PAID",
  "PAID",
  "CANCELLED",
]);

const paymentAllocationType = z.enum(["INVOICE", "OPENING_BALANCE"]);

const quotationStatus = z.enum(["DRAFT", "SENT", "ACCEPTED", "REJECTED"]);

const jobStatus = z
  .enum(["PENDING", "ACTIVE", "CANCELLED", "FREEZED", "COMPLETED"])
  .default("ACTIVE");
const jobType = z.enum(["ONEOFF", "CONTRACT"]);
const frequencyType = z
  .enum(["days", "weeks", "months", "years", "AS_NEEDED"])
  .default("days");

export const templateSettingsLineItemColumnsHidden = z.array(
  z.enum(["No", "Item", "Price", "Quantity", "VAT", "Total"])
);

const templateColumns = z.array(
  z.enum(["no", "item", "price", "quantity", "vat", "total", "custom"])
);

const paymentGateway = z.enum(["telr"]);
const paymentStatus = z.enum(["PENDING", "PAID", "FAILED"]);

const commentType = z.enum(["TEXT", "VOICE", "FILE", "IMAGE"]);

const activityTypes = z.enum([
  "email",
  "call",
  "todo",
  "meeting",
  "sms",
  "whatsapp_message",
]);

const importOperationType = z.enum([
  "CLIENTS",
  "CLIENT_ADDRESSES",
  "CLIENT_CONTACTS",
  "ADRESSES",
  "INVOICES",
  "INVOICE_LINE_ITEMS",
  "PRODUCTS",
]);

const userTypes = z.enum([
  "ADMIN",
  "SUPER_ADMIN",
  "STAFF",
  "OWNER",
  "FIELD_STAFF",
]);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const enumsToOptions = <T extends z.ZodEnum<any>>(
  zEnum: T
): Array<{ label: string; value: T["Values"] }> => {
  return (zEnum.options as Array<T["Values"]>).map((value) => ({
    label: String(value),
    value,
  }));
};

const purchaseOrderStatus = z.enum(["DRAFT", "SENT", "CLOSED", "CANCELLED"]);

const fsJobServiceType = z.enum(["PRODUCT", "SERVICE"]);

const tagTypes = z.enum([
  "QUOTATION",
  "INVOICE",
  "SCHEDULE",
  "JOB",
  "CLIENT",
  "PROJECT",
  "PROJECT_TASK",
  "PURCHASE_ORDER",
  "VENDOR",
  "BILL",
]);

const leadStageTypes = z.enum([
  "NEW",
  "CONTACTED",
  "WORKING",
  "UNQUALIFIED",
  "CONVERTED",
]);

const billStatuses = z.enum([
  "DRAFT",
  "OPEN",
  "PARTIALLY_PAID",
  "PAID",
  "CANCELLED",
]);

const templateTypes = z.enum([
  "QUOTATION",
  "INVOICE",
  "PROFORMA_INVOICE",
  "PAYMENT",
  "JOB",
  "SCHEDULE",
  "PURCHASE_ORDER",
  "BILL",
]);
const appKeys = z.enum([
  "CRM",
  "SALES",
  "PURCHASES",
  "CALENDAR",
  "PROJECTS",
  "FILES",
  "CONTACTS",
  "FIELD_SERVICE",
  "EMPLOYEES",
  "TEMPLATES",
  "AI_ASSISTANT",
  "SETTINGS",
]);

const systemFileFields = z.enum(["fs_job_lpo", "expense_reciept", "team_logo"]);

const accounting = {
  accountingExpensePaidBy: z.enum(["COMPANY", "EMPLOYEE"]),
  accountingExpenseType: z.enum([
    "EXPENSE",
    "COST_OF_GOODS_SOLD",
    "OTHER_EXPENSE",
  ]),
};

const jobSchedulePricingType = z.enum(["SCHEDULE", "FIELD_STAFF"]);

const expenses = {
  paidBy: {
    schema: z.enum(["COMPANY", "EMPLOYEE"]),
    values: [
      { label: "Company", value: "COMPANY" },
      { label: "Employee", value: "EMPLOYEE" },
    ],
  },
};

const entities = z.enum([
  "LEAD",
  "DEAL",
  "CONTACT",
  "CLIENT",
  "FS_JOB",
  "FS_SCHEDULE",
  "FILE",
  "TASK",
  "COMMENT",
  "PROJECT",
  "EXPENSE",
  "TEAM",
  "PROJECT_TASK",
]);

const S3Folders = z.enum([
  "files",
  "documents",
  "deals",
  "leads",
  "leads/comments",
  "projects",
  "projects/tasks",
  "expenses/receipts",
  "contacts",
  "users/profileImages",
  "templates",
  "documents/images",
  "field-service/jobs",
  "teams/logos",
]);

const lineItemViewType = z.enum(["LINE_ITEM", "HEADING"]);

export default {
  lineItemViewType,
  quotationStatus,
  scheduleStatus,
  jobStatus,
  jobType,
  frequencyType,
  invoiceStatus,
  importOperationType,
  templateSettingsLineItemColumnsHidden,
  accounting,
  expenses,
  templateTypes,
  S3Folders,
  activityTypes,
  userTypes,
  appKeys,
  commentType,
  fsJobServiceType,
  customFieldSections,
  customFieldTypes,
  userActivitiesActionTypes,
  projectTaskStatusTypes,
  timeSheetStatusTypes,
  tagTypes,
  lostReasonTypes,
  salesGoalTargetFor,
  purchaseOrderStatus,
  templateColumns,
  paymentGateway,
  paymentStatus,
  timeSheetDurationTypes,
  dealStageType,
  leadStageTypes,
  billStatuses,
  entities,
  jobSchedulePricingType,
  systemFileFields,
  projectStatsuses,
  paymentAllocationType,
  notificationTypes,
};
