import DataGrid from "@/components/dataGrid/DataGrid";
import FilterBar from "@/components/filters";
import Page from "@/components/page";
import PrintPreview from "@/components/print-preview";
import { trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import { formatCurrency } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import { Banknote, Download, Pencil, Plus, Receipt } from "lucide-react";
import { useState } from "react";
import {
  AddPaymentModal,
  EditPaymentModal,
} from "../invoices/components/payment-modals";
import { downloadPdf } from "../invoices/details";

type PageFilters = {
  dates: [Date, Date] | undefined;
  clients: number[];
  paymentMethodId: number[];
};

const PaymentsReceived = () => {
  const [filters, setFilters] = useParamsState<PageFilters>({
    dates: undefined,
    clients: [],
    paymentMethodId: [],
  });

  const [clientSearch, setClientSearch] = useState("");
  const [paymentDownloadLoading, setPaymentDownloadLoading] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showPaymentEditModal, setShowPaymentEditModal] = useState<
    number | undefined
  >(undefined);
  const [showPaymentDetailsModal, setShowPaymentDetailsModal] = useState<
    | {
        uuid: string;
        number: string;
      }
    | undefined
  >(undefined);

  const { data, isLoading } = trpc.invoices.payments.list.useQuery({
    clientId: filters.clients,
    paymentMethodId: filters.paymentMethodId,
    dates: filters.dates,
  });
  const { data: clients } = trpc.clients.list.useQuery({
    search: clientSearch,
    pageNo: 1,
    pageSize: 10,
  });
  const { data: paymentMethods } =
    trpc.invoices.payments.methods.list.useQuery();

  return (
    <Page title="Payments Received" className="!p-0" fullWidth>
      <div className="flex justify-between items-center p-3">
        <FilterBar
          suffix={
            <div className="p-1 px-2 text-sm rounded-lg border shadow-sm">
              Total: {formatCurrency(data?.total || 0, "AED")}
            </div>
          }
          filters={[
            {
              key: "date",
              type: "date-range",
              label: "Date",
              value: filters.dates,
              onChange: (value) => setFilters({ dates: value }),
            },
            {
              key: "clients",
              type: "checkbox",
              label: "Clients", // Added clients filter
              value: filters.clients,
              showSearch: true,
              onChange: (value) => setFilters({ clients: value as number[] }),
              onSearch: (value) => setClientSearch(value),
              options: clients?.clients.map((client) => ({
                label: client.name,
                value: client.id,
              })),
            },
            {
              key: "paymentMethod",
              type: "checkbox",
              label: "Payment Method",
              value: filters.paymentMethodId,
              onChange: (value) =>
                setFilters({ paymentMethodId: value as number[] }),
              options: paymentMethods?.map((method) => ({
                label: method.name,
                value: method.id,
              })),
            },
          ]}
        />
        <Button
          size="md"
          variant="primary"
          icon={Plus}
          onClick={() => setShowPaymentModal(true)}
        >
          Payment
        </Button>
      </div>
      <AddPaymentModal
        open={showPaymentModal}
        onClose={() => setShowPaymentModal(false)}
      />
      <ModalDrawer
        open={showPaymentDetailsModal !== undefined}
        onClose={() => setShowPaymentDetailsModal(undefined)}
        title={
          <div className="flex justify-between items-center pr-12">
            Payment reciept
            <Button
              onClick={async () => {
                await downloadPdf({
                  name: `Payment ${showPaymentDetailsModal?.number}`,
                  url: `print?payment=${showPaymentDetailsModal?.uuid}`,
                });
                setPaymentDownloadLoading(false);
              }}
              size="xs"
              icon={Download}
              variant="primaryOutline"
              loading={paymentDownloadLoading}
            >
              Download
            </Button>
          </div>
        }
        modalClassName="max-w-screen-sm"
      >
        <div className="overflow-auto w-full h-full">
          <div className="w-[21cm] h-[29.7cm] transform scale-[0.75] origin-top-left mx-auto">
            <PrintPreview paymentUuid={showPaymentDetailsModal?.uuid} />
          </div>
        </div>
      </ModalDrawer>
      {showPaymentEditModal && (
        <EditPaymentModal
          open={!!showPaymentEditModal}
          id={showPaymentEditModal}
          onClose={() => setShowPaymentEditModal(undefined)}
        />
      )}
      <DataGrid
        rowKey="id"
        name="invoicesPaymentsListMain"
        label="Payments"
        className="h-[calc(100vh-101px)] mt-12"
        loading={isLoading}
        empty={{
          icon: Banknote,
          title: "No Payments",
          description: "Please add some payments to get started.",
          buttonSize: "sm",
        }}
        rows={data?.payments || []}
        columns={[
          {
            key: "date",
            name: "Date",
            width: 150,
            renderCell: ({ row }) => dayjs(row.date).format("DD/MM/YYYY"),
          },
          {
            key: "number",
            name: "Number",
            width: 100,
            renderCell: ({ row }) => row.number,
          },
          {
            key: "client",
            name: "Client",
            width: 140,
            renderCell: ({ row }) => row.clients?.name,
          },
          {
            key: "paymentMethod",
            name: "Mode",
            width: 100,
            renderCell: ({ row }) => row.paymentMethods.name,
          },
          {
            key: "amount",
            name: "Amount",
            width: 140,
            renderCell: ({ row }) => formatCurrency(row.amount, "AED"),
          },
          {
            key: "referenceNumber",
            name: "Reference Number",
            width: 130,
            renderCell: ({ row }) => row.refNo,
          },
          {
            key: "actions",
            name: "Actions",
            width: 100,
            renderCell: ({ row }) => (
              <div className="flex gap-2 items-center">
                <Button
                  size="xs"
                  onClick={() =>
                    setShowPaymentDetailsModal({
                      uuid: row.uuid,
                      number: row.number,
                    })
                  }
                  variant="primaryOutline"
                  icon={Receipt}
                />
                <Button
                  onClick={() => setShowPaymentEditModal(row.id)}
                  size="xs"
                  variant="primaryOutline"
                  icon={Pencil}
                />
              </div>
            ),
          },
        ]}
      />
    </Page>
  );
};

export default PaymentsReceived;
