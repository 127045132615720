import RenderHtml from "@heffl/ui/components/render-html";
import { RouterOutputs } from "@/helpers/trpc";
import { formatCurrency } from "@heffl/ui/lib/utils";
import {
  calculateLineItems,
  calculateMultiplierTotal,
  calculateQuotationLineItems,
} from "@heffl/server/src/helpers/lineItems/calculateLineItems";
import { Separator } from "@heffl/ui/components/primitives/separator";
import { Box, Wrench } from "lucide-react";
import { match } from "ts-pattern";

type Quote = RouterOutputs["quotations"]["details"];
type Invoice = RouterOutputs["invoices"]["details"];

type Product = {
  viewType: "LINE_ITEM" | "HEADING";
  products?: {
    type: "SERVICE" | "PRODUCT";
  } | null;
  name: string;
  description?: string | null;
  quantity: number;
  price: number;
  tax?: number | null;
};

export const MobileLineItem: React.FC<{
  product: Product;
  onClick?: () => void;
}> = ({ product: item, onClick }) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-2" onClick={onClick}>
        {match(item.viewType)
          .with("LINE_ITEM", () => (
            <div className="flex flex-col gap-2 w-full">
              <div className="flex gap-2">
                {item.products?.type === "SERVICE" ? (
                  <Wrench className="p-2 w-12 h-12 text-gray-700 rounded-lg sm:h-10 sm:w-10 bg-primary-50" />
                ) : (
                  <Box className="p-2 w-12 h-12 text-gray-700 rounded-lg sm:h-10 sm:w-10 bg-primary-50" />
                )}
                <span className="flex flex-col justify-center">
                  <p className="text-sm font-medium">{item.name}</p>
                  <RenderHtml>{item.description}</RenderHtml>
                </span>
              </div>
              <div className="flex justify-between w-full">
                <div>
                  <p>
                    {item.quantity} x {formatCurrency(item.price, "AED")}{" "}
                    {item.tax ? `+ ${item.tax}% (VAT)` : ""}
                  </p>
                </div>
                <div className="font-medium">
                  {formatCurrency(
                    (item.price + (item.price / 100) * Number(item.tax || 0)) *
                      item.quantity,
                    "AED"
                  )}
                </div>
              </div>
            </div>
          ))
          .with("HEADING", () => (
            <p className="flex gap-2 items-center text-base font-medium">
              {item.name}
            </p>
          ))
          .exhaustive()}
      </div>
      <Separator className="w-full" />
    </div>
  );
};

type QuoteProduct =
  RouterOutputs["quotations"]["details"]["quotationProducts"][number];

const MobileQuoteLineItem: React.FC<{
  quote: Quote;
  product: QuoteProduct;
  onClick?: () => void;
}> = ({ quote, product: item, onClick }) => {
  const multiplierTotal = calculateMultiplierTotal({
    customFieldFields: quote.documentTemplates.customFields,
    customFieldValues: item.customFields,
  });

  const isMultipliers = quote.documentTemplates.customFields.some(
    (field) => field.isMultiplier
  );

  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-2" onClick={onClick}>
        {match(item.viewType)
          .with("LINE_ITEM", () => (
            <div className="flex flex-col gap-2 w-full">
              <div className="flex gap-2">
                {item.products?.type === "SERVICE" ? (
                  <Wrench className="p-2 w-12 h-12 text-gray-700 rounded-lg sm:h-10 sm:w-10 bg-primary-50" />
                ) : (
                  <Box className="p-2 w-12 h-12 text-gray-700 rounded-lg sm:h-10 sm:w-10 bg-primary-50" />
                )}
                <span className="flex flex-col justify-center">
                  <p className="text-sm font-medium">{item.name}</p>
                  <RenderHtml>{item.description}</RenderHtml>
                </span>
              </div>
              <div className="flex justify-between w-full">
                <div>
                  <p>
                    {item.quantity} {isMultipliers && ` x ${multiplierTotal}`} x{" "}
                    {formatCurrency(item.price, "AED")}{" "}
                    {item.tax ? `+ ${item.tax}% (VAT)` : ""}
                  </p>
                </div>
                <div className="font-medium">
                  {formatCurrency(
                    multiplierTotal *
                      item.quantity *
                      item.price *
                      (1 + item.tax / 100),
                    "AED"
                  )}
                </div>
              </div>
            </div>
          ))
          .with("HEADING", () => (
            <p className="flex gap-2 items-center text-base font-medium">
              {item.name}
            </p>
          ))
          .exhaustive()}
      </div>
      <Separator className="w-full" />
    </div>
  );
};

const MobileQuote = ({ quote }: { quote: Quote }) => {
  const values = calculateQuotationLineItems({
    lineItems: quote.quotationProducts,
    discount: quote.discount,
    customFields: quote.documentTemplates.customFields,
  });
  return (
    <div className="flex flex-col gap-2 p-3 w-full bg-white rounded-lg">
      <div className="flex flex-col gap-3">
        {quote.quotationProducts.map((product, index) => (
          <MobileQuoteLineItem key={index} quote={quote} product={product} />
        ))}
      </div>
      <div className="flex flex-col gap-2 mt-3">
        <div className="flex justify-between">
          <p className="text-sm text-gray-500">Subtotal</p>
          <p className="text-sm">{formatCurrency(values.subTotal, "AED")}</p>
        </div>
        <Separator className="w-full" />
        <div className="flex justify-between">
          <p className="text-sm text-gray-500">Discount</p>
          <p className="text-sm">{formatCurrency(quote.discount, "AED")}</p>
        </div>
        <Separator className="w-full" />
        {values.totalTax > 0 && (
          <div className="flex justify-between">
            <p className="text-sm text-gray-500">Tax</p>
            <p className="text-sm">{formatCurrency(values.totalTax, "AED")}</p>
          </div>
        )}
        <div className="flex justify-between">
          <p className="text-sm text-gray-500">Total</p>
          <p className="text-sm">{formatCurrency(values.total, "AED")}</p>
        </div>
      </div>
    </div>
  );
};

export const MobileInvoice = ({ invoice }: { invoice: Invoice }) => {
  const values = calculateLineItems({
    lineItems: invoice.invoiceProducts,
    discount: invoice.discount,
  });
  return (
    <div className="flex flex-col gap-2 p-3 w-full bg-white rounded-lg">
      <div className="flex flex-col gap-3">
        {invoice.invoiceProducts.map((product, index) => (
          <MobileLineItem key={index} product={product} />
        ))}
      </div>
      <div className="flex flex-col gap-2 mt-3">
        <div className="flex justify-between">
          <p className="text-sm text-gray-500">Subtotal</p>
          <p className="text-sm">{formatCurrency(values.subTotal, "AED")}</p>
        </div>
        <Separator className="w-full" />
        <div className="flex justify-between">
          <p className="text-sm text-gray-500">Discount</p>
          <p className="text-sm">{formatCurrency(invoice.discount, "AED")}</p>
        </div>
        <Separator className="w-full" />
        {values.totalTax > 0 && (
          <div className="flex justify-between">
            <p className="text-sm text-gray-500">Tax</p>
            <p className="text-sm">{formatCurrency(values.totalTax, "AED")}</p>
          </div>
        )}
        <div className="flex justify-between">
          <p className="text-sm text-gray-500">Total</p>
          <p className="text-sm">{formatCurrency(values.total, "AED")}</p>
        </div>
      </div>
    </div>
  );
};

export default MobileQuote;
