// @ts-ignore
import { Previewer } from "pagedjs";
import { useLayoutEffect, FC, useRef } from "react";

const BookViewer: FC = () => {
  const isInitializedRef = useRef(false);

  useLayoutEffect(() => {
    if (isInitializedRef.current) return;
    isInitializedRef.current = true;

    const previewer = new Previewer();
    const pagedjsdocroot = document.querySelector("#pagedjsdocroot");
    const preview = document.querySelector("#preview");

    if (pagedjsdocroot && preview) {
      previewer
        .preview(pagedjsdocroot.innerHTML, [], preview)
        .then((flow: { total: number }) => {
          console.log("preview rendered, total pages", flow.total, { flow });
        });
    }

    return () => {
      document.head
        .querySelectorAll("[data-pagedjs-inserted-styles]")
        .forEach((e) => e.parentNode?.removeChild(e));
    };
  }, []);

  return <div className="w-full h-full"></div>;
};

export default BookViewer;
