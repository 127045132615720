import { trpc } from "@/helpers/trpc";
import { deformatReminderDate } from "@heffl/server/src/helpers/utils/formatReminderDate";
import Schemas from "@heffl/server/src/schemas";
import enums from "@heffl/server/src/schemas/enums";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import { DateTimePicker } from "@heffl/ui/components/primitives/datetime-picker";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import MiniRichTextEditor from "@heffl/ui/components/primitives/mini-rich-text-editor";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";
import { MultipleContactsInput } from "../FormComponents";
import { activityDurations } from "./activities-modals";
import { Trash2 } from "lucide-react";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";

const LogForm = () => {
  return (
    <>
      <FormField label="Date" name="startDate">
        <DateTimePicker />
      </FormField>
      <MultipleContactsInput
        placeholder="Select contacts"
        name="activityAttendees"
        label="Contacted"
        className="w-full"
      />
      <FormField name="description">
        <MiniRichTextEditor height={150} placeholder="Describe the activity" />
      </FormField>
    </>
  );
};

const AddLogModal = (props: {
  open: boolean;
  onClose: () => void;
  type: z.infer<typeof enums.activityTypes>;
  dealId?: number;
  contactId?: number;
  clientId?: number;
  leadId?: number;
}) => {
  const form = useForm<z.infer<typeof Schemas.crm.activity>>({
    resolver: zodResolver(Schemas.crm.activity),
    defaultValues: {
      title: `Logged ${props.type.split("_").join(" ").toLowerCase()}`,
      type: props.type,
      isLogged: true,
      completed: true,
      startDate: new Date(),
      endDate: {
        date: new Date(),
        dateType: "CUSTOM_DATE",
      },
    },
  });

  const onModalClose = () => {
    props.onClose();
    form.reset();
  };

  const logAddMutation = trpc.activities.add.useMutation({
    onSuccess: () => {
      toast.success("Log added successfully");
      onModalClose();
    },
  });

  const onSubmit = (values: z.infer<typeof Schemas.crm.activity>) => {
    logAddMutation.mutate({
      ...values,
      endDate: { date: values.startDate, dateType: "CUSTOM_DATE" },
      leadId: props.leadId,
      clientId: props.clientId,
      dealId: props.dealId,
      contactId: props.contactId,
    });
  };

  return (
    <ModalDrawer
      open={props.open}
      onClose={onModalClose}
      title="Add Log"
      footer={
        <Button
          loading={logAddMutation.isLoading}
          onClick={form.handleSubmit(onSubmit)}
          variant="primary"
          className="w-full"
        >
          Add Log
        </Button>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <LogForm />
      </Form>
    </ModalDrawer>
  );
};

const EditLogModal = ({
  open,
  onClose,
  activityId,
}: {
  open: boolean;
  onClose: () => void;
  activityId: number;
}) => {
  const form = useForm<z.infer<typeof Schemas.crm.activity>>({
    resolver: zodResolver(Schemas.crm.activity),
  });
  const confirm = useConfirm();

  const { data: activity } = trpc.activities.details.useQuery(activityId);

  const onModalClose = () => {
    onClose();
    form.reset();
  };

  const logUpdateMutation = trpc.activities.update.useMutation({
    onSuccess: () => {
      toast.success("Log updated successfully");
      onModalClose();
    },
  });
  const logDeleteMutation = trpc.activities.delete.useMutation({
    onSuccess: () => {
      toast.success("Log deleted successfully");
      onClose();
    },
  });

  const onSubmit = (values: z.infer<typeof Schemas.crm.activity>) => {
    logUpdateMutation.mutate({ id: activityId, activity: values });
  };

  useEffect(() => {
    if (activity) {
      form.reset({
        ...activity,
        endDate: deformatReminderDate({
          ogDate: activity.endDate,
          date: activity.endDate,
          availableUnits: activityDurations,
        }),
        reminders: undefined,
        activityAttendees: activity.activityAttendees.map(
          (attendee) => attendee.contactId
        ),
        activityAssignees: activity.activityAssignees.map(
          (assignee) => assignee.userId
        ),
      });
    }
  }, [activity]);

  return (
    <ModalDrawer
      open={open}
      onClose={onModalClose}
      title="Edit Log"
      footer={
        <div className="flex gap-2 w-full">
          <Button
            loading={logDeleteMutation.isLoading}
            icon={Trash2}
            variant="destructiveOutline"
            onClick={async () => {
              const confirmed = await confirm({
                title: "Are you sure you want to delete this log?",
              });
              if (confirmed) {
                logDeleteMutation.mutate(activityId!);
              }
            }}
          />
          <Button
            loading={logUpdateMutation.isLoading}
            onClick={() => form.handleSubmit(onSubmit)()}
            variant="primary"
            className="w-full"
          >
            Update Log
          </Button>
        </div>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <LogForm />
      </Form>
    </ModalDrawer>
  );
};

export { AddLogModal, EditLogModal };
