import {
  closestCorners,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { cn, isMobile } from "@heffl/ui/lib/utils";
import { useMemo, useState } from "react";
import { Column } from "./column";

export interface KanbanColumn {
  id: string;
  label: string;
  value: string;
  color: string;
  summary?: string | number;
}

interface KanbanBoardProps<T> {
  disableDrag?: boolean;
  columns?: KanbanColumn[] | readonly KanbanColumn[];
  items?: T[];
  statusKey: keyof T;
  onChange: (
    items: {
      id: number;
      status: string;
      position: number;
    }[]
  ) => void;
  onDragging: (items: T[]) => void;
  renderItem: (item: T) => React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

// TODO: BUg on single board is empty droppable dosent work

const KanbanBoard = <T extends { id: number }>({
  disableDrag = false,
  columns = [],
  items: rawItems = [],
  statusKey,
  onChange,
  onDragging,
  renderItem,
  className,
  style,
}: KanbanBoardProps<T>) => {
  const items = useMemo(() => {
    return rawItems.map((item) => ({
      ...item,
      id: item.id.toString(),
    }));
  }, [rawItems]);

  const [activeCard, setActiveCard] = useState<T | null>(null);

  const sensors = useSensors(
    useSensor(isMobile() ? TouchSensor : PointerSensor, {
      activationConstraint: isMobile()
        ? {
            delay: 300,
            tolerance: 5,
          }
        : {
            distance: 10,
          },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const findColumn = (unique: string | null): KanbanColumn | null => {
    if (!unique) {
      return null;
    }
    if (columns.some((c) => c.id === unique)) {
      return columns.find((c) => c.id === unique) ?? null;
    }
    const id = String(unique);
    const itemWithColumnId = items.map((item) => ({
      itemId: item.id,
      columnId: item[statusKey] as string,
    }));
    const columnId = itemWithColumnId.find((i) => i.itemId === id)?.columnId;
    return columns.find((c) => c.id === columnId) ?? null;
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCorners}
      onDragStart={(event) => {
        const { active } = event;
        setActiveCard(items.find((item) => item.id === active.id) || null);
      }}
      onDragOver={(event) => {
        const { active, over } = event;
        if (!active || !over) return;

        const activeId = String(active.id);
        const overId = String(over.id);
        const activeColumn = findColumn(activeId);
        const overColumn = findColumn(overId);

        if (!activeColumn || !overColumn) return;

        const updatedItems = items.map((item) =>
          item.id === activeId ? { ...item, [statusKey]: overColumn.id } : item
        );

        const activeIndex = updatedItems.findIndex(
          (item) => item.id === activeId
        );
        const overIndex = updatedItems.findIndex((item) => item.id === overId);
        const targetIndex = overIndex === -1 ? 0 : overIndex;

        const reorderedItems = arrayMove(
          updatedItems,
          activeIndex,
          targetIndex
        );

        onDragging(reorderedItems);
      }}
      onDragEnd={(event) => {
        const { active, over } = event;
        if (!active || !over) return;

        const activeId = String(active.id);
        const overId = String(over.id);
        const activeColumn = findColumn(activeId);
        const overColumn = findColumn(overId);

        if (!activeColumn || !overColumn) return;

        const updatedItems = items.map((item) =>
          item.id === activeId ? { ...item, [statusKey]: overColumn.id } : item
        );

        const activeIndex = updatedItems.findIndex(
          (item) => item.id === activeId
        );
        const overIndex = updatedItems.findIndex((item) => item.id === overId);
        const targetIndex = overIndex === -1 ? 0 : overIndex;

        const reorderedItems = arrayMove(
          updatedItems,
          activeIndex,
          targetIndex
        );

        const overColumnItems = reorderedItems
          .filter((item) => item[statusKey] === overColumn.id)
          .map((item, index) => ({
            id: item.id,
            status: overColumn.id,
            position: index,
          }));
        onChange(overColumnItems);
        onDragging(reorderedItems);
        setActiveCard(null);
      }}
      onDragCancel={() => setActiveCard(null)}
    >
      <div
        className={cn("flex overflow-x-auto gap-3", className)}
        style={style}
      >
        {columns.map((column) => (
          <Column
            key={column.id}
            id={column.id}
            title={column.label}
            className="h-full"
            items={items.filter((item) => item[statusKey] === column.id)}
            renderItem={renderItem}
            color={column.color}
            summary={column.summary}
            disabled={disableDrag}
          />
        ))}
      </div>
      <DragOverlay dropAnimation={null}>
        {activeCard && (
          <div className="!max-w-[260px]">{renderItem(activeCard)}</div>
        )}
      </DragOverlay>
    </DndContext>
  );
};

export default KanbanBoard;
