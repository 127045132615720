import Empty from "@/components/Empty";
import FilterBar from "@/components/filters";
import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import { AddClientDrawer } from "@/pages/crm/clients/list";
import {
  QuickActioncard,
  SingleQuickAction,
} from "@/pages/crm/dashboard/dashboard";
import { LineText } from "@/pages/field-service/dashboard";
import { Card } from "@heffl/ui/components/primitives/card";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { formatAmountToK, formatCurrency } from "@heffl/ui/lib/utils";
import { DonutChart, Legend, LineChart, ProgressBar } from "@tremor/react";
import dayjs from "dayjs";
import {
  BadgeDollarSign,
  Building,
  DollarSign,
  ScrollText,
} from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import QuotationCard from "../quotations/QuotationCard";

type Filters = {
  dates?: [Date, Date] | undefined;
};

const SalesDashboard = () => {
  const [filters, setFilters] = useParamsState<Filters>({
    dates: [dayjs().subtract(30, "day").toDate(), dayjs().toDate()],
  });
  const [addClientDrawerVisible, setAddClientDrawerVisible] = useState(false);

  const navigate = useNavigate();
  const { data: dueInvoices } = trpc.salesDashbaord.dueInvoices.useQuery();
  const { data: latest } = trpc.salesDashbaord.latest.useQuery();
  const { data: invoiceMetrics } = trpc.salesDashbaord.invoiceMetrics.useQuery({
    startDate: filters.dates?.[0],
    endDate: filters.dates?.[1],
  });

  const { data: dashboard } = trpc.salesDashbaord.dashboard.useQuery({
    startDate: filters.dates?.[0],
    endDate: filters.dates?.[1],
  });
  const { data: fiscalYearCashFlow } =
    trpc.salesDashbaord.fiscalYearCashFlow.useQuery();

  const { quotationStats, invoiceStats } = dashboard || {};

  const quickActions: SingleQuickAction[] = [
    {
      path: "quotations/add",
      title: "Add quotation",
      icon: ScrollText,
      description: "Quickly add a new contact to the system",
      onClick: () => navigate("/sales/quotations/add"),
    },
    {
      path: "invoices/add",
      title: "Add invoice",
      icon: BadgeDollarSign,
      description: "Quickly add a new invoice to the system",
      onClick: () => navigate("/sales/invoices/add"),
    },
    {
      path: "proforma-invoices/add",
      title: "Add proforma invoice",
      icon: DollarSign,
      description: "Quickly add a new proforma invoice to the system",
      onClick: () => navigate("/sales/proforma-invoices/add"),
    },
    {
      path: "sales/client",
      title: "Add client",
      icon: Building,
      description: "Quickly add a new client to the system",
      onClick: () => setAddClientDrawerVisible(true),
    },
  ];

  return (
    <Page>
      <AddClientDrawer
        open={addClientDrawerVisible}
        onClose={() => setAddClientDrawerVisible(false)}
      />
      <div className="grid grid-cols-2 gap-4 mt-4 sm:grid-cols-4">
        {quickActions.map((action) => (
          <QuickActioncard action={action} key={action.path} />
        ))}
      </div>

      <FilterBar
        className="mt-3"
        filters={[
          {
            key: "date",
            type: "date-range",
            label: "Date",
            value: filters.dates,
            onChange: (value) =>
              setFilters({
                dates: value,
              }),
          },
        ]}
      />
      {!dueInvoices ||
      !latest ||
      !quotationStats ||
      !invoiceStats ||
      !invoiceMetrics ? (
        <FullScreenSpinner />
      ) : (
        <>
          <div className="grid grid-cols-1 gap-4 mt-4 sm:grid-cols-2">
            <Card className="flex flex-col justify-center">
              <p className="self-start mb-4 text-sm font-medium text-gray-500">
                Invoice payments
              </p>
              <p className="flex justify-between items-center text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                <span>
                  {formatCurrency(invoiceStats.paymentsRecieved, "AED")} &bull;{" "}
                  {invoiceStats.paidPercentage.toFixed(2)}%
                </span>
                <span>{formatCurrency(invoiceStats.invoiceTotal, "AED")}</span>
              </p>
              <ProgressBar
                value={invoiceStats.paidPercentage}
                color="teal"
                className="mt-3"
              />
              <div className="flex flex-col gap-2 items-start mt-4 w-full">
                <LineText
                  label="Total invoice amount"
                  value={formatCurrency(invoiceStats.invoiceTotal, "AED")}
                  color="blue"
                />
                <LineText
                  label="Total paid amount"
                  value={formatCurrency(invoiceStats.paymentsRecieved, "AED")}
                  color="green"
                />
                <LineText
                  label="Total due amount"
                  value={formatCurrency(
                    invoiceStats.invoicePendingAmount,
                    "AED"
                  )}
                  color="red"
                />
              </div>
            </Card>
            <Card className="flex flex-col justify-center items-center">
              <p className="self-start mb-4 text-sm font-medium text-gray-500">
                Invoice by status
              </p>
              <DonutChart
                data={invoiceMetrics}
                category="value"
                index="name"
                colors={["gray", "blue", "green", "red"]}
                className="w-40"
                onValueChange={(value) => {
                  const params = new URLSearchParams({
                    ...(value.name !== "UNASSIGNED" && {
                      statuses: JSON.stringify([value.name]),
                    }),
                    dates: JSON.stringify(filters.dates),
                  });
                  navigate(`/sales/invoices?${params.toString()}`);
                }}
              />
              <Legend
                categories={["DRAFT", "SENT", "PAID", "CANCELLED"]}
                colors={["gray", "blue", "green", "red"]}
                className="mt-4"
              />
            </Card>
            <Card className="flex flex-col justify-center">
              <p className="self-start mb-4 text-sm font-medium text-gray-500">
                Quotation conversions
              </p>
              <p className="flex justify-between items-center text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                <span>
                  {quotationStats[2].value} &bull;{" "}
                  {(
                    (quotationStats[2].value / quotationStats[4].value) *
                    100
                  ).toFixed(2)}
                  %
                </span>
                <span>{quotationStats[4].value}</span>
              </p>
              <ProgressBar
                value={Number(
                  (
                    (quotationStats[2].value / quotationStats[4].value) *
                    100
                  ).toFixed(2)
                )}
                color="teal"
                className="mt-3"
              />
              <div className="flex flex-col gap-2 items-start mt-4 w-full">
                <LineText
                  label="Total "
                  value={quotationStats[4].value.toString()}
                  color="blue"
                />
                <LineText
                  label="Draft"
                  value={quotationStats[0].value.toString()}
                  color="yellow"
                />
                <LineText
                  label="Sent"
                  value={quotationStats[1].value.toString()}
                  color="violet"
                />
                <LineText
                  label="Accepted"
                  value={quotationStats[2].value.toString()}
                  color="green"
                />
                <LineText
                  label="Rejected"
                  value={quotationStats[3].value.toString()}
                  color="red"
                />
              </div>
            </Card>
            <Card className="flex flex-col justify-center items-center">
              <p className="self-start mb-4 text-sm font-medium text-gray-500">
                Quotations by status
              </p>
              <DonutChart
                data={quotationStats.filter((stat) => stat.name !== "TOTAL")}
                category="value"
                index="name"
                colors={["gray", "blue", "green", "red"]}
                className="w-40"
                onValueChange={(value) => {
                  const params = new URLSearchParams({
                    statuses: JSON.stringify([value.name]),
                    dates: JSON.stringify(filters.dates),
                  });
                  navigate(`/sales/quotations?${params.toString()}`);
                }}
              />
              <Legend
                categories={["Draft", "Sent", "Accepted", "Rejected"]}
                colors={["gray", "blue", "green", "red"]}
                className="mt-4"
              />
            </Card>
          </div>
          <div className="grid grid-cols-1 gap-4 mt-4 sm:grid-cols-2">
            <Card className="flex flex-col justify-center items-center">
              <p className="self-start mb-4 text-sm font-medium text-gray-500">
                Top accepted quotations
              </p>

              <div className="flex flex-col gap-2 w-full">
                {dashboard?.topQuotations &&
                dashboard?.topQuotations.length > 0 ? (
                  dashboard?.topQuotations.map((quotation) => (
                    <QuotationCard key={quotation.id} data={quotation} />
                  ))
                ) : (
                  <Empty title="No accepted quotations" icon={ScrollText} />
                )}
              </div>
            </Card>
            <Card className="flex flex-col justify-center items-center">
              <p className="self-start mb-4 text-sm font-medium text-gray-500">
                Top rejected quotations
              </p>

              <div className="flex flex-col gap-2 w-full">
                {dashboard?.topRejectedQuotations &&
                dashboard?.topRejectedQuotations.length > 0 ? (
                  dashboard?.topRejectedQuotations.map((quotation) => (
                    <QuotationCard key={quotation.id} data={quotation} />
                  ))
                ) : (
                  <Empty title="No rejected quotations" icon={ScrollText} />
                )}
              </div>
            </Card>
          </div>
          <div className="grid grid-cols-1 gap-4 mt-4 sm:grid-cols-1">
            <Card className="flex flex-col justify-center items-center">
              <p className="self-start mb-4 text-sm font-medium text-gray-500">
                Fiscal year cash flow
              </p>

              <LineChart
                data={fiscalYearCashFlow || []}
                index="month"
                categories={["value"]}
                colors={["blue"]}
                valueFormatter={(value) => formatAmountToK(value)}
                customTooltip={(value) =>
                  formatCurrency(
                    typeof value.payload?.[0]?.value === "number"
                      ? value.payload[0].value
                      : 0,
                    "AED"
                  )
                }
                className="w-full"
              />
            </Card>
          </div>
        </>
      )}
    </Page>
  );
};

export default SalesDashboard;
