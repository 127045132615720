import {
  TabsList,
  TabsTrigger,
  TabsContent,
  Tabs,
} from "@heffl/ui/components/primitives/tabs";
import { cn } from "@heffl/ui/lib/utils";
import { LucideIcon } from "lucide-react";

type TabsProps<T extends string> = {
  value?: T;
  defaultValue?: T;
  onChange?: (value: T) => void;
  contentClassName?: string;
  tabClassName?: string;
  tabParentClassName?: string;
  items: {
    key: T;
    label: string;
    children?: React.ReactNode;
    icon?: LucideIcon;
    count?: number;
  }[];
  className?: string;
  suffix?: React.ReactNode;
};

const StripeTabs = <T extends string>({
  items,
  className,
  value,
  defaultValue,
  onChange,
  contentClassName,
  tabClassName,
  tabParentClassName,
  suffix,
}: TabsProps<T>) => {
  return (
    <Tabs
      value={value}
      onValueChange={(value) => onChange?.(value as T)}
      defaultValue={defaultValue || (items.length ? items[0].key : undefined)}
      className={cn(
        "overflow-x-auto overflow-y-hidden relative mr-auto w-full",
        className
      )}
    >
      <TabsList
        className={cn(
          "justify-between p-0 w-full bg-transparent rounded-none border-b",
          tabParentClassName
        )}
      >
        <div className="flex overflow-x-auto gap-6">
          {items.map((item) => (
            <TabsTrigger
              key={item.key}
              value={item.key}
              className={cn(
                "relative px-1 text-sm rounded-none border-b-2 border-b-transparent bg-transparent pb-3 pt-2 text-muted-foreground shadow-none transition-none focus-visible:ring-0 data-[state=active]:border-b-primary data-[state=active]:text-foreground data-[state=active]:shadow-none",
                tabClassName
              )}
            >
              {item.icon && <item.icon className="mr-2 w-4 h-4" />}
              {item.label}
              {!!item.count && (
                <div className="px-1.5 ml-1 text-primary-800 bg-primary rounded-sm text-white text-xs">
                  {item.count}
                </div>
              )}
            </TabsTrigger>
          ))}
        </div>
        {suffix && <div className="pr-5 mb-1.5">{suffix}</div>}
      </TabsList>
      {items
        .filter((item) => item.children)
        .map((item) => (
          <TabsContent
            key={item.key}
            value={item.key}
            className={cn("pt-2", contentClassName)}
          >
            {item.children}
          </TabsContent>
        ))}
    </Tabs>
  );
};

export default StripeTabs;
