import Empty from "@/components/Empty";
import FileIcon from "@/components/file-icon";
import Page from "@/components/page";
import { getS3URLLegacy } from "@/helpers/s3Helpers";
import { RouterOutputs, trpc } from "@/helpers/trpc";
import {
  ProviderNames,
  externalFileProviders,
  getExternalFileProvider,
} from "@/lib/constants";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import Schemas from "@heffl/server/src/schemas";
import { Icons } from "@heffl/ui/components/icons";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@heffl/ui/components/primitives/breadcrumb";
import { Button } from "@heffl/ui/components/primitives/button";
import { Checkbox } from "@heffl/ui/components/primitives/checkbox";
import { copyToClipboard } from "@heffl/ui/components/primitives/copy-to-clipboard";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@heffl/ui/components/primitives/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@heffl/ui/components/primitives/dropdown-menu";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import { Label } from "@heffl/ui/components/primitives/label";
import Modal from "@heffl/ui/components/primitives/modal";
import { cn, convertFileSize } from "@heffl/ui/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import {
  ArrowUp,
  Copy,
  Download,
  FileSearch,
  FileText,
  Folder,
  FolderPlus,
  Home,
  LayoutPanelLeftIcon,
  LucideIcon,
  Plus,
  Search,
  Trash2,
  Upload,
  XCircle,
} from "lucide-react";
import mime from "mime-types";
import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useImmer } from "use-immer";
import { z } from "zod";

type MenuTypes = "all" | "documents" | "trash";

const menuItems: { name: MenuTypes; label: string; icon: LucideIcon }[] = [
  {
    name: "all",
    label: "All Files",
    icon: LayoutPanelLeftIcon,
  },
  {
    name: "documents",
    label: "Documents",
    icon: FileText,
  },
  {
    name: "trash",
    label: "Trash",
    icon: Trash2,
  },
];

type Folder = RouterOutputs["files"]["list"]["folders"][number];

const FolderItem = ({
  file,
  onClick,
  checked,
}: {
  file: Folder;
  onClick: (file: Folder) => void;
  checked: boolean;
}) => {
  return (
    <div
      className="flex relative flex-col gap-1 cursor-pointer"
      onClick={() => onClick(file)}
    >
      <div
        className={`w-full h-[200px] rounded-sm flex items-center justify-center ${
          checked ? "bg-gray-400" : "bg-gray-100"
        } hover:bg-gray-200`}
      >
        <Folder className="h-[80px] w-[80px] text-blue-200 fill-blue-200" />
      </div>
      <div className="flex flex-row gap-1 items-center mt-2">
        <Folder className="h-8 text-blue-200 fill-blue-200" />
        <p className="text-sm">{file.name}</p>
      </div>
    </div>
  );
};

const FileItem = ({
  file,
  onClick,
  checked,
}: {
  file: FilesFile;
  onClick: () => void;
  checked: boolean;
}) => {
  const extension = file.format ? mime.extension(file.format) || "" : "";
  return (
    <div
      className="flex flex-col gap-1 cursor-pointer"
      onClick={() => file.type === "FILE" && onClick()}
    >
      <div
        className={` w-full h-[200px] rounded-sm flex items-center justify-center ${
          checked ? "bg-gray-400" : "bg-gray-100"
        } hover:bg-gray-200`}
      >
        {file.format?.startsWith("image/") ? (
          <img
            src={getS3URLLegacy("files", file.link)}
            alt={file.name}
            className="object-contain w-full h-full"
          />
        ) : (
          <FileIcon ext={extension} />
        )}
      </div>
      <p className="text-sm">{file.name}</p>
    </div>
  );
};

type FilesFile = RouterOutputs["files"]["list"]["files"][number];

const ExternalFileItem = ({
  file,
  checked,
}: {
  file: FilesFile;
  checked: boolean;
}) => {
  const ProviderIcon = getExternalFileProvider(
    file.linkProvider as ProviderNames
  ).icon;
  return (
    <div
      className="flex relative flex-col gap-1 cursor-pointer"
      onClick={() => window.open(file.link, "_blank")}
    >
      <div
        className={`w-[200px] h-[200px] rounded-sm flex items-center justify-center ${
          checked ? "bg-gray-400" : "bg-gray-100"
        } hover:bg-gray-200`}
      >
        <ProviderIcon className="w-16 h-16" />
      </div>
      <p className="text-sm">{file.name}</p>
    </div>
  );
};

const CreateFolderDialog = ({
  open,
  onClose,
  currentFolder,
}: {
  open: boolean;
  onClose: () => void;
  currentFolder: null | Folder;
}) => {
  const [folderName, setFolderName] = useState("");

  const onCloseModal = () => {
    setFolderName("");
    onClose();
  };

  const addFolderMutation = trpc.files.addFolder.useMutation({
    onSuccess() {
      toast.success("Folder created successfully");
      onCloseModal();
    },
  });

  return (
    <Dialog open={open} onOpenChange={onCloseModal}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Create folder</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-4">
          <Label htmlFor="name" className="text-start">
            Folder name
          </Label>
          <Input
            id="name"
            className="col-span-3"
            value={folderName}
            onChange={(e) => setFolderName(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && folderName.length) {
                addFolderMutation.mutate({
                  name: folderName,
                  parentFolderId: currentFolder?.id || null,
                });
              }
            }}
          />
        </div>
        <DialogFooter>
          <Button
            onClick={() => {
              folderName.length &&
                addFolderMutation.mutate({
                  name: folderName,
                  parentFolderId: currentFolder?.id || null,
                });
            }}
            type="submit"
            variant="primary"
            loading={addFolderMutation.isLoading}
          >
            Create
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const externalFileSchema = Schemas.files.file.pick({
  name: true,
  link: true,
});

const AddExternalFile = ({
  open,
  onClose,
  linkProvider,
  currentFolder,
}: {
  open: boolean;
  linkProvider: "dropbox" | "drive" | "zoho";
  currentFolder: Folder | null;
  onClose: () => void;
}) => {
  const form = useForm<z.infer<typeof externalFileSchema>>({
    resolver: zodResolver(externalFileSchema),
  });

  const addFileMutation = trpc.files.addExternalFile.useMutation({
    onSuccess() {
      toast.success("File added successfully");
      onClose();
    },
  });

  const LinkIcon = Icons[linkProvider];

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={
        <div className="flex gap-2 items-center">
          <LinkIcon className="h-5" />
          <p className="text-lg font-semibold">Link file from {linkProvider}</p>
        </div>
      }
    >
      <Form
        {...form}
        onSubmit={(values) => {
          addFileMutation.mutate({
            ...values,
            linkProvider,
            size: 0,
            parentFolderId: currentFolder?.id || null,
            format: "external",
            section: "files",
            sectionId: null,
            entity: "FILE",
            entityId: null,
          });
        }}
      >
        <FormField label="Name" name="name">
          <Input placeholder="Add a name for the file..." />
        </FormField>
        <FormField name="link" label="Link">
          <Input
            placeholder={`Enter link from ${linkProvider}...`}
            prefix={LinkIcon && <LinkIcon className="h-3" />}
          />
        </FormField>
        <Button
          type="submit"
          variant="primary"
          loading={addFileMutation.isLoading}
        >
          Add
        </Button>
      </Form>
    </Modal>
  );
};

export const UploadFileModal = ({
  open,
  onClose,
  currentFolder,
  file,
  projectId,
}: {
  open: boolean;
  currentFolder: Folder | null;
  onClose: () => void;
  file: File;
  projectId?: number;
}) => {
  const [uploading, setUploading] = useState(false);

  const fileUploadSchema = Schemas.files.file.pick({
    name: true,
  });

  const form = useForm<z.infer<typeof fileUploadSchema>>({
    resolver: zodResolver(fileUploadSchema),
    defaultValues: {
      name: file.name.split(".").slice(0, -1).join("."),
    },
  });

  const onModalClose = () => {
    setUploading(false);
    onClose();
  };

  const uploadFileMutation = trpc.files.uploadFile.useMutation({
    onSuccess() {
      toast.success("File added successfully");
      onModalClose();
    },
  });

  return (
    <ModalDrawer open={open} onClose={onModalClose} title="Upload file">
      <Form
        {...form}
        onSubmit={async (values) => {
          setUploading(true);
          const response = await uploadFileMutation.mutateAsync({
            ...values,
            size: file.size,
            format: file.type,
            parentFolderId: currentFolder?.id || null,
            projectId,
            section: "files",
            sectionId: null,
            entity: "FILE",
            entityId: null,
          });
          if (response.presignedUrl) {
            await axios.put(response.presignedUrl, file, {
              headers: {
                "Content-Type": file.type,
              },
            });
          }
          setUploading(false);
        }}
      >
        <div className="flex gap-3">
          <div className="flex flex-col items-center">
            <FileIcon ext={file.name.split(".").pop() || ""} />
            <p className="mt-1.5 text-xs text-gray-500">
              {convertFileSize(file.size)}
            </p>
          </div>
          <div className="w-full">
            <FormField label="Name" name="name">
              <Input
                placeholder="Add a name for the file..."
                suffix={`.${file.name.split(".").pop()}`}
              />
            </FormField>
          </div>
        </div>
        <Button
          type="submit"
          variant="primary"
          loading={uploadFileMutation.isLoading || uploading}
        >
          Upload file
        </Button>
      </Form>
    </ModalDrawer>
  );
};

type DocViewerProps = {
  open: boolean;
  onClose: () => void;
  doc: {
    name: string;
    link: string;
  };
};
export const DocumentViewer = ({ open, onClose, doc }: DocViewerProps) => {
  return (
    <Modal
      open={open}
      title={
        <div className="flex justify-between items-center pr-12">
          <p>{doc.name}</p>
          <Button
            size="sm"
            variant="outline"
            icon={Copy}
            onClick={() => copyToClipboard(doc.link)}
          />
        </div>
      }
      onClose={onClose}
    >
      <DocViewer
        // @ts-ignore
        pluginRenderers={DocViewerRenderers}
        documents={[{ uri: doc.link }]}
        config={{
          header: {
            disableHeader: false,
            disableFileName: false,
            retainURLParams: false,
          },
        }}
        style={{ height: 500 }}
      />
    </Modal>
  );
};

const Files = () => {
  const [choosenFile, setChoosenFile] = useState<File | null>(null);
  const [showAddFolderDialog, setShowAddFolderDialog] = useState(false);
  const [currentFolder, setCurrentFolder] = useState<null | Folder>(null);
  const [showAddExternalFile, setShowAddExternalFile] = useState<
    "drive" | "dropbox" | "zoho" | null
  >(null);
  const [showDocViewer, setShowDocViewer] = useState<null | FilesFile>(null);
  const [selctedMenu, setSelectedMenu] = useState<
    "all" | "documents" | "trash"
  >("all");
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useImmer<{
    files: number[];
    folders: number[];
    documents: number[];
  }>({
    files: [],
    folders: [],
    documents: [],
  });

  const { data: folderContents, isLoading } = trpc.files.list.useQuery({
    parentFolderId: currentFolder?.id || null,
    search,
    type: selctedMenu,
  });
  const bulkTrashMutation = trpc.files.bulkTrash.useMutation({
    onSuccess() {
      toast.success("Action performed successfully");
      setSelected((draft) => {
        draft.files = [];
        draft.folders = [];
        draft.documents = [];
      });
    },
  });

  return (
    <Page title="Files" fullWidth>
      {selected.files.length +
        selected.folders.length +
        selected.documents.length >
        0 && (
        <div className="flex fixed bottom-6 z-50 justify-center w-full bg-white">
          <div className="flex gap-2 justify-center items-center p-2 px-8 rounded-sm border shadow-md w-fit">
            <div className="flex justify-center items-center w-6 h-6 text-white bg-green-600 rounded">
              {selected.files.length +
                selected.folders.length +
                selected.documents.length}
            </div>
            <p className="text-sm">selected</p>
            {selctedMenu !== "trash" ? (
              <Button
                size="sm"
                onClick={() =>
                  bulkTrashMutation.mutate({
                    selected,
                    trashed: true,
                  })
                }
                loading={bulkTrashMutation.isLoading}
              >
                <Trash2 className="h-4" /> Move to trash
              </Button>
            ) : (
              <Button
                size="sm"
                onClick={() =>
                  bulkTrashMutation.mutate({
                    selected,
                    trashed: false,
                  })
                }
                loading={bulkTrashMutation.isLoading}
              >
                <Trash2 className="h-4 rotate-180" /> Restore selected
              </Button>
            )}
            <Button size="sm">
              <Download className="h-4" /> Download all
            </Button>
          </div>
        </div>
      )}
      {showDocViewer && (
        <DocumentViewer
          doc={{
            name: showDocViewer.name,
            link: getS3URLLegacy("files", showDocViewer.link),
          }}
          open={true}
          onClose={() => setShowDocViewer(null)}
        />
      )}

      {choosenFile && (
        <UploadFileModal
          open={true}
          file={choosenFile}
          onClose={() => setChoosenFile(null)}
          currentFolder={currentFolder || null}
        />
      )}
      <CreateFolderDialog
        currentFolder={currentFolder || null}
        open={showAddFolderDialog}
        onClose={() => setShowAddFolderDialog(false)}
      />
      {showAddExternalFile && (
        <AddExternalFile
          open={!!showAddExternalFile}
          linkProvider={showAddExternalFile}
          onClose={() => setShowAddExternalFile(null)}
          currentFolder={currentFolder || null}
        />
      )}
      <div
        className="w-[180px] bg-gray-50 fixed bottom-0 -ml-4 mr-4 hidden sm:block border-r border-gray-200"
        style={{
          height: `calc(100vh - 42px)`,
        }}
      >
        <div className="flex flex-col gap-3 px-4 pt-6">
          {menuItems.map((item) => (
            <div
              key={item.name}
              className={`flex flex-col sm:flex-row items-center justify-center sm:justify-normal px-3 py-0 sm:py-2 rounded-md gap-2 hover:bg-gray-100  cursor-pointer ${
                selctedMenu === item.name ? "bg-green-200" : ""
              }`}
              onClick={() => {
                setSelectedMenu(item.name);
                setSearch("");
                setCurrentFolder(null);
              }}
            >
              <item.icon className="h-4 text-primary sm:h-4" strokeWidth={2} />
              <p className="text-xs text-gray-900 sm:text-sm">{item.label}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="relative h-full w-full sm:w-calc-220 sm:h-calc-70 ml-0 sm:ml-[180px] mt-0">
        {selctedMenu === "all" && (
          <>
            <div className="flex flex-row gap-4 mt-4">
              <DropdownMenu>
                <DropdownMenuTrigger>
                  <div className="sm:w-[140px] rounded-lg py-4 h-full px-3 bg-primary text-white flex flex-col gap-2 cursor-pointer hover:bg-green-700">
                    <Plus className="h-5" />
                    <p className="text-sm">Create</p>
                  </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuSeparator />
                  <DropdownMenuGroup>
                    <DropdownMenuLabel className="font-normal text-gray-500">
                      Link files from...
                    </DropdownMenuLabel>
                    {externalFileProviders.map((external) => (
                      <DropdownMenuItem
                        key={external.name}
                        onClick={() => setShowAddExternalFile(external.name)}
                      >
                        <external.icon className="mr-1 h-4" />
                        {external.label}
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuGroup>
                </DropdownMenuContent>
              </DropdownMenu>

              <label
                htmlFor="file-upload"
                className="w-[140px] h-fit rounded-lg py-4 px-3 bg-white border border-gray-200 text-gray-600 flex flex-col gap-2 cursor-pointer hover:bg-gray-100"
              >
                <input
                  onClick={(e) => {
                    // for fixing file not selected if same second time
                    if (e.target && e.target) {
                      // @ts-ignore
                      e.target.value = null;
                    }
                  }}
                  onChange={(e) => {
                    if (e.target.files && e.target.files.length) {
                      setChoosenFile(e.target.files[0]);
                    }
                  }}
                  type="file"
                  id="file-upload"
                  className="hidden"
                />
                <Upload className="h-5" />
                <p className="text-sm">Upload</p>
              </label>
              <div
                className="w-[140px] rounded-lg py-4 px-3 bg-white border border-gray-200 text-gray-600 flex flex-col gap-2 cursor-pointer hover:bg-gray-100"
                onClick={() => setShowAddFolderDialog(!showAddFolderDialog)}
              >
                <FolderPlus className="h-5" />
                <p className="text-sm">Create folder</p>
              </div>
            </div>
          </>
        )}
        <div className="flex flex-col gap-2 mt-5 w-full">
          <p className="text-lg font-semibold">
            {currentFolder
              ? currentFolder.name
              : menuItems.find((item) => item.name === selctedMenu)?.label}
          </p>
          <Input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search files"
            className="w-full"
            prefix={<Search className="h-4 text-gray-500" />}
            suffix={
              search && (
                <XCircle
                  onClick={() => setSearch("")}
                  className="h-4 text-gray-500 cursor-pointer"
                />
              )
            }
          />
          <div
            className={cn(
              "flex flex-row items-center gap-2",
              !currentFolder && "invisible"
            )}
          >
            <>
              <Home
                className="h-4 cursor-pointer"
                onClick={() => {
                  setCurrentFolder(null);
                  setSearch("");
                }}
              />
              /
            </>
            <Breadcrumb separator="/">
              {folderContents?.breadcrumbs.map((crumb) => (
                <BreadcrumbItem key={crumb.id} className="text-gray-700">
                  <BreadcrumbLink onClick={() => setCurrentFolder(crumb)}>
                    {crumb.name}
                  </BreadcrumbLink>
                </BreadcrumbItem>
              ))}
            </Breadcrumb>
          </div>
          <div className="flex flex-row gap-1 items-center border-b border-gray-200">
            <p className="text-sm">Name</p>
            <ArrowUp className="h-4" />
          </div>

          <div className="grid w-full gap-3 py-2 sm:grid-cols-6 grid-cols-2 mb-[100px]">
            {folderContents?.folders?.map((folder) => {
              return (
                <FolderItem
                  key={folder.id}
                  file={folder}
                  onClick={() => setCurrentFolder(folder)}
                  checked={false}
                />
              );
            })}
            {folderContents?.files?.map((file) => {
              return (
                <div key={file.id} className="relative">
                  {file.type === "LINK" ? (
                    <ExternalFileItem
                      file={file}
                      checked={selected.files.includes(file.id)}
                    />
                  ) : (
                    <FileItem
                      onClick={() => setShowDocViewer(file)}
                      file={file}
                      checked={selected.files.includes(file.id)}
                    />
                  )}
                  <div className="absolute top-2 left-2 p-1">
                    <Checkbox
                      id="terms"
                      checked={selected.files.includes(file.id)}
                      onChange={() => {
                        setSelected((draft) => {
                          if (draft.files.includes(file.id)) {
                            draft.files = draft.files.filter(
                              (id) => id !== file.id
                            );
                          } else {
                            draft.files.push(file.id);
                          }
                        });
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {isLoading && <FullScreenSpinner className="h-80" />}
        {!folderContents?.files.length &&
          !folderContents?.folders.length &&
          !isLoading && (
            <Empty
              title="No files or folders found"
              icon={FileSearch}
              description="Create a new doc, upload a file, or link a file using the buttons above."
            />
          )}
        {/*  */}
      </div>
    </Page>
  );
};

export default Files;
