import { RouterOutputs, trpc } from "@/helpers/trpc";
import MiniRichTextEditor from "@heffl/ui/components/primitives/mini-rich-text-editor";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Calendar,
  Check,
  Clock,
  Pencil,
  RefreshCw,
  Timer,
  X,
} from "lucide-react";
import { useEffect, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";

import DataGrid from "@/components/dataGrid/DataGrid";
import FilterBar from "@/components/filters";
import { SearchInput, UserInput } from "@/components/FormComponents";
import Page from "@/components/page";
import { useParamsState } from "@/lib/hooks/useParamsState";
import { DateTimePickerNew } from "@/pages/field-service/schedules/components/schduleDetailsModal";
import Schemas from "@heffl/server/src/schemas";
import enums from "@heffl/server/src/schemas/enums";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import Select from "@heffl/ui/components/primitives/creatable-select";
import { DatePicker } from "@heffl/ui/components/primitives/datepicker";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import { Input } from "@heffl/ui/components/primitives/input";
import { Label } from "@heffl/ui/components/primitives/label";
import ResponsiveActionButton from "@heffl/ui/components/primitives/responsive-action-button";
import TabsInput from "@heffl/ui/components/primitives/TabsInput";
import RenderHtml from "@heffl/ui/components/render-html";
import {
  cn,
  dynamicDateFormatting,
  dynamicTwoDateFormatting,
  isMobile,
} from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useImmer } from "use-immer";
import Empty from "@/components/Empty";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";

const timeSheetStatuses = {
  OPEN: {
    label: "Pending",
    value: "OPEN",
    color: "text-yellow-500",
    variant: "warning",
    icon: Clock,
  },
  APPROVED: {
    label: "Approved",
    value: "APPROVED",
    color: "text-green-500",
    variant: "success",
    icon: Check,
  },
  REJECTED: {
    label: "Rejected",
    value: "REJECTED",
    color: "text-red-500",
    variant: "error",
    icon: X,
  },
} as const;

const TimesheetForm = ({
  form,
  showTaskSelect = true,
  clientId,
  projectId,
}: {
  form: UseFormReturn<z.infer<typeof Schemas.project.timesheet>>;
  showTaskSelect?: boolean;
  clientId?: number | null;
  projectId?: number | null;
}) => {
  const durationType = form.watch("durationType");

  const [selectValues, setSelectValues] = useImmer<{
    clientSearch: string;
    clientId: number | undefined;
    projectId: number | undefined;
  }>({
    clientSearch: "",
    clientId: undefined,
    projectId: undefined,
  });

  useEffect(() => {
    setSelectValues((draft) => {
      draft.clientId = clientId || undefined;
      draft.projectId = projectId || undefined;
    });
  }, [clientId, projectId]);

  const { data: clients } = trpc.clients.list.useQuery({
    search: selectValues.clientSearch,
    pageSize: 10,
    pageNo: 1,
    include: selectValues.clientId ? [selectValues.clientId] : [],
  });
  const { data: projects } = trpc.projects.list.useQuery(
    {
      clients: selectValues.clientId ? [selectValues.clientId] : [],
    },
    {
      enabled: !!selectValues.clientId,
    }
  );

  const { data: projectTasks } = trpc.projects.tasks.list.useQuery(
    {
      projectId: selectValues.projectId,
      showAllTasks: true,
    },
    {
      enabled: !!selectValues.projectId,
    }
  );

  return (
    <div className="flex flex-col gap-3">
      {showTaskSelect && (
        <>
          <div className="flex gap-2">
            <span className="space-y-1 w-full">
              <Label>Client</Label>
              <Select
                placeholder="Select client"
                value={selectValues.clientId}
                onChange={(v) =>
                  setSelectValues((draft) => {
                    draft.clientId = v;
                    draft.clientSearch = "";
                    draft.projectId = undefined;
                    // @ts-ignore
                    form.setValue("projectTaskId", null);
                  })
                }
                options={
                  clients?.clients.map((c) => ({
                    label: c.name,
                    value: c.id,
                  })) || []
                }
                onSearch={(v) =>
                  setSelectValues((draft) => {
                    draft.clientSearch = v;
                  })
                }
              />
            </span>
            <span className="space-y-1 w-full">
              <Label>Project</Label>
              <Select
                placeholder="Select project"
                value={selectValues.projectId}
                onChange={(v) => {
                  setSelectValues((draft) => {
                    draft.projectId = v;
                  });
                  // @ts-ignore
                  form.setValue("projectTaskId", null);
                }}
                options={
                  projects?.projects.map((p) => ({
                    label: p.title,
                    value: p.id,
                  })) || []
                }
              />
            </span>
          </div>
          <FormField name="projectTaskId" label="Task">
            <Select
              className="w-full"
              options={
                projectTasks?.map((t) => ({
                  label: t.title,
                  value: t.id,
                })) || []
              }
              placeholder="Select project task"
              value={form.watch("projectTaskId")}
              onChange={(v) => form.setValue("projectTaskId", v)}
            />
          </FormField>
        </>
      )}
      <FormField name="durationType" label="Type">
        <TabsInput
          options={[
            {
              value: "DATE",
              label: "Date",
              icon: Calendar,
            },
            {
              value: "MINUTES",
              label: "Duration",
              icon: Timer,
            },
          ]}
        />
      </FormField>
      {durationType === "DATE" && (
        <div className="flex flex-col gap-4 sm:flex-row">
          <FormField name="startTime" label="Start Time">
            <DateTimePickerNew />
          </FormField>
          <FormField name="endTime" label="End Time">
            <DateTimePickerNew />
          </FormField>
        </div>
      )}
      {durationType === "MINUTES" && (
        <div className="grid grid-cols-2 gap-2 sm:grid-cols-4">
          <FormField name="startTime" label="Date">
            <DatePicker />
          </FormField>
          <FormField name="minutesWorked" label="Minutes Worked">
            <Input type="number" placeholder="120" />
          </FormField>
        </div>
      )}

      <UserInput name="userId" label="User" defaultCurrentUser />
      <FormField name="notes" label="Notes">
        <MiniRichTextEditor placeholder="Add notes" />
      </FormField>
    </div>
  );
};

export const EditTimesheetDrawer = ({
  open,
  onClose,
  timesheetId,
}: {
  open: boolean;
  onClose: () => void;
  timesheetId: number;
}) => {
  const form = useForm<z.infer<typeof Schemas.project.timesheet>>({
    resolver: zodResolver(Schemas.project.timesheet),
    defaultValues: {},
  });
  const { data: timesheet } = trpc.timesheets.details.useQuery(timesheetId);

  const timesheetUpdateMutation = trpc.timesheets.update.useMutation({
    onSuccess: () => {
      toast.success("Timesheet updated successfully");
      onClose();
    },
  });

  useEffect(() => {
    if (timesheet) {
      form.reset({ ...timesheet });
    }
  }, [timesheet]);

  return (
    <ModalDrawer
      open={open}
      onClose={onClose}
      title="Edit timesheet"
      modalClassName="min-w-[600px]"
    >
      <Form
        {...form}
        onSubmit={(values) => {
          timesheetUpdateMutation.mutate({
            id: timesheetId,
            timesheet: values,
          });
        }}
      >
        <TimesheetForm
          form={form}
          clientId={timesheet?.projectTasks?.projects.clientId}
          projectId={timesheet?.projectTasks?.projects.id}
        />
        <Button
          type="submit"
          variant="primary"
          className="w-full"
          loading={timesheetUpdateMutation.isLoading}
        >
          Update timesheet
        </Button>
      </Form>
    </ModalDrawer>
  );
};

export const AddTimesheetDrawer = ({
  open,
  onClose,
  defaultValues,
  showTaskSelect = true,
}: {
  open: boolean;
  onClose: () => void;
  defaultValues?: Partial<z.infer<typeof Schemas.project.timesheet>>;
  showTaskSelect?: boolean;
}) => {
  const form = useForm<z.infer<typeof Schemas.project.timesheet>>({
    resolver: zodResolver(Schemas.project.timesheet),
    defaultValues: {
      durationType: "DATE",
      startTime: new Date(),
      endTime: new Date(),
      ...defaultValues,
    },
  });

  const onModalClose = () => {
    form.reset();
    onClose();
  };

  const addTimesheetMutation = trpc.timesheets.add.useMutation({
    onSuccess: () => {
      toast.success("Timesheet added successfully");
      onModalClose();
    },
  });

  const onSubmit = (values: z.infer<typeof Schemas.project.timesheet>) => {
    addTimesheetMutation.mutate({
      ...values,
      endTime: values.durationType === "DATE" ? values.endTime : undefined,
      minutesWorked:
        values.durationType === "MINUTES" ? values.minutesWorked : 0,
    });
  };

  return (
    <ModalDrawer
      open={open}
      onClose={onModalClose}
      title="Add timesheet"
      modalClassName="min-w-[600px]"
      footer={
        <div className="flex gap-2 w-full">
          <Button
            onClick={() => form.handleSubmit(onSubmit)()}
            type="submit"
            variant="primary"
            className="w-full"
            loading={addTimesheetMutation.isLoading}
          >
            Add timesheet
          </Button>
        </div>
      }
    >
      <Form
        {...form}
        onSubmit={(values) => {
          addTimesheetMutation.mutate({
            ...values,
          });
        }}
      >
        <TimesheetForm form={form} showTaskSelect={showTaskSelect} />
      </Form>
    </ModalDrawer>
  );
};

type Filters = {
  search: string;
  users: number[];
  approvalStatus: z.infer<typeof enums.timeSheetStatusTypes>[];
  dates?: [Date, Date];
};

const TimesheetsList = () => {
  const navigate = useNavigate();

  const [filters, setFilters] = useParamsState<Filters>({
    search: "",
    users: [],
    approvalStatus: [],
    dates: undefined,
  });

  const [addTimesheetDrawerOpen, setAddTimesheetDrawerOpen] = useState(false);
  const [editTimesheetDrawerOpen, setEditTimesheetDrawerOpen] = useState<
    number | null
  >(null);

  const { data: users } = trpc.users.list.useQuery();
  const { data: timesheets } = trpc.timesheets.list.useQuery({
    users: filters.users,
    approvalStatuses: filters.approvalStatus,
    dates: filters.dates,
  });
  const { data: currentUser } = trpc.users.currentUser.useQuery();
  const timesheetUpdateMutation = trpc.timesheets.update.useMutation({
    onSuccess: () => {
      toast.success("Timesheet updated successfully");
    },
  });

  const allowTimesheetEditing = (
    timesheet: RouterOutputs["timesheets"]["list"][number]
  ) => {
    if (!currentUser) return false;
    return (
      currentUser.type === "ADMIN" ||
      currentUser.type === "SUPER_ADMIN" ||
      (currentUser.id === timesheet.userId &&
        timesheet.approvalStatus !== "APPROVED")
    );
  };

  return (
    <Page title="Timesheets" fullWidth className="!p-0">
      <AddTimesheetDrawer
        open={addTimesheetDrawerOpen}
        onClose={() => setAddTimesheetDrawerOpen(false)}
      />
      {editTimesheetDrawerOpen && (
        <EditTimesheetDrawer
          open={true}
          onClose={() => setEditTimesheetDrawerOpen(null)}
          timesheetId={editTimesheetDrawerOpen}
        />
      )}
      <div className="">
        <div className="flex justify-between p-3 w-full border-b border-gray-200">
          <SearchInput
            value={filters.search || ""}
            onChange={(v) =>
              setFilters({
                search: v,
              })
            }
          />
          <ResponsiveActionButton
            onClick={() => setAddTimesheetDrawerOpen(true)}
            text="Timesheet"
          />
        </div>
        <FilterBar
          className="p-3"
          filters={[
            {
              key: "dates",
              type: "date-range",
              label: "Dates",
              value: filters.dates,
              onChange: (value) =>
                setFilters({
                  dates: value as [Date, Date],
                }),
            },
            {
              key: "users",
              type: "checkbox",
              label: "Users",
              value: filters.users,
              onChange: (value) =>
                setFilters({
                  users: value as number[],
                }),
              options:
                users?.map((user) => ({
                  label: user.firstName,
                  value: user.id,
                })) || [],
            },
            {
              key: "approvalStatus",
              type: "checkbox",
              label: "Approval Status",
              value: filters.approvalStatus,
              onChange: (value) =>
                setFilters({
                  approvalStatus: value as z.infer<
                    typeof enums.timeSheetStatusTypes
                  >[],
                }),

              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              options: Object.entries(timeSheetStatuses).map(([_, value]) => ({
                label: value.label,
                value: value.value,
              })),
            },
          ]}
        />
        <div className="sm:hidden">
          {timesheets && !timesheets?.length && (
            <Empty
              icon={Timer}
              title="No timesheets"
              description="Add timesheets to this task"
            />
          )}
          {!timesheets && <FullScreenSpinner />}
          {timesheets?.map((timesheet) => (
            <div
              onClick={() => {
                const allowEditing =
                  currentUser?.type === "ADMIN" ||
                  currentUser?.type === "SUPER_ADMIN" ||
                  timesheet.approvalStatus !== "APPROVED";
                if (allowEditing) {
                  setEditTimesheetDrawerOpen(timesheet.id);
                }
              }}
              key={timesheet.id}
              className="p-4 border-b border-gray-200"
            >
              <div className="flex justify-between items-center mb-2">
                <div className="flex items-center">
                  <div className="flex justify-center items-center mr-2 w-8 h-8 bg-gray-200 rounded-full">
                    {timesheet.users.firstName.charAt(0)}
                  </div>
                  <span className="font-medium">
                    {timesheet.users.firstName} {timesheet.users.lastName}
                  </span>
                </div>
                <span className="text-sm text-gray-500">
                  {dayjs(timesheet.startTime).format("MMM DD, YYYY")}
                </span>
              </div>
              <div className="flex justify-between items-center mb-2">
                <span className="text-sm text-gray-600">
                  {timesheet.projectTasks?.projects.title}
                </span>
                <span className="text-sm font-medium">
                  {Math.floor(timesheet.minutesWorked / 60)}h{" "}
                  {timesheet.minutesWorked % 60}m
                </span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-xs text-gray-500">
                  {timesheet.projectTasks?.title}
                </span>
                <span
                  className={cn(
                    "px-2 py-1 text-xs rounded-full",
                    timeSheetStatuses[timesheet.approvalStatus].color
                  )}
                >
                  {timeSheetStatuses[timesheet.approvalStatus].label}
                </span>
              </div>
              {timesheet.approvalStatus === "OPEN" &&
                currentUser &&
                ["ADMIN", "SUPER_ADMIN"].includes(currentUser.type) && (
                  <div className="flex gap-2 items-center mt-2">
                    <Button
                      onClick={(e) => {
                        timesheetUpdateMutation.mutate({
                          id: timesheet.id,
                          timesheet: { approvalStatus: "APPROVED" },
                        });
                        e.stopPropagation();
                      }}
                      size="sm"
                      icon={Check}
                      variant="primaryOutline"
                      loading={timesheetUpdateMutation.isLoading}
                    >
                      Approve
                    </Button>
                    <Button
                      onClick={(e) => {
                        timesheetUpdateMutation.mutate({
                          id: timesheet.id,
                          timesheet: { approvalStatus: "REJECTED" },
                        });
                        e.stopPropagation();
                      }}
                      size="sm"
                      icon={X}
                      variant="destructiveOutline"
                      loading={timesheetUpdateMutation.isLoading}
                    >
                      Reject
                    </Button>
                  </div>
                )}
            </div>
          ))}
        </div>

        {!isMobile() && (
          <DataGrid
            rowKey="id"
            name="timesheetsListMain"
            className="h-[calc(100vh-117px-var(--header-height))]"
            label="Timesheets"
            rows={timesheets || []}
            columns={[
              {
                key: "user",
                name: "User",
                width: 150,
                renderCell: ({ row }) => (
                  <p>
                    {row.users.firstName} {row.users.lastName}
                  </p>
                ),
              },
              {
                key: "project",
                name: "Project",
                width: 200,
                renderCell: ({ row }) => (
                  <p
                    onClick={() => {
                      navigate(
                        `/projects/details/${row.projectTasks?.projectId}`
                      );
                    }}
                    className="cursor-pointer hover:text-primary hover:underline"
                  >
                    {row.projectTasks?.projects.title}
                  </p>
                ),
              },
              {
                key: "task",
                name: "Task",
                width: 120,
                renderCell: ({ row }) => <p>{row.projectTasks?.title}</p>,
              },
              {
                key: "client",
                name: "Client",
                width: 120,
                renderCell: ({ row }) => (
                  <p>{row.projectTasks?.projects?.clients?.name}</p>
                ),
              },
              {
                key: "date",
                name: "Date",
                width: 120,
                renderCell: ({ row }) => (
                  <p>
                    {row.endTime
                      ? dynamicTwoDateFormatting({
                          startDate: row.startTime,
                          endDate: row.endTime,
                        })
                      : dynamicDateFormatting(row.startTime, true, ", ")}
                  </p>
                ),
              },
              {
                key: "hours",
                name: "Hours",
                width: 100,
                renderCell: ({ row }) => (
                  <p>
                    {Math.floor(row.minutesWorked / 60)}h{" "}
                    {row.minutesWorked % 60 > 0 && `${row.minutesWorked % 60}m`}
                  </p>
                ),
              },
              {
                key: "status",
                name: "Status",
                width: 120,
                renderCell: ({ row }) => (
                  <Badge
                    variant={timeSheetStatuses[row.approvalStatus].variant}
                    icon={timeSheetStatuses[row.approvalStatus].icon}
                  >
                    {timeSheetStatuses[row.approvalStatus].label}
                  </Badge>
                ),
              },
              {
                key: "notes",
                name: "Notes",
                width: 200,
                renderCell: ({ row }) => <RenderHtml>{row.notes}</RenderHtml>,
              },
              {
                key: "actions",
                name: "Actions",
                width: 120,
                renderCell: ({ row }) => (
                  <div className="flex gap-2">
                    {allowTimesheetEditing(row) && (
                      <Button
                        onClick={() => {
                          const allowEditing =
                            currentUser?.type === "ADMIN" ||
                            currentUser?.type === "SUPER_ADMIN" ||
                            row.approvalStatus !== "APPROVED";
                          if (allowEditing) {
                            setEditTimesheetDrawerOpen(row.id);
                          }
                        }}
                        size="xs"
                        icon={Pencil}
                        variant="primaryOutline"
                      >
                        Edit
                      </Button>
                    )}
                    {row.approvalStatus === "OPEN" &&
                      currentUser &&
                      ["ADMIN", "SUPER_ADMIN"].includes(currentUser.type) && (
                        <div className="flex gap-2 items-center">
                          <Button
                            onClick={(e) => {
                              timesheetUpdateMutation.mutate({
                                id: row.id,
                                timesheet: { approvalStatus: "APPROVED" },
                              });
                              e.stopPropagation();
                            }}
                            size="xs"
                            icon={Check}
                            variant="primaryOutline"
                            loading={timesheetUpdateMutation.isLoading}
                          >
                            Approve
                          </Button>
                          <Button
                            onClick={(e) => {
                              timesheetUpdateMutation.mutate({
                                id: row.id,
                                timesheet: { approvalStatus: "REJECTED" },
                              });
                              e.stopPropagation();
                            }}
                            size="xs"
                            icon={X}
                            variant="destructiveOutline"
                            loading={timesheetUpdateMutation.isLoading}
                          >
                            Reject
                          </Button>
                        </div>
                      )}
                    {row.approvalStatus !== "OPEN" && (
                      <Button
                        onClick={() => {
                          timesheetUpdateMutation.mutate({
                            id: row.id,
                            timesheet: { approvalStatus: "OPEN" },
                          });
                        }}
                        loading={timesheetUpdateMutation.isLoading}
                        size="xs"
                        icon={RefreshCw}
                        variant="outline"
                      >
                        Reset status
                      </Button>
                    )}
                  </div>
                ),
              },
            ]}
            empty={{
              icon: Timer,
              title: "No timesheets",
              description: "Add timesheets to this task",
            }}
          />
        )}
      </div>
    </Page>
  );
};

export default TimesheetsList;
