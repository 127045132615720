import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import CompanyCard from "@/pages/crm/clients/CompanyCard";
import DealCard from "@/pages/crm/deals/components/dealCard";
import { ContactCard, Stage } from "@/pages/crm/deals/details";
import { calculateLineItems } from "@heffl/server/src/helpers/lineItems/calculateLineItems";
import BigCalendar from "@heffl/ui/components/bigCalendar";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import { Card } from "@heffl/ui/components/primitives/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@heffl/ui/components/primitives/dropdown-menu";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Progress } from "@heffl/ui/components/primitives/progress";
import { Separator } from "@heffl/ui/components/primitives/separator";
import StripeTabs from "@heffl/ui/components/primitives/stripe-tabs";
import { Switch } from "@heffl/ui/components/primitives/switch";

import FilterBar from "@/components/filters";
import KanbanBoard from "@/components/kanbanBoard/kanban";
import { useParamsState } from "@/lib/hooks/useParamsState";
import enums from "@heffl/server/src/schemas/enums";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import {
  formatCurrency,
  formatName,
  generateUniqueColor,
  isMobile,
} from "@heffl/ui/lib/utils";
import { DropdownMenuItem } from "@radix-ui/react-dropdown-menu";
import { ScrollArea } from "@radix-ui/react-scroll-area";
import { format } from "date-fns";
import dayjs from "dayjs";
import {
  Archive,
  Banknote,
  Building2,
  Calendar,
  CheckCircle,
  CheckCircle2,
  ChevronLeft,
  ChevronRight,
  CircleDollarSign,
  DollarSign,
  Flag,
  Goal,
  HandCoins,
  History,
  Kanban as KanbanIcon,
  List,
  LucideIcon,
  MoreVertical,
  Paperclip,
  PencilIcon,
  Settings,
  StickyNote,
  Trash,
  UserCircle,
  XCircle,
} from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { P, match } from "ts-pattern";
import { z } from "zod";
import EditProjectDrawer from "../list/components/EditProjectDrawer";
import {
  AddProjectTaskModal,
  ProjectTaskDetailsModal,
} from "../tasks/components/project-task-modals";
import Expenses from "./components/Expenses";
import Files from "./components/Files";
import Invoices from "./components/Invoices";
import Plan from "./components/List";
import Notes from "./components/Notes";
import { taskStatuses } from "./components/kanban";
import TaskCard from "./components/kanban/TaskCard";

type ProjectTab =
  | "Kanban"
  | "List"
  | "Files"
  | "Notes"
  | "Expenses"
  | "Invoices"
  | "Calendar";
export const projectTabs: {
  label: ProjectTab;
  key: ProjectTab;
  icon: LucideIcon;
}[] = [
  { label: "Kanban", key: "Kanban", icon: KanbanIcon },
  { label: "List", key: "List", icon: List },
  { label: "Calendar", key: "Calendar", icon: Calendar },
  { label: "Files", key: "Files", icon: Paperclip },
  { label: "Notes", key: "Notes", icon: StickyNote },
  { label: "Expenses", key: "Expenses", icon: Banknote },
  { label: "Invoices", key: "Invoices", icon: CircleDollarSign },
];

type TaskFilter = {
  statuses: z.infer<typeof enums.projectTaskStatusTypes>[];
  dates: [Date, Date] | undefined;
  tags: number[];
  assignees: number[];
};

const ProjectDetails = () => {
  const params = useParams();
  const projectId = Number(params.projectId);
  const navigate = useNavigate();
  const confirm = useConfirm();
  const trpcUtils = trpc.useUtils();

  const [tabCounts, setTabCounts] = useState({
    Kanban: 0,
    List: 0,
    Files: 0,
    Notes: 0,
    Expenses: 0,
    Invoices: 0,
    Calendar: 0,
  });
  const [editProject, setEditProject] = useState(false);
  const [updating, setUpdating] = useState<
    [string | undefined, number | undefined]
  >([undefined, undefined]);
  const [addTaskModalOpen, setAddTaskModalOpen] = useState<boolean>(false);
  const [projectsTab, setSelectedProjectTab] = useState<ProjectTab>("Kanban");
  const [showCompleted, setShowCompleted] = useState(true);
  const [editTaskId, setEditTaskId] = useState<number | undefined>();
  const [filters, setFilters] = useParamsState<TaskFilter>({
    dates: undefined,
    tags: [],
    assignees: [],
    statuses: [],
  });

  const taskFilters = useMemo(() => {
    return {
      projectId: projectId,
      orderBy:
        projectsTab === "Kanban"
          ? ("position" as const)
          : ("createdAt" as const),
      showAllTasks: true,
      assignees: filters.assignees,
      tags: filters.tags,
      statuses: filters.statuses,
      dates: filters.dates,
      showAllProjectTasks: true,
    };
  }, [filters, projectsTab]);

  const bulkTaskUpdate = trpc.projects.tasks.bulkUpdate.useMutation();
  const { data: projectDetails } = trpc.projects.details.useQuery(projectId);
  const { data: tasks } = trpc.projects.tasks.list.useQuery(taskFilters);
  const { data: users } = trpc.users.list.useQuery({
    excludeType: ["FIELD_STAFF"],
  });
  const { data: tags } = trpc.tags.list.useQuery({
    type: "PROJECT_TASK",
  });

  const projectUpdateMutation = trpc.projects.update.useMutation({
    onSuccess: () => {
      setUpdating([undefined, undefined]);
    },
  });
  const projectDeleteMutation = trpc.projects.delete.useMutation({
    onSuccess: () => {
      toast.success("Successfully deleted project");
      navigate("/projects/board");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  useEffect(() => {
    if (projectDetails) {
      setTabCounts({
        Files: projectDetails._count.projectFiles,
        Notes: projectDetails._count.projectNotes,
        Kanban: 0,
        List: 0,
        Calendar: 0,
        Expenses: projectDetails._count.expenses,
        Invoices: projectDetails._count.projectInvoices,
      });
    }
  }, [projectDetails]);

  if (!projectDetails) return <FullScreenSpinner />;

  const totalExpenses = projectDetails.expenses.reduce(
    (acc, expense) => acc + expense.amount,
    0
  );

  const totalInvoices = projectDetails.projectInvoices.reduce(
    (total, invoice) => {
      const invoiceTotal = calculateLineItems({
        lineItems: invoice.invoices.invoiceProducts,
        discount: invoice.invoices.discount,
      }).total;
      return total + invoiceTotal;
    },
    0
  );

  const margin = totalInvoices - totalExpenses;

  const MoreButton = () => {
    return (
      <DropdownMenu>
        <DropdownMenuTrigger>
          <Button size="sm" variant="outline" className="!h-full">
            <MoreVertical className="w-4 h-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem>
            <Button
              className="gap-2"
              variant={"ghost"}
              loading={projectUpdateMutation.isLoading}
              onClick={() =>
                projectUpdateMutation.mutate({
                  id: projectDetails.id,
                  project: {
                    archived: !projectDetails.archived,
                  },
                })
              }
            >
              <Archive className="w-4 h-4" />
              {projectDetails.archived ? "Unarchive" : "Archive"}
            </Button>
          </DropdownMenuItem>
          <DropdownMenuItem
            className="flex flex-row gap-2 items-center p-3 text-red-400 cursor-pointer"
            onClick={async () => {
              const confirmed = await confirm({
                title: "Delete project",
                body: "Are you sure you want to delete this project? All tasks and files will be permanently lost.",
                actionButton: "Confirm",
              });
              if (confirmed) {
                projectDeleteMutation.mutate(projectId);
              }
            }}
          >
            <Trash className="w-4 h-4" /> Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    );
  };

  const projectInfo = (
    <ScrollArea
      className={`block overflow-auto p-4 w-full h-full border-l border-gray-200 sm:w-1/4 sm:block sm:p-3`}
    >
      <div className="flex flex-col gap-5 py-6">
        <div className="pr-0 text-lg font-semibold sm:pr-4">
          {projectDetails.title}
        </div>
        <Button
          onClick={() => setEditProject(true)}
          size={isMobile() ? "lg" : "sm"}
          className="self-center w-full sm:self-end sm:w-fit"
        >
          <PencilIcon className="h-4" />
          Edit project
        </Button>
        <div className="flex flex-row gap-2 items-center">
          <History className="w-4 h-4" />
          <Progress
            value={(projectDetails.completed / projectDetails.totalTasks) * 100}
            className="w-[220px] h-2 ml-4"
          />
          <p className="text-xs text-gray-500">
            {projectDetails.completed}/{projectDetails.totalTasks}
          </p>
        </div>

        <div className="flex flex-row justify-between w-full">
          <div className="flex flex-row gap-2 p-1 rounded-sm cursor-pointer hover:bg-gray-100">
            {/* TODO: onclick show calendar */}
            <Flag className="w-4 h-4" />
            <p className="text-xs text-gray-700">
              {dayjs(projectDetails.startDate).format("MMM DD, YYYY")}
            </p>
          </div>
          <div className="flex flex-row gap-2 p-1 rounded-sm cursor-pointer hover:bg-gray-100">
            <Goal className="w-4 h-4" />
            <p className="text-xs text-gray-700">
              {dayjs(projectDetails.endDate).format("MMM DD, YYYY")}
            </p>
          </div>
        </div>
        <div>
          <p className="text-xs text-gray-500">Client</p>
          <div className="flex flex-wrap gap-2 items-center mt-1">
            {projectDetails.clients ? (
              <Badge
                onClick={() =>
                  projectDetails.clients &&
                  navigate(`/crm/clients/details/${projectDetails.clients.id}`)
                }
                key={projectDetails.clients.id}
                icon={Building2}
                variant="warning"
                className="cursor-pointer"
              >
                {projectDetails.clients.name}
              </Badge>
            ) : (
              "No client added"
            )}
          </div>
          <p className="mt-2 text-xs text-gray-500">Assignees</p>
          <div className="flex flex-wrap gap-2 items-center mt-2">
            {projectDetails.projectAssignees.map((assignee) => (
              <Badge key={assignee.userId} icon={UserCircle} variant="unique">
                {assignee.users.firstName}
              </Badge>
            ))}
          </div>
          <p className="mt-3 text-xs text-gray-500">Description</p>
          <p>{projectDetails.description || "No description added"}</p>
        </div>
        <Separator className="w-full" />
        <p className="font-medium text-md">Linked to</p>
        <div className="flex flex-col gap-2">
          {projectDetails.contacts && (
            <ContactCard contact={projectDetails.contacts} />
          )}
          {projectDetails.clients && (
            <CompanyCard company={projectDetails.clients} />
          )}
          {projectDetails.deals && (
            <DealCard isListingCard={true} deal={projectDetails.deals} />
          )}
        </div>
        <Separator className="w-full" />
        <div className="flex flex-col gap-3">
          <p className="font-medium text-md">Profitability forecast</p>
          <Card className="flex gap-3">
            <div className="flex justify-center items-center p-4 bg-blue-100 rounded-lg">
              <DollarSign className="w-5 text-blue-500" />
            </div>
            <div className="flex flex-col gap-2 justify-center">
              <p className="text-sm text-gray-600">Revenues</p>
              <p className="text-lg font-medium">
                {formatCurrency(totalInvoices, "AED")} {""}
              </p>
            </div>
          </Card>
          <Card className="flex gap-3">
            <div className="flex justify-center items-center p-4 bg-red-100 rounded-lg">
              <Banknote className="w-5 text-red-500" />
            </div>
            <div className="flex flex-col gap-2 justify-center">
              <p className="text-sm text-gray-600">Expenses</p>
              <p className="text-lg font-medium">
                {formatCurrency(totalExpenses, "AED")} {""}
              </p>
            </div>
          </Card>
          <Card className="flex gap-3">
            <div className="flex justify-center items-center p-4 bg-green-100 rounded-lg">
              <HandCoins className="w-5 text-green-500" />
            </div>
            <div className="flex flex-col gap-2 justify-center">
              <p className="text-sm text-gray-600">Margin</p>
              <p className="text-lg font-medium">
                {formatCurrency(margin, "AED")} {""}
              </p>
            </div>
          </Card>
        </div>
      </div>
    </ScrollArea>
  );

  const projectTasks = (
    <div className="sm:w-3/4 !h-full sm:px-4 sm:py-4 p-4 sm:block mt-4 sm:mt-0  bg-white border-none sm:border-l border-gray-200 block  w-full">
      <div>
        <div className="hidden justify-between sm:flex">
          <div>
            <span className="text-gray-500">Start:</span>{" "}
            <span>
              {projectDetails.startDate
                ? format(projectDetails.startDate, "PPP")
                : "Not set"}
            </span>
          </div>

          <div>
            <span className="text-gray-500">Closing:</span>{" "}
            <span>
              {projectDetails.endDate
                ? format(projectDetails.endDate, "PPP")
                : "Not set"}
            </span>
          </div>
        </div>
        {isMobile() && (
          <div className="flex justify-between items-center sm:hidded">
            <Button variant={"ghost"}>
              <ChevronLeft className="w-4 h-4" />
            </Button>
            <p className="text-sm font-semibold text-gray-700">
              {projectDetails.projectPipelineStages.name}
            </p>
            <Button variant={"ghost"}>
              <ChevronRight className="w-4 h-4" />
            </Button>
          </div>
        )}
        <div className="flex flex-row flex-grow mt-1">
          {projectDetails.projectPipelineStages.projectPipelines.projectPipelineStages.map(
            (stage, index) => (
              <Stage
                key={stage.id}
                loading={updating[0] === "stage" && updating[1] === stage.id}
                onClick={() => {
                  setUpdating(["stage", stage.id]);
                  projectUpdateMutation.mutate({
                    id: projectId,
                    project: {
                      projectStageId: stage.id,
                    },
                  });
                }}
                index={index}
                stageCount={
                  projectDetails.projectPipelineStages.projectPipelines
                    .projectPipelineStages.length
                }
                text={stage.name}
                selected={projectDetails.projectStageId === stage.id}
                selectedIndex={
                  projectDetails.projectPipelineStages.projectPipelines.projectPipelineStages.findIndex(
                    (s) => s.id === projectDetails.projectStageId
                  ) || 0
                }
              />
            )
          )}
        </div>
        {isMobile() && (
          <div className="flex flex-row gap-2 mt-3">
            {match(projectDetails.status)
              .with("ACTIVE", () => (
                <div className="flex gap-3 w-full">
                  <Button
                    onClick={() =>
                      projectUpdateMutation.mutate({
                        id: projectId,
                        project: { status: "COMPLETED" },
                      })
                    }
                    loading={
                      projectUpdateMutation.variables?.project?.status ===
                        "COMPLETED" && projectUpdateMutation.isLoading
                    }
                    className="w-full"
                    variant={"primary"}
                    icon={CheckCircle2}
                  >
                    Completed
                  </Button>
                  <Button
                    onClick={() =>
                      projectUpdateMutation.mutate({
                        id: projectId,
                        project: { status: "CANCELLED" },
                      })
                    }
                    variant={"destructive"}
                    loading={
                      projectUpdateMutation.variables?.project?.status ===
                        "CANCELLED" && projectUpdateMutation.isLoading
                    }
                    className="w-full"
                  >
                    Cancelled
                  </Button>
                </div>
              ))
              .with(P.union("COMPLETED", "CANCELLED"), (status) => (
                <div className="flex flex-row items-center space-x-2">
                  <Badge
                    className={`capitalize ${
                      status === "COMPLETED" ? "bg-green-500" : "bg-red-500"
                    }`}
                  >
                    {status}
                  </Badge>
                  <Button
                    onClick={() =>
                      projectUpdateMutation.mutate({
                        id: projectId,
                        project: { status: "ACTIVE" },
                      })
                    }
                  >
                    Reset
                  </Button>
                </div>
              ))
              .exhaustive()}
            <MoreButton />
          </div>
        )}
      </div>

      <StripeTabs
        className="mt-4"
        value={projectsTab}
        onChange={(value) => setSelectedProjectTab(value as ProjectTab)}
        items={projectTabs.map((tab) => ({
          label: tab.label,
          key: tab.key,
          count: tabCounts[tab.key],
          icon: tab.icon,
        }))}
      />
      {projectId && (
        <AddProjectTaskModal
          open={addTaskModalOpen}
          onClose={() => setAddTaskModalOpen(false)}
          projectId={projectId}
        />
      )}

      {editTaskId && (
        <ProjectTaskDetailsModal
          open={!!editTaskId}
          onClose={() => setEditTaskId(undefined)}
          taskId={editTaskId}
        />
      )}

      {["Kanban", "List", "Calendar"].includes(projectsTab) && (
        <div className="flex gap-2 justify-between items-center py-4 w-full">
          <FilterBar
            filters={[
              {
                key: "dates",
                type: "date-range",
                label: "Date",
                value: filters.dates,
                onChange: (value) =>
                  setFilters({ dates: value as [Date, Date] }),
              },
              {
                key: "status",
                type: "checkbox",
                label: "Status",
                value: filters.statuses,
                onChange: (value) =>
                  setFilters({
                    statuses: value as z.infer<
                      typeof enums.projectTaskStatusTypes
                    >[],
                  }),
                options: taskStatuses.map((status) => ({
                  label: status.label,
                  value: status.value,
                })),
              },
              {
                key: "assignees",
                type: "checkbox",
                label: "Assignees",
                value: filters.assignees,
                onChange: (value) =>
                  setFilters({ assignees: value as number[] }),
                options: users?.map((user) => ({
                  label: formatName(user),
                  value: user.id,
                })),
              },
              {
                key: "tags",
                type: "checkbox",
                label: "Tags",
                value: filters.tags,
                onChange: (value) => setFilters({ tags: value as number[] }),
                options: tags?.map((tag) => ({
                  label: tag.name,
                  value: tag.id,
                })),
              },
            ]}
          />
          <div className="flex gap-2 items-center">
            <Button
              variant="primary"
              size="sm"
              icon={CheckCircle}
              onClick={() => setAddTaskModalOpen(true)}
            >
              Add task
            </Button>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button size="sm">
                  <Settings className="h-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem className="flex gap-2 items-center">
                  Show completed tasks{" "}
                  <Switch
                    checked={showCompleted}
                    onChange={() => setShowCompleted((old) => !old)}
                  />
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      )}
      <ScrollArea className="overflow-auto h-[calc(100%-200px)]">
        {projectsTab === "Kanban" ? (
          <KanbanBoard
            items={tasks || []}
            statusKey="status"
            columns={taskStatuses.map((status) => ({
              id: status.value,
              label: status.label,
              value: status.value,
              color: status.color,
            }))}
            renderItem={(task) => (
              <TaskCard
                task={task}
                onTaskClick={() => setEditTaskId(Number(task.id))}
              />
            )}
            onDragging={(updated) => {
              trpcUtils.projects.tasks.list.setData(taskFilters, () => updated);
            }}
            onChange={(updated) => {
              bulkTaskUpdate.mutate(
                updated.map((item) => ({
                  id: Number(item.id),
                  position: item.position,
                  status: item.status as z.infer<
                    typeof enums.projectTaskStatusTypes
                  >,
                }))
              );
            }}
            className="!h-full"
          />
        ) : (
          <></>
        )}
        {projectsTab === "List" ? (
          <Plan data={projectDetails} showCompleted={showCompleted} />
        ) : (
          <></>
        )}
        {projectsTab === "Calendar" && (
          <BigCalendar
            className="mt-2 h-full"
            events={
              tasks
                ?.filter(
                  (task): task is typeof task & { date: Date } =>
                    task.date !== null
                )
                .map((task) => ({
                  id: task.id.toString(),
                  title: task.title,
                  start: task.date,
                  end: task.date,
                  backgroundColor: generateUniqueColor(task.title, 500),
                })) ?? []
            }
            renderEvent={(event) => (
              <div className="text-white rounded-md">{event.event.title}</div>
            )}
            onEventClick={(event) => {
              setEditTaskId(Number(Number(event.id)));
            }}
          />
        )}
        {projectsTab === "Files" ? <Files /> : <></>}
        {projectsTab === "Notes" ? <Notes /> : <></>}
        {projectsTab === "Expenses" ? (
          <Expenses projectId={projectId} />
        ) : (
          <></>
        )}
        {projectsTab === "Invoices" ? (
          <Invoices
            projectId={projectId}
            clientId={projectDetails.clientId || undefined}
          />
        ) : (
          <></>
        )}
      </ScrollArea>
    </div>
  );

  return (
    <Page
      showBack
      fullWidth
      className="!p-0 overflow-hidden"
      title={`${projectDetails.projectPipelines.singularName} details`}
      tags={
        <div>
          {projectDetails.archived && (
            <Badge variant="neutral" icon={Archive}>
              Archived
            </Badge>
          )}
        </div>
      }
      breadcrumbs={[
        {
          label: "Projects",
          path: "/projects",
        },
        {
          label: "Project details",
          path: `/projects/details/${projectId}`,
        },
      ]}
      suffix={
        !isMobile() && (
          <div className="flex flex-row gap-2 sm:px-0">
            {match(projectDetails.status)
              .with("ACTIVE", () => (
                <>
                  <Button
                    onClick={() =>
                      projectUpdateMutation.mutate({
                        id: projectId,
                        project: { status: "COMPLETED" },
                      })
                    }
                    loading={
                      projectUpdateMutation.variables?.project?.status ===
                        "COMPLETED" && projectUpdateMutation.isLoading
                    }
                    variant={"primary"}
                    icon={CheckCircle2}
                  >
                    Completed
                  </Button>
                  <Button
                    onClick={() =>
                      projectUpdateMutation.mutate({
                        id: projectId,
                        project: { status: "CANCELLED" },
                      })
                    }
                    variant={"destructive"}
                    loading={
                      projectUpdateMutation.variables?.project?.status ===
                        "CANCELLED" && projectUpdateMutation.isLoading
                    }
                    icon={XCircle}
                  >
                    Cancelled
                  </Button>
                </>
              ))
              .with(P.union("COMPLETED", "CANCELLED"), (status) => (
                <div className="flex flex-row items-center space-x-2">
                  <Badge
                    className="capitalized"
                    variant={status === "COMPLETED" ? "success" : "error"}
                  >
                    {status}
                  </Badge>
                  <Button
                    onClick={() =>
                      projectUpdateMutation.mutate({
                        id: projectId,
                        project: { status: "ACTIVE" },
                      })
                    }
                  >
                    Reset
                  </Button>
                </div>
              ))
              .exhaustive()}

            <MoreButton />
          </div>
        )
      }
    >
      <EditProjectDrawer
        open={editProject}
        onClose={() => setEditProject(false)}
        id={projectId}
      />
      <div className="flex flex-col sm:flex-row mt-0  w-full sm:mb-0 mb-[60px]  sm:h-[calc(100vh-64px)]  h-full">
        {projectTasks}
        {projectInfo}
      </div>
    </Page>
  );
};

export default ProjectDetails;
