import Empty from "@/components/Empty";
import { SearchInput } from "@/components/FormComponents";
import Page from "@/components/page";
import Schemas from "@heffl/server/src/schemas";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import Select from "@heffl/ui/components/primitives/creatable-select";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import { Input } from "@heffl/ui/components/primitives/input";
import ResponsiveActionButton from "@heffl/ui/components/primitives/responsive-action-button";
import { zodResolver } from "@hookform/resolvers/zod";
import { Save, Tag, Trash2 } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useImmer } from "use-immer";
import { z } from "zod";
import { ColorPickerInput } from "../documentTemplates/components/templateForm";
import { RouterOutputs, trpc } from "@/helpers/trpc";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Card } from "@heffl/ui/components/primitives/card";
import toast from "react-hot-toast";
import { Button } from "@heffl/ui/components/primitives/button";
import { capitalizeFirstLetter, cn } from "@heffl/ui/lib/utils";
import enums from "@heffl/server/src/schemas/enums";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";

const tags: {
  value: z.infer<typeof enums.tagTypes>;
  label: string;
}[] = [
  {
    value: "QUOTATION",
    label: "Quotation",
  },
  {
    value: "INVOICE",
    label: "Invoice",
  },
  {
    value: "SCHEDULE",
    label: "Schedule",
  },
  {
    value: "JOB",
    label: "Job",
  },
  {
    value: "CLIENT",
    label: "Client",
  },
  {
    value: "PROJECT",
    label: "Project",
  },
  {
    value: "PROJECT_TASK",
    label: "Project Task",
  },
  {
    value: "PURCHASE_ORDER",
    label: "Purchase Order",
  },
];

export const TagForm = ({ edit }: { edit?: boolean }) => (
  <div className="flex flex-col gap-3">
    {!edit && (
      <FormField name="type" label="Type">
        <Select
          allowClear={false}
          options={tags}
          placeholder="Select tag type"
        />
      </FormField>
    )}
    <FormField name="name" label="Name">
      <Input placeholder="Tag name" />
    </FormField>
    <FormField name="color" label="Color">
      <ColorPickerInput />
    </FormField>
  </div>
);

const EditTagDrawer = ({
  open,
  onClose,
  tagId,
}: {
  open: boolean;
  onClose: () => void;
  tagId: number;
}) => {
  const confirm = useConfirm();

  const form = useForm<z.infer<typeof Schemas.tags.tags>, unknown>({
    resolver: zodResolver(Schemas.tags.tags),
    defaultValues: {},
  });
  const { data: tag } = trpc.tags.details.useQuery(tagId);

  const deleteTagMutation = trpc.tags.delete.useMutation({
    onSuccess: () => {
      toast.success("Tag deleted successfully");
      onClose();
    },
  });

  const tagUpdateMutation = trpc.tags.update.useMutation({
    onSuccess: () => {
      toast.success("Tag updated successfully");
      onClose();
    },
  });

  useEffect(() => {
    if (tag) {
      form.reset({
        name: tag.name,
        color: tag.color,
        type: tag.type,
      });
    }
  }, [tag]);

  return (
    <ModalDrawer
      open={open}
      onClose={onClose}
      title="Edit tag"
      footer={
        <div className="flex gap-3 w-full">
          <Button
            variant="destructiveOutline"
            onClick={async () => {
              const confirmed = await confirm({
                title: "Are you sure you want to delete this tag?",
                body: "This will delete all references to this tag, this cannot be undone.",
              });
              if (confirmed) {
                deleteTagMutation.mutate(tagId);
              }
            }}
            icon={Trash2}
          >
            Delete
          </Button>
          <Button
            icon={Save}
            type="submit"
            variant="primary"
            className="w-full"
            loading={tagUpdateMutation.isLoading}
          >
            Update tag
          </Button>
        </div>
      }
    >
      <Form
        {...form}
        onSubmit={(values) => {
          tagUpdateMutation.mutate({
            id: tagId,
            tag: {
              name: values.name,
              color: values.color,
            },
          });
        }}
      >
        <TagForm edit />
      </Form>
    </ModalDrawer>
  );
};

const AddTagDrawer = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const form = useForm<z.infer<typeof Schemas.tags.tags>, unknown>({
    resolver: zodResolver(Schemas.tags.tags),
    defaultValues: {
      type: "QUOTATION",
      color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
    },
  });

  const onModalClose = () => {
    form.reset();
    onClose();
  };

  const addTagMutation = trpc.tags.add.useMutation({
    onSuccess: () => {
      toast.success("Tag added successfully");
      onModalClose();
    },
  });

  return (
    <ModalDrawer open={open} onClose={onModalClose} title="Add tag">
      <Form
        {...form}
        onSubmit={(values) => {
          addTagMutation.mutate(values);
        }}
      >
        <TagForm />
        <Button
          type="submit"
          variant="primary"
          className="w-full"
          loading={addTagMutation.isLoading}
        >
          Add tag
        </Button>
      </Form>
    </ModalDrawer>
  );
};

type TTag = RouterOutputs["tags"]["list"][number];

const TagCard = ({ tag, onClick }: { tag: TTag; onClick?: () => void }) => {
  return (
    <Card className="flex gap-4 cursor-pointer" onClick={onClick}>
      <div
        className={cn(`p-4 rounded-full`)}
        style={{
          backgroundColor: tag.color,
        }}
      >
        <Tag className="h-6 text-white" />
      </div>
      <div className="flex flex-col gap-1 justify-center">
        <p className="text-sm font-medium">{tag.name}</p>
        <p className="text-xs text-gray-500">
          {capitalizeFirstLetter(tag.type)}
        </p>
      </div>
    </Card>
  );
};

const Tags = () => {
  const [filters, setFilters] = useImmer({
    search: "",
  });
  const [addTag, setAddTag] = useState(false);
  const [editTag, setEditTag] = useState<number | undefined>(undefined);
  const { data } = trpc.tags.list.useQuery();

  if (!data) return <FullScreenSpinner />;
  return (
    <Page title="Tags">
      <AddTagDrawer open={addTag} onClose={() => setAddTag(false)} />
      {editTag && (
        <EditTagDrawer
          open={!!editTag}
          onClose={() => setEditTag(undefined)}
          tagId={editTag}
        />
      )}
      <div className="flex justify-between w-full">
        <SearchInput
          placeholder="Search tags..."
          value={filters.search}
          onChange={(value) =>
            setFilters((f) => {
              f.search = value;
            })
          }
        />
        <div className="space-x-3">
          <ResponsiveActionButton onClick={() => setAddTag(true)} text="Tag" />
        </div>
      </div>

      {!data?.length ? (
        <Empty
          title="No tags added"
          icon={Tag}
          description="Please add a tag to the contact"
          className="mt-8"
        />
      ) : (
        <div className="grid grid-cols-2 gap-3 mt-4 sm:grid-cols-4">
          {data?.map((tag) => (
            <TagCard
              key={tag.id}
              tag={tag}
              onClick={() => setEditTag(tag.id)}
            />
          ))}
        </div>
      )}
    </Page>
  );
};

export default Tags;
