import DataGrid from "@/components/dataGrid/DataGrid";
import { RouterOutputs, trpc } from "@/helpers/trpc";
import { Button } from "@heffl/ui/components/primitives/button";
import TagsInput from "@heffl/ui/components/TagInput";
import { makeEllipsis } from "@heffl/ui/lib/utils";
import { ArrowUpRightFromSquare } from "lucide-react";
import { useNavigate } from "react-router-dom";

const ProjectTable = ({
  projects,
}: {
  projects?: RouterOutputs["projects"]["list"]["projects"];
}) => {
  const navigate = useNavigate();

  const { data: tags } = trpc.tags.list.useQuery({
    type: "PROJECT",
  });

  const updateProjectMutation = trpc.projects.update.useMutation();

  return (
    <DataGrid
      rowKey="id"
      className="h-[calc(100vh-152px)]"
      name="projectsListMain"
      label="Projects"
      rows={projects || []}
      columns={[
        {
          key: "title",
          name: "Title",
          width: 400,
          renderCell: ({ row }) => (
            <div
              className="font-medium cursor-pointer hover:text-primary-600"
              onClick={() => navigate(`/projects/details/${row.id}`)}
            >
              {row.title}
            </div>
          ),
        },
        {
          key: "board",
          name: "Board",
          width: 150,
          renderCell: ({ row }) => (
            <div className="font-medium text-gray-700">
              {row.projectPipelines.name}
            </div>
          ),
        },
        {
          key: "phase",
          name: "Phase",
          width: 150,
          renderCell: ({ row }) => (
            <div className="font-medium text-gray-700">
              {row.projectPipelineStages?.name}
            </div>
          ),
        },
        {
          key: "tags",
          name: "Tags",
          width: 150,
          renderCell: ({ row }) => (
            <TagsInput
              tags={tags}
              value={row.projectTags?.map((tag) => tag.tagId)}
              onChange={(tags) => {
                updateProjectMutation.mutate({
                  id: row.id,
                  project: {
                    projectTags: tags,
                  },
                });
              }}
            />
          ),
        },
        {
          key: "startDate",
          name: "Start Date",
          width: 120,
          renderCell: ({ row }) => (
            <div>{row.startDate?.toDateString() || ""}</div>
          ),
        },
        {
          key: "endDate",
          name: "End Date",
          width: 120,
          renderCell: ({ row }) => (
            <div>{row.endDate?.toDateString() || ""}</div>
          ),
        },
        {
          key: "assignee",
          name: "Assignee",
          width: 200,
          renderCell: ({ row }) => (
            <div>
              {row.projectAssignees
                ?.map((assignee) => assignee.users.firstName)
                .join(", ")}
            </div>
          ),
        },
        {
          key: "client",
          name: "Client",
          width: 200,
          renderCell: ({ row }) =>
            row.clients && (
              <Button
                variant="ghost"
                suffix={<ArrowUpRightFromSquare className="ml-2 w-4 h-4" />}
                className="hover:text-primary-700 hover:underline"
                onClick={() =>
                  row.clientId &&
                  navigate(`/crm/clients/details/${row.clientId}`)
                }
              >
                {makeEllipsis(row.clients.name, 10)}
              </Button>
            ),
        },
        // {
        //   key: "progressBar",
        //   name: "Progress",
        //   width: 200,
        //   renderCell: ({ row }) => (
        //     <div className="flex flex-col w-full">
        //       <div className="flex justify-between mb-1 text-xs">
        //         <span>{`${row.completed}/${row.totalTasks}`}</span>
        //         <span>{`${Math.round(
        //           (row.completed / row.totalTasks) * 100
        //         )}%`}</span>
        //       </div>
        //       <div className="w-full bg-gray-200 rounded-full h-2.5">
        //         <div
        //           className="bg-blue-600 h-2.5 rounded-full"
        //           style={{
        //             width: `${(row.completed / row.totalTasks) * 100}%`,
        //           }}
        //         ></div>
        //       </div>
        //     </div>
        //   ),
        // },
      ]}
    />
  );
};

export default ProjectTable;
