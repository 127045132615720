import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@heffl/ui/components/primitives/dropdown-menu";
import { cn } from "@heffl/ui/lib/utils";
import { LucideIcon } from "lucide-react";

export type DropMenuItem = {
  label: React.ReactNode | string;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  className?: string;
  icon?: LucideIcon;
  iconClassName?: string;
  style?: object;
};

const DropMenu = ({
  children,
  items,
  className,
  align = "end",
  contentClassName,
  empty,
}: {
  children: React.ReactNode;
  items: DropMenuItem[];
  className?: string;
  align?: "start" | "end" | "center";
  contentClassName?: string;
  empty?: React.ReactNode;
}) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger className={cn(className)}>
        {children}
      </DropdownMenuTrigger>
      <DropdownMenuContent align={align} className={cn(contentClassName)}>
        {items.length === 0 && empty
          ? empty
          : items.map((item, index) => {
              const Icon = item.icon;
              return (
                <DropdownMenuItem
                  key={index}
                  className={cn(
                    "cursor-pointer flex items-center gap-2 text-wrap",
                    item.className
                  )}
                  style={item.style}
                  onClick={(e) => {
                    e.stopPropagation();
                    item.onClick(e);
                  }}
                >
                  {Icon && (
                    <div className="flex-shrink-0">
                      <Icon className={cn("w-4 h-4", item.iconClassName)} />
                    </div>
                  )}
                  <div className="flex-grow text-ellipsis">{item.label}</div>
                </DropdownMenuItem>
              );
            })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default DropMenu;
