import { trpc } from "@/helpers/trpc";
import { Card } from "@heffl/ui/components/primitives/card";
import { DatePicker } from "@heffl/ui/components/primitives/datepicker";
import { FormField } from "@heffl/ui/components/primitives/form";
import { MultiSelect } from "@heffl/ui/components/primitives/multi-select";
import dayjs from "dayjs";
import { CalendarDays, Pencil, Plus, X } from "lucide-react";
import { useState } from "react";

import { Button } from "@heffl/ui/components/primitives/button";
import FormList from "@heffl/ui/components/primitives/form-list";
import { Label } from "@heffl/ui/components/primitives/label";
import { Sheet } from "@heffl/ui/components/primitives/sheet";
import { Switch } from "@heffl/ui/components/primitives/switch";
import { FsSchedule } from "./job-form";

export const SchedulesInput = ({
  value = [],
  name,
}: {
  name?: string;
  value?: FsSchedule[];
  onChange?: (value: FsSchedule[]) => void;
}) => {
  const [showEdit, setShowEdit] = useState(false);

  const { data: tags } = trpc.tags.list.useQuery({
    type: "SCHEDULE",
  });
  // TODO add enddate if start date is changed

  return (
    <>
      <Sheet
        title="Edit schedules"
        open={showEdit}
        onClose={() => setShowEdit(false)}
        className="w-[70vw]"
      >
        <div className="mt-8">
          <FormList name={name || ""}>
            {({ fields, append, _name, remove }) => {
              return (
                <div className="flex flex-col gap-2">
                  <Button
                    onClick={() =>
                      append({
                        startDate: dayjs().toDate(),
                        endDate: dayjs().toDate(),
                        timeDisabled: true,
                        status: "SCHEDULED" as const,
                        invoiceRequired: false,
                        fsScheduleAssignees:
                          value && value.length
                            ? value[0].fsScheduleAssignees
                            : [],
                      })
                    }
                    variant="primary"
                    size="md"
                    className="self-end"
                  >
                    <Plus className="h-5" />
                    Add new schedule
                  </Button>
                  <div className="grid grid-cols-3 gap-3">
                    {fields.map((field, index) => (
                      <Card key={field.id} className="flex relative gap-2 px-2">
                        <div className="rounded w-5 text-center h-5 bg-[#22577A] text-white">
                          {index + 1}
                        </div>
                        <div className="flex flex-col gap-2 w-full">
                          <FormField
                            name={_name(index, "startDate")}
                            label="Start date"
                          >
                            <DatePicker />
                          </FormField>
                          <FormField name={_name(index, "fsScheduleTags")}>
                            <MultiSelect
                              placeholder="Add tags"
                              options={
                                tags?.map((tag) => ({
                                  label: tag.name,
                                  value: tag.id,
                                  color: tag.color,
                                })) || []
                              }
                            />
                          </FormField>
                          <div className="flex justify-between items-center w-full">
                            <Label>Invoice required</Label>
                            <FormField
                              name={_name(index, "invoiceRequired")}
                              className="w-fit"
                            >
                              <Switch />
                            </FormField>
                          </div>
                        </div>
                        <X
                          onClick={() => remove(index)}
                          className="h-5 text-red-500 cursor-pointer"
                        />
                      </Card>
                    ))}
                  </div>
                </div>
              );
            }}
          </FormList>
        </div>
      </Sheet>
      <div className="flex justify-between mt-6 w-full">
        <Label>Schedules</Label>
        <Button
          onClick={() => setShowEdit(true)}
          size="xs"
          className="flex gap-1 items-center"
        >
          <span className="flex items-center">
            <Pencil className="h-3.5" /> Edit schedules
          </span>
          <span className="text-xs">({value?.length})</span>
        </Button>
      </div>
      <div className="grid grid-cols-3 gap-2 justify-between pt-1 w-full">
        {value?.slice(0, 5).map((schedule) => (
          <div
            className="flex gap-2 justify-center items-center p-2 w-full text-xs rounded-md border shadow-sm"
            key={schedule.startDate.toString()}
          >
            <CalendarDays className="w-4 h-4 text-yellow-600" />
            <p>{dayjs(schedule.startDate).format("DD/MM/YYYY")}</p>
          </div>
        ))}
        {value && value.length > 5 && (
          <div
            onClick={() => setShowEdit(true)}
            className="flex gap-2 justify-center items-center p-2 w-full text-xs rounded-md border shadow-sm cursor-pointer text-primary-800"
          >
            +{value.length - 5} more
          </div>
        )}
      </div>
    </>
  );
};
