import { Card } from "@heffl/ui/components/primitives/card";
import { RouterOutputs } from "@/helpers/trpc";
import { DATE_TIME_WORD_FORMAT } from "@/lib/constants";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

type TTemplate = RouterOutputs["templates"]["list"][number];

const TemplateCard = ({ data }: { data: TTemplate }) => {
  const navigate = useNavigate();

  return (
    <Card onClick={() => navigate(`/templates/edit/${data.id}`)}>
      <div className="flex flex-col gap-2">
        <div className="flex flex-row justify-between">
          <p className="text-sm font-medium">{data.name}</p>

          <div className="flex flex-row gap-2">
            <div
              className={`flex px-3 py-1 text-xs font-medium text-white rounded-full bg-primary w-fit itemplateCards-center`}
            >
              {data.type}
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-between">
          <p className="text-xs">Quotation number</p>
          <p className="text-xs text-gray-500">#{data.id}</p>
        </div>
        <div className="flex flex-row justify-between">
          <p className="text-xs">Created on</p>
          <p className="text-xs text-gray-500">
            {" "}
            {format(data.createdAt, DATE_TIME_WORD_FORMAT)}
          </p>
        </div>
      </div>
    </Card>
  );
};

export default TemplateCard;
