import { z } from "zod";

export const column = z.object({
  key: z.string(),
  name: z.string(),
  width: z.number().optional(),
  hidden: z.boolean(),
  frozen: z.boolean(),
  position: z.number(),
});

export const table = z.object({
  name: z.string(),
  label: z.string(),
  settings: z.object({
    columns: z.array(column),
  }),
});
