import enums from "@heffl/server/src/schemas/enums";
import { downloadFile } from "@heffl/ui/lib/utils";
import mime from "mime-types";
import { z } from "zod";

export const getS3URL = (file: { section: string; link: string }) => {
  return `${import.meta.env.VITE_S3_ENDPOINT}/${file.section}/${file.link}`;
};

export const getS3URLLegacy = (
  folder: z.infer<typeof enums.S3Folders>,
  link: string
) => {
  return `${import.meta.env.VITE_S3_ENDPOINT}/${folder}/${link}`;
};

export const downloadS3File = async (
  folder: z.infer<typeof enums.S3Folders>,
  {
    format,
    name,
    link,
  }: {
    name: string;
    format: string | null;
    link: string;
  }
) => {
  const fileName = `${name}.${mime.extension(format || "")}`;
  const fileUrl = getS3URL({ section: folder, link });
  downloadFile(fileName, fileUrl);
};
