import LogoSmall from "@/assets/images/logo_small.png";
import { cn, isMobile } from "@heffl/ui/lib/utils";
import { useMobileDrawer } from "@/stores/useMobileDrawer";
import { useEffect, useState } from "react";
import { LucideIcon, MoreHorizontal } from "lucide-react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import useNav from "@/stores/useNav";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
type TProps = {
  links: {
    icon: LucideIcon;
    path: string;
    label: string;
    subMenu?: boolean;
  }[];
};

type TSideBarProps = {
  isHovered: boolean;
  isPinned: boolean;
  setIsPinned: (v: boolean) => void;
  selectedPage: string;
  setSelectedPage: (v: string) => void;
  links: { icon: LucideIcon; path: string; label: string }[];
};

const SideBar = ({
  isHovered,
  isPinned,
  selectedPage,
  links,
  setSelectedPage,
}: TSideBarProps) => {
  const navigate = useNavigate();
  const { setIsDrawerVisible } = useMobileDrawer();

  return (
    <div className={cn("flex relative flex-col gap-2 px-4 mt-5")}>
      {/* {isHovered || isPinned ? (
        <Button
          variant="outline"
          className="rounded-full p-0 w-9 h-9 absolute right-[-20px] top-3 hidden sm:flex"
          onClick={() => setIsPinned(!isPinned)}
        >
          {isPinned ? <PinOff className="h-4" /> : <Pin className="h-4" />}
        </Button>
      ) : null} */}
      {links.map((link) => (
        <div
          key={link.path}
          onClick={() => {
            setSelectedPage(link.path);
            navigate(link.path);
            setIsDrawerVisible(false);
          }}
          className={`flex gap-1 rounded-lg cursor-pointer items-center p-2 hover:bg-gray-100 ${
            selectedPage === link.path && "bg-green-200"
          }`}
        >
          <link.icon
            className={`h-5 text-primary  ${!isPinned && !isHovered && "h-6"}`}
          />

          <p
            className={`text-[13px] font-medium ${
              isHovered || isPinned ? "block" : "hidden"
            } `}
          >
            {link.label}
          </p>
        </div>
      ))}
    </div>
  );
};

type TMoreDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  links: { icon: LucideIcon; path: string; label: string }[];
};

const MoreDrawer = ({ isOpen, onClose, links }: TMoreDrawerProps) => {
  const { selectedPage, setSelectedPage } = useNav();
  const navigate = useNavigate();

  return (
    <ModalDrawer open={isOpen} onClose={onClose}>
      <div className="flex flex-col gap-2 h-full">
        {links.map((link) => (
          <div
            key={link.path}
            onClick={() => {
              setSelectedPage(link.path);
              navigate(link.path);
              onClose();
            }}
            className={`flex gap-1 rounded-lg cursor-pointer items-center p-2 hover:bg-gray-100 ${
              selectedPage === link.path && "bg-green-200"
            }`}
          >
            <link.icon className={`h-5 text-primary`} />

            <p className={`text-sm font-medium`}>{link.label}</p>
          </div>
        ))}
      </div>
    </ModalDrawer>
  );
};

const showBottomBarPages = [
  "crm/dashboard",
  "crm/leads/:view",
  "crm/deals/:view",
  "crm/deals",
  "crm/contacts",
  "crm/clients",
  "crm/goals",
  "crm/tasks/:view",

  "sales/dashboard",
  "sales/invoices",
  "sales/quotations",
  "sales/proforma-invoices",
  "sales/products",
  "sales/clients",

  "projects/dashboard",
  "projects/tasks/:view",
  "projects/templates",
  "projects/archive",
  "projects/pipelines/list/:id",
  "projects/:view",
  "projects/timesheets",

  "field-service/dashboard",
  "field-service/schedules/:view",
  "field-service/jobs",

  "settings",
  "settings/tags",
];

const SideBarWrapper = ({ links = [] }: TProps) => {
  const location = useLocation();

  const { selectedPage, setSelectedPage } = useNav();
  const navigate = useNavigate();

  const [isHovered, setIsHovered] = useState(false);
  const [isPinned, setIsPinned] = useState(true);
  const [isMoreDrawerOpen, setIsMoreDrawerOpen] = useState(false);

  useEffect(() => {
    setIsPinned(true);
  }, [isMobile]);

  useEffect(() => {
    if (!selectedPage) {
      setSelectedPage(location.pathname.split("/")[2]);
    }
  }, [location.pathname]);

  const slicedLinks = links.length > 5 ? links.slice(0, 4) : links;

  const showBottomBar = () => {
    return showBottomBarPages.some((pageRaw) => {
      const pages = pageRaw.split("/");
      const currentPages = location.pathname
        .split("/")
        .filter((page) => page !== "");
      if (pages.length !== currentPages.length) {
        return false;
      }
      const shouldShow = pages.every((page, index) => {
        if (page.startsWith(":")) {
          return true;
        }
        return page === currentPages[index];
      });
      return shouldShow;
    });
  };

  const mobileBottomBar = (
    <div className="flex bottom-0 left-0 w-full h-[65px] bg-white border-b border-t border-gray-200 px-0 justify-between items-center fixed z-20">
      {slicedLinks.map((link) => (
        <div
          key={link.path}
          onClick={() => {
            setSelectedPage(link.path);
            navigate(link.path);
          }}
          className={cn(
            "w-full h-full px-4 py-2 text-[11px] font-medium text-gray-600 flex flex-col items-center gap-1",
            selectedPage === link.path && "text-primary"
          )}
        >
          <link.icon className={`h-5 ${!isPinned && !isHovered && "h-6"}`} />
          {link.label}
        </div>
      ))}
      {/* Next line showing MoreHorizontal icon */}
      {links.length > 5 && (
        <div
          className={cn(
            "flex flex-col gap-1 items-center px-4 py-2 w-full h-full font-medium text-gray-600 text-[11px]"
          )}
          onClick={() => setIsMoreDrawerOpen(true)}
        >
          <MoreHorizontal
            className={`h-5 transition-all duration-300 ${
              !isPinned && !isHovered && "h-6"
            }`}
          />
          More
        </div>
      )}
      <MoreDrawer
        isOpen={isMoreDrawerOpen}
        onClose={() => setIsMoreDrawerOpen(false)}
        links={links}
      />
    </div>
  );

  return (
    <div className="w-full">
      {isMobile() && showBottomBar() && mobileBottomBar}
      {!isMobile() && (
        <div
          className={cn(
            "hover:w-[180px] fixed bottom-0 mr-4 sm:block hidden border-r border-[rgb(238, 239, 241)] flex flex-col justify-between h-full bg-[#FBFBFB]",
            isPinned ? "w-[180px]" : "w-[70px]"
          )}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div
            className="flex gap-2 items-center px-6 h-[49px] border-b border-gray-200 cursor-pointer"
            onClick={() => {
              navigate("/");
            }}
          >
            <img src={LogoSmall} alt="Heffl" className="w-4 sm:w-5" />
            {(isHovered || isPinned) && (
              <p className="text-lg font-semibold text-gray-800 sm:text-xl sm:font-semibold sm:block">
                Heffl
              </p>
            )}
          </div>
          <SideBar
            isHovered={isHovered}
            isPinned={isPinned}
            setIsPinned={setIsPinned}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            links={links}
          />
        </div>
      )}

      <div
        className={`mt-0 ml-0 w-full sm:w-calc-70 } sm:ml-[70px]`}
        style={{
          width: isMobile()
            ? "100%"
            : isHovered || isPinned
            ? "calc(100% - 180px)"
            : "calc(100% - 70px)",
          marginLeft: isMobile()
            ? "0px"
            : isHovered || isPinned
            ? "180px"
            : "70px",
        }}
      >
        <Outlet />
      </div>
    </div>
  );
};

export default SideBarWrapper;
