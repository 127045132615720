type pullToRefreshStatus = {
  action: "pullToRefreshStatus";
  data: boolean;
};
type copyToClipboard = {
  action: "copyToClipboard";
  data: string;
};
type openExternalLink = {
  action: "openExternalLink";
  data: string;
};
type openInAppBrowser = {
  action: "openInAppBrowser";
  data: string;
};
type downloadFile = {
  action: "downloadFile";
  data: {
    url: string;
    fileName: string;
  };
};

type userAuth = {
  action: "userAuth";
  data: {
    login: boolean;
    userId: number;
  };
};

type Message =
  | pullToRefreshStatus
  | copyToClipboard
  | userAuth
  | openExternalLink
  | openInAppBrowser
  | downloadFile;

const messageToMobileApp = (message: Message) => {
  // @ts-ignore
  if (window && window?.ReactNativeWebView) {
    // send data object to React Native (only string)
    // @ts-ignore
    window.ReactNativeWebView.postMessage(JSON.stringify(message));
  }
};

export default messageToMobileApp;
