import { MultipleContactsInput } from "@/components/FormComponents";
import {
  activityDurations,
  reminderDurations,
} from "@/components/actionsModals/activities-modals";
import SelectableDateicker from "@/components/selectable-date-picker";
import { trpc } from "@/helpers/trpc";
import { activityItems, dealPriorityColors } from "@/lib/constants";
import { capitalize } from "@heffl/ui/lib/utils";
import Schemas from "@heffl/server/src/schemas";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import TabsInput from "@heffl/ui/components/primitives/TabsInput";
import { Button } from "@heffl/ui/components/primitives/button";
import Select from "@heffl/ui/components/primitives/creatable-select";
import { DateTimePicker } from "@heffl/ui/components/primitives/datetime-picker";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import MiniRichTextEditor from "@heffl/ui/components/primitives/mini-rich-text-editor";
import { MultiSelect } from "@heffl/ui/components/primitives/multi-select";
import {
  RawSelect,
  RawSelectItem,
} from "@heffl/ui/components/primitives/raw-select";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import { zodResolver } from "@hookform/resolvers/zod";
import dayjs from "dayjs";
import {
  AlarmClock,
  CircleUserRound,
  Clock,
  SquareKanban,
  Trash2,
} from "lucide-react";
import { useEffect } from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { z } from "zod";

export type Priority = "LOW" | "MEDIUM" | "HIGH";

export const priotityOptions: { label: string; value: Priority }[] = [
  { label: "Low", value: "LOW" },
  { label: "Medium", value: "MEDIUM" },
  { label: "High", value: "HIGH" },
];

const ProjectActivityForm = ({
  form,
  projectId,
}: {
  form: UseFormReturn<z.infer<typeof Schemas.project.projectActivity>>;
  projectId: number;
}) => {
  const type = form.watch("type");
  const startDate = form.watch("startDate");

  const { data: users } = trpc.users.list.useQuery();
  const { data: stages } = trpc.projects.pipelines.getProjectStages.useQuery({
    projectId: projectId,
  });

  return (
    <>
      <FormField name="type">
        <TabsInput
          options={activityItems.map((item) => ({
            label: (
              <div key={item.value} className="flex items-center">
                <item.icon className="h-3.5" /> {item.label}
              </div>
            ),
            value: item.value,
          }))}
        />
      </FormField>
      <FormField name="title">
        <Input placeholder={capitalize(type)} />
      </FormField>
      <FormField name="description">
        <MiniRichTextEditor
          placeholder="Describe the activity, add notes etc.."
          height={160}
        />
      </FormField>
      <FormField label="Assigned to" name="projectActivityAssignees">
        <MultiSelect
          placeholder="Assign team"
          icon={CircleUserRound}
          options={
            users?.map((user) => ({
              label: user.firstName,
              value: user.id,
            })) || []
          }
        />
      </FormField>
      <MultipleContactsInput
        name="projectActivityAttendees"
        label="Guests"
        className="w-full"
        placeholder="Select contacts"
      />
      <div className="flex flex-wrap gap-1 pb-4 sm:pb-0">
        <FormField className="w-fit" name="startDate">
          <DateTimePicker placeholder="Date" />
        </FormField>
        <FormField className="w-fit" name="endDate">
          <SelectableDateicker
            renderClassName="text-orange-500"
            icon={Clock}
            placeholder="Duration"
            ogDate={startDate || null}
            options={activityDurations}
          />
        </FormField>
        <FormField name="reminders" className="w-fit">
          <SelectableDateicker
            placeholder="Reminders"
            icon={AlarmClock}
            ogDate={startDate || null}
            options={reminderDurations}
          />
        </FormField>
        <FormField name="priority" className="w-fit">
          <RawSelect placeholder="Priority">
            {priotityOptions.map((option) => (
              <RawSelectItem value={option.value} key={option.value}>
                <div className="flex gap-2 items-center">
                  <div
                    className={`h-2.5 w-2.5 rounded`}
                    style={{
                      background: dealPriorityColors[option.value],
                    }}
                  />
                  {option.label}
                </div>
              </RawSelectItem>
            ))}
          </RawSelect>
        </FormField>
        <FormField name="pipelineStageId" className="w-fit">
          <Select
            icon={<SquareKanban className="h-5" />}
            placeholder="Pipeline phase"
            options={
              stages?.map((stage) => ({
                label: stage.name,
                value: stage.id,
              })) || []
            }
          />
        </FormField>
      </div>
    </>
  );
};

export const AddProjectActivityModal = ({
  open,
  onClose,
  defaultValues,
}: {
  open: boolean;
  onClose: () => void;
  defaultValues?: Partial<z.infer<typeof Schemas.project.projectActivity>>;
}) => {
  const params = useParams();
  const projectId = Number(params.projectId);

  const form = useForm<
    z.infer<typeof Schemas.project.projectActivity>,
    unknown
  >({
    resolver: zodResolver(Schemas.project.projectActivity),
    defaultValues: {
      priority: "MEDIUM",
      type: "todo",
      startDate: new Date(),
      endDate: {
        date: dayjs().add(15, "minutes").toDate(),
        dateType: "15_minutes_after",
      },
      ...defaultValues,
    },
  });

  const { data: currentUser } = trpc.users.currentUser.useQuery();

  const activityAddMutation = trpc.projects.activities.add.useMutation({
    onSuccess() {
      toast.success("Added activity successfully.");
      onClose();
    },
  });

  useEffect(() => {
    if (currentUser) {
      form.setValue("projectActivityAssignees", [currentUser.id]);
    }
  }, [currentUser, form]);

  const onCloseModal = () => {
    onClose();
    form.reset();
  };

  const onSubmit = (
    values: z.infer<typeof Schemas.project.projectActivity>
  ) => {
    activityAddMutation.mutate(values);
  };

  return (
    <ModalDrawer
      open={open}
      onClose={onCloseModal}
      title="Add activity"
      footer={
        <Button
          loading={activityAddMutation.isLoading}
          type="submit"
          variant="primary"
          className="w-full"
          onClick={() => form.handleSubmit(onSubmit)()}
        >
          Add activity
        </Button>
      }
    >
      <Form {...form} onSubmit={(values) => activityAddMutation.mutate(values)}>
        <ProjectActivityForm form={form} projectId={projectId} />
      </Form>
    </ModalDrawer>
  );
};

export const EditProjectActivityModal = ({
  open,
  onClose,
  id,
}: {
  open: boolean;
  onClose: () => void;
  id: number;
}) => {
  const form = useForm<
    z.infer<typeof Schemas.project.projectActivity>,
    unknown
  >({
    resolver: zodResolver(Schemas.project.projectActivity),
  });
  const confirm = useConfirm();

  const { data: activity } = trpc.projects.activities.details.useQuery(id, {
    refetchOnWindowFocus: false,
  });

  const onCloseModal = () => {
    onClose();
    form.reset();
  };

  const activityDeleteMutation = trpc.projects.activities.delete.useMutation({
    onSuccess() {
      toast.success("Deleted activity successfully.");
      onCloseModal();
    },
  });
  const activityUpdateMutation = trpc.projects.activities.update.useMutation({
    onSuccess() {
      toast.success("Updated activity.");
      onCloseModal();
    },
  });

  useEffect(() => {
    if (activity) {
      form.reset({
        ...activity,
        endDate: {
          date: activity.endDate,
          dateType: "CUSTOM_DATE",
        },
        projectActivityAssignees: activity.projectActivityAssignees.map(
          (v) => v.users.id
        ),
        projectActivityAttendees: activity.projectActivityAttendees.map(
          (v) => v.contacts.id
        ),
        reminders: activity?.reminders.length
          ? {
              date: activity?.reminders[0].date,
              dateType: activity?.reminders[0].dateType,
            }
          : undefined,
      });
    }
  }, [activity, form]);

  const onSubmit = (
    values: z.infer<typeof Schemas.project.projectActivity>
  ) => {
    activityUpdateMutation.mutate({ id, projectActivity: values });
  };

  return (
    <ModalDrawer
      open={open}
      onClose={onCloseModal}
      title="Edit activity"
      footer={
        <div className="flex gap-2 w-full">
          <Button
            loading={activityDeleteMutation.isLoading}
            icon={Trash2}
            variant="destructiveOutline"
            onClick={async () => {
              const confirmed = await confirm({
                title: "Are you sure you want to delete this activity?",
              });
              if (confirmed) {
                activityDeleteMutation.mutate(id);
              }
            }}
          />
          <Button
            loading={activityUpdateMutation.isLoading}
            variant="primary"
            className="w-full"
            onClick={() => form.handleSubmit(onSubmit)()}
          >
            Update activity
          </Button>
        </div>
      }
    >
      {activity ? (
        <Form
          {...form}
          onSubmit={(values) =>
            activityUpdateMutation.mutate({ id, projectActivity: values })
          }
        >
          <ProjectActivityForm form={form} projectId={activity.projectId} />
        </Form>
      ) : (
        <FullScreenSpinner />
      )}
    </ModalDrawer>
  );
};
