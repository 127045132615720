import Schemas from "@heffl/server/src/schemas";
import { Button } from "@heffl/ui/components/primitives/button";
import Select from "@heffl/ui/components/primitives/creatable-select";
import { FormField } from "@heffl/ui/components/primitives/form";
import FormList from "@heffl/ui/components/primitives/form-list";
import { Input } from "@heffl/ui/components/primitives/input";
import { Switch } from "@heffl/ui/components/primitives/switch";
import { LucideIcon, PlusIcon, Trash } from "lucide-react";
import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { z } from "zod";
import slugify from "slugify";
import { trpc } from "@/helpers/trpc";
import enums from "@heffl/server/src/schemas/enums";
import appIcons from "@heffl/ui/components/appIcons";

export const customFieldSections: {
  value: z.infer<typeof enums.customFieldSections>;
  label: string;
  icon: LucideIcon;
}[] = [
  {
    value: "CLIENT",
    icon: appIcons.sales.clients.icon,
    label: "Clients",
  },
  {
    value: "LEAD",
    icon: appIcons.sales.leads.icon,
    label: "Leads",
  },
  {
    value: "DEAL",
    icon: appIcons.sales.deals.icon,
    label: "Deals",
  },
  {
    value: "PROJECT",
    icon: appIcons.projects.project.icon,
    label: "Projects",
  },
  {
    value: "QUOTATION_LINE_ITEM",
    icon: appIcons.common.lineItems.icon,
    label: "Quotation Line Items",
  },
];

const customFieldDataTypes: z.infer<typeof enums.customFieldTypes>[] = [
  "TEXT",
  "NUMBER",
  "DATE",
  "SINGLE_OPTION",
];

const CustomFieldForm = ({
  form,
  edit = false,
}: {
  form: UseFormReturn<
    z.infer<typeof Schemas.customFields.customFields>,
    unknown
  >;
  edit?: boolean;
}) => {
  const label = form.watch("label");
  const dataType = form.watch("dataType");
  const name = form.watch("name");
  const section = form.watch("section");
  const values = form.watch("values");

  const { data: dealPipelines } = trpc.deals.pipelines.list.useQuery(
    undefined,
    {
      enabled: section === "DEAL",
    }
  );
  const { data: projectPipelines } = trpc.projects.pipelines.list.useQuery(
    undefined,
    {
      enabled: section === "PROJECT",
    }
  );
  const { data: documentTemplates } = trpc.templates.list.useQuery(
    {
      type: "QUOTATION",
    },
    {
      enabled: section === "QUOTATION_LINE_ITEM",
    }
  );

  useEffect(() => {
    if (label && !edit) {
      form.setValue(
        "name",
        slugify(label || "", {
          lower: true,
          replacement: "_",
          trim: false,
          strict: true,
        })
      );
    }
  }, [label, form, edit]);

  useEffect(() => {
    if (name) {
      form.setValue(
        "name",
        slugify(name, {
          lower: true,
          replacement: "_",
          trim: false,
          strict: true,
        })
      );
    }
  }, [name, form]);

  return (
    <>
      <div className="grid grid-cols-2 gap-3">
        <FormField name="label" label="Label">
          <Input placeholder="Label" />
        </FormField>
        <FormField name="name" label="Name" info="Used as unique  identifier">
          <Input prefix="cf_" placeholder="Field name" disabled={edit} />
        </FormField>
        <FormField name="section" label="Section">
          <Select options={customFieldSections} disabled={edit} />
        </FormField>
        {section === "DEAL" && (
          <FormField name="dealPipelineId" label="Deal Pipeline">
            <Select
              disabled={edit}
              options={dealPipelines?.map((deal) => ({
                label: deal.name,
                value: deal.id,
              }))}
            />
          </FormField>
        )}
        {section === "QUOTATION_LINE_ITEM" && (
          <FormField name="documentTemplateId" label="Document Template">
            <Select
              disabled={edit}
              options={documentTemplates?.map((template) => ({
                label: template.name,
                value: template.id,
              }))}
            />
          </FormField>
        )}
        {section === "PROJECT" && (
          <FormField name="projectPipelineId" label="Project Pipeline">
            <Select
              disabled={edit}
              options={projectPipelines?.map((pipeline) => ({
                label: pipeline.name,
                value: pipeline.id,
              }))}
            />
          </FormField>
        )}
        <FormField name="dataType" label="Type">
          <Select
            onSelect={({ value }) =>
              form.setValue(
                "values",
                value === "SINGLE_OPTION" ? [{ value: "", isNew: true }] : []
              )
            }
            options={customFieldDataTypes.map((field) => ({
              label: field,
              value: field,
            }))}
            disabled={edit}
          />
        </FormField>
        {section === "QUOTATION_LINE_ITEM" && dataType === "NUMBER" && (
          <FormField
            name="isMultiplier"
            label="Is Multiplier"
            info="If true, the value will be multipled with the line item quantity"
          >
            <Switch disabled={edit} />
          </FormField>
        )}
      </div>

      {dataType === "SINGLE_OPTION" && (
        <FormField name="values" label="Options">
          <FormList>
            {({ fields, append, remove, _name }) => {
              return (
                <div className="space-y-2">
                  {fields.map((field, index) => (
                    <div className="flex gap-2 items-center" key={field.id}>
                      <FormField name={_name(index, "value")}>
                        <Input
                          type="text"
                          placeholder="Option name"
                          disabled={edit && !values[index].isNew}
                        />
                      </FormField>
                      <Button
                        disabled={
                          (edit && !values[index].isNew) || fields.length === 1
                        }
                        variant="link"
                        onClick={() => remove(index)}
                        icon={Trash}
                        iconClassName="text-red-500"
                      />
                    </div>
                  ))}
                  <Button
                    size="sm"
                    variant="link"
                    onClick={() => append({ value: "", isNew: true })}
                    className="font-medium"
                  >
                    <PlusIcon className="h-4" />
                    Add option
                  </Button>
                </div>
              );
            }}
          </FormList>
        </FormField>
      )}
      <div className="grid grid-cols-2 gap-3">
        <FormField
          name="placeholder"
          label="Helper text"
          info="Shows as a placeholder in the input"
        >
          <Input placeholder="Placeholder" />
        </FormField>
        <FormField label="Required" name="required">
          <Switch />
        </FormField>
      </div>
    </>
  );
};

export default CustomFieldForm;
