import { trpc } from "@/helpers/trpc";
import Schemas from "@heffl/server/src/schemas";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import { Form } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import { zodResolver } from "@hookform/resolvers/zod";
import { Trash2 } from "lucide-react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";
import { ProductForm } from "./addProductDrawer";

export const EditProductDrawer = ({
  productId,
  onClose,
}: {
  productId: number;
  onClose: () => void;
}) => {
  const confirm = useConfirm();
  const form = useForm<z.infer<typeof Schemas.crm.product>, unknown>({
    resolver: zodResolver(Schemas.crm.product),
  });

  const { data: product, isLoading } =
    trpc.products.details.useQuery(productId);

  const productUpdateMutation = trpc.products.update.useMutation({
    onSuccess: () => {
      toast.success("Product updated successfully");
      onClose();
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const productDeleteMutation = trpc.products.delete.useMutation({
    onSuccess: () => {
      toast.success("Product deleted successfully");
      onClose();
    },
    onError: (err) => {
      toast.error(err.message || "Deletion failed, this product is in use");
    },
  });

  useEffect(() => {
    if (product) {
      form.reset(product);
    }
  }, [product]);

  return (
    <ModalDrawer
      open={true}
      onClose={onClose}
      className={`w-full sm:w-1/4`}
      title="Edit Product"
      closeOnOverlayClick={false}
    >
      {isLoading && <FullScreenSpinner />}
      {!isLoading && (
        <Form
          {...form}
          onSubmit={(values) => {
            productUpdateMutation.mutate({ id: productId, product: values });
          }}
        >
          <ProductForm />
          <div className="flex gap-2">
            <Button
              loading={productDeleteMutation.isLoading}
              variant="destructiveOutline"
              onClick={async () => {
                const confirmed = await confirm(
                  "Are you sure you want to delete this product?"
                );
                if (confirmed) {
                  productDeleteMutation.mutate(productId);
                }
              }}
              icon={Trash2}
              size="md"
            />
            <Button
              type="submit"
              variant="primary"
              className="w-full"
              loading={productUpdateMutation.isLoading}
            >
              Update Product
            </Button>
          </div>
        </Form>
      )}
    </ModalDrawer>
  );
};
