import { useRef } from "react";

type CameraPickerProps = {
  children: React.ReactNode;
  onChange: (file: File) => void;
};

// function isCaptureSupported() {
//   const i = document.createElement("input");
//   i.setAttribute("capture", "");
//   return !!i.capture;
// }

const CameraPicker = ({ children, onChange }: CameraPickerProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
    e.stopPropagation();
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      onChange(e.target.files[0]);
    }
  };

  return (
    <>
      <input
        onClick={(e) => {
          e.stopPropagation();
          // for fixing file not selected if same second time
          if (e.target && e.target) {
            // @ts-ignore
            e.target.value = null;
          }
        }}
        type="file"
        ref={fileInputRef}
        className="!hidden"
        accept="image/*"
        capture="environment"
        onChange={handleFileSelect}
        onError={(e) => {
          console.error(e);
          window.alert(`Error capturing image: ${JSON.stringify(e)}`);
        }}
      />
      <div onClick={handleClick}>{children}</div>
    </>
  );
};

export default CameraPicker;
