import { SearchInput } from "@/components/FormComponents";
import Page from "@/components/page";
import { RouterOutputs, trpc } from "@/helpers/trpc";
import Schemas from "@heffl/server/src/schemas";
import appIcons from "@heffl/ui/components/appIcons";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import { Form } from "@heffl/ui/components/primitives/form";
import StripeTabs from "@heffl/ui/components/primitives/stripe-tabs";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Binary,
  CalendarDays,
  Copy,
  LucideIcon,
  MenuSquare,
  Plus,
  Type,
} from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";
import CustomFieldForm, {
  customFieldSections,
} from "./components/custom-field-form";
import Empty from "@/components/Empty";
import { Card } from "@heffl/ui/components/primitives/card";
import { copyToClipboard } from "@heffl/ui/components/primitives/copy-to-clipboard";
import enums from "@heffl/server/src/schemas/enums";

export const AddCustomFieldModal = ({
  open,
  onClose,
  defaultValues,
}: {
  open: boolean;
  onClose: () => void;
  defaultValues?: Partial<z.infer<typeof customFieldsAddSchema>>;
}) => {
  const form = useForm<z.infer<typeof customFieldsAddSchema>>({
    resolver: zodResolver(customFieldsAddSchema),
  });

  useEffect(() => {
    if (defaultValues) {
      form.reset({ ...defaultValues, dataType: "TEXT" });
    }
  }, [defaultValues]);

  const customFieldAddMutation =
    trpc.customizations.customFields.add.useMutation({
      onSuccess() {
        onClose();
        form.reset();
        toast.success("Added custom field.");
      },
      onError(error) {
        toast.error(error.message);
      },
    });

  return (
    <ModalDrawer
      className="max-w-sm"
      open={open}
      title="Add custom field"
      onClose={() => {
        onClose();
        form.reset({});
      }}
    >
      <Form
        {...form}
        onSubmit={(values) => customFieldAddMutation.mutate(values)}
      >
        <CustomFieldForm form={form} />
        <Button
          className="mt-3"
          type="submit"
          variant="primary"
          loading={customFieldAddMutation.isLoading}
        >
          Add field
        </Button>
      </Form>
    </ModalDrawer>
  );
};

const customFieldsAddSchema = Schemas.customFields.customFields.superRefine(
  ({ section, dealPipelineId, projectPipelineId, documentTemplateId }, ctx) => {
    if (section === "DEAL" && !dealPipelineId) {
      ctx.addIssue({
        path: ["dealPipelineId"],
        code: z.ZodIssueCode.custom,
        message: "dealPipelineId is required for DEAL section",
      });
    }
    if (section === "PROJECT" && !projectPipelineId) {
      ctx.addIssue({
        path: ["projectPipelineId"],
        code: z.ZodIssueCode.custom,
        message: "projectPipelineId is required for PROJECT section",
      });
    }
    if (section === "QUOTATION_LINE_ITEM" && !documentTemplateId) {
      ctx.addIssue({
        path: ["documentTemplateId"],
        code: z.ZodIssueCode.custom,
        message:
          "documentTemplateId is required for QUOTATION_LINE_ITEM section",
      });
    }
  }
);

export const EditCustomFieldModal = ({
  open,
  onClose,
  customFieldId,
}: {
  open: boolean;
  onClose: () => void;
  customFieldId: number;
}) => {
  const form = useForm<z.infer<typeof customFieldsAddSchema>>({
    resolver: zodResolver(customFieldsAddSchema),
  });

  const { data: customField } =
    trpc.customizations.customFields.details.useQuery(customFieldId);

  const customFieldUpdateMutation =
    trpc.customizations.customFields.update.useMutation({
      onSuccess() {
        onClose();
        form.reset();
        toast.success("Updated custom field successfully");
      },
      onError(error) {
        toast.error(error.message);
      },
    });

  useEffect(() => {
    if (customField) {
      form.reset({
        ...customField,
        values:
          customField?.values?.map((value) => ({
            ...value,
            isNew: false,
          })) || [],
      });
    }
  }, [customField]);

  const onSubmit = (
    values: z.infer<typeof Schemas.customFields.customFields>
  ) => {
    customFieldUpdateMutation.mutate({
      id: customFieldId,
      customField: values,
    });
  };

  return (
    <ModalDrawer
      className="max-w-sm"
      open={open}
      title="Edit custom field"
      onClose={onClose}
      footer={
        <Button
          type="submit"
          variant="primary"
          loading={customFieldUpdateMutation.isLoading}
          className="w-full"
          onClick={() => form.handleSubmit(onSubmit)()}
        >
          Update field
        </Button>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <CustomFieldForm form={form} edit />
      </Form>
    </ModalDrawer>
  );
};

const typeIcons: {
  [key in z.infer<
    typeof Schemas.customFields.customFields
  >["dataType"]]: LucideIcon;
} = {
  TEXT: Type,
  SINGLE_OPTION: MenuSquare,
  NUMBER: Binary,
  DATE: CalendarDays,
};

export const CustomFieldCard = ({
  field,
  onClick,
}: {
  field: RouterOutputs["customizations"]["customFields"]["list"][number];
  onClick: () => void;
}) => {
  const Icon = typeIcons[field.dataType];
  return (
    <Card onClick={onClick} className="flex gap-2 items-center cursor-pointer">
      <Icon className="p-2 w-14 h-12 text-white bg-gray-500 rounded" />
      <div className="relative w-full">
        <Button
          variant="outline"
          size="icon"
          icon={Copy}
          className="absolute top-0 right-0"
          onClick={(e) => {
            e.stopPropagation();
            copyToClipboard(`cf_${field.name}`);
          }}
        />
        <p className="w-2/3 font-medium">{field.label}</p>
        <p className="text-sm font-medium text-gray-500">
          {field.dataType} -{" "}
          <span className="italic font-normal">cf_{field.name}</span>
        </p>
        <p className="text-xs text-red-500">
          {field.required ? "Required" : ""}
        </p>
      </div>
    </Card>
  );
};

const CustomFields = () => {
  const [showAdd, setShowAdd] = useState(false);
  const [selectedSection, setSelectedSection] =
    useState<z.infer<typeof enums.customFieldSections>>("CLIENT");

  const [editFieldId, setEditFieldId] = useState<number | null>(null);

  const { data: customFields, isLoading } =
    trpc.customizations.customFields.list.useQuery({
      section: selectedSection,
    });

  return (
    <Page title="Custom fields" className="!p-0">
      <AddCustomFieldModal
        open={showAdd}
        onClose={() => setShowAdd(false)}
        defaultValues={{ section: selectedSection }}
      />
      {editFieldId && (
        <EditCustomFieldModal
          open={true}
          onClose={() => setEditFieldId(null)}
          customFieldId={editFieldId}
        />
      )}
      <StripeTabs
        className="pt-2"
        tabParentClassName="pl-4"
        contentClassName="pt-0"
        onChange={setSelectedSection}
        value={selectedSection}
        suffix={
          <Button
            onClick={() => setShowAdd(true)}
            variant="primary"
            icon={Plus}
          >
            Custom field
          </Button>
        }
        items={customFieldSections.map((section) => ({
          key: section.value,
          icon: section.icon,
          label: section.label,
        }))}
      />
      <div className="p-2">
        <div className="flex justify-between w-full">
          <SearchInput value="" onChange={() => {}} />
        </div>
        {!customFields?.length && (
          <Empty
            loading={isLoading}
            className="mt-4"
            icon={appIcons.common.customFields.icon}
            title="No custom fields"
            description="Add a custom field to get started"
          />
        )}
        <div className="grid grid-cols-3 gap-4 mt-4">
          {customFields?.map((field) => {
            return (
              <CustomFieldCard
                key={field.id}
                field={field}
                onClick={() => setEditFieldId(field.id)}
              />
            );
          })}
        </div>
      </div>
    </Page>
  );
};

export default CustomFields;
