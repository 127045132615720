import { RouterOutputs } from "@/helpers/trpc";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import Projectcard from "./components/projectCard";
import { useNavigate } from "react-router-dom";

const MobileList = ({
  projects,
}: {
  projects: RouterOutputs["projects"]["list"]["projects"];
}) => {
  const navigate = useNavigate();

  if (!projects) return <FullScreenSpinner />;
  return (
    <div className="flex flex-col gap-2 items-center px-4 pb-6 mt-4 w-full">
      {projects.map((project) => (
        <Projectcard
          key={project.id}
          project={project}
          dragOverlay={false}
          className="!w-full !max-w-none"
          onProjectClick={() => {
            navigate(`/projects/details/${project.id}`);
          }}
        />
      ))}
    </div>
  );
};

export default MobileList;
