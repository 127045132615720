import InfoItemsHZ from "@/components/InfoItemHZ";
import ResponsiveActions from "@/components/ResponsiveActions";
import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import RenderTimeline from "@/helpers/userActivity/render-timeline";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import { Card } from "@heffl/ui/components/primitives/card";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import MiniRichTextEditor from "@heffl/ui/components/primitives/mini-rich-text-editor";
import TemplateRender from "@heffl/ui/components/template-render";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import { formatName, isMobile } from "@heffl/ui/lib/utils";
import { format } from "date-fns";
import dayjs from "dayjs";
import { debounce } from "lodash";
import {
  ArrowDownToLine,
  CalendarDays,
  Copy,
  Info,
  Loader,
  MoreHorizontal,
  PencilIcon,
  Printer,
  StickyNote,
  Trash,
} from "lucide-react";
import { useRef, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { downloadPdf } from "../../sales/invoices/details";

export const generateBillName = (bill: {
  date: Date;
  number: string;
  vendors: {
    name: string;
  };
}) => {
  if (!bill) return "";
  return `Bill ${bill.number}-${bill.vendors.name}-${dayjs(bill.date).format(
    "DD-MM-YYYY"
  )}`;
};

export const BillStatusBadge: React.FC<{
  status: string;
  small?: boolean;
}> = ({ status, small }) => {
  const badgeProps = {
    small,
    variant: {
      DRAFT: "neutral",
      OPEN: "default",
      PAID: "success",
      CANCELLED: "error",
    }[status] as "neutral" | "default" | "success" | "error",
  };

  return (
    <Badge {...badgeProps}>
      {status.charAt(0) + status.slice(1).toLowerCase()}
    </Badge>
  );
};

const BillDetails: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const billPrintRef = useRef<HTMLDivElement>(null);
  const confirm = useConfirm();

  const billId = Number(params.id);

  const [previewMode, setPreviewMode] = useState(true);
  const [downloadLoading, setDownloadLoading] = useState(false);

  const { data: bill, isLoading } =
    trpc.purchases.bills.details.useQuery(billId);

  const printBill = useReactToPrint({
    content: () => billPrintRef.current,
    documentTitle: bill ? generateBillName(bill) : "",
  });

  const updateNotesMutation = trpc.purchases.bills.update.useMutation({
    onSuccess() {
      return {
        invalidate: false,
      };
    },
    onError(error) {
      toast.error(error.message);
    },
  });
  const debouncedUpdateNotes = debounce(updateNotesMutation.mutate, 500);

  const deleteBillMutation = trpc.purchases.bills.delete.useMutation({
    onSuccess() {
      toast.success("Bill deleted successfully");
      navigate("/purchases/bills");
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  if (isLoading || !bill) return <FullScreenSpinner />;

  return (
    <Page
      fullWidth
      title={`Bill #${bill.number}`}
      tags={
        <div className="flex flex-wrap gap-2">
          <BillStatusBadge status={bill.status} small={isMobile()} />
          {bill.billTags?.map((tag) => (
            <Badge small={isMobile()} key={tag.id} background={tag.tags.color}>
              {tag.tags.name}
            </Badge>
          ))}
        </div>
      }
      description={`Created on ${format(
        bill.createdAt,
        "dd MMM yyyy hh:mm a"
      )}`}
      showBack
      suffix={
        <div className="flex gap-2">
          <ResponsiveActions
            actions={[
              {
                icon: Printer,
                buttonVariant: "outline",
                iconClassName: "text-purple-500",
                buttonClassName: "sm:rounded-r-none",
                label: "Print",
                onClick: () => {
                  setPreviewMode(false);
                  setTimeout(() => {
                    printBill();
                    setPreviewMode(true);
                  }, 100);
                },
              },
              {
                buttonClassName: "sm:rounded-l-none sm:rounded-r-none sm:-ml-2",
                buttonVariant: "outline",
                icon: ArrowDownToLine,
                label: "Download",
                onClick: async () => {
                  setDownloadLoading(true);
                  await downloadPdf({
                    name: generateBillName(bill),
                    url: `print?bill=${bill.uuid}`,
                  });
                  setTimeout(() => {
                    setDownloadLoading(false);
                  }, 3000);
                },
                loading: downloadLoading,
                hideLabelOnDesktop: true,
              },
              {
                buttonClassName: "sm:rounded-l-none sm:-ml-2",
                buttonVariant: "outline",
                label: "Duplicate",
                icon: Copy,
                hideLabelOnDesktop: true,
                onClick: () =>
                  navigate(`/purchases/bills/add?billId=${bill.id}`),
              },
              {
                icon: MoreHorizontal,
                label: "More",
                onClick: () => {},
                dropdown: (
                  <>
                    <Button
                      variant="ghost"
                      className="flex justify-start"
                      onClick={() =>
                        navigate(`/purchases/bills/edit/${bill.id}`)
                      }
                    >
                      <PencilIcon className="mr-2 w-5" />
                      Edit
                    </Button>
                    <Button
                      variant="ghost"
                      className="flex justify-start"
                      onClick={async () => {
                        const confirmed = await confirm(
                          "Are you sure to delete this bill?"
                        );
                        if (confirmed) {
                          deleteBillMutation.mutate(bill.id);
                        }
                      }}
                    >
                      <Trash className="mr-2 w-5 text-red-500" />
                      Delete
                    </Button>
                  </>
                ),
              },
            ]}
          />
        </div>
      }
    >
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-12">
        <div className="flex justify-center w-full sm:col-span-8">
          <div className="w-full rounded-lg border border-gray-200 shadow-lg sm:w-fit">
            <div ref={billPrintRef}>
              <TemplateRender
                contentHtml={bill.contentInjected}
                template={bill.documentTemplates}
                previewMode={previewMode}
              />
            </div>
          </div>
        </div>
        <div className="space-y-4 w-full sm:col-span-4">
          <Card
            title="Details"
            icon={<Info className="h-5 text-blue-500" />}
            className="space-y-3"
          >
            <InfoItemsHZ
              items={[
                {
                  label: "Date",
                  value: dayjs(bill.date).format("DD/MM/YYYY"),
                },
                {
                  label: "Status",
                  value: <BillStatusBadge status={bill.status} />,
                },
                {
                  label: "Vendor",
                  value: bill.vendors.name,
                },
                {
                  label: "Created by",
                  value: formatName(bill.createdBy),
                },
                {
                  label: "Due Date",
                  value: bill.dueDate
                    ? dayjs(bill.dueDate).format("DD/MM/YYYY")
                    : "Not specified",
                },
              ]}
            />
          </Card>
          <Card
            title="Notes"
            icon={<StickyNote className="text-yellow-500" />}
            className="pb-4"
          >
            <MiniRichTextEditor
              placeholder="Add notes about bill..."
              value={bill.notes || ""}
              onChange={(v) => {
                debouncedUpdateNotes({
                  id: bill.id,
                  bill: {
                    notes: v,
                  },
                });
              }}
              height={170}
            />
            {updateNotesMutation.isLoading && (
              <div className="flex gap-2 items-center mt-1 float-end">
                <p>Updating</p>
                <Loader className="h-5 animate-spin text-primary" />
              </div>
            )}
          </Card>
          <Card
            title="Timeline"
            icon={<CalendarDays className="h-5 text-green-500" />}
          >
            <RenderTimeline userActivities={bill.userActivities} />
          </Card>
        </div>
      </div>
    </Page>
  );
};

export default BillDetails;
