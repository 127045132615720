import DataGrid from "@/components/dataGrid/DataGrid";
import FilterBar from "@/components/filters";
import { SearchInput } from "@/components/FormComponents";
import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import { calculateLineItems } from "@heffl/server/src/helpers/lineItems/calculateLineItems";
import enums from "@heffl/server/src/schemas/enums";
import DropMenu from "@heffl/ui/components/DropMenu";
import { Badge, BadgeProps } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import ResponsiveActionButton from "@heffl/ui/components/primitives/responsive-action-button";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import { formatCurrency, isMobile } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import {
  ArrowDownToLine,
  Copy,
  MoreHorizontal,
  ReceiptText,
  Trash,
} from "lucide-react";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

type PageFilters = {
  search: string;
  pageNo: number;
  statuses: z.infer<typeof enums.billStatuses>[];
  dates?: [Date, Date];
  vendors: number[];
};

export const BillStatusBadge: React.FC<{
  status: z.infer<typeof enums.billStatuses>;
  small?: boolean;
}> = ({ status, small }) => {
  const badgeProps = {
    small,
    variant: {
      DRAFT: "neutral",
      OPEN: "default",
      PARTIALLY_PAID: "warning",
      PAID: "success",
      CANCELLED: "error",
    }[status] as BadgeProps["variant"],
  };

  return (
    <Badge {...badgeProps}>
      {status.charAt(0) + status.slice(1).toLowerCase()}
    </Badge>
  );
};

const BillList: React.FC = () => {
  const navigate = useNavigate();
  const confirm = useConfirm();

  const [filters, setFilters] = useParamsState<PageFilters>({
    search: "",
    pageNo: 1,
    statuses: [],
    dates: undefined,
    vendors: [],
  });

  const [vendorSearch, setVendorSearch] = useState("");

  const { data: vendors } = trpc.purchases.vendors.list.useQuery({
    search: vendorSearch,
  });
  const { data: bills, isLoading } = trpc.purchases.bills.list.useQuery({
    statuses: filters.statuses,
    dates: filters.dates,
    pageNo: filters.pageNo,
    pageSize: 30,
    vendorId: filters.vendors[0],
    search: filters.search,
  });

  const deleteBillMutation = trpc.purchases.bills.delete.useMutation({
    onSuccess() {
      toast.success("Bill deleted successfully");
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  return (
    <Page fullWidth title="Bills" className="sm:p-0">
      <div className="flex flex-col gap-3 justify-between w-full border-gray-200 sm:border-b sm:p-3 sm:flex-row sm:gap-0">
        <SearchInput
          value={filters.search}
          onChange={(e: string) => setFilters({ search: e })}
        />

        <ResponsiveActionButton onClick={() => navigate("add")} text="Bill" />
      </div>

      <div className="sm:px-3">
        <FilterBar
          onChange={() => {
            setFilters({
              pageNo: 1,
            });
          }}
          className="py-3"
          filters={[
            {
              key: "date",
              type: "date-range",
              label: "Date",
              value: filters.dates,
              onChange: (value) => setFilters({ dates: value }),
            },
            {
              key: "status",
              type: "checkbox",
              label: "Status",
              value: filters.statuses,
              onChange: (value) =>
                setFilters({
                  statuses: value as z.infer<typeof enums.billStatuses>[],
                }),
              options: [
                { label: "Draft", value: "DRAFT" },
                { label: "Open", value: "OPEN" },
                { label: "Partially Paid", value: "PARTIALLY_PAID" },
                { label: "Paid", value: "PAID" },
                { label: "Cancelled", value: "CANCELLED" },
              ],
            },
            {
              key: "vendors",
              type: "checkbox",
              label: "Vendors",
              showSearch: true,
              value: filters.vendors,
              onChange: (value) => {
                setFilters({ vendors: value as number[] });
              },
              options: vendors?.vendors.map((vendor) => ({
                label: vendor.name,
                value: vendor.id,
              })),
              onSearch: (e: string) => setVendorSearch(e),
            },
          ]}
        />
      </div>
      {isMobile() ? (
        <div className="flex flex-col gap-2 mt-4 mb-[100px]">
          {/* Implement mobile view if needed */}
        </div>
      ) : (
        <DataGrid
          rowKey="id"
          name="billsListMain"
          className="h-[calc(100vh-117px-var(--header-height))]"
          label="Bills"
          loading={isLoading}
          onCellClick={({ row }) => {
            navigate(`/purchases/bills/details/${row.id}`);
          }}
          empty={{
            icon: ReceiptText,
            title: "No bills added",
            description: "Please add a bill",
            actionText: "Add bill",
            onAction: () => navigate("/purchases/bills/add"),
            buttonSize: "sm",
          }}
          rows={bills?.data || []}
          columns={[
            {
              key: "date",
              name: "Date",
              width: 140,
              renderCell: ({ row }) => dayjs(row.date).format("DD/MM/YYYY"),
            },
            {
              key: "number",
              name: "Number",
              width: 120,
              renderCell: ({ row }) => (
                <p className="cursor-pointer hover:underline hover:text-primary-600">
                  {row.number}
                </p>
              ),
            },
            {
              key: "vendor",
              name: "Vendor",
              width: 300,
              renderCell: ({ row }) => <p>{row.vendors.name}</p>,
            },
            {
              key: "status",
              name: "Status",
              width: 100,
              renderCell: ({ row }) => <BillStatusBadge status={row.status} />,
            },
            {
              key: "dueDate",
              name: "Due Date",
              width: 140,
              renderCell: ({ row }) => dayjs(row.dueDate).format("DD/MM/YYYY"),
            },
            {
              key: "amount",
              name: "Amount",
              width: 130,
              renderCell: ({ row }) =>
                formatCurrency(
                  calculateLineItems({
                    lineItems: row.billItems,
                    discount: row.discount,
                  }).total,
                  row.currencies.symbol
                ),
            },
            {
              key: "actions",
              name: "Actions",
              width: 100,
              renderCell: ({ row }) => (
                <DropMenu
                  items={[
                    {
                      icon: Trash,
                      className: "text-red-500",
                      label: "Delete",
                      onClick: async () => {
                        const confirmed = await confirm(
                          "Are you sure to delete this bill?"
                        );
                        if (confirmed) {
                          deleteBillMutation.mutate(row.id);
                        }
                      },
                    },
                    {
                      label: "Duplicate",
                      icon: Copy,
                      onClick: () =>
                        navigate(`/purchases/bills/add?billId=${row.id}`),
                    },
                    {
                      label: "Download",
                      icon: ArrowDownToLine,
                      onClick: async () => {
                        // Implement download functionality
                      },
                    },
                  ]}
                >
                  <Button variant="ghost">
                    <MoreHorizontal className="h-4 text-gray-700" />
                  </Button>
                </DropMenu>
              ),
            },
          ]}
        />
      )}
    </Page>
  );
};

export default BillList;
