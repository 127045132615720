import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import { Form } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import { ProjectPipelineForm, pipelineSchema } from "./add";
import { Button } from "@heffl/ui/components/primitives/button";
import { Save, Trash } from "lucide-react";

const ProjectPipelinEdit = () => {
  const navigate = useNavigate();
  const params = useParams();
  const pipelineId = Number(params.pipelineId);

  const form = useForm<z.infer<typeof pipelineSchema>>({
    resolver: zodResolver(pipelineSchema.partial()),
  });

  const trpcUtils = trpc.useUtils();

  const projectStagesNew = form.watch("projectPipelineStages");

  const { data: pipelineDetails } =
    trpc.projects.pipelines.details.useQuery(pipelineId);
  const deletePipelineMutation = trpc.projects.pipelines.delete.useMutation({
    onSuccess: () => {
      toast.success("Pipeline deleted");
      navigate("/projects/board");
    },
    onError(error) {
      console.log(error);
      toast.error(
        "Please remove projects in the pipeline before deleting the pipeline"
      );
    },
  });

  useEffect(() => {
    if (pipelineDetails) {
      form.reset({
        ...pipelineDetails,
      });
    }
  }, [pipelineDetails, form]);

  const updatePipeline = trpc.projects.pipelines.update.useMutation({
    onSuccess: () => {
      toast.success("Pipeline updated");
      navigate(-1);
    },
    onError(error) {
      toast.error(error.message);
      form.reset();
      trpcUtils.projects.pipelines.details.invalidate();
    },
  });

  const onSubmit = (values: z.infer<typeof pipelineSchema>) => {
    updatePipeline.mutate({
      id: pipelineId,
      pipeline: {
        ...values,
        projectPipelineStages: values.projectPipelineStages.map(
          (stage, index) => ({
            ...stage,
            position: index + 1,
            id: isNaN(stage.id as unknown as number) ? 0 : Number(stage.id),
          })
        ),
      },
    });
  };

  if (!pipelineDetails || !projectStagesNew) return <FullScreenSpinner />;
  return (
    <Page
      fullWidth
      title="Edit pipeline"
      showBack
      suffix={
        <div className="flex gap-2">
          <Button
            icon={Trash}
            variant="destructive"
            onClick={() => deletePipelineMutation.mutate(pipelineId)}
            loading={deletePipelineMutation.isLoading}
          >
            Delete
          </Button>
          <Button
            icon={Save}
            variant="primary"
            loading={updatePipeline.isLoading}
            onClick={() => form.handleSubmit(onSubmit)()}
          >
            Update pipeline
          </Button>
        </div>
      }
      breadcrumbs={[
        {
          label: "Projects",
          path: "/projects",
        },
        {
          label: "Pipelines",
          path: "/projects/pipelines",
        },
        {
          label: "Edit pipeline",
          path: `/projects/pipelines/edit/${pipelineId}`,
        },
      ]}
    >
      <Form {...form} onSubmit={onSubmit}>
        <ProjectPipelineForm form={form} edit />
      </Form>
    </Page>
  );
};

export default ProjectPipelinEdit;
