import { trpc } from "@/helpers/trpc";
import TemplateRender from "@heffl/ui/components/template-render";
import { Suspense } from "react";

type PrintPreviewProps = {
  invoiceUuid?: string;
  quotationUuid?: string;
  proformaInvoiceUuid?: string;
  paymentUuid?: string;
  documentUuid?: string;
  purchaseOrderUuid?: string;
  billUuid?: string;
};

const RenderPage: React.FC<PrintPreviewProps> = ({
  invoiceUuid,
  quotationUuid,
  proformaInvoiceUuid,
  paymentUuid,
  documentUuid,
  purchaseOrderUuid,
  billUuid,
}) => {
  const { data: invoice } = trpc.public.invoices.details.useQuery(
    invoiceUuid || "",
    {
      enabled: !!invoiceUuid,
    }
  );

  const { data: document } = trpc.public.documents.details.useQuery(
    documentUuid || "",
    {
      enabled: !!documentUuid,
    }
  );

  const { data: payment } = trpc.public.invoices.payments.details.useQuery(
    paymentUuid || "",
    {
      enabled: !!paymentUuid,
    }
  );

  const { data: quotation } = trpc.public.quotations.details.useQuery(
    quotationUuid || "",
    {
      enabled: !!quotationUuid,
    }
  );

  const { data: proformaInvoice } =
    trpc.public.proformaInvoices.details.useQuery(proformaInvoiceUuid || "", {
      enabled: !!proformaInvoiceUuid,
    });

  const { data: purchaseOrder } = trpc.public.purchaseOrders.details.useQuery(
    purchaseOrderUuid || "",
    {
      enabled: !!purchaseOrderUuid,
    }
  );

  const { data: bill } = trpc.public.bills.details.useQuery(billUuid || "", {
    enabled: !!billUuid,
  });

  if (
    !invoice &&
    !quotation &&
    !proformaInvoice &&
    !payment &&
    !document &&
    !purchaseOrder &&
    !bill
  )
    return <div>Loading....</div>;

  const contentHtml =
    invoice?.contentInjected ||
    quotation?.contentInjected ||
    proformaInvoice?.contentInjected ||
    payment?.contentInjected ||
    document?.contentInjected ||
    purchaseOrder?.contentInjected ||
    bill?.contentInjected ||
    "";

  const template =
    invoice?.documentTemplates ||
    quotation?.documentTemplates ||
    proformaInvoice?.documentTemplates ||
    payment?.documentTemplates ||
    document?.documentTemplates ||
    purchaseOrder?.documentTemplates ||
    bill?.documentTemplates;

  return (
    <TemplateRender
      contentHtml={contentHtml}
      template={template!}
      previewMode={false}
    />
  );
};

const PrintPreview: React.FC<PrintPreviewProps> = (props) => {
  return (
    <Suspense fallback={<div>Loading....</div>}>
      <RenderPage {...props} />
    </Suspense>
  );
};

export default PrintPreview;
