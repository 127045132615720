import {
  Building2,
  Handshake,
  KanbanSquare,
  LucideIcon,
  Rows3,
  Target,
  TextCursorInput,
} from "lucide-react";

type AppIcon = {
  icon: LucideIcon;
  color: string;
};

const appIcons: Record<string, Record<string, AppIcon>> = {
  sales: {
    deals: { icon: Handshake, color: "#EF4444" },
    leads: { icon: Target, color: "#F97316" },
    clients: { icon: Building2, color: "#10B981" },
  },
  projects: {
    project: { icon: KanbanSquare, color: "#A855F7" },
  },
  common: {
    customFields: { icon: TextCursorInput, color: "#F97316" },
    lineItems: { icon: Rows3, color: "#F97316" },
  },
};

export default appIcons;
