import Empty from "@/components/Empty";
import { SearchInput } from "@/components/FormComponents";
import Page from "@/components/page";
import { RouterOutputs, trpc } from "@/helpers/trpc";
import Schemas from "@heffl/server/src/schemas";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import { Card } from "@heffl/ui/components/primitives/card";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import ResponsiveActionButton from "@heffl/ui/components/primitives/responsive-action-button";
import { zodResolver } from "@hookform/resolvers/zod";
import { MapPin } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

import { useImmer } from "use-immer";
import { z } from "zod";

export const ZoneForm = () => (
  <div className="flex flex-col gap-3 w-full">
    <FormField name="name" label="Name">
      <Input placeholder="Name" />
    </FormField>
    <FormField name="description" label="Description">
      <Input placeholder="Description" />
    </FormField>
  </div>
);

const AddZoneDrawer = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const form = useForm<z.infer<typeof Schemas.zones.zoneSchema>, unknown>({
    resolver: zodResolver(Schemas.zones.zoneSchema),
    defaultValues: {},
  });

  const addZoneMutation = trpc.zones.add.useMutation({
    onSuccess: () => {
      form.reset();
      toast.success("Succesfully added zone.");
      onClose();
    },
    onError: () => {
      form.reset();
      toast.error("Failed to add zone.");
    },
  });

  return (
    <ModalDrawer
      open={open}
      onClose={onClose}
      className="w-full sm:max-w-sm"
      title="Add Zone"
    >
      <div className="flex flex-col gap-3 items-center w-full">
        <Form
          {...form}
          onSubmit={(values) => {
            addZoneMutation.mutate(values);
          }}
          className="w-full"
        >
          <ZoneForm />

          <Button
            type="submit"
            variant="primary"
            size="sm"
            className="w-full"
            loading={addZoneMutation.isLoading}
          >
            Add zone
          </Button>
        </Form>
      </div>
    </ModalDrawer>
  );
};

type Tzone = RouterOutputs["zones"]["list"][number];
const ZoneCard = ({ zone }: { zone: Tzone }) => {
  return (
    <Card className="flex gap-4">
      <div className="p-3 bg-green-100 rounded-full">
        <MapPin className="h-6 text-primary" />
      </div>
      <div className="flex flex-col gap-1 justify-center">
        <p className="text-sm font-medium">{zone.name}</p>
        <p className="text-xs text-gray-500">{zone.description}</p>
      </div>
    </Card>
  );
};

const Zones = () => {
  const [filters, setFilters] = useImmer({
    search: "",
  });
  const [addZone, setAddZone] = useState(false);
  //   const [editZone, setEditZone] = useState<number | undefined>(undefined);

  const { data } = trpc.zones.list.useQuery();

  if (!data) return <FullScreenSpinner />;
  return (
    <Page title="Tags">
      <AddZoneDrawer open={addZone} onClose={() => setAddZone(false)} />
      <div className="flex justify-between w-full">
        <SearchInput
          placeholder="Search zones..."
          value={filters.search}
          onChange={(value) =>
            setFilters((f) => {
              f.search = value;
            })
          }
        />
        <div className="space-x-3">
          <ResponsiveActionButton
            onClick={() => setAddZone(true)}
            text="Zone"
          />
        </div>
      </div>
      {!data?.length ? (
        <Empty
          title="No zones added"
          icon={MapPin}
          description="Please add a zone"
          className="mt-8"
        />
      ) : (
        <div className="grid grid-cols-2 gap-3 mt-4 sm:grid-cols-4">
          {data?.map((zone) => (
            <ZoneCard key={zone.id} zone={zone} />
          ))}
        </div>
      )}
    </Page>
  );
};

export default Zones;
