import { cn } from "@heffl/ui/lib/utils";
import {
  File,
  FileArchive,
  Image,
  LucideIcon,
  Sheet,
  Text,
  Video,
} from "lucide-react";

type Icon = {
  icon: LucideIcon;
  parentClass: string;
  iconClass: string;
  textClass: string;
};

const styles: { [key: string]: Icon } = {
  pdf: {
    icon: Text,
    parentClass: "bg-gradient-to-b from-red-400 to-red-600",
    iconClass: "text-red-400",
    textClass: "",
  },
  video: {
    icon: Video,
    parentClass: "bg-gradient-to-b from-orange-400 to-orange-600",
    iconClass: "text-orange-200",
    textClass: "",
  },
  excel: {
    icon: Sheet,
    parentClass: "bg-gradient-to-b from-green-400 to-green-600",
    iconClass: "text-green-200",
    textClass: "",
  },
  img: {
    icon: Image,
    parentClass: "bg-green-500",
    iconClass: "text-green-200",
    textClass: "",
  },
  default: {
    icon: File,
    parentClass: "bg-gray-100",
    iconClass: "text-gray-400",
    textClass: "text-white bg-gray-500",
  },
  zip: {
    icon: FileArchive,
    parentClass: "bg-yellow-500",
    iconClass: "text-yellow-200",
    textClass: "",
  },
  doc: {
    icon: Text,
    parentClass: "bg-gradient-to-b from-blue-400 to-blue-600",
    iconClass: "text-blue-200",
    textClass: "",
  },
};

const videoTypes = ["mp4", "mkv", "webm", "avi", "mov", "wmv", "flv", "3gp"];
const imageTypes = ["jpg", "jpeg", "png", "gif", "webp", "bmp", "svg"];

const excelTypes = ["xls", "xlsx"];

const getIconName = (ext: string) => {
  if (imageTypes.includes(ext)) return "img";
  if (videoTypes.includes(ext)) return "video";
  if (excelTypes.includes(ext)) return "excel";
  return ext;
};

const FileIcon = ({ ext, className }: { ext: string; className?: string }) => {
  const extUpdated = getIconName(ext);
  const style = styles[extUpdated] || styles.default;

  const Icon = style.icon;
  return (
    <div
      className={cn(
        "flex overflow-hidden flex-col items-center justify-between w-12  rounded h-15 relative ",
        className,
        style.parentClass
      )}
    >
      <Icon className={cn("w-9 h-9 p-1", style.iconClass)} />
      <p
        className={cn(
          "!text-xs font-semibold text-white lowercase w-full text-center py-0.5",
          style.textClass
        )}
      >
        {ext}
      </p>
    </div>
  );
};

export default FileIcon;
