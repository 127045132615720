import * as React from "react";

import { cn } from "@heffl/ui/lib/utils";

// @ts-ignore
export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  prefix?: string | React.ReactNode;
  suffix?: string | React.ReactNode;
  prefixFilled?: boolean;
  suffixFilled?: boolean;
  parentClassName?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      type,
      value,
      parentClassName,
      prefix,
      suffix,
      prefixFilled = false,
      suffixFilled = false,
      ...props
    },
    ref
  ) => {
    return (
      <div className={cn("flex items-center w-full", parentClassName)}>
        {prefix && (
          <p
            className={cn(
              "grid sm:h-9 h-12 px-2 border border-r-0 rounded-l-md place-items-center",
              prefixFilled && "bg-gray-100 border-r"
            )}
          >
            {prefix}
          </p>
        )}
        <input
          {...props}
          onChange={props.onChange}
          value={value ?? ""}
          type={type}
          className={cn(
            "flex sm:h-9 h-12 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-400 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
            className,
            prefix && "rounded-l-none border-l-0 px-1",
            suffix && "rounded-r-none border-r-0"
          )}
          ref={ref}
        />
        {suffix && (
          <p
            className={cn(
              "grid place-items-center px-2 h-12 rounded-r-md border border-l-0 sm:h-9",
              suffixFilled && "bg-gray-100 border-l"
            )}
          >
            {suffix}
          </p>
        )}
      </div>
    );
  }
);
Input.displayName = "Input";

export { Input };
