import { Prisma } from "@prisma/client";
import { calculateLineItems } from "./calculateLineItems";

type TScheduleRevenue = Prisma.fsSchedulesGetPayload<{
  select: {
    fsScheduleServices: {
      select: {
        quantity: true;
        price: true;
        tax: true;
        viewType: true;
      };
    };
    fsScheduleAssignees: {
      select: {
        id: true;
      };
    };
    fsJobs: {
      select: {
        discount: true;
        fsSchedulePricingType: true;
        fsJobServices: {
          select: {
            quantity: true;
            price: true;
            tax: true;
            viewType: true;
          };
        };
        fsSchedules: {
          select: {
            fsScheduleServices: true;
            fsScheduleAssignees: {
              select: {
                id: true;
              };
            };
          };
        };
      };
    };
  };
}>;

// TODO: if 1st schedule drain total job revenue, reflect on next schedules

export const calculateScheduleRevenue = (row: TScheduleRevenue) => {
  const schedulesWithServices = row.fsJobs.fsSchedules.filter(
    (schedule) => schedule.fsScheduleServices.length
  );

  const totalSchedulesCountWithoutServices =
    row.fsJobs.fsSchedules.length - schedulesWithServices.length;

  const totalJobRevenue = calculateLineItems({
    lineItems: row.fsJobs.fsJobServices,
    discount: row.fsJobs.discount,
  }).total;

  const totalScheduleServicesRevenue = schedulesWithServices.length
    ? schedulesWithServices.reduce((acc, schedule) => {
        const scheduleServicesRevenue = calculateLineItems({
          lineItems: schedule.fsScheduleServices,
          discount: 0,
        });
        return acc + scheduleServicesRevenue.total;
      }, 0)
    : 0;

  const currentScheduleServicesRevenue = row.fsScheduleServices.length
    ? calculateLineItems({
        lineItems: row.fsScheduleServices,
        discount: 0,
      })
    : undefined;

  if (row.fsJobs.fsSchedulePricingType === "SCHEDULE") {
    if (currentScheduleServicesRevenue) {
      return currentScheduleServicesRevenue.total;
    }
    return (
      (totalJobRevenue - totalScheduleServicesRevenue) /
      totalSchedulesCountWithoutServices
    );
  }
  const totalAssigneesCount = row.fsJobs.fsSchedules.reduce(
    (acc, schedule) => acc + schedule.fsScheduleAssignees.length,
    0
  );
  if (row.fsJobs.fsSchedulePricingType === "FIELD_STAFF") {
    const scheduleAssigneePercentage =
      row.fsScheduleAssignees.length / totalAssigneesCount;
    return totalJobRevenue * scheduleAssigneePercentage;
  }
  return 0;
};
