import { z } from "zod";

const aiChatHistory = z.object({
  message: z.string(),
  response: z.string().optional(),
  messageToken: z.number().optional(),
  responseToken: z.number().optional(),
  threadId: z.number().optional(),
});

const aiChatThread = z.object({
  title: z.string(),
});

export default {
  aiChatHistory,
  aiChatThread,
};
