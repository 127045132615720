import { Check, Loader2 } from "lucide-react";

type TProps = {
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  loading?: boolean;
  checked?: boolean;
};

const CircleTick = ({
  loading = false,
  checked = true,
  onClick = () => {},
}: TProps) => {
  return !loading ? (
    <div
      onClick={(e) => (!loading ? onClick(e) : () => {})}
      className={`flex items-center justify-center w-5 h-5 p-0.5 cursor-pointer border-2 text-white hover:text-green-600 ${
        checked && "bg-green-600 border-none"
      } hover:border-green-600  border-gray-300 rounded-full`}
    >
      <Check className="w-4 h-4 " strokeWidth={4} />
    </div>
  ) : (
    <Loader2 className="w-6 h-6 text-yellow-300 animate-spin" />
  );
};

export default CircleTick;
