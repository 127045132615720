type Column<T> = {
  label: string;
  key: keyof T;
};

type Row<T> = T;

const SimpleTable = <T extends Record<string, any>>({
  columns,
  rows,
}: {
  columns: Column<T>[];
  rows: Row<T>[];
}) => {
  return (
    <div className="flex flex-col">
      <div className="-m-1.5 overflow-x-auto">
        <div className="p-1.5 min-w-full inline-block align-middle">
          <div className="overflow-hidden rounded-lg border">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {columns.map((column) => (
                    <th
                      key={column.label}
                      scope="col"
                      className="px-6 py-3 text-xs font-medium text-gray-500 uppercase text-start"
                    >
                      {column.label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {rows.map((row) => (
                  <tr key={row.id}>
                    {columns.map((column) => (
                      <td
                        key={column.key as string}
                        className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap"
                      >
                        {row[column.key]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimpleTable;
