import dayjs from "dayjs";
import { RouterOutputs } from "../../trpc/trpc";

const renderCustomFieldValue = (
  field: RouterOutputs["leads"]["details"]["customFieldFields"][0],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  defaultValue: string = "Not set"
) => {
  if (field.dataType === "DATE" && value) {
    return dayjs(value).format("DD/MM/YYYY");
  }
  if (field.dataType === "NUMBER" && field.isMultiplier) {
    return Number(value) || 1;
  }

  return value || defaultValue;
};

export default renderCustomFieldValue;
