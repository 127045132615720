import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import Schemas from "@heffl/server/src/schemas";
import { Button } from "@heffl/ui/components/primitives/button";
import { Form } from "@heffl/ui/components/primitives/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Plus } from "lucide-react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import BillForm from "./bill-form";

const BillAdd = () => {
  const navigate = useNavigate();

  const form = useForm<z.infer<typeof Schemas.purchases.bill>, unknown>({
    resolver: zodResolver(Schemas.purchases.bill),
    defaultValues: {
      date: new Date(),
      billItems: [
        {
          productId: undefined,
          quantity: 1,
          price: 0,
          description: "",
          tax: 5,
          viewType: "LINE_ITEM",
        },
      ],
    },
  });

  const billAddMutation = trpc.purchases.bills.add.useMutation({
    onSuccess: (response) => {
      navigate(`/purchases/bills/details/${response.id}`);
    },
  });

  const onSubmit = (values: z.infer<typeof Schemas.purchases.bill>) => {
    billAddMutation.mutate(values);
  };

  return (
    <Page
      title="Add Bill"
      className="max-w-screen-lg"
      showBack
      breadcrumbs={[
        { label: "Bills", path: "/purchases/bills" },
        { label: "Add", path: "/purchases/bills/add" },
      ]}
      suffix={
        <>
          <Button
            onClick={() => form.handleSubmit(onSubmit)()}
            loading={billAddMutation.isLoading}
            variant="primary"
            icon={Plus}
          >
            Add bill
          </Button>
        </>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <div className="flex justify-center px-3 py-5 w-full sm:px-0">
          <BillForm form={form} />
        </div>
      </Form>
    </Page>
  );
};

export default BillAdd;
