import DropMenu from "@/components/DropMenu";
import { SearchInput } from "@/components/FormComponents";
import Pagination from "@/components/Pagination";
import DataGrid from "@/components/dataGrid/DataGrid";
import FilterBar from "@/components/filters";
import Page from "@/components/page";
import { RouterOutputs, trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import usePermissions from "@/lib/hooks/usePermissions";
import calculateInvoice, {
  CalculateInvoice,
} from "@heffl/server/src/helpers/lineItems/calculateInvoice";
import enums from "@heffl/server/src/schemas/enums";
import TagsInput from "@heffl/ui/components/TagInput";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import ResponsiveActionButton from "@heffl/ui/components/primitives/responsive-action-button";
import { formatCurrency, formatName, isMobile } from "@heffl/ui/lib/utils";
import { format } from "date-fns";
import {
  ArrowDownToLine,
  BadgeDollarSign,
  Building,
  Calendar,
  CalendarClock,
  CheckCircle,
  Copy,
  DollarSign,
  Eye,
  HandCoins,
  Mail,
  MoreHorizontal,
  Pencil,
  Tags,
  User,
  X,
  Zap,
} from "lucide-react";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import InvoiceCard from "./components/InvoiceCard";
import { downloadPdf, generateInvoiceName } from "./details";

type InvoiceStatus = z.infer<typeof enums.invoiceStatus>;
type InvoiceProducts =
  RouterOutputs["invoices"]["list"]["invoices"][number]["invoiceProducts"];

export const getProductName = (products: InvoiceProducts) => {
  if (products.length > 1) {
    return (
      <p>
        {products[0].name}..{" "}
        <span className="text-primary">+{products.length - 1} more</span>
      </p>
    );
  }
  if (products.length === 1) {
    return products[0].name;
  }
  return "No product";
};

export const InvoiceStatusBadge = ({
  invoice,
}: {
  invoice: CalculateInvoice & {
    dueDate: Date;
    status: InvoiceStatus;
  };
}) => {
  if (invoice.status === "PAID") {
    return (
      <Badge variant="success" icon={CheckCircle}>
        Paid
      </Badge>
    );
  }
  if (invoice.status === "CANCELLED") {
    return (
      <Badge variant="error" icon={X}>
        Cancelled
      </Badge>
    );
  }

  if (invoice.status === "PARTIALLY_PAID") {
    return (
      <Badge variant="warning" icon={HandCoins}>
        Partially Paid
      </Badge>
    );
  }
  if (invoice.status === "SENT") {
    return (
      <Badge className="!bg-blue-300 text-white" icon={Mail}>
        Sent
      </Badge>
    );
  }
  return <Badge variant="neutral">Draft</Badge>;
};

type PageFilters = {
  search: string;
  pageNo: number;
  statuses: InvoiceStatus[];
  dates: [Date, Date] | undefined;
  clients: number[];
  tags: number[];
  salesPersons: number[];
};

const Invoices = () => {
  const navigate = useNavigate();

  const [filters, setFilters] = useParamsState<PageFilters>({
    search: "",
    pageNo: 1,
    statuses: [],
    dates: undefined,
    clients: [],
    tags: [],
    salesPersons: [],
  });
  const permissions = usePermissions();

  const [clientSearch, setClientSearch] = useState("");

  const { data: users } = trpc.users.list.useQuery({
    excludeType: ["FIELD_STAFF"],
  });
  const { data: clients } = trpc.clients.list.useQuery({
    search: clientSearch,
    pageNo: 1,
    pageSize: 10,
  });
  const { data: tags } = trpc.tags.list.useQuery({
    type: "INVOICE",
  });

  const { data: invoicesList, isLoading } = trpc.invoices.list.useQuery({
    ...filters,
    statuses: filters.statuses,
    pageNo: filters.pageNo,
    pageSize: 30,
    clients: filters.clients,
    tags: filters.tags,
    startDate: filters.dates?.[0],
    endDate: filters.dates?.[1],
    salesPersons: filters.salesPersons,
  });
  const { data: invoiceTags } = trpc.tags.list.useQuery({
    type: "INVOICE",
  });

  const updateInvoiceMutation = trpc.invoices.update.useMutation({
    onError(error) {
      toast.error(error.message);
    },
  });

  if (!invoiceTags || !permissions) return <FullScreenSpinner />;
  if (!permissions.VIEW_INVOICES.allowed) {
    return (
      <Page fullWidth title="Invoices">
        <div className="flex flex-col justify-center items-center h-screen">
          <div className="flex flex-col gap-2 justify-center items-center p-3">
            <h1 className="text-3xl font-bold">
              You don&apos;t have permission to view invoices
            </h1>
            <p className="text-base font-medium">
              Please contact the admin to request access.
            </p>
          </div>
        </div>
      </Page>
    );
  }
  return (
    <Page fullWidth title="Invoices" className="!p-0">
      <div className="flex flex-col gap-2 justify-between p-3 border-b sm:flex-row">
        <SearchInput
          placeholder="Search invoices..."
          value={filters.search}
          onChange={(e) => setFilters({ search: e })}
        />
        {permissions.CREATE_INVOICES.allowed && (
          <ResponsiveActionButton
            onClick={() => navigate("add")}
            text="Invoice"
          />
        )}
      </div>
      <FilterBar
        onChange={() => {
          setFilters({
            pageNo: 1,
          });
        }}
        className="p-3"
        filters={[
          {
            key: "date",
            type: "date-range",
            label: "Date",
            value: filters.dates,
            onChange: (value) => setFilters({ dates: value }),
          },
          {
            key: "status",
            type: "checkbox",
            label: "Status",
            value: filters.statuses,
            onChange: (value) =>
              setFilters({ statuses: value as InvoiceStatus[] }),
            options: [
              { label: "Draft", value: "DRAFT" },
              { label: "Sent", value: "SENT" },
              { label: "Partially Paid", value: "PARTIALLY_PAID" },
              { label: "Paid", value: "PAID" },
              { label: "Cancelled", value: "CANCELLED" },
            ],
          },
          {
            key: "clients",
            type: "checkbox",
            label: "Clients", // Added clients filter
            value: filters.clients,
            showSearch: true,
            onChange: (value) => setFilters({ clients: value as number[] }),
            onSearch: (value) => setClientSearch(value),
            options: clients?.clients.map((client) => ({
              label: client.name,
              value: client.id,
            })),
          },
          {
            key: "tags",
            label: "Tags",
            type: "checkbox",
            value: filters.tags,
            options:
              tags?.map((tag) => ({
                label: tag.name,
                value: tag.id,
              })) ?? [],
            onChange: (e) => {
              setFilters({ tags: e as number[] });
            },
          },
          {
            key: "salesPersons",
            label: "Sales Persons",
            type: "checkbox",
            value: filters.salesPersons,
            options: users?.map((user) => ({
              label: formatName(user),
              value: user.id,
            })),
            onChange: (e) => {
              setFilters({ salesPersons: e as number[] });
            },
          },
        ]}
      />
      {isLoading || !invoicesList ? (
        <FullScreenSpinner />
      ) : isMobile() ? (
        <div className="flex flex-col gap-2 mt-4">
          {invoicesList?.invoices.map((invoice) => (
            <InvoiceCard data={invoice} key={invoice.id} />
          ))}
          <Pagination
            pageNo={filters.pageNo}
            totalPages={invoicesList?.totalPages}
            setPageNo={(pageNo) => setFilters({ pageNo })}
            className="justify-start"
          />
        </div>
      ) : (
        <DataGrid
          rowKey="id"
          className="h-[calc(100vh-var(--header-height)-117px)]"
          onCellClick={(invoice) => navigate(`details/${invoice.row.id}`)}
          loading={isLoading}
          name="invoicesListMain"
          label="Invoices"
          empty={{
            icon: DollarSign,
            title: "No invoices found",
            description: "No invoices available to display.",
          }}
          pagination={{
            pageNo: filters.pageNo,
            count: invoicesList?.count,
            pageSize: 30,
            setPageNo: (pageNo) => setFilters({ pageNo }),
          }}
          rows={invoicesList?.invoices || []}
          columns={[
            {
              key: "number",
              name: "#",
              width: 100,
              renderCell: ({ row }) => row.number,
            },
            {
              key: "client",
              name: "Client",
              width: 300,
              icon: Building,
              renderCell: ({ row }) => <p>{row.clients.name}</p>,
            },
            {
              key: "status",
              name: "Status",
              width: 130,
              icon: Eye,
              renderCell: ({ row }) => <InvoiceStatusBadge invoice={row} />,
            },
            {
              key: "date",
              name: "Date",
              width: 160,
              icon: Calendar,
              renderCell: ({ row }) => format(row.date, "dd/MM/yyyy"),
            },
            {
              key: "amount",
              name: "Amount",
              width: 160,
              icon: DollarSign,
              renderCell: ({ row }) =>
                formatCurrency(calculateInvoice(row).invoiceTotal, "AED"),
            },
            {
              key: "tags",
              name: "Tags",
              width: 200,
              icon: Tags,
              renderCell: ({ row }) => (
                <TagsInput
                  value={row.invoiceTags.map((tag) => tag.tagId)}
                  onChange={(tags) => {
                    updateInvoiceMutation.mutate({
                      id: row.id,
                      invoice: {
                        invoiceTags: tags,
                      },
                    });
                  }}
                  tags={invoiceTags}
                />
              ),
            },
            {
              key: "dueDate",
              name: "Due Date",
              width: 160,
              icon: CalendarClock,
              renderCell: ({ row }) => format(row.dueDate, "dd/MM/yyyy"),
            },
            {
              key: "pendingAmount",
              name: "Pending Amount",
              width: 160,
              icon: BadgeDollarSign,
              renderCell: ({ row }) =>
                formatCurrency(calculateInvoice(row).pendingTotal, "AED"),
            },
            {
              key: "salesPerson",
              name: "Sales Person",
              width: 160,
              icon: User,
              renderCell: ({ row }) => (
                <p>{row.salesPerson ? formatName(row.salesPerson) : "-"}</p>
              ),
            },
            {
              key: "actions",
              name: "Actions",
              width: 100,
              icon: Zap,
              renderCell: ({ row }) => (
                <DropMenu
                  items={[
                    {
                      label: "Edit",
                      icon: Pencil,
                      onClick: () => navigate(`/sales/invoices/edit/${row.id}`),
                    },
                    {
                      label: "Duplicate",
                      icon: Copy,
                      onClick: () =>
                        navigate(`/sales/invoices/add?invoiceId=${row.id}`),
                    },
                    {
                      label: "Download",
                      icon: ArrowDownToLine,
                      onClick: async () => {
                        await downloadPdf({
                          name: generateInvoiceName(row),
                          url: `print?invoice=${row.uuid}`,
                        });
                      },
                    },
                  ]}
                >
                  <Button
                    onClick={(e) => e.stopPropagation()}
                    variant={"ghost"}
                  >
                    <MoreHorizontal className="h-4 text-gray-700" />
                  </Button>
                </DropMenu>
              ),
            },
          ]}
        />
      )}
    </Page>
  );
};

export default Invoices;
