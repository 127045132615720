import { z } from "zod";

const profile = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
});

export default {
  profile,
};
