import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import Schemas from "@heffl/server/src/schemas";
import { Form } from "@heffl/ui/components/primitives/form";
import ResponsivePrimaryButton from "@heffl/ui/components/ResponsivePrimaryButton";
import { zodResolver } from "@hookform/resolvers/zod";
import { Save } from "lucide-react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import BillForm from "./bill-form";

const BillEdit: React.FC = () => {
  const params = useParams();
  const billId = Number(params.id);
  const navigate = useNavigate();

  const form = useForm<z.infer<typeof Schemas.purchases.bill>>({
    resolver: zodResolver(Schemas.purchases.bill),
  });

  const { data: billDetails } = trpc.purchases.bills.details.useQuery(billId, {
    refetchOnWindowFocus: false,
  });

  const billUpdateMutation = trpc.purchases.bills.update.useMutation({
    onSuccess: () => {
      form.reset();
      toast.success("Bill updated successfully");
      navigate(-1);
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  useEffect(() => {
    if (!form.getValues("vendorId") && billDetails) {
      form.reset({
        ...billDetails,
        billTags: billDetails.billTags.map((tag) => tag.tagId),
      });
    }
  }, [billDetails, form]);

  const onSubmit = (values: z.infer<typeof Schemas.purchases.bill>) => {
    billUpdateMutation.mutate({
      id: billId,
      bill: values,
    });
  };

  return (
    <Page
      showBack
      title="Edit Bill"
      description="Update bill details"
      className="max-w-screen-lg"
      suffix={
        <ResponsivePrimaryButton
          onClick={() => form.handleSubmit(onSubmit)()}
          loading={billUpdateMutation.isLoading}
          variant="primary"
          size="md"
          icon={Save}
        >
          Update Bill
        </ResponsivePrimaryButton>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <BillForm form={form} edit />
      </Form>
    </Page>
  );
};

export default BillEdit;
