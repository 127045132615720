import { Button } from "@heffl/ui/components/primitives/button";
import { Checkbox } from "@heffl/ui/components/primitives/checkbox";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@heffl/ui/components/primitives/popover";
import { cn } from "@heffl/ui/lib/utils";
import { ChevronDown, ChevronLeft, ChevronRight } from "lucide-react";

export type PaginationProps = {
  pageNo: number;
  totalPages?: number | null;
  setPageNo: (pageNo: number) => void;
  setPageSize?: (pageSize: number) => void;
  className?: string;
  pageSize?: number;
};

const pageSizeOptions = [
  { label: "30 per page", value: 30 },
  { label: "50 per page", value: 50 },
  { label: "100 per page", value: 100 },
  { label: "1000 per page", value: 1000 },
];

const Pagination = ({
  pageNo,
  totalPages = 0,
  setPageNo,
  className,
  pageSize = 0,
  setPageSize,
}: PaginationProps) => {
  return (
    <div
      className={cn(
        "flex gap-3 justify-end items-center pr-4 mt-4 w-full",
        className
      )}
    >
      {setPageSize && (
        <Popover>
          <PopoverTrigger>
            <Button
              size="xs"
              className="flex gap-1 items-center h-8 !text-[13px]"
            >
              {pageSize} <span className="text-gray-600">per page</span>{" "}
              <ChevronDown className="ml-1 w-4 h-4" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="p-4 rounded-lg w-42" align="start">
            <div className="flex flex-col gap-2 mt-0">
              {pageSizeOptions.map((option) => (
                <div
                  key={option.label}
                  className="flex gap-2 items-center cursor-pointer"
                >
                  <Checkbox
                    id={option.value.toString()}
                    checked={pageSize === option.value}
                    onCheckedChange={() => {
                      setPageSize(option.value);
                    }}
                  />
                  <label
                    htmlFor={option.value.toString()}
                    className="cursor-pointer"
                  >
                    {option.label}
                  </label>
                </div>
              ))}
            </div>
          </PopoverContent>
        </Popover>
      )}
      <div className="flex items-center rounded-md ring-1 ring-input ring-offset-background">
        <Button
          size="xs"
          className="flex gap-1 items-center h-8 !text-[13px]"
          variant="ghost"
        >
          Page {pageNo} <span className="text-gray-600">of</span> {totalPages}
        </Button>
        <Button
          disabled={pageNo === 1}
          size="xs"
          variant="ghost"
          className="p-0 px-1.5 h-8 !text-[13px]"
          icon={ChevronLeft}
          iconClassName="w-4 h-4"
          onClick={() => setPageNo(pageNo - 1)}
        />
        <Button
          disabled={pageNo === totalPages}
          size="xs"
          iconClassName="w-4 h-4"
          className="p-0 px-1.5 h-8"
          variant="ghost"
          onClick={() => setPageNo(pageNo + 1)}
          icon={ChevronRight}
        />
      </div>
    </div>
  );
};

export default Pagination;
