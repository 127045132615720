import DataTable from "@/components/DataTable";
import { getS3URLLegacy } from "@/helpers/s3Helpers";
import { trpc } from "@/helpers/trpc";
import { DocumentViewer } from "@/pages/files";
import {
  AddExpenseDrawer,
  EditExpenseDrawer,
} from "@/pages/purchases/expenses/list";
import Schemas from "@heffl/server/src/schemas";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import { Card } from "@heffl/ui/components/primitives/card";
import { formatCurrency } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import { BadgeDollarSign, Paperclip, Plus } from "lucide-react";
import { useState } from "react";
import { z } from "zod";

type Props = {
  projectId?: number;
  fsJobId?: number;
  showAdd?: boolean;
  defaultValues?: Partial<z.infer<typeof Schemas.expenses.expense>> & {
    aggregatorCharge?: number;
  };
};

const Expenses = ({
  projectId,
  fsJobId,
  defaultValues,
  showAdd: showAddExpense = true,
}: Props) => {
  const [editExpenseId, setEditExpenseId] = useState<number | null>(null);
  const [showAdd, setShowAdd] = useState(false);
  const [showReceipt, setShowReceipt] = useState<{
    name: string;
    link: string;
  } | null>(null);

  const { data: expenses, isLoading } = trpc.expenses.list.useQuery({
    projectId,
    fsJobId,
  });

  return (
    <div className="mt-4">
      {showReceipt && (
        <DocumentViewer
          open={true}
          onClose={() => setShowReceipt(null)}
          doc={showReceipt}
        />
      )}
      <AddExpenseDrawer
        open={showAdd}
        onClose={() => setShowAdd(false)}
        defaultValues={{
          ...defaultValues,
          projectId,
          fsJobId,
        }}
      />
      {editExpenseId && (
        <EditExpenseDrawer
          open={!!editExpenseId}
          onClose={() => setEditExpenseId(null)}
          expenseId={editExpenseId}
        />
      )}

      <Card
        title="Expenses"
        suffix={
          showAddExpense && (
            <Button
              icon={Plus}
              onClick={() => setShowAdd(true)}
              variant="primary"
            >
              Expense
            </Button>
          )
        }
      >
        <DataTable
          rowKey="id"
          onRowClick={(expense) => setEditExpenseId(expense.id)}
          mobileColumns={[0, 1, 3]}
          columns={[
            {
              title: "Date",
              dataIndex: "date",
              render: (row) => dayjs(row.date).format("DD/MM/YYYY"),
            },
            {
              title: "Payee",
              render: (row) => row.users.firstName,
            },
            {
              title: "Category",
              render: (row) => row.accountingCategories.name,
            },
            {
              title: "Amount",
              render: (expense) => (
                <div className="flex gap-2 items-center">
                  <p className="w-24 font-medium text-right">
                    {formatCurrency(expense.amount, "AED")}
                  </p>
                  {expense.paidBy === "EMPLOYEE" && (
                    <Badge>To be Reimbursed</Badge>
                  )}
                  {expense.files && expense.files.fields.expense_reciept && (
                    <Button
                      size="sm"
                      icon={Paperclip}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (
                          expense.files &&
                          expense.files.fields.expense_reciept
                        ) {
                          setShowReceipt({
                            name: expense.files.fields.expense_reciept.name,
                            link: getS3URLLegacy(
                              "expenses/receipts",
                              expense.files.fields.expense_reciept.link
                            ),
                          });
                        }
                      }}
                    >
                      Receipt
                    </Button>
                  )}
                </div>
              ),
            },
            { title: "Notes", dataIndex: "notes" },
          ]}
          data={expenses?.expenses || []}
          loading={isLoading}
          empty={{
            icon: BadgeDollarSign,
            title: "No expenses",
            description: "Add your project expenses and start tracking them",
          }}
        />
      </Card>
    </div>
  );
};

export default Expenses;
