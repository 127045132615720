import { AvatarsGroup } from "@/components/UserAvatar";
import { RouterOutputs, trpc } from "@/helpers/trpc";
import { calculateCompleted, cn, isMobile } from "@heffl/ui/lib/utils";
import CircleTick from "@/pages/crm/deals/details/components/circle-tick";
import DropMenu from "@heffl/ui/components/DropMenu";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@heffl/ui/components/primitives/accordion";
import { Button } from "@heffl/ui/components/primitives/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@heffl/ui/components/primitives/dropdown-menu";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@heffl/ui/components/primitives/grid-table";
import { Input } from "@heffl/ui/components/primitives/input";
import {
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
} from "@heffl/ui/components/primitives/popover";
import { Tooltip } from "@heffl/ui/components/primitives/tooltip";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import { makeEllipsis } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import {
  ArrowUpRightFromSquare,
  CalendarCheck2,
  CheckCircle,
  ChevronDown,
  MoreHorizontal,
  Pencil,
  Plus,
  Trash,
} from "lucide-react";
import { ReactNode, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { match } from "ts-pattern";
import {
  AddProjectTaskModal,
  ProjectTaskDetailsModal,
} from "../../tasks/components/project-task-modals";
import { getProjectTaskStatus, taskStatuses } from "./kanban";
import {
  AddProjectActivityModal,
  EditProjectActivityModal,
} from "./project-activity-modals";
import TaskCard from "./kanban/TaskCard";

type TaskRowProps = {
  task: Omit<
    NonNullable<RouterOutputs["projects"]["tasks"]["details"]>,
    "files"
  >;
  onTaskClick: (id: number) => void;
  mobileView?: boolean;
  showProject?: boolean;
  showClient?: boolean;
};

type SubTaskRowProps = {
  task: NonNullable<
    NonNullable<
      RouterOutputs["projects"]["tasks"]["details"]
    >["projectSubTasks"][number]
  >;
  isLast: boolean;
  onTaskClick: (id: number) => void;
};

export const TaskRow = ({
  task,
  onTaskClick,
  mobileView = false,
  showProject = false,
  showClient = false,
}: TaskRowProps) => {
  const confirm = useConfirm();
  const navigate = useNavigate();

  const [showSubTasks, setShowSubTasks] = useState(false);
  const [open, setOpen] = useState(false);

  const taskUpdateMutation = trpc.projects.tasks.update.useMutation({
    onSuccess() {
      toast.success("Task updated successfully");
    },
    onError(error) {
      toast.error(error.message);
    },
  });
  const taskDeleteMutation = trpc.projects.tasks.delete.useMutation({
    onSuccess() {
      toast.success("Task deleted successfully");
    },
  });

  return isMobile() || mobileView ? (
    <TaskCard
      task={task}
      onTaskClick={onTaskClick}
      inProject
      className="w-full !max-w-none"
    />
  ) : (
    <>
      <TableRow
        key={task.id}
        onClick={() => onTaskClick(task.id)}
        className="cursor-pointer"
      >
        <TableCell>
          <CheckCircle className="w-4 h-4" />
        </TableCell>
        <TableCell
          className={cn(
            task.status === "COMPLETED" && "line-through text-gray-500"
          )}
        >
          <div className="flex gap-2 items-center">
            <p>{task.title}</p>
            {!!task.projectSubTasks.length && (
              <Tooltip content="Show subtasks">
                <div
                  onClick={(e) => {
                    setShowSubTasks(!showSubTasks);
                    e.stopPropagation();
                  }}
                  className="flex items-center gap-0.5 hover:bg-gray-200 p-1 rounded-md"
                >
                  <span className="flex items-center px-1.5 text-xs font-medium bg-gray-200 rounded-md">
                    {`${calculateCompleted(
                      task.projectSubTasks,
                      "status",
                      "COMPLETED"
                    )}/${task.projectSubTasks.length}`}
                  </span>
                  <ChevronDown className="w-4 h-4" />
                </div>
              </Tooltip>
            )}
          </div>
        </TableCell>
        {showClient && (
          <TableCell
            onClick={() =>
              task.projects.clients
                ? navigate(`/crm/clients/details/${task.projects.clients.id}`)
                : null
            }
          >
            {task.projects.clients && (
              <Button
                variant="ghost"
                suffix={<ArrowUpRightFromSquare className="ml-2 w-4 h-4" />}
                className="hover:text-primary-700 hover:underline"
              >
                {makeEllipsis(task.projects.clients.name, 10)}
              </Button>
            )}
          </TableCell>
        )}
        {showProject && (
          <TableCell
            onClick={() => navigate(`/projects/details/${task.projectId}`)}
          >
            <Button
              variant="ghost"
              suffix={<ArrowUpRightFromSquare className="ml-2 w-4 h-4" />}
              className="hover:text-primary-700 hover:underline"
            >
              {makeEllipsis(task.projects.title, 10)}
            </Button>
          </TableCell>
        )}
        <TableCell
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="p-0 bg-black"
        >
          <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger className="w-full h-full">
              <div
                className={cn(
                  "w-full h-full flex items-center justify-center text-white font-medium",
                  getProjectTaskStatus(task.status).color
                )}
              >
                {getProjectTaskStatus(task.status).label}
              </div>
            </PopoverTrigger>
            <PopoverContent className="space-y-2 w-56" sideOffset={0}>
              <PopoverArrow className="fill-white" />
              {taskStatuses.map((status) => (
                <div
                  key={status.value}
                  onClick={() => {
                    taskUpdateMutation.mutate({
                      id: task.id,
                      task: {
                        status: status.value,
                      },
                    });
                    setOpen(false);
                  }}
                  className={cn(
                    "p-2 cursor-pointer w-full flex items-center justify-center rounded-sm text-white font-medium",
                    status.color
                  )}
                  style={{
                    backgroundColor: status.hex,
                  }}
                >
                  {status.label}
                </div>
              ))}
            </PopoverContent>
          </Popover>
        </TableCell>
        <TableCell>
          <AvatarsGroup
            users={task.projectTaskAssignees.map((user) => user.users)}
          />
        </TableCell>
        <TableCell
          className={cn(
            task.status === "COMPLETED" && "line-through text-gray-500"
          )}
        >
          {task.date ? dayjs(task.date).format("MMM DD, YYYY") : "No due date"}
        </TableCell>
        <TableCell>
          <Button
            onClick={(e) => {
              e.stopPropagation();
            }}
            variant="ghost"
          >
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="icon">
                  <MoreHorizontal className="h-5" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem onClick={() => onTaskClick(task.id)}>
                  Edit
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={async () => {
                    const confirmed = await confirm({
                      title: "Are you sure to delete this task?",
                      body: `This will delete the task "${task.title}" and its subtasks from the project.`,
                      actionButton: "Delete",
                    });
                    if (confirmed) {
                      taskDeleteMutation.mutate(task.id);
                    }
                  }}
                  className="text-red-600"
                >
                  Delete
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </Button>
        </TableCell>
      </TableRow>
      {showSubTasks && (
        <>
          {task.projectSubTasks.map((subTask, i) => (
            <SubTaskRow
              key={subTask.id}
              task={subTask}
              onTaskClick={(id) => onTaskClick(id)}
              isLast={i === task.projectSubTasks.length - 1}
            />
          ))}
        </>
      )}
    </>
  );
};
const SubTaskRow = ({
  task: subTask,
  onTaskClick,
  isLast,
}: SubTaskRowProps) => {
  const taskUpdateMutation = trpc.projects.tasks.updateSubTask.useMutation({
    onSuccess() {
      toast.success("Task updated successfully");
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  return (
    <TableRow
      key={subTask.id}
      onClick={() => onTaskClick(subTask.parentTaskId)}
      className="cursor-pointer"
    >
      <TableCell className="p-0">
        <div className="grid !w-full h-full grid-cols-2">
          <div></div>
          <div className="rounded-bl border-b border-l border-gray-300" />
          <div />
          <div
            className={cn("border-l border-gray-300", isLast && "border-none")}
          />
        </div>
      </TableCell>
      <TableCell className="flex items-center pl-6">
        <CircleTick
          loading={
            taskUpdateMutation.variables?.id === subTask.id &&
            taskUpdateMutation.isLoading
          }
          checked={subTask.status === "COMPLETED"}
          onClick={(e) => {
            e.stopPropagation();
            taskUpdateMutation.mutate({
              id: subTask.id,
              subTask: {
                status: subTask.status === "COMPLETED" ? "OPEN" : "COMPLETED",
              },
            });
          }}
        />
        <p
          className={cn(
            "pl-2",
            subTask.status === "COMPLETED" && "line-through text-gray-500"
          )}
        >
          {subTask.title}
        </p>
      </TableCell>
      <TableCell />
    </TableRow>
  );
};

interface ActivityProps {
  activity: NonNullable<RouterOutputs["projects"]["activities"]["details"]>;
  onActivityClick: (id: number) => void;
  mobileView?: boolean;
}

export const ActivityRow = ({
  activity,
  onActivityClick,
  mobileView = false,
}: ActivityProps) => {
  const confirm = useConfirm();

  const activityUpdateMutation = trpc.projects.activities.update.useMutation({
    onSuccess() {
      toast.success("Activity updated successfully");
    },
  });
  const activityDeleteMutation = trpc.projects.activities.delete.useMutation({
    onSuccess() {
      toast.success("Activity deleted successfully");
    },
  });

  return isMobile() || mobileView ? (
    <div
      className="flex gap-3 justify-between items-center px-2 py-3 w-full bg-gray-50 rounded-lg shadow-md"
      key={activity.id}
      onClick={() => onActivityClick(activity.id)}
    >
      <CalendarCheck2 className="w-4 h-4" />
      <div className="flex flex-col gap-1 w-full">
        <p className="text-sm">{activity.title}</p>
      </div>
      <CircleTick
        loading={
          activityUpdateMutation.variables?.id === activity.id &&
          activityUpdateMutation.isLoading
        }
        checked={activity.completed}
        onClick={(e) => {
          e.stopPropagation();
          activityUpdateMutation.mutate({
            id: activity.id,
            projectActivity: {
              completed: !activity.completed,
            },
          });
        }}
      />
    </div>
  ) : (
    <TableRow
      key={activity.id}
      onClick={() => onActivityClick(activity.id)}
      className="cursor-pointer"
    >
      <TableCell>
        <CalendarCheck2 className="w-4 h-4" />
      </TableCell>
      <TableCell className="flex justify-center items-center">
        <CircleTick
          loading={
            activityUpdateMutation.variables?.id === activity.id &&
            activityUpdateMutation.isLoading
          }
          checked={activity.completed}
          onClick={(e) => {
            e.stopPropagation();
            activityUpdateMutation.mutate({
              id: activity.id,
              projectActivity: {
                completed: !activity.completed,
              },
            });
          }}
        />
      </TableCell>
      <TableCell
        className={cn(activity.completed && "line-through text-gray-500")}
      >
        {activity.title}
      </TableCell>
      <TableCell>
        <AvatarsGroup
          users={activity.projectActivityAssignees.map((user) => user.users)}
        />
      </TableCell>
      <TableCell
        className={cn(activity.completed && "line-through text-gray-500")}
      >
        {dayjs(activity.startDate).format("MMM DD, YYYY")}
      </TableCell>
      <TableCell>
        <Button
          onClick={(e) => {
            e.stopPropagation();
          }}
          variant="ghost"
        >
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" size="icon">
                <MoreHorizontal className="h-5" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem onClick={() => onActivityClick(activity.id)}>
                Edit
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={async () => {
                  const confirmed = await confirm({
                    title: "Are you sure to delete this activity?",
                    body: `This will delete the activity "${activity.title}" and its reminders from the project.`,
                    actionButton: "Delete",
                  });
                  if (confirmed) {
                    activityDeleteMutation.mutate(activity.id);
                  }
                }}
                className="text-red-600"
              >
                Delete
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </Button>
      </TableCell>
    </TableRow>
  );
};

type ProjectSectionProps = {
  id: number | null;
  title: string;
  icon?: ReactNode;
  showCompleted: boolean;
  onTaskClick: (id: number) => void;
  onTaskAddClick: () => void;
  onActivityClick: (id: number) => void;
  onActivityAddClick: () => void;
  createdAt?: Date;
  timeline: {
    type: "task";
    value: RouterOutputs["projects"]["tasks"]["list"][number];
  }[];
};

const ProjectSection = ({
  title,
  id,
  icon,
  onTaskClick,
  onTaskAddClick,
  createdAt,
  timeline,
}: ProjectSectionProps) => {
  const [editTitle, setEditTitle] = useState(
    title === "New section" &&
      createdAt &&
      dayjs().diff(createdAt, "second") <= 10
      ? true
      : false
  );
  const [updatedTitle, setUpdatedTitle] = useState(title);

  const updateSectionMutation = trpc.projects.sections.update.useMutation();
  const deleteSectionMutation = trpc.projects.sections.delete.useMutation();

  if (!timeline) return <FullScreenSpinner />;

  return (
    <div className="pt-0">
      <Accordion type="single" collapsible defaultValue="stage-unassigned">
        <AccordionItem value="stage-unassigned">
          <div className="flex flex-col">
            <div className="flex gap-2 items-center">
              <AccordionTrigger />
              <div className="flex flex-row justify-between items-center w-full">
                <div className="flex flex-row gap-2 items-center group">
                  {icon && icon}
                  {editTitle ? (
                    <Input
                      autoFocus
                      value={updatedTitle}
                      onChange={(e) => setUpdatedTitle(e.target.value)}
                      onBlur={() => {
                        if (id) {
                          setEditTitle(false);
                          updateSectionMutation.mutate({
                            id,
                            section: { title: updatedTitle },
                          });
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.currentTarget.blur();
                        }
                      }}
                    />
                  ) : (
                    <p
                      className="text-base font-semibold"
                      onClick={() => id && setEditTitle(true)}
                    >
                      {title}
                    </p>
                  )}
                  <div
                    className={cn(
                      "flex invisible gap-1 items-center group-hover:visible"
                    )}
                  >
                    <Button
                      icon={Plus}
                      variant="ghost"
                      iconClassName="w-4 h-4"
                      onClick={onTaskAddClick}
                    />
                    {id && (
                      <DropMenu
                        align="start"
                        items={[
                          {
                            label: "Rename section",
                            onClick: () => setEditTitle(true),
                            icon: Pencil,
                          },
                          {
                            label: "Delete section",
                            onClick: () => {
                              deleteSectionMutation.mutate(id);
                            },
                            icon: Trash,
                            className: "text-red-600",
                          },
                        ]}
                      >
                        <Button variant="ghost" size="icon">
                          <MoreHorizontal className="h-5" />
                        </Button>
                      </DropMenu>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <AccordionContent>
              <Table className="overflow-hidden mt-1">
                {!isMobile() && (
                  <TableHeader>
                    <TableRow>
                      <TableHead className="w-[40px]"></TableHead>
                      <TableHead>Subject</TableHead>
                      <TableHead className="w-[140px]">Status</TableHead>
                      <TableHead className="w-[120px]">Assignee</TableHead>
                      <TableHead className="w-[120px]">Due Date</TableHead>
                      <TableHead className="w-[60px]"></TableHead>
                    </TableRow>
                  </TableHeader>
                )}
                <TableBody
                  className={
                    isMobile() ? "flex flex-col gap-2 pb-2 sm:pb-0" : ""
                  }
                >
                  {timeline.map((item) =>
                    match(item)
                      .with({ type: "task" }, ({ value: task }) => (
                        <TaskRow
                          task={task}
                          onTaskClick={(id) => onTaskClick(id)}
                        />
                      ))
                      .exhaustive()
                  )}
                </TableBody>
              </Table>
            </AccordionContent>
          </div>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

interface PlanProps {
  data: RouterOutputs["projects"]["details"];
  showCompleted: boolean;
}

const Plan = ({ data, showCompleted }: PlanProps) => {
  const params = useParams();

  const projectId = Number(params.projectId);

  const [addTaskModal, setAddTaskModal] = useState<
    undefined | { open: boolean; stageId: null | number }
  >();
  const [addActivityModal, setAddActivityModal] = useState<
    undefined | { open: boolean; stageId: null | number }
  >();
  const [editTaskId, setEditTaskId] = useState<number | undefined>();
  const [editActivityId, setEditActivityId] = useState<number | undefined>();

  const { data: tasks } = trpc.projects.tasks.list.useQuery({
    projectId: projectId,
    orderBy: "createdAt",
    showAllTasks: true,
  });

  const addSectionMutation = trpc.projects.sections.add.useMutation();

  if (!data) return <FullScreenSpinner />;

  return (
    <div className="">
      {editTaskId && (
        <ProjectTaskDetailsModal
          open={!!editTaskId}
          onClose={() => setEditTaskId(undefined)}
          taskId={editTaskId}
        />
      )}
      {addActivityModal && (
        <AddProjectActivityModal
          open={addActivityModal.open}
          onClose={() => setAddActivityModal(undefined)}
          defaultValues={{
            projectId,
            pipelineStageId: addActivityModal.stageId,
          }}
        />
      )}
      {addTaskModal && (
        <AddProjectTaskModal
          open={addTaskModal.open}
          onClose={() => setAddTaskModal(undefined)}
          defaultValues={{
            projectSectionId: addTaskModal.stageId,
          }}
          projectId={projectId}
        />
      )}
      {editActivityId && (
        <EditProjectActivityModal
          open={true}
          onClose={() => setEditActivityId(undefined)}
          id={editActivityId}
        />
      )}

      <div className="flex flex-col gap-3">
        {tasks &&
          tasks?.filter((item) => item.projectSectionId === null).length >
            0 && (
            <ProjectSection
              id={null}
              title="No section"
              onTaskAddClick={() =>
                setAddTaskModal({ open: true, stageId: null })
              }
              onTaskClick={(id) => setEditTaskId(id)}
              onActivityAddClick={() =>
                setAddActivityModal({ open: true, stageId: null })
              }
              onActivityClick={(id) => setEditActivityId(id)}
              showCompleted={showCompleted}
              timeline={tasks
                ?.filter((item) => item.projectSectionId === null)
                .map((item) => ({
                  type: "task",
                  value: item,
                }))}
            />
          )}
        {data.projectSections &&
          data.projectSections.map((section) => (
            <ProjectSection
              key={section.id}
              timeline={
                tasks
                  ?.filter((item) => item.projectSectionId === section.id)
                  .map((item) => ({
                    type: "task",
                    value: item,
                  })) || []
              }
              id={section.id}
              title={section.title}
              showCompleted={showCompleted}
              onTaskAddClick={() =>
                setAddTaskModal({ open: true, stageId: section.id })
              }
              onTaskClick={(id) => setEditTaskId(id)}
              onActivityAddClick={() =>
                setAddActivityModal({ open: true, stageId: section.id })
              }
              onActivityClick={(id) => setEditActivityId(id)}
              createdAt={section.createdAt}
            />
          ))}
        <Button
          className="mt-4 w-fit"
          size="sm"
          variant="primaryOutline"
          icon={Plus}
          onClick={() => {
            addSectionMutation.mutate({
              projectId,
              title: "New section",
            });
          }}
        >
          Add section
        </Button>
      </div>
    </div>
  );
};

export default Plan;
