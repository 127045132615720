/* eslint-disable @typescript-eslint/ban-ts-comment */
import { WorkFlowAction } from "../../../types";
import sendTelegramMessage from "../../../../agenda/sendTelegramMessage";

export const sendTelegramMessageAction: WorkFlowAction = {
  name: "send-message",
  label: "Send message",
  description: "Send a message to a contact",
  inputs: [
    {
      name: "chat_id",
      label: "Chat ID",
      type: "TEXT",
      placeholder: "Chat id",
      required: true,
    },
    {
      name: "message",
      label: "Message",
      type: "TEXT",
      placeholder: "Message",
      required: true,
    },
  ],
  run: async ({ payload }) => {
    if (!payload) return;
    await sendTelegramMessage({
      // @ts-ignore
      to: payload.chat_id,
      // @ts-ignore
      message: payload.message,
    });
  },
};
