import DataTable from "@/components/DataTable";
import { trpc } from "@/helpers/trpc";
import { InvoiceStatusBadge } from "@/pages/sales/invoices/list";
import { useNavigate } from "react-router-dom";
import { useImmer } from "use-immer";

import { SearchInput } from "@/components/FormComponents";
import { formatCurrency } from "@heffl/ui/lib/utils";
import calculateInvoice from "@heffl/server/src/helpers/lineItems/calculateInvoice";
import { Button } from "@heffl/ui/components/primitives/button";
import { Card } from "@heffl/ui/components/primitives/card";
import { objectToParams } from "@heffl/ui/lib/utils";
import { format } from "date-fns";
import { DollarSign, Plus } from "lucide-react";
import enums from "@heffl/server/src/schemas/enums";
import { z } from "zod";

type Props = {
  projectId?: number;
  clientId?: number;
  fsJobId?: number;
  showAdd?: boolean;
};

const defaultFilters = {
  search: "",
  pageNo: 1,
  pageSize: 20,
  statuses: [],
  startDate: undefined,
  endDate: undefined,
};

const Invoices = ({ projectId, clientId, fsJobId, showAdd = true }: Props) => {
  const navigate = useNavigate();

  const [filters, setFilters] = useImmer<{
    search: string;
    pageNo: number;
    statuses: z.infer<typeof enums.invoiceStatus>[];
  }>(defaultFilters);

  const { data: invoices } = trpc.invoices.list.useQuery({
    ...filters,
    statuses: filters.statuses,
    projectId: projectId,
    fsJobId: fsJobId,
  });

  return (
    <Card parentClassName="mt-4">
      <div className="flex flex-col gap-3 justify-between sm:flex-row">
        <SearchInput
          className="w-full"
          placeholder="Search invoices..."
          value={filters.search}
          onChange={(e) => {
            setFilters((f) => {
              f.search = e;
            });
          }}
        />
        {showAdd && (
          <div className="flex flex-col gap-2 sm:flex-row">
            <Button
              size="md"
              variant="primary"
              onClick={() => {
                navigate(
                  `/sales/invoices/add?${objectToParams({
                    projectId,
                    clientId,
                    fsJobId,
                  })}`
                );
              }}
              icon={Plus}
            >
              Invoice
            </Button>
          </div>
        )}
      </div>
      <DataTable
        rowKey="id"
        className="mt-6"
        data={invoices?.invoices || []}
        mobileColumns={[0, 1, 2, 4]}
        onRowClick={(invoice) => {
          navigate(`/sales/invoices/details/${invoice.id}`);
        }}
        columns={[
          {
            title: "No",
            render: (invoice) => `#${invoice.number}`,
          },
          {
            title: "Client",
            render: (invoice) => invoice.clients.name,
          },
          {
            title: "Status",
            render: (invoice) => <InvoiceStatusBadge invoice={invoice} />,
          },
          {
            title: "Date",
            render: (invoice) => format(new Date(invoice.date), "dd/MM/yyyy"),
          },
          {
            title: "Total",
            render: (invoice) =>
              formatCurrency(calculateInvoice(invoice).invoiceTotal, "AED"),
          },
        ]}
        empty={{
          title: "No invoices",
          icon: DollarSign,
          description: "Create invoices and get them paid.",
          buttonSize: "sm",
        }}
      />
    </Card>
  );
};

export default Invoices;
