import { trpc } from "@/helpers/trpc";
import Schemas from "@heffl/server/src/schemas";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import { Textarea } from "@heffl/ui/components/primitives/textarea";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import { zodResolver } from "@hookform/resolvers/zod";
import { Trash2 } from "lucide-react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";

type Props = {
  leadId?: number;
  dealId?: number;
  contactId?: number;
  clientId?: number;
  open: boolean;
  onClose: () => void;
};

const NotesForm = () => {
  return (
    <>
      <FormField name="note" label="Note">
        <Textarea
          placeholder="Write notes about the deal..."
          className="mt-3 h-[60px] bg-yellow-100"
        />
      </FormField>
    </>
  );
};

export const AddNotesModal = (props: Props) => {
  const form = useForm<z.infer<typeof Schemas.crm.notes>>({
    resolver: zodResolver(Schemas.crm.notes),
  });
  const onClose = () => {
    form.reset();
    props.onClose();
  };
  const notesAddMutation = trpc.notes.add.useMutation({
    onSuccess: () => {
      toast.success("Note added successfully");
      onClose();
    },
  });
  const onSubmit = async (values: z.infer<typeof Schemas.crm.notes>) => {
    notesAddMutation.mutate({
      ...values,
      leadId: props.leadId,
      dealId: props.dealId,
      contactId: props.contactId,
      clientId: props.clientId,
    });
  };

  return (
    <ModalDrawer
      open={props.open}
      onClose={onClose}
      title="Add Note"
      footer={
        <Button
          loading={notesAddMutation.isLoading}
          onClick={form.handleSubmit(onSubmit)}
          variant="primary"
          className="w-full"
        >
          Add Note
        </Button>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <NotesForm />
      </Form>
    </ModalDrawer>
  );
};

export const EditNotesModal = ({
  open,
  onClose,
  noteId,
}: {
  open: boolean;
  onClose: () => void;
  noteId: number;
}) => {
  const confirm = useConfirm();
  const form = useForm<z.infer<typeof Schemas.crm.notes>>({
    resolver: zodResolver(Schemas.crm.notes),
  });

  const { data: noteDetails } = trpc.notes.details.useQuery(noteId);

  const noteUpdateMutation = trpc.notes.update.useMutation({
    onSuccess: () => {
      toast.success("Note updated successfully");
      onClose();
      form.reset();
    },
  });
  const noteDeleteMutation = trpc.notes.delete.useMutation({
    onSuccess: () => {
      toast.success("Note deleted successfully");
      onClose();
    },
  });

  useEffect(() => {
    if (noteDetails) {
      form.reset({
        ...noteDetails,
      });
    }
  }, [noteDetails, form]);

  const onSubmit = (values: z.infer<typeof Schemas.crm.notes>) => {
    noteUpdateMutation.mutate({
      id: noteId,
      note: values,
    });
  };

  return (
    <ModalDrawer
      open={open}
      onClose={onClose}
      title="Edit Note"
      footer={
        <div className="flex gap-2 w-full">
          <Button
            loading={noteUpdateMutation.isLoading}
            icon={Trash2}
            variant="destructiveOutline"
            onClick={async () => {
              const confirmed = await confirm({
                title: "Are you sure you want to delete this note?",
              });
              if (confirmed) {
                noteDeleteMutation.mutate(noteId);
              }
            }}
          />
          <Button
            loading={noteUpdateMutation.isLoading}
            onClick={form.handleSubmit(onSubmit)}
            variant="primary"
            className="w-full"
          >
            Update Note
          </Button>
        </div>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <NotesForm />
      </Form>
    </ModalDrawer>
  );
};
