import { MultipleUserInput, UserInput } from "@/components/FormComponents";
import { trpc } from "@/helpers/trpc";
import Schemas from "@heffl/server/src/schemas";
import Select from "@heffl/ui/components/primitives/creatable-select";
import { FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import { Mail } from "lucide-react";
import { useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import CustomFieldsInputForm from "../../../../helpers/customFields/custom-fields-input-form";
import { PhoneInput } from "@heffl/ui/components/primitives/phone-input";

const LeadForm = ({
  form,
  edit = false,
}: {
  form: UseFormReturn<z.infer<typeof Schemas.crm.lead>, unknown>;
  edit?: boolean;
}) => {
  const navigate = useNavigate();

  const [titleTouched, setTitleTouched] = useState(false);

  const { data: crmSources } = trpc.sources.list.useQuery();

  const leadName = form.watch("name");
  const { data: users } = trpc.users.list.useQuery();

  useEffect(() => {
    if (!titleTouched && leadName && !edit) {
      form.setValue("title", `${leadName}'s Lead`);
    }
  }, [leadName]);

  if (!users) return <FullScreenSpinner />;

  return (
    <>
      <FormField name="name" label="Name">
        <Input placeholder="Person/Business name" autoFocus />
      </FormField>
      <div className="grid gap-2 sm:grid-cols-2">
        <FormField name="mobile">
          <PhoneInput placeholder="Mobile" defaultCountry="AE" />
        </FormField>
        <FormField name="email">
          <Input placeholder="Email" prefix={<Mail className="h-5" />} />
        </FormField>
      </div>

      <FormField name="secondaryMobile">
        <PhoneInput placeholder="Secondary Mobile" defaultCountry="AE" />
      </FormField>
      <FormField name="title" label="Title">
        <Input placeholder="Title" onInput={() => setTitleTouched(true)} />
      </FormField>
      <div className="grid grid-cols-2 gap-2">
        <FormField name="value" label="Expected value">
          <Input prefix="AED" prefixFilled type="number" placeholder="00.00" />
        </FormField>
        <FormField name="crmSourceId" label="Source">
          <Select
            createButton={{
              label: "Add new",
              onClick: () => navigate("/settings/sources"),
            }}
            placeholder="Select source"
            options={
              crmSources?.map((source) => ({
                label: source.name,
                value: source.id,
              })) || []
            }
          />
        </FormField>
      </div>
      <div className="grid grid-cols-1 gap-3 sm:grid-cols-2">
        <MultipleUserInput
          name="leadAssignees"
          label="Assigned to"
          className="w-full"
        />
        <UserInput
          className="sm:col-span-1"
          name="ownerUserId"
          label="Owner"
          defaultCurrentUser={!edit}
        />
      </div>
      <CustomFieldsInputForm section="LEAD" />
    </>
  );
};

export default LeadForm;
