import React, { useRef, ChangeEvent } from "react";

type Props = {
  onPick: (file: FileList) => void;
  allowMultiple?: boolean;
  accept?: string;
  children: React.ReactNode;
};

const FilePicker = ({
  onPick,
  children,
  allowMultiple = false,
  accept,
}: Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length) {
      onPick(e.target.files);
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
    e.stopPropagation();
  };

  return (
    <>
      <input
        onClick={(e) => {
          e.stopPropagation();
          // for fixing file not selected if same second time
          if (e.target && e.target) {
            // @ts-ignore
            e.target.value = null;
          }
        }}
        type="file"
        ref={fileInputRef}
        className="!hidden"
        onChange={handleFileSelect}
        // for fixing issue in ios webview takephoto
        accept={accept}
        multiple={allowMultiple}
      />
      <div onClick={handleClick}>{children}</div>
    </>
  );
};

export default FilePicker;
