import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Card } from "@heffl/ui/components/primitives/card";
import { Key, Plus, UserCircle } from "lucide-react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import { Input } from "@heffl/ui/components/primitives/input";
import { Button } from "@heffl/ui/components/primitives/button";
import Schemas from "@heffl/server/src/schemas";
import { useState } from "react";

const AddPermissionSetModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const navigate = useNavigate();

  const form = useForm<z.infer<typeof Schemas.permissions.permissionSet>>({
    resolver: zodResolver(Schemas.permissions.permissionSet),
  });

  const addPermissionSetMutation = trpc.permissionSets.add.useMutation({
    onSuccess: (newPermissionSet) => {
      navigate(`edit/${newPermissionSet.id}`);
      form.reset();
    },
  });

  const onSubmit = (
    values: z.infer<typeof Schemas.permissions.permissionSet>
  ) => {
    addPermissionSetMutation.mutate(values);
  };

  return (
    <ModalDrawer
      open={open}
      onClose={onClose}
      title="Add Permission Set"
      className="bg-gray-100"
      modalClassName="max-w-sm"
      footer={
        <Button
          loading={addPermissionSetMutation.isLoading}
          type="submit"
          variant="primary"
          onClick={() => form.handleSubmit(onSubmit)()}
          className="w-full"
        >
          Add Permission Set
        </Button>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <FormField name="name" label="Name">
          <Input placeholder="Permission set name" />
        </FormField>
      </Form>
    </ModalDrawer>
  );
};

const PermissionSets = () => {
  const navigate = useNavigate();

  const [showAddPermissionSetModal, setShowAddPermissionSetModal] =
    useState(false);

  const { data: permissionSets } = trpc.permissionSets.list.useQuery();

  return (
    <Page title="Permission sets">
      <AddPermissionSetModal
        open={showAddPermissionSetModal}
        onClose={() => setShowAddPermissionSetModal(false)}
      />
      <div className="flex justify-end">
        <Button
          icon={Plus}
          variant="primary"
          onClick={() => setShowAddPermissionSetModal(true)}
        >
          Permisison set
        </Button>
      </div>
      <div className="grid grid-cols-2 gap-2 sm:grid-cols-4">
        {permissionSets?.map((permissionSet) => (
          <Card
            onClick={() => navigate(`edit/${permissionSet.id}`)}
            title={permissionSet.name}
            parentClassName="cursor-pointer"
            key={permissionSet.id}
          >
            <div className="flex justify-between">
              <div className="flex items-center">
                <Key className="h-4" />
                {permissionSet.permissionsSetItems.length} permissions
              </div>
              <div className="flex items-center">
                <UserCircle className="h-4" />
                {permissionSet._count
                  .users_users_permissionSetIdTopermissionSets || 0}{" "}
                users
              </div>
            </div>
          </Card>
        ))}
      </div>
    </Page>
  );
};

export default PermissionSets;
