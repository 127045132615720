import { create } from "zustand";

type DrawerState = {
  isDrawerVisible: boolean;
  setIsDrawerVisible: (visible: boolean) => void;
  menuIconVisible: boolean;
  setMenuIconVisible: (visible: boolean) => void;
};

export const useMobileDrawer = create<DrawerState>((set) => ({
  isDrawerVisible: false,
  setIsDrawerVisible: (visible) => set(() => ({ isDrawerVisible: visible })),
  menuIconVisible: false,
  setMenuIconVisible: (visible) => set(() => ({ menuIconVisible: visible })),
}));
