import { RouterOutputs } from "@/helpers/trpc";
import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Button } from "@heffl/ui/components/primitives/button";
import {
  ScrollArea,
  ScrollBar,
} from "@heffl/ui/components/primitives/scroll-area";
import { cn } from "@heffl/ui/lib/utils";
import { GripHorizontal, Plus } from "lucide-react";
import SortableTaskCard from "./SortableTaskCard";
import { taskStatuses } from ".";

export type ProjectTask = RouterOutputs["projects"]["tasks"]["list"][number];

export default function TaskBoard({
  tasks,
  sectionLabel,
  sectionId,
  onTaskClick,
  inProject = false,
}: {
  tasks: ProjectTask[];
  sectionLabel: string;
  sectionId: string;
  onTaskClick: (id: number) => void;
  inProject?: boolean;
}) {
  const { setNodeRef } = useDroppable({ id: sectionId });

  const sectionColor = taskStatuses.find((status) => status.value === sectionId)
    ?.color as string;

  return (
    <SortableContext
      items={tasks}
      id={sectionId}
      strategy={verticalListSortingStrategy}
    >
      <section
        className={cn("flex flex-col pt-0 rounded-sm min-w-[300px]")}
        ref={setNodeRef}
      >
        <div className="flex flex-col justify-between items-center pb-2 w-full border-b border-gray-200">
          <div className="flex gap-2 justify-between items-center w-full">
            <div className="flex gap-2 items-center">
              <div className={cn("w-3 h-3 rounded-full", sectionColor)} />
              <p className="text-sm font-semibold">{sectionLabel}</p>
            </div>
            <div className="flex items-center pr-2">
              <Button variant="ghost" className="p-1">
                <Plus className="h-4 text-gray-400" />
              </Button>
              <Button variant="ghost" className="p-1">
                <GripHorizontal className="h-4 text-gray-400" />
              </Button>
            </div>
          </div>
        </div>
        <ScrollArea className="overflow-hidden hover:overflow-y-auto mt-1.5 pb-1.5 lg:overflow-y-auto">
          <ul className="flex flex-col gap-2 py-2">
            {tasks.map((task) => (
              <SortableTaskCard
                key={task.id}
                task={task}
                onTaskClick={onTaskClick}
                inProject={inProject}
              />
            ))}
          </ul>
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
      </section>
    </SortableContext>
  );
}
