import { trpc } from "@/helpers/trpc";
import { calculateQuotationLineItems } from "@heffl/server/src/helpers/lineItems/calculateLineItems";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import {
  Tabs,
  TabsList,
  TabsTrigger,
} from "@heffl/ui/components/primitives/tabs";
import { Textarea } from "@heffl/ui/components/primitives/textarea";
import TemplateRender from "@heffl/ui/components/template-render";
import { isMobile } from "@heffl/ui/lib/utils";
import { useState } from "react";
import { useParams } from "react-router-dom";
import MobileQuote from "./MobileQuote";

const RequestChangeModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  return (
    <ModalDrawer
      open={open}
      onClose={onClose}
      className="w-full sm:w-[450px] h-4/5 sm:h-full "
      title="Request changes"
    >
      <div className="flex flex-col gap-4 pt-3">
        <Textarea placeholder="Please provide as much detail as possible" />
        <div className="flex gap-3 justify-end w-full">
          <Button variant={"outline"} onClick={onClose}>
            Cancel
          </Button>
          <Button variant={"primary"}>Request change</Button>
        </div>
      </div>
    </ModalDrawer>
  );
};

const RejectModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  return (
    <ModalDrawer
      open={open}
      onClose={onClose}
      className="w-full sm:w-[450px] h-4/5 sm:h-full "
      title="Reject changes"
    >
      <div className="flex flex-col gap-4 pt-3">
        <Textarea placeholder="Please provide as much detail as possible" />
        <div className="flex gap-3 justify-end w-full">
          <Button variant={"outline"} onClick={onClose}>
            Cancel
          </Button>
          <Button variant={"primary"}>Reject</Button>
        </div>
      </div>
    </ModalDrawer>
  );
};

const ApproveModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const [selectedTab, setSelectedTab] = useState("draw");
  return (
    <ModalDrawer
      open={open}
      onClose={onClose}
      className="w-full sm:w-[450px] h-4/5 sm:h-full "
      title="Approve changes"
    >
      <div className="flex flex-col gap-4 pt-3">
        <Tabs
          value={selectedTab}
          onValueChange={(value) => setSelectedTab(value)}
        >
          <TabsList>
            <TabsTrigger value="draw">Draw</TabsTrigger>
            <TabsTrigger value="type">Type</TabsTrigger>
          </TabsList>
        </Tabs>
        {selectedTab === "draw" ? (
          <Input placeholder="Enter new value" />
        ) : (
          <Textarea placeholder="Type" />
        )}
        <div className="flex gap-3 justify-end w-full">
          <Button variant={"outline"} onClick={onClose}>
            Cancel
          </Button>
          <Button variant={"primary"}>Approve</Button>
        </div>
      </div>
    </ModalDrawer>
  );
};

const ClientQuoteShare = () => {
  const [showRequestChangeModal, setShowRequestChangeModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const params = useParams();
  const id = Number(params.id);
  const { data: quotation } = trpc.quotations.details.useQuery(id);

  if (!quotation) return <FullScreenSpinner />;
  return (
    <div className="">
      <RequestChangeModal
        open={showRequestChangeModal}
        onClose={() => setShowRequestChangeModal(false)}
      />
      <RejectModal
        open={showRejectModal}
        onClose={() => setShowRejectModal(false)}
      />
      <ApproveModal
        open={showApproveModal}
        onClose={() => setShowApproveModal(false)}
      />
      <div className="flex h-[80px] w-full justify-between items-center px-4 bg-white border-b border-gray-200 sticky top-0 z-10">
        <h2 className="text-lg font-semibold">Softex</h2>
      </div>
      <div className="flex gap-6 w-full min-h-[90vh] justify-center bg-gray-50 p-4">
        <div className="flex flex-col sm:gap-0 gap-4 w-full sm:w-fit sm:mb-0 mb-[300px]">
          <div className="flex justify-between items-center w-full">
            <p className="text-sm font-medium">Client Portal</p>
            <Button variant={"outline"}>Download PDF</Button>
          </div>
          {isMobile() ? (
            <MobileQuote quote={quotation} />
          ) : (
            <div className="mt-3 bg-white rounded-lg border shadow-lg w-fit">
              <TemplateRender
                contentHtml={quotation.contentInjected}
                template={quotation.documentTemplates}
                previewMode={true}
              />
            </div>
          )}
        </div>

        <div className="flex fixed bottom-0 left-0 flex-col gap-4 p-3 w-full bg-white border-t border-gray-200 sm:w-fit md:relative md:bg-transparent sm:border-none">
          <h1 className="text-lg font-semibold">
            Quotation Total AED
            {
              calculateQuotationLineItems({
                lineItems: quotation.quotationProducts,
                discount: quotation.discount,
                customFields: quotation.documentTemplates.customFields,
              }).total
            }
          </h1>
          <Button variant={"primary"} onClick={() => setShowApproveModal(true)}>
            Approve
          </Button>
          <Button
            variant={"outline"}
            className="text-red-500 border-red-500"
            onClick={() => setShowRejectModal(true)}
          >
            Reject
          </Button>
          <Button
            variant={"outline"}
            onClick={() => setShowRequestChangeModal(true)}
          >
            Request Changes
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ClientQuoteShare;
