import Empty from "@/components/Empty";
import { SearchInput } from "@/components/FormComponents";
import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import { isMobile } from "@heffl/ui/lib/utils";
import enums from "@heffl/server/src/schemas/enums";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import TabsInput from "@heffl/ui/components/primitives/TabsInput";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import Select from "@heffl/ui/components/primitives/creatable-select";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@heffl/ui/components/primitives/grid-table";
import { Input } from "@heffl/ui/components/primitives/input";
import ResponsiveActionButton from "@heffl/ui/components/primitives/responsive-action-button";
import { zodResolver } from "@hookform/resolvers/zod";
import { format } from "date-fns";
import { capitalize } from "lodash";
import { Code, Pencil } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import TemplateCard from "./components/TemplateCard";
import Schemas from "@heffl/server/src/schemas";
import toast from "react-hot-toast";

const templateAddSchema = Schemas.crm.template.pick({
  name: true,
  type: true,
});

export const templateTypes: {
  key: string;
  label: string;
  value: z.infer<typeof enums.templateTypes>;
  color: string;
}[] = [
  {
    key: "quotation",
    label: "Quotation",
    value: "QUOTATION",
    color: "#FFB3B3", // Light Tailwind color
  },
  {
    key: "invoice",
    label: "Invoice",
    value: "INVOICE",
    color: "#B3FFB3", // Light Tailwind color
  },
  {
    key: "proforma-invoice",
    label: "Proforma Invoice",
    value: "PROFORMA_INVOICE",
    color: "#B3B3FF", // Light Tailwind color
  },
  {
    key: "payment",
    label: "Payment",
    value: "PAYMENT",
    color: "#FFB3E6", // Light Tailwind color
  },
  {
    key: "job",
    label: "Job",
    value: "JOB",
    color: "#FFD1B3", // Light Tailwind color
  },
  {
    key: "schedule",
    label: "Schedule",
    value: "SCHEDULE",
    color: "#B3FFF0", // Light Tailwind color
  },
  {
    key: "purchase-order",
    label: "Purchase Order",
    value: "PURCHASE_ORDER",
    color: "#FFB3B3", // Light Tailwind color
  },
  {
    key: "bill",
    label: "Bill",
    value: "BILL",
    color: "#FFB3B3", // Light Tailwind color
  },
];

const AddTemplateModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const navigate = useNavigate();

  const form = useForm<z.infer<typeof templateAddSchema>>({
    resolver: zodResolver(templateAddSchema),
    defaultValues: {
      type: "QUOTATION",
    },
  });

  const addTemplateMutation = trpc.templates.add.useMutation({
    onSuccess: (newTemplate) => {
      navigate(`edit/${newTemplate.id}`);
      form.reset();
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  return (
    <ModalDrawer
      open={open}
      onClose={onClose}
      title="Add template"
      description="Add templates for quick creation."
      className="bg-gray-100"
      modalClassName="max-w-sm"
    >
      <Form
        {...form}
        onSubmit={(values) =>
          addTemplateMutation.mutate({ ...values, contentHtml: "" })
        }
      >
        <FormField name="name" label="Name">
          <Input placeholder="Template name" />
        </FormField>
        <FormField name="type" label="Type">
          <Select allowClear={false} options={templateTypes} />
        </FormField>
        <div className="self-end mt-6 space-x-2">
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            loading={addTemplateMutation.isLoading}
            type="submit"
            variant="primary"
          >
            Add template
          </Button>
        </div>
      </Form>
    </ModalDrawer>
  );
};

const TemplatesList = () => {
  const [addTemplate, setAddTemplate] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedTemplateType, setSelectedTemplateType] = useState<
    z.infer<typeof enums.templateTypes> | "all"
  >("all");

  const { data: templates, isLoading } = trpc.templates.list.useQuery({
    type: selectedTemplateType === "all" ? undefined : selectedTemplateType,
    search,
    includeInactive: true,
  });

  const navigate = useNavigate();

  return (
    <Page title="Templates" fullWidth>
      <AddTemplateModal
        open={addTemplate}
        onClose={() => setAddTemplate(false)}
      />
      {/* Header actions */}
      <div className="flex flex-row gap-3 justify-between">
        <SearchInput value={search} onChange={(e) => setSearch(e)} />
        <ResponsiveActionButton
          onClick={() => setAddTemplate(true)}
          text="Template"
        />
      </div>
      <TabsInput
        className="mt-4"
        options={[
          { label: "All", value: "all" },
          ...templateTypes.map((type) => ({
            label: type.label,
            value: type.value,
          })),
        ]}
        value={selectedTemplateType}
        onChange={(value) =>
          setSelectedTemplateType(value as typeof selectedTemplateType)
        }
      />
      {isMobile() ? (
        <div className="flex flex-col gap-2 mt-4 mb-[100px]">
          {templates?.map((template) => (
            <TemplateCard key={template.id} data={template} />
          ))}
        </div>
      ) : (
        <Table className="mt-6">
          <TableHeader>
            <TableRow>
              <TableHead className="w-16">Id</TableHead>
              <TableHead>Name</TableHead>
              <TableHead>Type</TableHead>
              <TableHead>Created on</TableHead>
              <TableHead>Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {templates?.map((template) => (
              <TableRow key={template.id} className="cursor-pointer">
                <TableCell>#{template.id}</TableCell>
                <TableCell
                  onClick={() => navigate(`edit/${template.id}`)}
                  className="font-medium"
                >
                  {template.name}
                </TableCell>
                <TableCell>
                  <Badge variant="unique">
                    {capitalize(template.type.replace("_", " ").toLowerCase())}
                  </Badge>
                </TableCell>
                <TableCell>{format(template.createdAt, "dd/MM/yy")}</TableCell>
                <TableCell>
                  <Button
                    size="xs"
                    onClick={() => {
                      navigate(`edit/${template.id}`);
                    }}
                    icon={Pencil}
                  >
                    Edit
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      {!templates?.length && (
        <Empty
          loading={isLoading}
          className="mt-8"
          title="No templates added yet"
          icon={Code}
          description="Please add a template to the system"
          actionText="Add template"
          onAction={() => setAddTemplate(true)}
          buttonSize="sm"
        />
      )}
    </Page>
  );
};

export default TemplatesList;
