import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import { cn } from "@heffl/ui/lib/utils";
import useNav from "@/stores/useNav";
import enums from "@heffl/server/src/schemas/enums";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import {
  BadgeDollarSign,
  Bolt,
  Contact,
  FileCode,
  Folder,
  KanbanSquare,
  LucideIcon,
  ShoppingBag,
  Users,
  UsersRound,
  Wrench,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

export type Apps = {
  name: string;
  icon: LucideIcon;
  path: string;
  color: string;
  id: z.infer<typeof enums.appKeys>;
}[];
export const apps: Apps = [
  {
    name: "CRM",
    icon: Users,
    path: "/crm/dashboard",
    color: "#16a34a", // green-600
    id: "CRM",
  },
  {
    name: "Sales",
    icon: BadgeDollarSign,
    path: "/sales/dashboard",
    color: "#3b82f6", // blue-500
    id: "SALES",
  },
  {
    name: "Purchases",
    icon: ShoppingBag,
    path: "/purchases/vendors",
    color: "#10B981",
    id: "PURCHASES",
  },
  // {
  //   name: "Calendar",
  //   icon: CalendarDays,
  //   path: "/calendar",
  //   color: "#ef4444", // red-500
  //   id: "CALENDAR",
  // },
  {
    name: "Projects",
    icon: KanbanSquare,
    path: "/projects/dashboard",
    color: "#8b5cf6", // violet-500
    id: "PROJECTS",
  },
  {
    name: "Files",
    icon: Folder,
    path: "/files",
    color: "#ef4444", // red-500
    id: "FILES",
  },
  {
    name: "Contacts",
    icon: Contact,
    path: "/contacts",
    color: "#22c55e", // green-500
    id: "CONTACTS",
  },
  {
    name: "Service",
    icon: Wrench,
    path: "/field-service/dashboard",
    color: "#f97316", // orange-500
    id: "FIELD_SERVICE",
  },
  {
    name: "Employees",
    icon: UsersRound,
    path: "/employees/list",
    color: "#3b82f6", // blue-500
    id: "EMPLOYEES",
  },
  {
    name: "Templates",
    icon: FileCode,
    path: "/templates",
    color: "#eab308", // yellow-500
    id: "TEMPLATES",
  },
  // {
  //   name: "Assistant",
  //   icon: Bot,
  //   path: "/ai-assistant",
  //   color: "#06b6d4", // cyan-500
  //   id: "AI_ASSISTANT",
  // },
  {
    name: "Settings",
    icon: Bolt,
    path: "/settings/profile",
    color: "#525252", // gray-600
    id: "SETTINGS",
  },
];

const AppsList = () => {
  const { setSelectedPage } = useNav();

  const { data: currentUser } = trpc.users.currentUser.useQuery();

  const navigate = useNavigate();

  if (!currentUser) return <FullScreenSpinner />;

  return (
    <Page
      fullWidth
      className="h-full bg-green-100"
      style={{
        backgroundImage: "linear-gradient(to top, #fff1eb 0%, #ace0f9 100%)",
      }}
      pageTitleParentClassName="bg-[#ACDFF9]"
    >
      <div className="grid grid-cols-3 gap-y-5 px-6 mx-auto max-w-screen-md sm:pt-20 sm:gap-4 sm:grid-cols-4">
        {apps
          .filter(
            (app) =>
              app.id !== "EMPLOYEES" || currentUser.type === "SUPER_ADMIN"
          )
          .map((app) => (
            <div
              className="flex flex-col gap-2 justify-center items-center font-medium text-gray-700 cursor-pointer sm:gap-3"
              key={app.name}
              onClick={() => {
                console.log(app.path.split("/").pop(), "selected path");
                setSelectedPage(app.path.split("/").pop() || "");
                navigate(app.path);
              }}
            >
              <div className="p-6 w-20 h-20 bg-white rounded-lg shadow-md transition-all duration-100 sm:w-24 sm:h-24 hover:scale-110 hover:shadow-lg sm:rounded-xl">
                <app.icon
                  className={cn(
                    "w-full h-full hover:rotate-12 transition-all  duration-75",
                    `text-${app.color}`
                  )}
                />
              </div>
              <p>{app.name}</p>
            </div>
          ))}
      </div>
    </Page>
  );
};

export default AppsList;
