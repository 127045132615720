import Empty from "@/components/Empty";
import Page from "@/components/page";
import { RouterOutputs, trpc } from "@/helpers/trpc";
import Schemas from "@heffl/server/src/schemas";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import ResponsiveActionButton from "@heffl/ui/components/primitives/responsive-action-button";
import { zodResolver } from "@hookform/resolvers/zod";
import { Card } from "@heffl/ui/components/primitives/card";
import { UserSearch } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";

export const SourceForm = () => (
  <div className="flex flex-col gap-3 w-full">
    <FormField name="name" label="Name">
      <Input placeholder="Name" />
    </FormField>
    <FormField name="description" label="Description">
      <Input placeholder="Description" />
    </FormField>
  </div>
);

const EditSourceDrawer = ({
  open,
  onClose,
  sourceId,
}: {
  open: boolean;
  onClose: () => void;
  sourceId: number;
}) => {
  const form = useForm<z.infer<typeof Schemas.sources.sourceSchema>>({
    resolver: zodResolver(Schemas.sources.sourceSchema),
  });

  const { data } = trpc.sources.details.useQuery(sourceId);

  const editSourceMutation = trpc.sources.update.useMutation({
    onSuccess: () => {
      form.reset();
      toast.success("Successfully updated source.");
      onClose();
    },
    onError: () => {
      toast.error("Failed to update source.");
    },
  });

  useEffect(() => {
    if (data) {
      form.reset({
        name: data.name,
        description: data.description,
      });
    }
  }, [data]);

  return (
    <ModalDrawer
      open={open}
      onClose={onClose}
      className="w-full sm:max-w-sm"
      title="Edit Source"
    >
      <div className="flex flex-col gap-3 items-center w-full">
        <Form
          {...form}
          onSubmit={(values) => {
            editSourceMutation.mutate({ id: sourceId, source: values });
          }}
          className="w-full"
        >
          <SourceForm />

          <Button
            type="submit"
            variant="primary"
            className="w-full"
            loading={editSourceMutation.isLoading}
          >
            Update source
          </Button>
        </Form>
      </div>
    </ModalDrawer>
  );
};

const AddSourceDrawer = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const form = useForm<z.infer<typeof Schemas.sources.sourceSchema>, unknown>({
    resolver: zodResolver(Schemas.sources.sourceSchema),
    defaultValues: {},
  });

  const addSourceMutation = trpc.sources.add.useMutation({
    onSuccess: () => {
      form.reset();
      toast.success("Succesfully added source.");
      onClose();
    },
    onError: () => {
      form.reset();
      toast.error("Failed to add source.");
    },
  });

  return (
    <ModalDrawer
      open={open}
      onClose={onClose}
      className="w-full sm:max-w-sm"
      title="Add Source"
    >
      <div className="flex flex-col gap-3 items-center w-full">
        <Form
          {...form}
          onSubmit={(values) => {
            addSourceMutation.mutate(values);
          }}
          className="w-full"
        >
          <SourceForm />

          <Button
            type="submit"
            variant="primary"
            className="w-full"
            loading={addSourceMutation.isLoading}
          >
            Add source
          </Button>
        </Form>
      </div>
    </ModalDrawer>
  );
};

type TSource = RouterOutputs["sources"]["list"][number];
const SourceCard = ({
  source,
  onClick,
}: {
  source: TSource;
  onClick: () => void;
}) => {
  return (
    <Card className="flex gap-4 cursor-pointer" onClick={onClick}>
      <div className="p-3 bg-green-100 rounded-full">
        <UserSearch className="h-6 text-primary" />
      </div>
      <div className="flex flex-col gap-1 justify-center">
        <p className="text-sm font-medium">{source.name}</p>
        <p className="text-xs text-gray-500">{source.description}</p>
      </div>
    </Card>
  );
};

const Sources = () => {
  const [addSource, setAddSource] = useState(false);
  const [editSource, setEditSource] = useState<number | null>(null);

  const { data } = trpc.sources.list.useQuery();

  if (!data) return <FullScreenSpinner />;
  return (
    <Page title="Sources">
      <AddSourceDrawer open={addSource} onClose={() => setAddSource(false)} />
      {editSource && (
        <EditSourceDrawer
          open={editSource !== null}
          onClose={() => setEditSource(null)}
          sourceId={editSource}
        />
      )}
      <div className="flex justify-between w-full">
        <div />
        <div className="space-x-3">
          <ResponsiveActionButton
            onClick={() => setAddSource(true)}
            text="Source"
          />
        </div>
      </div>
      {!data?.length ? (
        <Empty
          title="No sources added"
          icon={UserSearch}
          description="Please add a source"
          className="mt-8"
        />
      ) : (
        <div className="grid grid-cols-2 gap-3 mt-4 sm:grid-cols-4">
          {data?.map((source) => (
            <SourceCard
              key={source.id}
              source={source}
              onClick={() => setEditSource(source.id)}
            />
          ))}
        </div>
      )}
    </Page>
  );
};

export default Sources;
