import Page from "@/components/page";
import { preventInputMinus } from "@/helpers/customFields/custom-fields-input-form";
import { trpc } from "@/helpers/trpc";
import { integrationProviders } from "@heffl/server/src/helpers/integrations/index";
import Schemas from "@heffl/server/src/schemas";
import { CustomIcon } from "@heffl/ui/components/icons";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import Select from "@heffl/ui/components/primitives/creatable-select";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import { MultiSelect } from "@heffl/ui/components/primitives/multi-select";
import { generateUniqueColor } from "@heffl/ui/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { DatePicker } from "@tremor/react";
import { Link, UserCircle2 } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { match } from "ts-pattern";
import { z } from "zod";

type ValidateCustomFieldsProps = {
  customFields: { required?: boolean; name: string; label: string }[];
  customFieldsValues: Record<string, string>;
  form: unknown;
};
const validateCustomFields = ({
  customFields,
  customFieldsValues,
  form,
}: ValidateCustomFieldsProps) => {
  if (!customFields) return true;

  const customFieldErrors = customFields
    .filter((field) => field.required && !customFieldsValues[field.name])
    .map((field) => ({
      name: `data.${field.name}`,
      message: `${field.label} is required`,
    }));
  if (customFieldErrors.length) {
    customFieldErrors.forEach((error) =>
      // @ts-ignore
      form.setError(error.name, { message: error.message })
    );
    return false;
  }
  return true;
};

const Integrations = () => {
  const form = useForm<z.infer<typeof Schemas.integrations.integration>>({
    resolver: zodResolver(Schemas.integrations.integration),
    defaultValues: {
      provider: "zoho_books",
    },
  });

  const [showInputEntry, setShowInputEntry] = useState(false);
  const [integrationLoading, setIntegrationLoading] = useState(false);

  const { data: integrations } = trpc.integrations.list.useQuery();
  const initiateZohoBooksOAuth =
    trpc.integrations.initiateZohoBooksOAuth.useMutation();

  const { data: users } = trpc.users.list.useQuery();

  const addIntegrationMutation = trpc.integrations.add.useMutation();

  const onDataSubmit = async (
    values: z.infer<typeof Schemas.integrations.integration>
  ) => {
    const isValid = validateCustomFields({
      customFieldsValues: values.data,
      form,
      customFields: integrationProviders[values.provider].inputs,
    });
    if (!isValid) return;
    setIntegrationLoading(true);
    await addIntegrationMutation.mutateAsync({
      provider: values.provider,
      data: values.data,
      connected: values.provider === "telr" ? true : false,
    });
    if (values.provider === "zoho_books") {
      const authUrl = await initiateZohoBooksOAuth.mutateAsync();
      window.open(authUrl, "_self");
    }
    setShowInputEntry(false);
    setIntegrationLoading(false);
  };

  useEffect(() => {
    const zohoIntegration = integrations?.find(
      (i) => i.provider === "zoho_books"
    );
    if (zohoIntegration) {
      form.reset({
        provider: "zoho_books",
        data: zohoIntegration.data,
      });
    }
  }, [integrations]);

  if (!integrations) {
    return <FullScreenSpinner />;
  }

  return (
    <Page
      title="Integrations"
      description="Connect your favorite apps to heffl."
    >
      <ModalDrawer
        title="Connect Zoho Books"
        open={showInputEntry}
        onClose={() => setShowInputEntry(false)}
        footer={
          <Button
            onClick={() => {
              form.handleSubmit(onDataSubmit)();
            }}
            variant="primary"
            loading={addIntegrationMutation.isLoading || integrationLoading}
          >
            Continue
          </Button>
        }
      >
        <Form {...form} onSubmit={onDataSubmit}>
          {integrationProviders[form.getValues("provider")].inputs.map(
            (field) => (
              <FormField
                name={`data.${field.name}`}
                key={field.name}
                label={field.label}
              >
                {match(field)
                  .with({ type: "TEXT" }, () => (
                    <Input placeholder={field.placeholder} />
                  ))
                  .with({ type: "NUMBER" }, () => (
                    <Input
                      type="number"
                      onKeyDown={preventInputMinus}
                      min="0"
                      placeholder={field.placeholder}
                      // for preventing number increment or decrement on scroll
                      onWheel={(e) => e.currentTarget.blur()}
                    />
                  ))
                  .with({ type: "DATE" }, () => (
                    <DatePicker placeholder={field.placeholder} />
                  ))
                  .with({ type: "SINGLE_OPTION" }, () => (
                    <Select
                      placeholder={field.placeholder}
                      options={field?.values?.map((field) => ({
                        label: field.label,
                        value: field.value,
                      }))}
                    />
                  ))
                  .with({ type: "MULTIPLE_USER_SELECT" }, () => (
                    <MultiSelect
                      icon={UserCircle2}
                      placeholder={field.placeholder}
                      options={
                        users?.map((user) => ({
                          label: user.firstName,
                          value: user.id,
                          color: generateUniqueColor(user.firstName, 500),
                        })) || []
                      }
                    />
                  ))
                  .exhaustive()}
              </FormField>
            )
          )}
        </Form>
      </ModalDrawer>
      <div className="flex gap-3">
        <div className="flex gap-3 items-center p-4 w-[500px] rounded-md border shadow-md">
          <CustomIcon icon="zoho" className="w-20" />
          <div>
            <p className="text-lg font-medium">Zoho Books</p>
            <p className="w-4/5 text-sm text-gray-500">
              Connect zoho books and sync your invoices and expenses.
            </p>
          </div>
          <Button
            onClick={() => {
              form.reset({
                provider: "zoho_books",
              });
              setShowInputEntry(true);
            }}
            disabled={integrations.some(
              (integration) =>
                integration.provider === "zoho_books" &&
                integration.accessToken &&
                integration.connected
            )}
            variant={
              integrations.some(
                (integration) =>
                  integration.provider === "zoho_books" &&
                  integration.accessToken &&
                  integration.connected
              )
                ? "secondary"
                : "primary"
            }
            icon={Link}
          >
            {integrations.some(
              (integration) =>
                integration.provider === "zoho_books" &&
                integration.accessToken &&
                integration.connected
            )
              ? "Connected"
              : "Connect"}
          </Button>
        </div>
        <div className="flex gap-3 items-center p-4 w-[500px] rounded-md border shadow-md">
          <CustomIcon icon="telr" className="w-20 h-20" />
          <div>
            <p className="text-lg font-medium">Telr</p>
            <p className="w-4/5 text-sm text-gray-500">
              Connect telr and create payment links.
            </p>
          </div>
          <Button
            onClick={() => {
              form.reset({
                provider: "telr",
              });
              setShowInputEntry(true);
            }}
            disabled={integrations.some(
              (integration) =>
                integration.provider === "telr" && integration.connected
            )}
            variant={
              integrations.some(
                (integration) =>
                  integration.provider === "telr" && integration.connected
              )
                ? "secondary"
                : "primary"
            }
            icon={Link}
          >
            {integrations.some(
              (integration) =>
                integration.provider === "telr" && integration.connected
            )
              ? "Connected"
              : "Connect"}
          </Button>
        </div>
      </div>
    </Page>
  );
};

export default Integrations;
