import messageToMobileApp from "@heffl/ui/lib/messageToMobileApp";

type loginData = {
  token: string;
  userId: number;
};
export const doLogin = (data: loginData) => {
  messageToMobileApp({
    action: "userAuth",
    data: {
      login: true,
      userId: data.userId,
    },
  });
  localStorage.setItem("hefflToken", data.token);
};

export const doLogout = () => {
  messageToMobileApp({
    action: "userAuth",
    data: {
      login: false,
      userId: 0,
    },
  });
  localStorage.removeItem("hefflToken");
};
