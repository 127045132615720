import { cn } from "@heffl/ui/lib/utils";
import CopyToClipboard from "@heffl/ui/components/primitives/copy-to-clipboard";
import { Link } from "lucide-react";

type Props = {
  items: {
    label: string;
    value: string;
    onClick?: () => void;
    allowCopy?: boolean;
    copyText?: string;
  }[];
};

export const InfoItemVTSingle = ({ item }: { item: Props["items"][0] }) => {
  return (
    <div className="py-2">
      <dt className="font-medium text-gray-900">{item.label}</dt>
      <dd
        className={cn(
          "text-gray-700 sm:col-span-2 flex items-center gap-2",
          item.onClick && "hover:text-primary cursor-pointer"
        )}
        onClick={item.onClick}
      >
        {item.value}
        {item.allowCopy && (
          <CopyToClipboard copyText={item.copyText || item.value || ""} />
        )}
        {!!item.onClick && <Link className="h-3.5 text-primary" />}
      </dd>
    </div>
  );
};

const InfoItemsVT = ({ items }: Props) => {
  return (
    <div className="flow-root">
      <dl className="-my-3 text-sm divide-y divide-gray-100">
        {items.map((item) => (
          <InfoItemVTSingle key={item.label} item={item} />
        ))}
      </dl>
    </div>
  );
};

export default InfoItemsVT;
