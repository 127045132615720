import { cn, isMobile } from "@heffl/ui/lib/utils";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@heffl/ui/components/primitives/grid-table";
import { LucideIcon } from "lucide-react";
import { create } from "zustand";
import Empty, { EmptyProps } from "./Empty";
import Pagination, { PaginationProps } from "./Pagination";

interface Column<T> {
  icon?: LucideIcon;
  title: string;
  dataIndex?: keyof T;
  render?: (row: T) => React.ReactNode;
  className?: string;
  textAlign?: "left" | "right" | "center";
}

type Props<T> = {
  columns?: Column<T>[];
  // pass index
  mobileColumns?: number[];
  data?: T[];
  rowKey: keyof T;
  loading?: boolean;
  empty?: EmptyProps;
  onRowClick?: (row: T) => void;
  className?: string;
  pagination?: PaginationProps;
};

interface DataTableStoreState {
  lastClickedRow: string | null;
  setLastClickedRow: (id: string) => void;
}

const useDataTableStore = create<DataTableStoreState>((set) => ({
  lastClickedRow: null,
  setLastClickedRow: (id: string) => set({ lastClickedRow: id }),
}));
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DataTable = <T extends { [key: string]: any }>({
  columns = [],
  data,
  rowKey = "id",
  loading,
  empty,
  onRowClick,
  className,
  pagination,
  mobileColumns = [],
}: Props<T>) => {
  const lastClickedRow = useDataTableStore((state) => state.lastClickedRow);
  const setLastClickedRow = useDataTableStore(
    (state) => state.setLastClickedRow
  );

  const filteredColumns = columns.filter((_, index) => {
    if (isMobile() && mobileColumns.length) {
      return mobileColumns.includes(index);
    }
    return true;
  });

  return (
    <>
      {/* {pagination && <Pagination {...pagination} />} */}
      <Table className={cn(className)}>
        <TableHeader>
          <TableRow>
            {filteredColumns.map((column) => {
              return (
                <TableHead
                  key={column.title}
                  className={cn("", column.className)}
                >
                  <div className="flex items-center">
                    {column.icon && (
                      <column.icon className="h-3.5 text-gray-600" />
                    )}
                    {column.title}
                  </div>
                </TableHead>
              );
            })}
          </TableRow>
        </TableHeader>

        <TableBody>
          {data?.map((row, index) => (
            <TableRow
              key={row[rowKey] || index}
              className={cn(
                "cursor-pointer",
                lastClickedRow === String(row[rowKey]) ? "bg-[#f5fffb]" : ""
              )}
              onClick={() => {
                setLastClickedRow(String(row[rowKey]));
                if (onRowClick) {
                  onRowClick(row);
                }
              }}
            >
              {filteredColumns.map((column) =>
                column.render ? (
                  <TableCell
                    key={column.title}
                    className={cn(
                      column.textAlign && `text-${column.textAlign}`
                    )}
                  >
                    {column.render(row)}
                  </TableCell>
                ) : (
                  <TableCell
                    key={column.title}
                    className={cn(
                      "",
                      column.textAlign && `text-${column.textAlign}`
                    )}
                  >
                    {column.dataIndex
                      ? String(row[column.dataIndex] || "")
                      : ""}
                  </TableCell>
                )
              )}
            </TableRow>
          ))}
          {loading && (
            <TableRow>
              <TableCell colSpan={columns.length}>
                <FullScreenSpinner className="pt-0 h-20" />
              </TableCell>
            </TableRow>
          )}
          {!loading && data && data.length === 0 && empty && (
            <TableRow>
              <TableCell colSpan={columns.length}>
                <Empty {...empty} className="py-8" />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {pagination && <Pagination {...pagination} />}
    </>
  );
};

export default DataTable;
