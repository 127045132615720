import { RouterOutputs } from "../trpc";

type ValidateCustomFieldsProps = {
  customFields?: RouterOutputs["customizations"]["customFields"]["list"];
  customFieldsValues: Record<string, string>;
  form: unknown;
  name?: string;
};

const validateCustomFields = ({
  customFields,
  customFieldsValues,
  form,
  name = "customFields",
}: ValidateCustomFieldsProps) => {
  if (!customFields) return true;

  const customFieldErrors: {
    name: string;
    message: string;
  }[] = [];

  customFields.filter((field) => {
    const value = customFieldsValues[field.name];
    if (field.required && !value) {
      customFieldErrors.push({
        name: `${name}.${field.name}`,
        message: `${field.label} is required`,
      });
    }

    if (field.dataType === "NUMBER") {
      const isInteger =
        field.dataType === "NUMBER" && value && Number.isInteger(Number(value));
      if (!isInteger && value) {
        customFieldErrors.push({
          name: `${name}.${field.name}`,
          message: `${field.label} is not a valid number`,
        });
      }
      if (field.isMultiplier && Number(value || 0) <= 0) {
        customFieldErrors.push({
          name: `${name}.${field.name}`,
          message: `${field.label} must be greater than 0`,
        });
      }
    }
  });
  if (customFieldErrors.length) {
    customFieldErrors.forEach((error) =>
      // @ts-ignore
      form.setError(error.name, { message: error.message })
    );
    return false;
  }
  return true;
};

export default validateCustomFields;
