import { UserAvatar } from "@/components/UserAvatar";
import { Card } from "@heffl/ui/components/primitives/card";
import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import { formatName } from "@heffl/ui/lib/utils";
import { Pencil } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Badge } from "@heffl/ui/components/primitives/badge";

const Employees = () => {
  const navigate = useNavigate();

  const { data: users } = trpc.users.list.useQuery({
    includeInactive: true,
  });

  return (
    <Page title="Employees" description="Manage your team.">
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
        {users?.map((user) => (
          <Card
            key={user.id}
            className="cursor-pointer"
            onClick={() => navigate(`/employees/details/${user.id}`)}
          >
            <div className="flex items-center space-x-4">
              <UserAvatar user={user} size="lg" />
              <div className="grid gap-0.5 text-xs">
                <div className="flex gap-2 text-base font-medium">
                  {formatName(user)}
                  {!user.active && <Badge variant="error">Inactive</Badge>}
                </div>
                <div className="text-sm text-gray-500 dark:text-gray-400">
                  {user.email}
                </div>
              </div>
              <div className="flex justify-center items-center ml-auto w-8 h-8 rounded-full border border-gray-200 cursor-pointer dark:border-gray-800 hover:bg-primary hover:text-white">
                <Pencil className="w-4 h-4" />
              </div>
            </div>
          </Card>
        ))}
      </div>
    </Page>
  );
};

export default Employees;
