import { trpc } from "@/helpers/trpc";
import { integrationSchemas } from "@heffl/server/src/helpers/integrations";
import { z } from "zod";

const useIntegrations = () => {
  const { data, isLoading } = trpc.integrations.list.useQuery();

  const get = <T extends keyof typeof integrationSchemas>(provider: T) => {
    const integration = data?.find((i) => i.provider === provider);

    if (isLoading || !data || !integration || !integration.connected)
      return {
        integrated: false,
        data: undefined as z.infer<(typeof integrationSchemas)[T]> | undefined,
      };

    return {
      integrated: true,
      data: integration.data as z.infer<(typeof integrationSchemas)[T]>,
    };
  };

  return { get };
};

export default useIntegrations;
