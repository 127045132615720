import { create } from "zustand";
import { io, Socket } from "socket.io-client";

type SocketStore = {
  socket: Socket | null;
  isConnected: boolean;
  connect: () => void;
  disconnect: () => void;
};

export const useRealtime = create<SocketStore>((set) => ({
  socket: null,
  isConnected: false,
  connect: () => {
    const socket = io(import.meta.env.VITE_SERVER_URL);
    socket.on("connect", () => set({ isConnected: true }));
    socket.on("disconnect", () => set({ isConnected: false }));
    set({ socket });
  },
  disconnect: () => {
    set((state) => {
      state.socket?.disconnect();
      return { socket: null, isConnected: false };
    });
  },
}));
