import { Card } from "@heffl/ui/components/primitives/card";
import { RouterOutputs, trpc } from "@/helpers/trpc";
import { DATE_TIME_WORD_FORMAT, QUOTATION_STATUS } from "@/lib/constants";
import { cn, formatCurrency } from "@heffl/ui/lib/utils";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import TagsInput from "@heffl/ui/components/TagInput";
import toast from "react-hot-toast";
import { calculateQuotationLineItems } from "@heffl/server/src/helpers/lineItems/calculateLineItems";

type TQuotation = Omit<
  RouterOutputs["quotations"]["list"]["quotations"][number],
  "salesPerson"
>;

const QuotationCard = ({ data }: { data: TQuotation }) => {
  const navigate = useNavigate();

  const { data: tags } = trpc.tags.list.useQuery({ type: "QUOTATION" });

  const updateQuotationMutation = trpc.quotations.update.useMutation({
    onError(error) {
      toast.error(error.message);
    },
  });

  return (
    <Card onClick={() => navigate(`/sales/quotations/details/${data.id}`)}>
      <div className="flex flex-col gap-2">
        <div className="flex flex-row justify-between">
          <p className="text-sm font-medium">
            {data.clients?.name ?? "No company"}
          </p>

          <div className="flex flex-row gap-2">
            <div
              className={cn(
                QUOTATION_STATUS.find((status) => status.value === data.status)
                  ?.color,
                "hover:text-black rounded-full px-3 py-1 text-xs text-white font-medium w-fit flex items-center h-fit"
              )}
            >
              {
                QUOTATION_STATUS.find((status) => status.value === data.status)
                  ?.label
              }
            </div>
            {/* <Button variant="outline" className="px-1" size="sm">
              <MoreVertical className="h-3" />
            </Button> */}
          </div>
        </div>
        <TagsInput
          emptyLabel="No tags"
          value={data.quotationTags.map((tag) => tag.tagId)}
          onChange={(tags) => {
            updateQuotationMutation.mutate({
              id: data.id,
              quotation: { quotationTags: tags },
            });
          }}
          tags={tags || []}
        />
        <div className="flex flex-row justify-between">
          <p className="text-xs">Quotation number</p>
          <p className="text-xs text-gray-500">#{data.id}</p>
        </div>
        <div className="flex flex-row justify-between">
          <p className="text-xs">Created on</p>
          <p className="text-xs text-gray-500">
            {" "}
            {format(data.createdAt, DATE_TIME_WORD_FORMAT)}
          </p>
        </div>
        <div className="flex flex-row justify-between">
          <p className="text-xs">Total</p>
          <p className="text-xs text-gray-500">
            {formatCurrency(
              calculateQuotationLineItems({
                lineItems: data.quotationProducts,
                discount: data.discount,
                customFields: data.documentTemplates.customFields,
              }).total,
              "AED"
            )}
          </p>
        </div>
      </div>
    </Card>
  );
};

export default QuotationCard;
