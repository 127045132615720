import DataTable from "@/components/DataTable";
import { SearchInput } from "@/components/FormComponents";
import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import Schemas from "@heffl/server/src/schemas";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import { Input } from "@heffl/ui/components/primitives/input";
import MiniRichTextEditor from "@heffl/ui/components/primitives/mini-rich-text-editor";
import { zodResolver } from "@hookform/resolvers/zod";
import dayjs from "dayjs";
import { Plus, Workflow } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

const AddWorkflowModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const navigate = useNavigate();

  const form = useForm<z.infer<typeof Schemas.workflows.workflow>>({
    resolver: zodResolver(Schemas.workflows.workflow),
    defaultValues: {
      name: "",
    },
  });

  const addWorkflowMutation = trpc.automations.add.useMutation({
    onSuccess: (newWorkflow) => {
      navigate(`edit/${newWorkflow.id}`);
      form.reset();
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const handleSubmit = (values: z.infer<typeof Schemas.workflows.workflow>) => {
    addWorkflowMutation.mutate(values);
  };

  return (
    <ModalDrawer
      open={open}
      onClose={onClose}
      title="Add Automation"
      description="Create an automation to automate your workflows."
      className="bg-gray-100"
      modalClassName="max-w-sm"
      footer={
        <Button
          loading={addWorkflowMutation.isLoading}
          type="submit"
          variant="primary"
          className="w-full"
          icon={Plus}
          onClick={() => form.handleSubmit(handleSubmit)()}
        >
          Add Workflow
        </Button>
      }
    >
      <Form {...form} onSubmit={handleSubmit}>
        <FormField name="name" label="Name">
          <Input placeholder="Workflow name" />
        </FormField>
        <FormField name="description" label="Description">
          <MiniRichTextEditor placeholder="Workflow description" />
        </FormField>
      </Form>
    </ModalDrawer>
  );
};

const AutomationsList = () => {
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [addWorkflowModalOpen, setAddWorkflowModalOpen] = useState(false);

  const { data: workflows, isLoading } = trpc.automations.list.useQuery({
    search,
  });

  return (
    <Page title="Automations">
      <AddWorkflowModal
        open={addWorkflowModalOpen}
        onClose={() => setAddWorkflowModalOpen(false)}
      />
      <div className="flex justify-between">
        <SearchInput value={search} onChange={setSearch} />
        <Button
          icon={Plus}
          variant="primary"
          onClick={() => setAddWorkflowModalOpen(true)}
        >
          Create Automation
        </Button>
      </div>
      <DataTable
        className="mt-8"
        rowKey="id"
        data={workflows || []}
        onRowClick={(row) => {
          navigate(`/automations/edit/${row.id}`);
        }}
        columns={[
          {
            title: "Name",
            dataIndex: "name",
          },
          {
            title: "Created At",
            dataIndex: "createdAt",
            render: (row) => (
              <div>{dayjs(row.createdAt).format("DD/MM/YYYY")}</div>
            ),
          },
          {
            title: "Status",
            render: () => <Badge variant="error">Inactive</Badge>,
          },
        ]}
        loading={isLoading}
        empty={{
          icon: Workflow,
          title: "No automations",
          description: "Create an automation to automate your workflows.",
        }}
      />
    </Page>
  );
};

export default AutomationsList;
