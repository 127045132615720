import { AddActivityModal } from "@/components/actionsModals/activities-modals";
import { AddLogModal } from "@/components/actionsModals/log-modals";
import { AddNotesModal } from "@/components/actionsModals/notes-modals";
import { AddTaskModal } from "@/components/actionsModals/tasks-modals";

import DropMenu from "@/components/DropMenu";
import Page from "@/components/page";
import { renderCustomFieldValue } from "@heffl/server/src/helpers/customFields";
import { trpc } from "@/helpers/trpc";
import { formatCurrency, isMobile } from "@heffl/ui/lib/utils";
import { openGoogleMaps } from "@/pages/field-service/components/property-selector";
import enums from "@heffl/server/src/schemas/enums";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import {
  CollapsibleContent,
  CollapsibleTrigger,
} from "@heffl/ui/components/primitives/collapsible";
import CopyToClipboard from "@heffl/ui/components/primitives/copy-to-clipboard";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { ScrollArea } from "@heffl/ui/components/primitives/scroll-area";
import NextTabs from "@heffl/ui/components/tabs";
import { Collapsible } from "@radix-ui/react-collapsible";
import {
  Calendar,
  CalendarCheck2,
  CheckCircle,
  ChevronDown,
  Mail,
  MessageCircle,
  MessageSquare,
  Navigation,
  PenSquare,
  Pencil,
  Phone,
  Plus,
  StickyNote,
} from "lucide-react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useImmer } from "use-immer";
import { z } from "zod";
import InvoiceCard from "../../../sales/invoices/components/InvoiceCard";
import QuotationCard from "../../../sales/quotations/QuotationCard";
import AddDealDrawer from "../../deals/components/AddDealDrawer";
import { CompanyLogo } from "../../deals/components/dealCard";
import { InfoItem } from "../../deals/details";
import { EditClientDrawer } from "../list";
import ClientFocus from "./focus";
import ClientHistory from "./history";

const ClientDetails = () => {
  const params = useParams();
  const clientId = Number(params.clientId);
  const navigate = useNavigate();

  const [showAdd, setShowAdd] = useImmer<{
    activity: boolean;
    task: boolean;
    note: boolean;
    log: z.infer<typeof enums.activityTypes> | undefined;
  }>({
    activity: false,
    task: false,
    note: false,
    log: undefined,
  });
  const [editClient, setEditClient] = useState(false);
  const [addDeal, setAddDeal] = useState(false);

  const { data: client } = trpc.clients.details.useQuery(clientId);

  if (!client) return <FullScreenSpinner />;

  const ClientDetails = (
    <ScrollArea className={`block px-3 w-full h-full sm:w-1/5 sm:block`}>
      <div className="flex flex-col gap-3">
        <div className="flex flex-col gap-1 items-center mt-4 w-full">
          <CompanyLogo company={client} className="w-14 h-14 rounded-full" />
          <h1 className="text-lg font-semibold text-gray-800">{client.name}</h1>
          <Button
            variant="outline"
            size="sm"
            className="py-0 text-xs"
            onClick={() => setEditClient(true)}
          >
            <Pencil className="h-3.5" />
            Edit client
          </Button>
          <div className="flex flex-row gap-2">
            <p className="text-sm text-gray-400">{client.type}</p>
          </div>
        </div>

        <div className="flex flex-row gap-1 justify-center items-center w-full">
          <div className="w-1 h-1 bg-green-500 rounded-full" />
          <p className="text-xs text-gray-400">
            Last activity: {client.updatedAt?.toDateString() || "Not updated"}
          </p>
        </div>
      </div>

      <div className="flex flex-col gap-3 mt-5">
        {client.taxNumber && (
          <InfoItem
            label="Tax Number"
            value={
              <div className="flex flex-wrap gap-1">
                <div className="flex gap-1 items-center px-2 py-1 bg-gray-100 rounded-xl">
                  <p className="text-xs">{client.taxNumber}</p>
                  <CopyToClipboard copyText={client.taxNumber} />
                </div>
              </div>
            }
          />
        )}
        {client.clientAddresses.length > 0 && (
          <InfoItem
            label="Address"
            value={
              <div className="flex flex-wrap gap-1">
                <p className="text-sm">
                  {client.clientAddresses[0]?.city},{" "}
                  {client.clientAddresses[0]?.countries?.name}
                </p>
                <Button
                  onClick={() => openGoogleMaps(client.clientAddresses[0])}
                  variant="primaryOutline"
                  size="sm"
                  icon={Navigation}
                >
                  Open on map
                </Button>
              </div>
            }
          />
        )}
        {client.website && (
          <InfoItem
            label="Website"
            value={
              <div className="flex flex-wrap gap-1">
                <div className="flex gap-1 items-center px-2 py-1 bg-gray-100 rounded-xl">
                  <p className="text-xs">{client.website}</p>
                  <CopyToClipboard copyText={client.website} />
                </div>
              </div>
            }
          />
        )}
        {client.customFieldFields &&
          client.customFieldFields.map((field) => (
            <InfoItem
              key={field.id}
              label={field.label}
              value={renderCustomFieldValue(
                field,
                client.customFields[field.name]
              )}
            />
          ))}
      </div>
    </ScrollArea>
  );

  const clientActivity = (
    <ScrollArea
      className={` sm:w-3/5 !h-full sm:px-4 sm:py-4 p-0 sm:block bg-white sm:bg-gray-100 border-none sm:border-l border-gray-200 block  w-full  `}
    >
      <div className="flex justify-center items-center mt-3 w-full sm:justify-end">
        <div className="grid grid-cols-4 gap-1 sm:gap-2 sm:flex">
          <DropMenu
            items={[
              {
                label: "Email",
                onClick: () =>
                  setShowAdd((draft) => {
                    draft.log = "email";
                  }),
                icon: Mail,
              },
              {
                label: "Call",
                onClick: () =>
                  setShowAdd((draft) => {
                    draft.log = "call";
                  }),
                icon: Phone,
              },
              {
                label: "Whatsapp message",
                onClick: () =>
                  setShowAdd((draft) => {
                    draft.log = "whatsapp_message";
                  }),
                icon: MessageCircle,
              },
              {
                label: "Meeting",
                onClick: () =>
                  setShowAdd((draft) => {
                    draft.log = "meeting";
                  }),
                icon: Calendar,
              },
              {
                label: "SMS",
                onClick: () =>
                  setShowAdd((draft) => {
                    draft.log = "sms";
                  }),
                icon: MessageSquare,
              },
            ]}
          >
            <Button size="sm" variant="outline">
              <PenSquare className="h-4" /> Log
            </Button>
          </DropMenu>

          <Button
            size="sm"
            variant="outline"
            onClick={() =>
              setShowAdd((draft) => {
                draft.note = true;
              })
            }
          >
            <StickyNote className="h-4" /> Note
          </Button>
          <Button
            size="sm"
            variant="outline"
            onClick={() =>
              setShowAdd((draft) => {
                draft.task = true;
              })
            }
          >
            <CheckCircle className="h-4" /> Task
          </Button>

          <Button
            size="sm"
            variant="outline"
            onClick={() =>
              setShowAdd((draft) => {
                draft.activity = true;
              })
            }
          >
            <CalendarCheck2 className="h-4" /> Activity
          </Button>
        </div>
      </div>

      <ClientFocus />
      <ClientHistory />
    </ScrollArea>
  );

  const clientResources = (
    <ScrollArea
      className={`box-border block p-3 px-0 w-full h-full sm:w-1/5 sm:block sm:h-[85vh]`}
    >
      <div className="flex flex-col gap-3">
        {/* Deals section */}
        <Collapsible defaultOpen className="p-4">
          <CollapsibleTrigger>
            <div className="flex flex-row gap-1 items-center">
              <ChevronDown className="w-4 h-4" />
              <h1 className="text-sm font-semibold">Deal</h1>
            </div>
          </CollapsibleTrigger>
          <CollapsibleContent className="flex flex-col gap-3 py-3">
            <Button
              onClick={() => setAddDeal(true)}
              variant="outline"
              className="w-full text-gray-600"
            >
              <Plus className="w-4 h-4 text-gray-500" />
              Create new deal
            </Button>

            {client.deals.length ? (
              client.deals.map((deal) => (
                <div
                  key={deal.id}
                  className="flex flex-col gap-2 p-4 w-full rounded-lg border border-gray-200 cursor-pointer hover:bg-gray-50"
                  onClick={() => navigate(`/crm/deals/details/${deal.id}`)}
                >
                  <div className="flex flex-row gap-2">
                    <p className="text-xs text-gray-400">
                      Closing date: {deal.closingDate?.toDateString()}
                    </p>
                  </div>
                  <Badge className="text-xs w-fit">{deal.status}</Badge>
                  <p className="text-sm font-semibold">{deal.title}</p>
                  {deal.price && (
                    <p>{formatCurrency(deal.price, deal.currencies.symbol)}</p>
                  )}
                </div>
              ))
            ) : (
              <div className="flex flex-col gap-2 justify-center items-center p-3 w-full">
                <p className="text-sm">No deals added</p>
              </div>
            )}
          </CollapsibleContent>
        </Collapsible>
        {/* Deals section ends here */}

        {/* Quotations section */}
        <Collapsible defaultOpen className="p-4">
          <CollapsibleTrigger>
            <div className="flex flex-row gap-1 items-center">
              <ChevronDown className="w-4 h-4" />
              <h1 className="text-sm font-semibold">Quotations</h1>
            </div>
          </CollapsibleTrigger>
          <CollapsibleContent className="flex flex-col gap-3 py-3">
            <Button
              onClick={() => navigate(`/sales/quotations/add`)}
              variant="outline"
              className="w-full text-gray-600"
            >
              <Plus className="w-4 h-4 text-gray-500" />
              Create new Quotation
            </Button>
            {client.quotations.length ? (
              client.quotations.map((quotation) => (
                <QuotationCard key={quotation.id} data={quotation} />
              ))
            ) : (
              <div className="flex flex-col gap-2 justify-center items-center p-3 w-full">
                <p className="text-sm">No quotations added</p>
              </div>
            )}
          </CollapsibleContent>
        </Collapsible>
        {/* Quotations section ends here */}

        {/* Invoices section */}
        <Collapsible defaultOpen className="p-4">
          <CollapsibleTrigger>
            <div className="flex flex-row gap-1 items-center">
              <ChevronDown className="w-4 h-4" />
              <h1 className="text-sm font-semibold">Invoices</h1>
            </div>
          </CollapsibleTrigger>
          <CollapsibleContent className="flex flex-col gap-3 py-3">
            <Button
              onClick={() => navigate(`/sales/invoices/add`)}
              variant="outline"
              className="w-full text-gray-600"
            >
              <Plus className="w-4 h-4 text-gray-500" />
              Create new Invoice
            </Button>
            {client.invoices.length ? (
              client.invoices.map((invoice) => (
                <InvoiceCard key={invoice.id} data={invoice} />
              ))
            ) : (
              <div className="flex flex-col gap-2 justify-center items-center p-3 w-full">
                <p className="text-sm">No invoices added</p>
              </div>
            )}
          </CollapsibleContent>
        </Collapsible>
        {/* Invoices section ends here */}
      </div>
    </ScrollArea>
  );

  return (
    <Page
      fullWidth
      showBack
      title="Client details"
      breadcrumbs={[
        {
          label: "Contacts",
          path: "/crm/contacts",
        },
        {
          label: "Contact details",
          path: `/crm/contacts/details/${client.id}`,
        },
      ]}
      className="pt-0"
    >
      <AddActivityModal
        open={showAdd.activity}
        onClose={() =>
          setShowAdd((draft) => {
            draft.activity = false;
          })
        }
        clientId={clientId}
      />
      <AddNotesModal
        open={showAdd.note}
        onClose={() =>
          setShowAdd((draft) => {
            draft.note = false;
          })
        }
        clientId={clientId}
      />
      <AddTaskModal
        open={showAdd.task}
        onClose={() =>
          setShowAdd((draft) => {
            draft.task = false;
          })
        }
        clientId={clientId}
      />
      {showAdd.log && (
        <AddLogModal
          open={!!showAdd.log}
          onClose={() =>
            setShowAdd((draft) => {
              draft.log = undefined;
            })
          }
          clientId={clientId}
          type={showAdd.log}
        />
      )}
      <AddDealDrawer
        open={addDeal}
        onClose={() => {
          setAddDeal(false);
        }}
      />
      {editClient && (
        <EditClientDrawer id={clientId} onClose={() => setEditClient(false)} />
      )}

      {isMobile() ? (
        <NextTabs
          className="mt-3"
          tabs={[
            {
              id: "info",
              label: "Info",
              content: ClientDetails,
            },
            {
              id: "activity",
              label: "Activity",
              content: clientActivity,
            },
            {
              id: "resources",
              label: "Resources",
              content: clientResources,
            },
          ]}
        />
      ) : (
        <div className="flex flex-row mt-0  w-full sm:mb-0 mb-[60px] sm:h-[92vh] h-full">
          {ClientDetails}
          {clientActivity}
          {clientResources}
        </div>
      )}
    </Page>
  );
};

export default ClientDetails;
