import Empty from "@/components/Empty";
import { Icons } from "@heffl/ui/components/icons";
import { getS3URLLegacy } from "@/helpers/s3Helpers";
import { trpc } from "@/helpers/trpc";
import ActivityTimeline from "@/pages/crm/components/activity-timeline";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@heffl/ui/components/primitives/avatar";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import RenderHtml from "@heffl/ui/components/render-html";
import NextTabs from "@heffl/ui/components/tabs";
import dayjs from "dayjs";
import {
  Calendar,
  CheckCircle,
  MessageCircle,
  Navigation,
  Phone,
  XCircle,
} from "lucide-react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { openGoogleMaps } from "../../components/property-selector";
import { callMobile, messageOnWhatsApp } from "../schedules/details";
import Page from "@/components/page";
import { CommentInput } from "@/pages/crm/leads/details";

type TTab = "details" | "messages";

const ActivitiesDetails = () => {
  const params = useParams();
  const activityId = Number(params.id);

  const [tab, setTab] = useState<TTab>("details");

  const { data: activity } = trpc.activities.details.useQuery(activityId);
  const updateActivityMutation = trpc.activities.update.useMutation();

  if (!activity) {
    return <FullScreenSpinner />;
  }

  return (
    <Page
      title="Activity"
      description={`${dayjs(activity.createdAt).format("DD MMM YYYY HH:mm A")}`}
      showBack
    >
      {tab === "messages" && (
        <CommentInput
          activityId={activity.id}
          className="fixed left-0 bottom-2 z-50 px-2 w-full"
        />
      )}
      <NextTabs
        value={tab}
        onChange={setTab as (tab: TTab) => void}
        tabs={[
          {
            id: "details",
            label: "Details",
            content: (
              <div className="mt-3">
                <div className="flex flex-col items-center">
                  <div className="flex gap-1">
                    <Badge className="text-sm capitalize" variant="unique">
                      {activity.type}
                    </Badge>
                  </div>
                  <p className="flex items-center mt-2 text-lg font-medium hover:text-primary-500">
                    {activity.title}
                  </p>

                  <div className="flex items-center mt-2 text-sm text-gray-700">
                    <Calendar className="mr-2 w-4 h-4" />
                    {dayjs(activity.createdAt).format("DD MMM YYYY hh:mm A")}
                    {activity.endDate &&
                    !dayjs(activity.createdAt).isSame(activity.endDate, "day")
                      ? ` - ${dayjs(activity.endDate).format(
                          "DD MMM YYYY hh:mm A"
                        )}`
                      : ` - ${dayjs(activity.endDate).format("hh:mm A")}`}
                  </div>
                </div>
                <div className="grid grid-cols-4 gap-3 mt-4">
                  <div className="space-y-1 text-center">
                    <Button
                      variant="secondary"
                      className="rounded-full"
                      size="icon"
                      onClick={() => {
                        updateActivityMutation.mutate({
                          id: activity.id,
                          activity: {
                            completed: !activity.completed,
                          },
                        });
                      }}
                    >
                      {activity.completed ? (
                        <XCircle className="w-5 h-5 text-red-600" />
                      ) : (
                        <CheckCircle className="w-5 h-5 text-primary-800" />
                      )}
                    </Button>
                    <p>{activity.completed ? "Mark Undone" : "Mark Done"}</p>
                  </div>

                  <div className="space-y-1 text-center">
                    <Button
                      disabled={!activity.locationLink}
                      onClick={() => {
                        openGoogleMaps({
                          googleMapsLink: activity.locationLink,
                        });
                      }}
                      variant="secondary"
                      className="rounded-full"
                      size="icon"
                    >
                      <Navigation className="w-5 h-5 text-primary-800" />
                    </Button>
                    <p>Map</p>
                  </div>

                  <div className="space-y-1 text-center">
                    <Button
                      variant="secondary"
                      className="rounded-full"
                      size="icon"
                      onClick={() => {
                        setTab("messages");
                      }}
                    >
                      <MessageCircle className="w-5 h-5 text-primary-800" />
                    </Button>

                    <p>Add note</p>
                  </div>
                </div>

                <RenderHtml className="mt-4">
                  {activity.description || "No description"}
                </RenderHtml>

                <p className="mt-4 mb-1 font-medium">Assignees</p>
                <div className="flex flex-col gap-4 mt-3">
                  {activity.activityAssignees.map((assignee) => (
                    <div key={assignee.id} className="flex gap-2">
                      <Avatar>
                        {assignee.users.profileImage && (
                          <AvatarImage
                            src={getS3URLLegacy(
                              "users/profileImages",
                              assignee.users.profileImage
                            )}
                            alt={`${assignee.users.firstName} ${assignee.users.lastName}`}
                          />
                        )}
                        <AvatarFallback>
                          {assignee.users.firstName[0]}
                        </AvatarFallback>
                      </Avatar>
                      <div className="flex justify-between w-full">
                        <div>
                          <p className="font-medium capitalize">{`${assignee.users.firstName} ${assignee.users.lastName}`}</p>
                          <p className="text-sm text-gray-500">
                            {assignee.users.phone || "No phone number"}
                          </p>
                        </div>
                        <div className="flex gap-">
                          <Button
                            onClick={() => {
                              assignee.users.phone &&
                                messageOnWhatsApp(assignee.users.phone, "Call");
                            }}
                            size="icon"
                            variant="ghost"
                            disabled={!assignee.users.phone}
                          >
                            <Icons.whatsapp className="w-5 h-5 text-primary-800" />
                          </Button>
                          <Button
                            disabled={!assignee.users.phone}
                            onClick={() => {
                              assignee.users.phone &&
                                callMobile(assignee.users.phone);
                            }}
                            size="icon"
                            variant="ghost"
                          >
                            <Phone className="w-5 h-5 text-primary-800" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ),
          },
          {
            id: "messages",
            label: "Messages",
            content: (
              <div className="p-3 rounded-lg">
                {!activity.comments?.length && (
                  <Empty
                    title="No messages"
                    description="Add a message to get started"
                    icon={MessageCircle}
                  />
                )}
                {activity.comments && (
                  <ActivityTimeline
                    activities={activity.comments.map((comment) => ({
                      type: "comment",
                      value: {
                        ...comment,
                        date: comment.createdAt,
                      },
                    }))}
                  />
                )}
              </div>
            ),
          },
        ]}
      />
    </Page>
  );
};

export default ActivitiesDetails;
