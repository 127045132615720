import { z } from "zod";

export const defaultLineItemsColumns = [
  {
    label: "No",
    name: "no",
    width: null,
    style: "text-align:left",
    contentStyle: "",
  },
  {
    label: "Item",
    name: "item",
    width: "50%",
    style: "text-align:left",
    contentStyle: "text-align:left",
  },
  {
    label: "Quantity",
    name: "quantity",
    width: null,
    style: "text-align:right",
    contentStyle: "text-align:right",
  },
  {
    label: "Price",
    name: "price",
    width: null,
    style: "text-align:right",
    contentStyle: "text-align:right",
  },
  {
    label: "VAT",
    name: "vat",
    width: null,
    style: "text-align:right",
    contentStyle: "text-align:right",
  },
  {
    label: "Total",
    name: "total",
    width: null,
    style: "text-align:right",
    contentStyle: "text-align:right",
  },
];

// all setting should start with 'page,lineItem,header,footer' only
export const templateSettingsSchema = z.object({
  pageSize: z.enum(["A4", "A5", "Letter"]),
  pageMarginTop: z.coerce.number(),
  pageMarginBottom: z.coerce.number(),
  pageMarginLeft: z.coerce.number(),
  pageMarginRight: z.coerce.number(),
  lineItemFont: z.string(),
  lineItemFontSize: z.coerce.number(),
  lineItemHeaderBackgroundColor: z.string(),
  lineItemHeaderTextColor: z.string(),
  lineItemColumnsHidden: z.string().array(),
  showHeader: z.boolean(),
  showFooter: z.boolean(),
  headerFirstPageOnly: z.boolean(),
  headerBackgroundImage: z.string(),
  lineItemColumns: z
    .object({
      name: z.string(),
      label: z.string(),
      type: z.enum(["default", "custom"]),
      width: z.string().nullable(),
      style: z.string().default(""),
      contentStyle: z.string().default(""),
    })
    .array(),
  headerBackgroundImagePosition: z.enum([
    "top",
    "center",
    "bottom",
    "left",
    "right",
  ]),
  footerBackgroundImage: z.string(),
  footerBackgroundImagePosition: z.enum([
    "top",
    "center",
    "bottom",
    "left",
    "right",
  ]),
});

export type TemplateSettings = z.infer<typeof templateSettingsSchema>;

export const templateSettingsDefaults: {
  [key in keyof TemplateSettings]: TemplateSettings[key];
} = {
  lineItemFont: "Inter",
  lineItemFontSize: 13,
  pageSize: "A4",
  pageMarginTop: 0.7,
  pageMarginBottom: 0.7,
  pageMarginLeft: 0.55,
  pageMarginRight: 0.55,
  lineItemHeaderBackgroundColor: "#3C3D3A",
  lineItemHeaderTextColor: "#ffffff",
  lineItemColumnsHidden: [],
  headerBackgroundImage: "",
  headerBackgroundImagePosition: "top",
  footerBackgroundImage: "",
  footerBackgroundImagePosition: "bottom",
  showHeader: true,
  showFooter: true,
  headerFirstPageOnly: false,
  lineItemColumns: defaultLineItemsColumns.map((column) => ({
    ...column,
    type: "default",
  })),
};
