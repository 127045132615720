import { styled } from "goober";

const RenderHtml = ({
  children,
  className,
}: {
  children: string | undefined | null;
  className?: string;
}) => {
  const Wrapper = styled("div")`
    ol li {
      list-style-position: outside !important;
      margin-left: 14px !important;
    }
    ul li {
      list-style-position: outside !important;
      margin-left: 14px !important;
    }
  `;
  return (
    <Wrapper
      dangerouslySetInnerHTML={{ __html: children || "" }}
      className={`${className}`}
    />
  );
};

export default RenderHtml;
