import ActivityCalendar from "@/components/ActivityCalendar";
import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import Schemas from "@heffl/server/src/schemas";
import enums, { enumsToOptions } from "@heffl/server/src/schemas/enums";
import { Button } from "@heffl/ui/components/primitives/button";
import Select from "@heffl/ui/components/primitives/creatable-select";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import { PhoneInput } from "@heffl/ui/components/primitives/phone-input";
import { Separator } from "@heffl/ui/components/primitives/separator";
import { Switch } from "@heffl/ui/components/primitives/switch";
import { zodResolver } from "@hookform/resolvers/zod";
import { Key, Save } from "lucide-react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";

const EmployeeDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const form = useForm<z.infer<typeof Schemas.user.user>>({
    resolver: zodResolver(Schemas.user.user),
  });

  const { data: user } = trpc.users.details.useQuery({ id: Number(params.id) });
  const { data: permissionSets } = trpc.permissionSets.list.useQuery();

  const updateUserMutation = trpc.users.update.useMutation({
    onSuccess: () => {
      toast.success("User updated successfully");
    },
  });

  useEffect(() => {
    if (user) {
      form.reset(user);
    }
  }, [user]);

  if (!user) return <FullScreenSpinner />;
  return (
    <Page className="flex justify-center" title="Profile" showBack>
      <div className="flex flex-col gap-3 p-5 w-full max-w-3xl">
        <Form
          {...form}
          onSubmit={(values) => {
            updateUserMutation.mutate({
              id: user.id,
              user: values,
            });
          }}
        >
          <div className="grid grid-cols-3 gap-3 mt-6">
            <FormField name="firstName" label="First Name">
              <Input />
            </FormField>
            <FormField name="lastName" label="Last Name">
              <Input />
            </FormField>
            <FormField name="mobile" label="Mobile">
              <PhoneInput placeholder="Mobile" defaultCountry="AE" />
            </FormField>
          </div>
          <div className="grid grid-cols-2 gap-3">
            <FormField name="email" label="Email">
              <Input />
            </FormField>
            <FormField name="permissionSetId" label="Permission Set">
              <Select
                placeholder="Select permission set"
                allowClear
                options={permissionSets?.map((permissionSet) => ({
                  label: permissionSet.name,
                  value: permissionSet.id,
                }))}
                icon={<Key className="h-4" />}
                createButton={{
                  label: "Create new permission set",
                  onClick: () => navigate("/employees/permission-sets"),
                }}
              />
            </FormField>
          </div>
          <div className="grid grid-cols-2 gap-3 sm:grid-cols-3">
            <FormField name="type" label="Type">
              <Select
                placeholder="Select type"
                options={enumsToOptions(enums.userTypes)}
              />
            </FormField>
            <FormField name="hourlyRate" label="Hourly rate">
              <Input type="number" />
            </FormField>
            <FormField name="active" label="Active">
              <Switch />
            </FormField>
          </div>
          <Button
            loading={updateUserMutation.isLoading}
            type="submit"
            variant="primary"
            icon={Save}
            className="mt-3"
          >
            Update user
          </Button>
        </Form>
        <div>
          <h3 className="mt-4 text-xl font-semibold">Your activity</h3>
          <Separator className="mb-2" />
          <div className="w-full h-[230px]">
            <ActivityCalendar />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default EmployeeDetails;
