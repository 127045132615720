// @ts-ignore
import BookViewer from "./sales/quotations/print-preview";

const html = `<div id="print-wrapper" class="go1380588907" properties="[object Object]" style="zoom: 1;"><div class="page-header" style="padding-bottom: 0.7in; padding-left: 0.55in; padding-right: 0.4in; background-size: contain; background-repeat: no-repeat; background-position: center top;"><div class="go3308338495 undefined">
  <style>undefined</style>
  </div></div><div class="page-footer" style="padding-top: 0.7in; padding-left: 0.55in; padding-right: 0.4in; background-size: contain; background-repeat: no-repeat; background-position: center bottom;"><div class="go3308338495 undefined">
  <style>undefined</style>
  </div></div><style>
    ol li {
      list-style-position: outside !important;
      margin-left: 14px !important;
    }
    ul li {
      list-style-position: outside !important;
      margin-left: 14px !important;
    }
    @page {
      margin-top:0inin !important;
      margin-bottom:0in !important;
      margin-left:0 !important;
      margin-right:0 !important;
    }
    @page :first {
      margin-top:0in !important;
    }
    </style><table class="w-full"><thead><tr><td><div style="height: 67.1953px;"></div></td></tr></thead><tbody><tr><td style="padding-left: 0.55in; padding-right: 0.4in;"><div class="go3308338495 undefined">
  <style>
   table.line-items table,table.line-items th,table.line-items td {
      border: 0px solid black;
      
    }
    table.line-items td, table.line-items th {
      padding:5px;
      vertical-align:top;
    }
    table.line-items th {
      padding-top:10px;
      padding-bottom:10px;
      color:#ffffff;
    }
    table.line-items thead {
      background-color:#25a2ce;
    }
      table th:first-child{
      border-radius:3px 0 0 3px;
    }

    table th:last-child{
      border-radius:0 3px 3px 0;
    }
    table.line-items-summary td, table.line-items-summary th {
      padding:5px;
    }
    .total-text {
      color:black;
    }
    </style>
  <p style="line-height: 1.3;"><img src="https://heffl.s3.me-central-1.amazonaws.com/files/XdmDwIcr9mGDed9IVpU5H.png" alt="" width="100%"></p>
<p style="line-height: 1.3;">&nbsp;</p>
<p style="line-height: 1.1;"><span style="color: #25a2cd; font-size: 20px;"><strong><span style="font-family: arial, helvetica, sans-serif;">PREPARED FOR:</span></strong></span></p>
<p style="line-height: 1.1;">&nbsp;</p>
<table style="border-collapse: collapse; width: 73.6533%;" border="0"><colgroup><col style="width: 26.2207%;"><col style="width: 73.7793%;"></colgroup>
<tbody>
<tr>
<td style="line-height: 1.1;"><span style="font-family: arial, helvetica, sans-serif; font-size: 12px;">Customer Name:</span></td>
<td style="line-height: 1.1;"><span style="font-family: arial, helvetica, sans-serif; font-size: 12px;">Dayim middleast holding DIFC</span></td>
</tr>
<tr>
<td style="vertical-align: top;"><span style="font-family: arial, helvetica, sans-serif; font-size: 12px;">Address:</span></td>
<td style="vertical-align: top;"><span style="font-family: arial, helvetica, sans-serif; font-size: 12px;">United Arab Emirates</span></td>
</tr>
</tbody>
</table>
<p style="line-height: 1.3;">&nbsp;</p>
<div style="page-break-before: always; clear: both;">&nbsp;</div>
<p>&nbsp;</p>
<p class="" style="line-height: 1.3;">&nbsp;</p>
<p><img src="https://heffl.s3.me-central-1.amazonaws.com/files/nM6NcelZv-R3LaTuGMMSe.png" alt="" width="100" height="100"></p>
<div style="height: 1.1px; background: #9e9e9e; margin-top: 3px;">&nbsp;</div>
<p class="" style="line-height: 1.3;">&nbsp;</p>
<p class="" style="line-height: 1.3;"><span style="color: #25a2cd; font-size: 23px; font-family: arial, helvetica, sans-serif;"><strong>PROPOSAL</strong></span></p>
<table style="border-collapse: collapse; width: 100%; margin-top: 8px; height: 92px; border-spacing: 0px;" border="0"><colgroup><col style="width: 16.5753%;"><col style="width: 83.4247%;"></colgroup>
<tbody>
<tr>
<td style="line-height: 1; padding: 3px;"><span style="color: #000000; font-size: 12px; font-family: arial, helvetica, sans-serif;"><strong>Date</strong></span></td>
<td style="line-height: 1; padding: 3px;"><span style="color: #000000; font-size: 12px; font-family: arial, helvetica, sans-serif;">27/07/2024</span></td>
</tr>
<tr>
<td style="line-height: 1; padding: 3px;"><span style="color: #000000; font-size: 12px; font-family: arial, helvetica, sans-serif;"><strong>Valid Till</strong></span></td>
<td style="line-height: 1; padding: 3px;"><span style="color: #000000; font-size: 12px; font-family: arial, helvetica, sans-serif;">26/08/2024</span></td>
</tr>
<tr style="height: 23px;">
<td style="line-height: 1; padding: 3px;"><span style="color: #000000; font-size: 12px; font-family: arial, helvetica, sans-serif;"><strong>Ref Number</strong></span></td>
<td style="line-height: 1; padding: 3px;"><span style="color: #000000; font-size: 12px; font-family: arial, helvetica, sans-serif;">26</span></td>
</tr>
<tr style="height: 23px;">
<td style="line-height: 1; padding: 3px;"><span style="color: #000000; font-size: 12px; font-family: arial, helvetica, sans-serif;"><strong>Contact</strong></span></td>
<td style="line-height: 1; padding: 3px;"><span style="font-family: arial, helvetica, sans-serif; font-size: 12px;">assad shafi</span></td>
</tr>
<tr>
<td style="line-height: 1; padding: 3px;"><span style="color: #000000; font-size: 12px; font-family: arial, helvetica, sans-serif;"><strong>Building Name</strong></span></td>
<td style="line-height: 1; padding: 3px;"><span style="color: #000000; font-size: 12px; font-family: arial, helvetica, sans-serif;">&nbsp;</span></td>
</tr>
<tr style="height: 23px;">
<td style="line-height: 1; padding: 3px;"><span style="color: #000000; font-size: 12px; font-family: arial, helvetica, sans-serif;"><strong>Sales person</strong></span></td>
<td style="line-height: 1; padding: 3px;"><span style="color: #000000; font-size: 12px; font-family: arial, helvetica, sans-serif;">Nasim Muahmmed</span></td>
</tr>
<tr style="height: 23px;">
<td style="line-height: 1; padding: 3px;"><span style="color: #000000; font-size: 12px; font-family: arial, helvetica, sans-serif;"><strong>Amount</strong></span></td>
<td style="line-height: 1; padding: 3px;"><span style="color: #000000; font-size: 12px; font-family: arial, helvetica, sans-serif;">AED 775</span></td>
</tr>
</tbody>
</table>
<p style="line-height: 1.3;">&nbsp;</p>
<p style="line-height: 0.75;"><span style="font-size: 12px; color: #000000; font-family: arial, helvetica, sans-serif;"><strong>Dear Sir / Madam,&nbsp;</strong></span></p>
<p style="line-height: 0.75;">&nbsp;</p>
<p style="line-height: 0.75;"><span style="font-size: 12px; color: #000000; font-family: arial, helvetica, sans-serif;">As per your request, we are pleased to enclose here below our quotation.</span></p>
<p style="line-height: 0.75;">&nbsp;</p>
<p style="line-height: 0.75;"><span style="font-size: 12px; color: #000000; font-family: arial, helvetica, sans-serif;">Pluspoint Cleaning Services is an Dubai Municipality approved and certified company to clean any type or size of water tank system.</span></p>
<p style="line-height: 0.75;">&nbsp;</p>
<p style="line-height: 0.75;"><span style="font-size: 12px; color: #000000; font-family: arial, helvetica, sans-serif;">Our water systems cleaning services are carried out in full conformance withDubai Municipality guidelines.</span></p>
<p style="line-height: 0.75;">&nbsp;</p>
<p style="line-height: 0.75;"><span style="font-size: 12px; color: #000000; font-family: arial, helvetica, sans-serif;">Our service will do by all DM rules and regulations with industry standard: </span></p>
<p style="line-height: 1.3;">&nbsp;</p>
<ul>
<li style="line-height: 1.3; font-family: arial, helvetica, sans-serif;"><span style="font-size: 12px; color: #000000; font-family: arial, helvetica, sans-serif;">Dubai Municipality Approved.</span></li>
<li style="line-height: 1.3; font-family: arial, helvetica, sans-serif;"><span style="font-size: 12px; color: #000000; font-family: arial, helvetica, sans-serif;">Technicians are Trained Professionals – (For Water Tank Cleaning &amp; Disinfecting).</span></li>
<li style="line-height: 1.3; font-family: arial, helvetica, sans-serif;"><span style="font-size: 12px; color: #000000; font-family: arial, helvetica, sans-serif;">Healthy Standard of Work Quality.</span></li>
<li style="line-height: 1.3; font-family: arial, helvetica, sans-serif;"><span style="font-size: 12px; color: #000000; font-family: arial, helvetica, sans-serif;">Managing safe work – Confined space entry/safety trained staff.</span></li>
<li style="line-height: 1.3; font-family: arial, helvetica, sans-serif;"><span style="font-size: 12px; color: #000000; font-family: arial, helvetica, sans-serif;">Managing Health and Safety IOSH.</span></li>
<li style="line-height: 1.3; font-family: arial, helvetica, sans-serif;"><span style="font-size: 12px; font-family: arial, helvetica, sans-serif;">DM approved chemical for clean &amp; disinfect (HygieneX)</span></li>
<li style="line-height: 1.3; font-family: arial, helvetica, sans-serif;"><span style="font-size: 12px; font-family: arial, helvetica, sans-serif;">Supervisor monitor on site all time.</span></li>
<li style="line-height: 1.3; font-family: arial, helvetica, sans-serif;"><span style="font-size: 12px; font-family: arial, helvetica, sans-serif;">24/7 service available</span></li>
</ul>
<p style="line-height: 1.3;">&nbsp;</p>
<p style="line-height: 1;"><span style="font-size: 12px; color: #000000; font-family: arial, helvetica, sans-serif;">After completion of service, We will submit you detailed service reports, Before &amp; After Pictures of tanks and Job completion report from the site.</span></p>
<p style="line-height: 1;">&nbsp;</p>
<p style="line-height: 1;"><span style="font-size: 12px; color: #000000; font-family: arial, helvetica, sans-serif;">Give us a chance to serve you, we assure you that we shall provide you with our best service at all times.</span></p>
<p style="line-height: 1;">&nbsp;</p>
<p style="line-height: 1;"><span style="font-size: 12px; color: #000000; font-family: arial, helvetica, sans-serif;">We remain at your disposal should you require any further information on our offer as we look forward to introducing this new integration and demonstrate our quality water tank cleaning services to you.</span></p>
<p style="line-height: 1.3;">&nbsp;</p>
<p style="line-height: 1.3;"><span style="font-size: 12px; color: #000000; font-family: arial, helvetica, sans-serif;">Regards,</span></p>
<p style="line-height: 1.3;"><span style="color: #000000; font-size: 12px; font-family: arial, helvetica, sans-serif;">Nasim Muahmmed</span></p>
<p style="line-height: 1.3;"><span style="font-size: 12px; color: #000000; font-family: arial, helvetica, sans-serif;">0588863314</span></p>
<p class="" style="line-height: 1.3;">&nbsp;</p>
<div style="page-break-before: always; clear: both;">&nbsp;</div>
<p>&nbsp;</p>
<p class="" style="line-height: 1.3;">&nbsp;</p>
<p><img src="https://heffl.s3.me-central-1.amazonaws.com/files/nM6NcelZv-R3LaTuGMMSe.png" alt="" width="100" height="100"></p>
<div style="height: 1.1px; background: #9e9e9e; margin-top: 3px;">&nbsp;</div>
<p class="" style="line-height: 1.3;">&nbsp;</p>
<p class="" style="line-height: 1.3;"><span style="color: #25a2cd; font-size: 23px; font-family: arial, helvetica, sans-serif;"><strong>PRICING</strong></span></p>
<p class="" style="line-height: 1.3;"><span style="color: #25a2cd; font-size: 18px; font-family: arial, helvetica, sans-serif;"></span></p><div style="font-size:13px !important; font-family:Inter !important;">
  <table class="line-items" style="width:100%;margin-top:6px;">
     <thead>
        <tr>
        <th style="text-align:left;width:auto;">No</th><th style="text-align:left;width:50%;">Item</th><th style="text-align:right;width:auto;">Price</th><th style=";width:auto;">Type</th><th style="text-align:right;width:auto;">Quantity</th><th style="text-align:right;width:auto;">VAT</th><th style="text-align:right;width:auto;">Total</th>
        </tr>
     </thead>
     <tbody>
        
            <tr style="border-bottom:1px solid #D1D5DB;">
           <td style="">1</td><td style="text-align:left"><span style="font-weight:600;false">
               Deep Cleaning
               </span>
               <br>
               <span style="color:#3d3d3d;">
               
               </span>
             </td><td style="text-align:right">500</td><td style="text-align:center;">Onetime</td><td style="text-align:right">1</td><td style="text-align:right">55%</td><td style="text-align:right">775.00</td>
        </tr>
        
     </tbody>
  </table>
  <div style="width:100%;display:flex;justify-content:end;margin-top:15px;break-inside:avoid;">
     <table class="line-items-summary">
        <tbody><tr>
           <td style="text-align:left">Subtotal</td>
           <td style="text-align:right;">500.00
           </td>
        </tr>
        
        <tr>
           <td style="text-align:left">VAT(55%)</td>
           <td style="text-align:right">275.00
           </td>
        </tr>
        <tr style="font-weight:600;border-top:2px solid black;">
           <td style="text-align:left;" class="total-text">
              Total
           </td>
           <td style="text-align:right;">
           <span style="color:gray;">AED</span>
              775.00
           </td>
        </tr>
     </tbody></table>
  </div>
</div><p></p>
<p class="" style="line-height: 1.3;">&nbsp;</p>
<p style="line-height: 1.3;"><span style="font-size: 13px; color: #25a2cd;"><strong>Terms &amp; Conditions</strong></span></p>
<p style="line-height: 1.3;">&nbsp;</p>
<ol>
<li style="font-size: 12px; color: #000000; font-family: arial, helvetica, sans-serif;"><span style="font-size: 12px; color: #000000; font-family: arial, helvetica, sans-serif;">Payment Terms: Cash/Bank transfer / Credit card upon completion. For credit card payments an extra AED 2.50 % will be charged extra.</span></li>
<li style="font-size: 12px; color: #000000; font-family: arial, helvetica, sans-serif;"><span style="font-size: 12px; color: #000000; font-family: arial, helvetica, sans-serif;">Access pass arranged by client. (if required)</span></li>
<li style="font-size: 12px; color: #000000; font-family: arial, helvetica, sans-serif;"><span style="font-size: 12px; color: #000000; font-family: arial, helvetica, sans-serif;">Mobilisation: As promised, upon receipt of written confirmation / LPO.</span></li>
</ol>
<p style="line-height: 1.3;">&nbsp;</p>
<p style="line-height: 1.3;"><span style="font-size: 12px; color: #000000; font-family: arial, helvetica, sans-serif;">Hope the above is inline with your requirements and we await to receive your valuable order.</span></p>
<p style="line-height: 1.3;">&nbsp;</p>
<p style="line-height: 1.3;"><span style="font-size: 13px; color: #25a2cd;"><strong>General Agreement Terms</strong></span></p>
<p style="line-height: 1.3;">&nbsp;</p>
<ol>
<li style="line-height: 1.3; font-size: 12px;"><span style="font-size: 12px; color: #000000; font-family: arial, helvetica, sans-serif;">Pluspoint will guarantee, to its best knowledge, that information acquired from clients shall not be shared with any third party or entity anywhere and at any time.&nbsp;</span></li>
<li style="line-height: 1.3; font-size: 12px;"><span style="font-size: 12px; color: #000000; font-family: arial, helvetica, sans-serif;">Pluspoint will guarantee, to its best knowledge, that all services and advice offered to clients will be accurate and to the best interest of the clients.&nbsp;</span></li>
<li style="line-height: 1.3; font-size: 12px;"><span style="font-size: 12px; color: #000000; font-family: arial, helvetica, sans-serif;">Working Hours: 6-8 Hours Straight Shift (Adjustable)&nbsp;</span></li>
<li style="line-height: 1.3; font-size: 12px;"><span style="font-size: 12px; color: #000000; font-family: arial, helvetica, sans-serif;">Insurance: In order to protect our clients from likely damages and losses while we do our service we have taken Public Liability Insurance for AED. One Million. In addition, our workforce is covered underWorkmen's Compensation Policy and all our vehicles are insured comprehensively.&nbsp;</span></li>
<li style="line-height: 1.3; font-size: 12px;"><span style="font-size: 12px; color: #000000; font-family: arial, helvetica, sans-serif;">Safety Policy: We provide our workforce with proper uniforms, hand gloves, face masks and other safety equipment to be used while on duty. We also take special care to seclude the area of our work by proper partition and provide signboards in the area of work. We clean the area completely after completion of the work and never leave tools, waste, etc. at the worksite.&nbsp;</span></li>
<li style="line-height: 1.3; font-size: 12px;"><span style="font-size: 12px; color: #000000; font-family: arial, helvetica, sans-serif;">Water and power required for cleaning are to be provided by you.&nbsp;</span></li>
<li style="line-height: 1.3; font-size: 12px;"><span style="font-size: 12px; color: #000000; font-family: arial, helvetica, sans-serif;">Access to the site for our vehicle personnel and equipment to be facilitated by you.&nbsp;</span></li>
<li style="line-height: 1.3; font-size: 12px;"><span style="font-size: 12px; color: #000000; font-family: arial, helvetica, sans-serif;">Work permit/gate pass, if required, to be provided by you.&nbsp;</span></li>
<li style="line-height: 1.3; font-size: 12px;"><span style="font-size: 12px; color: #000000; font-family: arial, helvetica, sans-serif;">Maintenance staff from your side to be appointed to monitor the work and for assistance&nbsp;</span></li>
<li style="line-height: 1.3; font-size: 12px;"><span style="font-size: 12px; color: #000000; font-family: arial, helvetica, sans-serif;">Parking space for our service vehicle to be arranged by you</span></li>
</ol>
<p style="line-height: 1.3;">&nbsp;</p>
<div style="page-break-before: always; clear: both;">&nbsp;</div>
<p>&nbsp;</p>
<p style="line-height: 1.3;">&nbsp;</p>
<p><img src="https://heffl.s3.me-central-1.amazonaws.com/files/nM6NcelZv-R3LaTuGMMSe.png" alt="" width="100" height="100"></p>
<div style="height: 1.1px; background: #9e9e9e; margin-top: 3px;">&nbsp;</div>
<p style="line-height: 1.3;">&nbsp;</p>
<p style="line-height: 1.3;"><span style="font-size: 13px; color: #25a2cd; font-family: arial, helvetica, sans-serif;"><strong>SCOPE OF WORK</strong></span></p>
<p style="line-height: 1.3;"><span style="font-size: 12px; color: #000000; font-family: arial, helvetica, sans-serif;"><span style="font-size: 13px;"><strong>Tank Inspection</strong></span> </span></p>
<p style="line-height: 1.3;"><span style="font-size: 12px; color: #000000; font-family: arial, helvetica, sans-serif;">We will provide a preliminary inspection of the tank, structural integrity checks, and any necessary preparation. </span></p>
<p style="line-height: 1.3;">&nbsp;</p>
<p style="line-height: 1.3;"><span style="font-size: 13px; color: #000000; font-family: arial, helvetica, sans-serif;"><strong>Clean &amp; wash all internal surfaces </strong></span></p>
<p style="line-height: 1.3;"><span style="font-size: 12px; color: #000000; font-family: arial, helvetica, sans-serif;">Mix the Dubai Municipality-approved cleaning chemical and water solution to disinfect the tank’s internal surfaces. This can be done with a stiff brush or a high-pressure jet. </span></p>
<p style="line-height: 1.3;">&nbsp;</p>
<p style="line-height: 1.3;"><span style="font-size: 12px; color: #000000; font-family: arial, helvetica, sans-serif;"><span style="font-size: 13px;"><strong>Tank Disinfection</strong></span> </span></p>
<p style="line-height: 1.3;"><span style="font-size: 12px; color: #000000; font-family: arial, helvetica, sans-serif;">Clean and disinfect the internal tank surfaces by using Dubai Municipality-approved disinfectant. This will kill pathogens and other contaminants in the water. </span></p>
<p style="line-height: 1.3;">&nbsp;</p>
<p style="line-height: 1.3;"><span style="font-size: 12px; color: #000000; font-family: arial, helvetica, sans-serif;"><span style="font-size: 13px;"><strong>Removal of contaminated water</strong></span> </span></p>
<p style="line-height: 1.3;"><span style="font-size: 12px; color: #000000; font-family: arial, helvetica, sans-serif;">Removal of contaminants by using a submergible pump and remove all contaminants and continue flushing the tank until no signs of detergent are visible in the water. </span></p>
<p style="line-height: 1.3;">&nbsp;</p>
<p style="line-height: 1.3;"><span style="font-size: 12px; color: #000000; font-family: arial, helvetica, sans-serif;"><span style="font-size: 13px;"><strong>Comprehensive Report</strong></span> </span></p>
<p style="line-height: 1.3;"><span style="font-size: 12px; color: #000000; font-family: arial, helvetica, sans-serif;">A detailed report of the services including before/after pictures of the clean tank shared with the customer. </span></p>
<p style="line-height: 1.3;">&nbsp;</p>
<p style="line-height: 1.3;"><span style="font-size: 12px; color: #000000; font-family: arial, helvetica, sans-serif;"><span style="font-size: 13px;"><strong>Water Tank Sample Testing </strong></span></span></p>
<p style="line-height: 1.3;"><span style="font-size: 12px; color: #000000; font-family: arial, helvetica, sans-serif;">&nbsp;An accredited Laboratory examination shall be carried out on one sample collection from tank's water and the test report shall be<br>submitted. (there will be additional charges for the water test)</span></p>
<p style="line-height: 1.3;">&nbsp;</p>
<p style="text-align: center; line-height: 1.3;"><span style="color: #25a2cd; font-family: arial, helvetica, sans-serif;"><strong><span style="font-size: 13px;">CLEAN AND DISINFECT YOUR WATER STORAGE TANKS EVERY SIX MONTHS </span></strong></span></p>
<p style="text-align: center; line-height: 1.3;"><span style="color: #25a2cd; font-family: arial, helvetica, sans-serif;"><strong><span style="font-size: 13px;">FOR A BETTER RESULT OF HEALTHY WATER.</span></strong></span></p>
<p style="text-align: center; line-height: 1.3;">&nbsp;</p>
<div style="page-break-before: always; clear: both;">&nbsp;</div>
<p>&nbsp;</p>
<p style="text-align: left; line-height: 1.3;"><img src="https://heffl.s3.me-central-1.amazonaws.com/files/uvjSTltrr_o4_-4VMrVzD.png" alt="" width="100%"></p>
<p style="text-align: left; line-height: 1.3;">&nbsp;</p>
<div style="page-break-before: always; clear: both;">&nbsp;</div>
<p>&nbsp;</p>
<p style="text-align: left; line-height: 1.3;"><img src="https://heffl.s3.me-central-1.amazonaws.com/files/-GrePikMNavJFj0rXwpQ7.jpeg" alt="" width="1000" height="1415"></p></div></td></tr></tbody><tfoot><tr><td><div style="height: 67.1953px;"></div></td></tr></tfoot></table></div>`;
const FileForgeTest: React.FC = () => {
  const getStyle = () => {
    return `
    body {
      background: red;
    }
    .pagedjs_page {
      background: white;
      box-shadow: 10px 12px 30px 0 rgba(0, 0, 0, 0.1);
    }
    @page {
      size: A4;
      @bottom-right {
        content: counter(page);
      }
    }
    .pagedjs_pagebox {
      position: relative;
    }
    .pagedjs_pagebox::after {
      content: counter(page);
      position: absolute;
      bottom: 10px;
      right: 10px;
    }

    `;
  };

  return (
    <div>
      <div id="pagedjsdocroot" style={{ display: "none" }}>
        <style>{getStyle()}</style>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
      <div id="preview"></div>
      <BookViewer></BookViewer>
    </div>
  );
};

export default FileForgeTest;
