import { z } from "zod";
import { templateSettingsSchema } from "../helpers/templates/templateSettingsManifest";
import enums from "./enums";
import parsePhone from "../helpers/utils/parsePhone";
import * as files from "./files";

export const contact = z.object({
  firstName: z.string(),
  lastName: z.string().nullish(),
  salutation: z.string().nullish(),
  mobile: z.string().transform(parsePhone).nullish(),
  email: z.union([z.literal(""), z.string().email()]).nullish(),
  jobTitle: z.string().nullish(),
  customFields: z.any(),
  clientId: z.number().nullish(),
  vendorId: z.number().nullish(),
  propertyId: z.number().nullish(),
});

export const contactTask = z.object({
  title: z.string(),
  description: z.string().optional(),
  date: z.date().optional(),
  taskAssignees: z.array(z.coerce.number()),
  dealId: z.number().nullish(),
  contactId: z.number().nullish(),
});

export const previewQuote = z.object({
  toBusinessName: z.string().min(1),
  toAddressLineOne: z.string().nullish(),
  toAddressLineTwo: z.string().nullish(),
  toWebsite: z.string().nullish(),
  quoteNumber: z.coerce.number(),
});

export type TPreviewQuote = z.infer<typeof previewQuote>;

export const product = z.object({
  name: z.string(),
  type: z.enum(["PRODUCT", "SERVICE"]),
  price: z.coerce.number(),
  sku: z.string().nullish(),
  description: z.string().nullish(),
  buyPrice: z.coerce.number().nullish(),
});

export const notes = z.object({
  note: z.string(),
  dealId: z.number().nullish(),
  contactId: z.number().nullish(),
  leadId: z.number().nullish(),
  clientId: z.number().nullish(),
});

export const lostReason = z.object({
  reason: z.string(),
  type: z.enum(["LEAD", "DEAL", "QUOTATION"]),
});

export const deal = z.object({
  ownerUserId: z.coerce.number().nullish().optional(),
  dealContacts: z.coerce.number().array().optional(),
  dealStageId: z.coerce.number().min(1, "Deal stage is required"),
  title: z.string().min(1, "Please add a title to deal"),
  closingDate: z.date().nullish(),
  clientId: z.coerce.number({
    required_error: "Client is required",
    invalid_type_error: "Client is required",
  }),
  priority: z.enum(["LOW", "MEDIUM", "HIGH"]).default("MEDIUM"),
  currencyId: z.coerce.number().min(1),
  price: z.coerce.number().nullish(),
  status: z.enum(["WON", "LOST", "ACTIVE"]).default("ACTIVE"),
  dealProducts: z
    .object({
      productId: z.coerce.number(),
      quantity: z.coerce.number(),
      price: z.coerce.number(),
      description: z.string().nullish(),
    })
    .array()
    .nullish(),
  dealAssignees: z.coerce.number().array().nullish(),
  customFields: z.any().nullish(),
  lostReasonId: z.number().nullish(),
  lostReasonDescription: z.string().nullish(),
});

export const dealPiplineStage = z.object({
  name: z.string(),
  position: z.coerce.number(),
  probability: z.coerce.number().default(0),
  rottingInDays: z.coerce.number().default(0),
  stageType: enums.dealStageType.default("ACTIVE"),
});

export const dealPipeline = z.object({
  name: z.string(),
  dealPipelineStages: z.array(dealPiplineStage).refine(
    (stages) => {
      const hasActive = stages.some((stage) => stage.stageType === "ACTIVE");
      const hasWon = stages.some((stage) => stage.stageType === "WON");
      const hasLost = stages.some((stage) => stage.stageType === "LOST");
      return hasActive && hasWon && hasLost;
    },
    {
      message:
        "Pipeline must have at least one ACTIVE, one WON, and one LOST stage",
    }
  ),
});

export const tasksStatusSchema = z.enum([
  "OPEN",
  "IN_PROGRESS",
  "COMPLETED",
  "ON_HOLD",
]);

export const selectableDatePicker = z.object({
  date: z.date(),
  dateType: z.string(),
});

export const reminder = z
  .object({
    taskId: z.number().nullish(),
    activityId: z.number().nullish(),
    projectTaskId: z.number().nullish(),
  })
  .merge(selectableDatePicker);

export const task = z.object({
  title: z.string().min(1, "Please add a title to task"),
  description: z.string().nullish(),
  date: z.date(),
  status: tasksStatusSchema.default("OPEN"),
  dealId: z.number().nullish(),
  contactId: z.number().nullish(),
  clientId: z.number().nullish(),
  leadId: z.number().nullish(),
  taskAssignees: z.coerce.number().array().nullish(),
  reminders: reminder.nullish(),
});

export const activity = z.object({
  type: enums.activityTypes,
  priority: z.enum(["LOW", "MEDIUM", "HIGH"]).nullish(),
  title: z.string(),
  description: z.string().nullish(),
  activityAssignees: z.coerce.number().array().nullish(),
  activityAttendees: z.coerce.number().array().nullish(),
  startDate: z.date(),
  endDate: selectableDatePicker,
  completed: z.boolean().default(false),
  completedOn: z.date().nullish(),
  dealId: z.number().nullish(),
  leadId: z.number().nullish(),
  clientId: z.number().nullish(),
  contactId: z.number().nullish(),
  isLogged: z.boolean().default(false),
  reminders: reminder.nullish(),
  locationLink: z.string().nullish(),
});

export const address = z.object({
  type: z.enum(["BILLING", "NORMAL"]),
  city: z.string().nullish(),
  address: z.string().nullish(),
  latitude: z.number().nullish(),
  longitude: z.number().nullish(),
  landmark: z.string().nullish(),
  stateId: z.number().nullish(),
  countryId: z.number().nullish(),
  googleMapsPlaceId: z.string().nullish(),
  googleMapsLink: z.string().nullish(),
});

export const comment = z.object({
  type: enums.commentType,
  comment: z.string(),
  leadId: z.number().nullish(),
  dealId: z.number().nullish(),
  fsScheduleId: z.number().nullish(),
  files: files.filesInput.nullish(),
  activityId: z.number().nullish(),
});

export const client = z.object({
  type: z.enum(["BUSINESS", "INDIVIDUAL"]),
  name: z.string().min(1, "Please add a name to client"),
  website: z.string().nullish(),
  taxNumber: z
    .string()
    .min(15, "Invalid tax number")
    .max(15, "Invalid tax number")
    .nullish()
    .or(z.literal("")),
  billingAddress: address.nullish(),
  crmSourceId: z.number().nullish(),
  clientTags: z.coerce.number().array().default([]),
  primaryContact: contact
    .partial({
      firstName: true,
    })
    .omit({ clientId: true, vendorId: true })
    .nullish(),
  customFields: z.any().nullish(),
  openingBalance: z.coerce.number().default(0),
});

export const lead = z.object({
  name: z.string(),
  mobile: z.string().transform(parsePhone).nullish(),
  secondaryMobile: z.string().transform(parsePhone).nullish(),
  email: z.union([z.literal(""), z.string().email()]).nullish(),
  title: z.string().min(1),
  crmSourceId: z.number().nullish(),
  value: z.coerce.number().nullish(),
  // aed default currency
  currencyId: z.number().default(1),
  expectedCloseDate: z.date().nullish(),
  leadSource: z.number().nullish(),
  qualityScore: z.number().nullish(),
  stage: enums.leadStageTypes,
  ownerUserId: z.number(),
  archived: z.boolean().default(false),
  leadAssignees: z.coerce.number().array().nullish(),
  customFields: z.any().nullish(),
  lostReasonId: z.number().nullish(),
  lostReasonDescription: z.string().nullish(),
});

export const template = z.object({
  name: z.string().min(1, "Please add a name to template"),
  type: enums.templateTypes,
  isActive: z.boolean().default(true),
  contentHtml: z.string(),
  headerHtml: z.string().nullish(),
  footerHtml: z.string().nullish(),
  templateProperties: templateSettingsSchema.partial().optional(),
});

export const salesGoal = z.object({
  type: z.enum(["FIELD_SERVICE_JOB", "CRM_DEAL"]),
  measure: z.string(),
  frequency: z.enum(["WEEKLY", "MONTHLY"]),
  trackingMetric: z.enum(["COUNT", "VALUE"]).default("VALUE"),
  startDate: z.date(),
  endDate: z.date().nullish(),
  goal: z.coerce.number(),
  userId: z.number().nullish(),
  targetFor: enums.salesGoalTargetFor.default("USER"),
});
