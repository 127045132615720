export const getServerUrl = () => {
  const environment = process.env.NODE_ENV;
  if (environment === "development") {
    return "http://localhost:3000";
  }
  return "https://api.heffl.com";
};

export const getClientUrl = () => {
  const environment = process.env.NODE_ENV;
  if (environment === "development") {
    return "http://localhost:5002";
  }
  return "https://app.heffl.com";
};
