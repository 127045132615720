import { Check, Copy } from "lucide-react";
import { useState } from "react";
import toast from "react-hot-toast";

export const copyToClipboard = async (text: string, showToast = true) => {
  try {
    await navigator.clipboard.writeText(text);
    if (showToast) {
      toast.success("Copied to clipboard!");
    }
  } catch (error) {
    const textField = document.createElement("textarea");
    textField.value = text;
    textField.style.position = "fixed"; // Prevent scrolling to bottom of page in MS Edge.
    document.body.appendChild(textField);
    textField.focus();
    textField.select();
    const successful = document.execCommand("copy");
    document.body.removeChild(textField);

    if (successful) {
      if (showToast) {
        toast.success("Copied to clipboard!");
      }
    } else {
      toast.error("Failed to copy to clipboard.");
    }
  }
};

interface Props {
  copyText: string;
  className?: string;
}

const CopyToClipboard = ({ copyText, className }: Props) => {
  const [isCopied, setIsCopied] = useState(false);

  const doCopy = (e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      copyToClipboard(copyText);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 1500);
    } catch (error) {
      toast.error("Something went wrong, couldnt copy!");
    }
  };

  return (
    <span className="w-5">
      {isCopied ? (
        <Check
          className={`h-4 cursor-pointer text-primary ${className}`}
          style={{
            animation:
              "rotate-in-2-cw 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both",
          }}
        />
      ) : (
        <Copy className="w-4 cursor-pointer text-primary" onClick={doCopy} />
      )}
    </span>
  );
};

export default CopyToClipboard;
