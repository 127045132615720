/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from "@heffl/ui/components/primitives/button";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FormList from "@heffl/ui/components/primitives/form-list";
import { Input } from "@heffl/ui/components/primitives/input";
import { trpc } from "@/helpers/trpc";
import {
  DndContext,
  MouseSensor,
  TouchSensor,
  rectIntersection,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";
import { zodResolver } from "@hookform/resolvers/zod";
import { PlusCircle } from "lucide-react";
import { nanoid } from "nanoid";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { DraggableBoard, pipelineSchema } from "./edit";
import Page from "@/components/page";
import { Card } from "@heffl/ui/components/primitives/card";
import { useRef } from "react";

const PipelineAdd = () => {
  const navigate = useNavigate();
  const boardContainerRef = useRef<HTMLDivElement>(null);
  const form = useForm<z.infer<typeof pipelineSchema>>({
    resolver: zodResolver(pipelineSchema),
    defaultValues: {
      dealPipelineStages: [
        {
          id: nanoid(),
          name: "Qualified",
          position: 1,
          probability: 10,
          rottingInDays: 10,
          stageType: "ACTIVE",
        },
        {
          id: nanoid(),
          name: "Quotation prepared",
          stageType: "ACTIVE",
          position: 1,
          probability: 40,
          rottingInDays: 10,
        },
        {
          id: nanoid(),
          name: "Negotiations started",
          stageType: "ACTIVE",
          position: 3,
          probability: 60,
          rottingInDays: 10,
        },
      ],
    },
  });
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });
  const touchSensor = useSensor(TouchSensor);
  const sensors = useSensors(mouseSensor, touchSensor);
  const dealStagesNew = form.watch("dealPipelineStages") || [];

  const pipelineAddMutation = trpc.deals.pipelines.add.useMutation({
    onSuccess: () => {
      toast.success("Pipeline added successfully");
      navigate(`/crm/deals/board`);
    },
    onError() {
      toast.error(
        "Pipeline must have atleas one active, one won, and one lost stage"
      );
    },
  });

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    if (!over) return;
    if (String(active.id) !== String(over.id)) {
      const oldPipelines = form.getValues().dealPipelineStages;
      if (!oldPipelines) return;
      const oldIndex = oldPipelines.findIndex(
        (item) => String(item.id) === String(active.id)
      );
      const newIndex = oldPipelines.findIndex(
        (item) => String(item.id) === String(over.id)
      );
      const updatedPipelins = arrayMove(oldPipelines, oldIndex, newIndex);
      form.setValue("dealPipelineStages", updatedPipelins);
    }
  };

  return (
    <Page
      title="Add pipeline"
      showBack
      breadcrumbs={[
        {
          label: "Deals",
          path: "/crm/deals",
        },
        {
          label: "Add pipeline",
          path: "/crm/deals/pipelines/add",
        },
      ]}
      fullWidth
    >
      <Form
        {...form}
        onSubmit={(values) => {
          pipelineAddMutation.mutate({
            ...values,
            dealPipelineStages: values.dealPipelineStages.map(
              (stage, index) => ({
                ...stage,
                position: index + 1,
                id: isNaN(Number(stage.id)) ? 0 : Number(stage.id),
              })
            ),
          });
        }}
      >
        <div className="w-full">
          <div className="flex flex-row gap-3 justify-between">
            <div className="flex flex-row gap-2 mt-1">
              <FormField name="name" label="Pipeline name">
                <Input placeholder="Pipeline name" />
              </FormField>
            </div>
            <div className="flex flex-row gap-3">
              <Button
                variant={"outline"}
                onClick={() => navigate(`/crm/deals/pipelines/list/`)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="primary"
                loading={pipelineAddMutation.isLoading}
              >
                Add pipeline
              </Button>
            </div>
          </div>
          {/* Header actions ends here */}
          <div className="flex gap-2">
            <Card
              parentClassName="h-[365px] rounded-md w-[250px] mt-7 text-center"
              className="flex flex-col gap-2 justify-center items-center h-full"
            >
              <p className="text-xl font-medium">Add new stage</p>
              <p className="font-light tet-gray-600">
                Add stages and arrange them in the order you want.
              </p>
              <Button
                className="mt-4"
                size="md"
                onClick={() => {
                  const currentStages = form.getValues("dealPipelineStages");
                  form.setValue("dealPipelineStages", [
                    ...currentStages,
                    {
                      id: nanoid(),
                      name: "New stage",
                      stageType: "ACTIVE",
                      probability: 0,
                      rottingInDays: 0,
                      position: 0,
                    },
                  ]);
                  setTimeout(() => {
                    if (boardContainerRef.current) {
                      boardContainerRef.current.scrollTo({
                        left: boardContainerRef.current.scrollWidth,
                        behavior: "smooth",
                      });
                    }
                  }, 100);
                }}
                variant="outline"
              >
                <PlusCircle className="h-5" /> Add new stage
              </Button>
            </Card>
            <DndContext
              sensors={sensors}
              onDragEnd={handleDragEnd}
              collisionDetection={rectIntersection}
            >
              <SortableContext
                items={dealStagesNew.map((s) => String(s.id))}
                strategy={horizontalListSortingStrategy}
              >
                <FormList name="dealPipelineStages">
                  {({ fields, _name, remove }) => {
                    return (
                      <div
                        ref={boardContainerRef}
                        className="flex overflow-x-auto flex-row gap-4 p-1 py-3 mt-4 w-full rounded-lg"
                      >
                        {fields.map((field, index) => (
                          <DraggableBoard
                            key={field.id}
                            index={index}
                            _name={_name}
                            form={form}
                            remove={remove}
                          />
                        ))}
                      </div>
                    );
                  }}
                </FormList>
              </SortableContext>
            </DndContext>
          </div>
        </div>
      </Form>
    </Page>
  );
};

export default PipelineAdd;
