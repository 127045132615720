import Page from "@/components/page";
import { RouterOutputs, trpc } from "@/helpers/trpc";
import Schemas from "@heffl/server/src/schemas";
import { Button } from "@heffl/ui/components/primitives/button";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import DropMenu from "@/components/DropMenu";
import { AvatarsGroup } from "@/components/UserAvatar";
import { calculateCompleted } from "@heffl/ui/lib/utils";
import { InfoItem } from "@/pages/crm/deals/details";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Form } from "@heffl/ui/components/primitives/form";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@heffl/ui/components/primitives/grid-table";
import { Input } from "@heffl/ui/components/primitives/input";
import { ScrollArea } from "@heffl/ui/components/primitives/scroll-area";
import { Tooltip } from "@heffl/ui/components/primitives/tooltip";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import { cn, isMobile } from "@heffl/ui/lib/utils";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@radix-ui/react-accordion";
import dayjs from "dayjs";
import {
  Archive,
  CheckCircle,
  ChevronDown,
  ChevronUp,
  MoreHorizontal,
  Pencil,
  Plus,
  Trash,
} from "lucide-react";
import { ReactNode, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { match } from "ts-pattern";
import { ProjectTemplateForm } from ".";
import {
  AddTemplateTaskModal,
  EditTemplateTaskModal,
} from "./TemplateTaskModals";

type TaskRowProps = {
  task: NonNullable<
    RouterOutputs["projects"]["templates"]["details"]
  >["projectTemplateTasks"][number];
  onTaskClick: (id: number) => void;
  up?: {
    id: number;
    position: number;
  };
  down?: {
    id: number;
    position: number;
  };
};
type SubTaskRowProps = {
  task: NonNullable<
    NonNullable<
      RouterOutputs["projects"]["templates"]["details"]
    >["projectTemplateTasks"][number]["projectTemplateSubTasks"][number]
  >;
  isLast: boolean;
  onTaskClick: (id: number) => void;
};

const TaskRow = ({ task, onTaskClick, up, down }: TaskRowProps) => {
  const confirm = useConfirm();

  const [showSubTasks, setShowSubTasks] = useState(false);

  const taskDeleteMutation = trpc.projects.templates.tasks.delete.useMutation({
    onSuccess() {
      toast.success("Task deleted successfully");
    },
  });

  const taskBulkUpdateMutation =
    trpc.projects.templates.tasks.bulkUpdate.useMutation();

  return (
    <>
      <TableRow
        key={task.id}
        onClick={() => onTaskClick(task.id)}
        className="cursor-pointer"
      >
        <TableCell>
          <div className="flex gap-2">
            <Button
              onClick={(e) => {
                e.stopPropagation();
                if (up) {
                  taskBulkUpdateMutation.mutate([
                    { id: up.id, position: task.position },
                    { id: task.id, position: up.position },
                  ]);
                }
              }}
              icon={ChevronUp}
              size="sm"
              className="px-0"
            />
            <Button
              onClick={(e) => {
                e.stopPropagation();
                if (down) {
                  taskBulkUpdateMutation.mutate([
                    { id: down.id, position: task.position },
                    { id: task.id, position: down.position },
                  ]);
                }
              }}
              icon={ChevronDown}
              size="sm"
              className="px-0"
            />
          </div>
        </TableCell>
        <TableCell>
          <div className="flex gap-2 items-center">
            <p>{task.title}</p>
            {!!task.projectTemplateSubTasks.length && (
              <Tooltip content="Show subtasks">
                <div
                  onClick={(e) => {
                    setShowSubTasks(!showSubTasks);
                    e.stopPropagation();
                  }}
                  className="flex items-center gap-0.5 hover:bg-gray-200 p-1 rounded-md"
                >
                  <span className="flex items-center px-1.5 text-xs font-medium bg-gray-200 rounded-md">
                    {`${calculateCompleted(
                      task.projectTemplateSubTasks,
                      "status",
                      "COMPLETED"
                    )}/${task.projectTemplateSubTasks.length}`}
                  </span>
                  <ChevronDown className="w-4 h-4" />
                </div>
              </Tooltip>
            )}
          </div>
        </TableCell>
        <TableCell>
          <AvatarsGroup
            users={task.projectTemplateTaskAssignees.map((user) => user.users)}
          />
        </TableCell>
        <TableCell>{task.dueDateDays}</TableCell>
        <TableCell>
          <DropMenu
            items={[
              {
                label: "Edit",
                onClick: () => onTaskClick(task.id),
              },
              {
                label: "Delete",
                onClick: async () => {
                  const confirmed = await confirm({
                    title: "Are you sure to delete this task?",
                    body: `This will delete the task "${task.title}" and its subtasks from the project.`,
                    actionButton: "Delete",
                  });
                  if (confirmed) {
                    taskDeleteMutation.mutate(task.id);
                  }
                },
                className: "text-red-600",
              },
            ]}
          >
            <Button variant="ghost" size="icon">
              <MoreHorizontal className="h-5" />
            </Button>
          </DropMenu>
        </TableCell>
      </TableRow>
      {showSubTasks && (
        <>
          {task.projectTemplateSubTasks.map((subTask, i) => (
            <SubTaskRow
              key={subTask.id}
              task={subTask}
              onTaskClick={(id) => onTaskClick(id)}
              isLast={i === task.projectTemplateSubTasks.length - 1}
            />
          ))}
        </>
      )}
    </>
  );
};
const SubTaskRow = ({ task, onTaskClick, isLast }: SubTaskRowProps) => {
  return (
    <TableRow
      key={task.id}
      onClick={() => onTaskClick(task.parentProjectTemplateTaskId)}
      className="cursor-pointer"
    >
      <TableCell className="p-0">
        <div className="grid !w-full h-full grid-cols-2">
          <div></div>
          <div className="rounded-bl border-b border-l border-gray-300" />
          <div />
          <div
            className={cn("border-l border-gray-300", isLast && "border-none")}
          />
        </div>
      </TableCell>
      <TableCell>{task.title}</TableCell>
    </TableRow>
  );
};

type TemplateProjectStageProps = {
  id: number | null;
  title: string;
  icon?: ReactNode;
  onTaskClick: (id: number) => void;
  onTaskAddClick: () => void;
  createdAt?: Date;
  projectTemplateId: number;
};

const ProjectTemplateSection = ({
  title,
  id,
  icon,
  onTaskClick,
  onTaskAddClick,
  createdAt,
  projectTemplateId,
}: TemplateProjectStageProps) => {
  const { data: timeline } = trpc.projects.templates.timeline.useQuery({
    projectTemplateId,
    projectTemplateSectionId: id,
  });

  const [editTitle, setEditTitle] = useState(
    title === "New section" &&
      createdAt &&
      dayjs().diff(createdAt, "second") <= 10
      ? true
      : false
  );
  const [updatedTitle, setUpdatedTitle] = useState(title);

  const updateSectionMutation =
    trpc.projects.templates.sections.update.useMutation();
  const deleteSectionMutation =
    trpc.projects.templates.sections.delete.useMutation();

  if (!timeline) return <FullScreenSpinner />;

  return (
    <div className="pt-0">
      <Accordion type="single" collapsible defaultValue="stage-unassigned">
        <AccordionItem value="stage-unassigned">
          <div className="flex flex-col">
            <div className="flex gap-2 items-center">
              <AccordionTrigger />
              <div className="flex flex-row justify-between items-center w-full">
                <div className="flex flex-row gap-2 items-center group">
                  {icon && icon}
                  {editTitle ? (
                    <Input
                      autoFocus
                      value={updatedTitle}
                      onChange={(e) => setUpdatedTitle(e.target.value)}
                      onBlur={() => {
                        if (id) {
                          setEditTitle(false);
                          updateSectionMutation.mutate({
                            id,
                            section: { title: updatedTitle },
                          });
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.currentTarget.blur();
                        }
                      }}
                    />
                  ) : (
                    <p
                      className="text-base font-semibold"
                      onClick={() => id && setEditTitle(true)}
                    >
                      {title}
                    </p>
                  )}
                  <div
                    className={cn(
                      "flex invisible gap-1 items-center group-hover:visible"
                    )}
                  >
                    <Button
                      icon={Plus}
                      variant="ghost"
                      iconClassName="w-4 h-4"
                      onClick={onTaskAddClick}
                    />
                    {id && (
                      <DropMenu
                        items={[
                          {
                            label: "Rename section",
                            onClick: () => setEditTitle(true),
                            icon: Pencil,
                          },
                          {
                            label: "Delete section",
                            onClick: () => {
                              deleteSectionMutation.mutate(id);
                            },
                            icon: Trash,
                            className: "text-red-600",
                          },
                        ]}
                      >
                        <Button variant="ghost" size="icon">
                          <MoreHorizontal className="h-5" />
                        </Button>
                      </DropMenu>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <AccordionContent>
              <Table className="mt-1">
                {!isMobile() && (
                  <TableHeader>
                    <TableRow>
                      <TableHead className="w-[40px]"></TableHead>
                      <TableHead>Subject</TableHead>
                      <TableHead className="w-[120px]">Assignee</TableHead>
                      <TableHead className="w-[120px]">Due Date</TableHead>
                      <TableHead className="w-[60px]"></TableHead>
                    </TableRow>
                  </TableHeader>
                )}
                <TableBody
                  className={
                    isMobile() ? "flex flex-col gap-2 pb-2 sm:pb-0" : ""
                  }
                >
                  {timeline.map((item, index) =>
                    match(item)
                      .with({ type: "task" }, ({ value: task }) => (
                        <TaskRow
                          up={
                            index > 0
                              ? {
                                  id: timeline[index - 1].value.id,
                                  position: timeline[index - 1].value.position,
                                }
                              : undefined
                          }
                          down={
                            index < timeline.length - 1
                              ? {
                                  id: timeline[index + 1].value.id,
                                  position: timeline[index + 1].value.position,
                                }
                              : undefined
                          }
                          task={task}
                          onTaskClick={(id) => onTaskClick(id)}
                        />
                      ))
                      .exhaustive()
                  )}
                </TableBody>
              </Table>
            </AccordionContent>
          </div>
        </AccordionItem>
      </Accordion>
    </div>
  );
};
const EditTemplateModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const params = useParams();
  const templateId = Number(params.id);

  const form = useForm<z.infer<typeof Schemas.project.projectTemplate>>({
    resolver: zodResolver(Schemas.project.projectTemplate),
  });

  const { data: templateDetails } =
    trpc.projects.templates.details.useQuery(templateId);
  const { data: projectPipelines } = trpc.projects.pipelines.list.useQuery();

  const updateTemplateMutation = trpc.projects.templates.update.useMutation({
    onSuccess() {
      onClose();
      toast.success("Template updated successfully");
    },
  });

  useEffect(() => {
    if (templateDetails) {
      form.reset({
        ...templateDetails,
      });
    }
  }, [templateDetails]);

  if (!projectPipelines) return <FullScreenSpinner />;

  return (
    <ModalDrawer title="Update template" open={open} onClose={onClose}>
      <Form
        {...form}
        onSubmit={(values) =>
          updateTemplateMutation.mutate({
            id: templateId,
            projectTemplate: values,
          })
        }
      >
        <ProjectTemplateForm edit />

        <Button
          loading={updateTemplateMutation.isLoading}
          type="submit"
          variant="primary"
          className="w-full"
        >
          Update template
        </Button>
      </Form>
    </ModalDrawer>
  );
};

const EditTemplate = () => {
  const params = useParams();
  const templateId = Number(params.id);

  const [editTempalte, setEditTempalte] = useState(false);
  const [editTaskId, setEditTaskId] = useState<number | null>(null);
  const [addTaskModal, setAddTaskModal] = useState<
    undefined | { open: boolean; sectionId: null | number }
  >();

  const { data: templateDetails } =
    trpc.projects.templates.details.useQuery(templateId);
  const addSectionMutation = trpc.projects.templates.sections.add.useMutation();

  if (!templateDetails) return <FullScreenSpinner />;
  return (
    <Page
      title="Edit project template"
      showBack
      fullWidth
      breadcrumbs={[
        {
          label: "Projects",
          path: "/projects",
        },
        {
          label: "Templates",
          path: "/projects/templates",
        },
        {
          label: templateDetails.name,
          path: `/projects/templates/edit/${templateDetails.id}`,
        },
      ]}
      className="pt-0"
    >
      <EditTemplateModal
        open={editTempalte}
        onClose={() => setEditTempalte(false)}
      />
      {editTaskId && (
        <EditTemplateTaskModal
          open={!!editTaskId}
          onClose={() => setEditTaskId(null)}
          id={editTaskId}
          projectTemplateId={templateId}
        />
      )}
      <AddTemplateTaskModal
        open={!!addTaskModal}
        onClose={() => setAddTaskModal(undefined)}
        projectTemplateId={templateId}
        defaultValues={{
          projectTemplateSectionId: addTaskModal?.sectionId,
        }}
      />

      <div className="flex h-[85vh] ">
        <div className="flex flex-col pt-3 pr-4 space-y-3 w-1/4 bg-white border-r border-gray-300">
          <div className="pr-0 text-lg font-semibold sm:pr-4">
            {templateDetails.name}
          </div>
          <Button
            onClick={() => setEditTempalte(true)}
            size="sm"
            className="self-end w-fit"
          >
            <Pencil className="h-4" />
            Edit template
          </Button>
          <InfoItem
            label="Pipeline"
            value={templateDetails.projectPipelines.name}
          />
          <InfoItem label="Description" value={templateDetails.description} />
        </div>
        <ScrollArea className="p-4 w-5/6 h-full">
          <div className="flex flex-col gap-3 mt-4">
            <div className="self-end space-x-2">
              <Button
                variant="primary"
                onClick={() => setAddTaskModal({ open: true, sectionId: null })}
              >
                <CheckCircle className="h-4" />
                Add task
              </Button>
            </div>
            <ProjectTemplateSection
              id={null}
              projectTemplateId={templateId}
              title="No section"
              icon={<Archive className="h-4" />}
              onTaskAddClick={() =>
                setAddTaskModal({ open: true, sectionId: null })
              }
              onTaskClick={(id) => setEditTaskId(id)}
            />
            {templateDetails.projectTemplateSections.map((section) => (
              <ProjectTemplateSection
                key={section.id}
                id={section.id}
                title={section.title}
                projectTemplateId={templateId}
                onTaskAddClick={() => {
                  setAddTaskModal({ open: true, sectionId: section.id });
                }}
                onTaskClick={(id) => {
                  setEditTaskId(id);
                }}
              />
            ))}
            <Button
              className="mt-4 w-fit"
              size="sm"
              variant="primaryOutline"
              icon={Plus}
              onClick={() => {
                addSectionMutation.mutate({
                  projectTemplateId: templateId,
                  title: "New section",
                });
              }}
            >
              Add section
            </Button>
          </div>
        </ScrollArea>
      </div>
    </Page>
  );
};

export default EditTemplate;
