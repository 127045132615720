import { cn } from "@heffl/ui/lib/utils";
import { LucideIcon } from "lucide-react";
import PageTitle, { PageTitleProps } from "./layouts/PageTitle";
import { useState } from "react";

type SideBarItem = {
  key: string;
  icon?: LucideIcon;
  label: string;
  onClick: () => void;
};

type Props = {
  children: React.ReactNode;
  fullWidth?: boolean;
  className?: string;
  loading?: boolean;
  pageTitleParentClassName?: string;
  sideBarItems?: SideBarItem[];
  style?: React.CSSProperties;
  isHome?: boolean;
};

export const PageTopHeight = "49px";
export const PageHeight = "calc(100vh - 49px)";

const Page = ({
  children,
  fullWidth,
  className,
  pageTitleParentClassName,
  sideBarItems,
  style,
  ...pageTitleProps
}: Props & PageTitleProps) => {
  const [sideBarItemSelected, setSideBarItemSelected] = useState<string>("all");

  return (
    <div className="relative">
      <div
        className={cn(
          "w-full  bg-white border-gray-200 sticky top-0 z-10",
          !pageTitleProps.title && "border-b-0 py-0",
          pageTitleParentClassName
        )}
      >
        <PageTitle {...pageTitleProps} />
      </div>
      <div className="flex">
        {!!sideBarItems?.length && (
          <div className="flex flex-col px-2 py-4 w-52">
            {sideBarItems?.map((item) => (
              <div
                key={item.label}
                className={cn(
                  "flex flex-row p-2 rounded-md w-52 cursor-pointer ",
                  sideBarItemSelected === item.key &&
                    "bg-primary-500 text-white"
                )}
                onClick={() => setSideBarItemSelected(item.key)}
              >
                {item.label}
              </div>
            ))}
          </div>
        )}
        <div
          className={cn(
            "flex justify-center w-full sm:pb-0",
            !pageTitleProps.showBack && "pb-[65px]"
          )}
        >
          <div
            className={cn(
              "w-full max-w-screen-xl sm:p-6 p-3",
              fullWidth && "!w-full max-w-none",
              className
            )}
            style={style}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page;
