import dayjs from "dayjs";
import sendWhatsappMessage from "../../../../agenda/sendWhatsappMessage";
import { WorkFlowAction } from "../../../types";

export const sendMessageAction: WorkFlowAction = {
  name: "send-message",
  label: "Send message",
  description: "Send a message to a contact",
  inputs: [
    {
      name: "mobile",
      label: "Mobile",
      type: "TEXT",
      placeholder: "Mobile with country code",
      required: true,
    },
    {
      name: "message",
      label: "Message",
      type: "TEXT",
      placeholder: "Message",
      required: true,
    },
  ],
  run: async ({ payload }) => {
    if (!payload) return;

    await sendWhatsappMessage({
      template: "visa_reminder",
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      to: payload.mobile,
      variables: [`Nasim Muhammed`, dayjs().format("DD/MM/YYYY"), "Faisal"],
    });
  },
};
