import {
  useSortable,
  defaultAnimateLayoutChanges,
  type AnimateLayoutChanges,
} from "@dnd-kit/sortable";
import { RouterOutputs } from "@/helpers/trpc";
import TaskCard from "./TaskCard";
import { cn } from "@heffl/ui/lib/utils";

const animateLayoutChanges: AnimateLayoutChanges = (args) => {
  const { isSorting, wasDragging } = args;
  if (isSorting || wasDragging) return defaultAnimateLayoutChanges(args);
  return true;
};

export type TTask = NonNullable<
  RouterOutputs["projects"]["tasks"]["list"]
>[number];

const SortableTaskCard: React.FC<{
  task: TTask;
  onTaskClick: (id: number) => void;
  inProject?: boolean;
}> = ({ task, onTaskClick, inProject = false }) => {
  const {
    setNodeRef,
    listeners,
    attributes,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: task.id,
    data: { task: task, type: "task" },
    animateLayoutChanges,
  });

  const cardStyle = {
    transform: transform
      ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
      : undefined,
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <li
      style={cardStyle}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      className={cn("bg-white w-[300px]")}
    >
      <TaskCard task={task} onTaskClick={onTaskClick} inProject={inProject} />
    </li>
  );
};

export default SortableTaskCard;
