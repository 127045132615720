import { Button } from "@heffl/ui/components/primitives/button";
import { Form } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import Page, { PageHeight } from "@/components/page";
import { trpc } from "@/helpers/trpc";
import Schemas from "@heffl/server/src/schemas";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import TemplateForm from "./components/templateForm";
import { focusManager } from "@tanstack/react-query";
import { onWindowFocus } from "@/helpers/onWindowFocus";
import { Save } from "lucide-react";

// solution for iframes trigger trpc refetch when focused out (tinymce)
focusManager.setEventListener(onWindowFocus);

const TemplateUpdate = () => {
  const params = useParams();
  const navigate = useNavigate();

  const templateId = Number(params.id);

  const { data: template } = trpc.templates.details.useQuery(templateId, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const form = useForm<z.infer<typeof Schemas.crm.template>>({
    resolver: zodResolver(Schemas.crm.template),
    defaultValues: {
      type: "QUOTATION",
    },
  });

  const updateTemplateMutation = trpc.templates.update.useMutation({
    onSuccess: () => {
      toast.success("Template updated successfully");
      setTimeout(() => navigate("/templates"), 200);
    },
  });

  useEffect(() => {
    if (template) form.reset(template);
  }, [template, form]);

  if (!template) return <FullScreenSpinner />;

  return (
    <Page
      fullWidth
      showBack
      title="Update Template"
      suffix={
        <>
          <Button
            onClick={() =>
              form.handleSubmit((values) =>
                updateTemplateMutation.mutate({
                  id: Number(params.id),
                  template: values,
                })
              )()
            }
            loading={updateTemplateMutation.isLoading}
            variant="primary"
            icon={Save}
          >
            Save template
          </Button>
        </>
      }
      breadcrumbs={[
        {
          label: "Templates",
          path: "/crm/templates",
        },
        {
          label: "Edit template",
          path: ``,
        },
      ]}
      className="overflow-hidden bg-gray-100"
      style={{ height: PageHeight }}
    >
      <Form
        {...form}
        onSubmit={(values) => {
          updateTemplateMutation.mutate({
            id: templateId,
            template: values,
          });
          form.reset({});
        }}
      >
        <TemplateForm form={form} id={templateId} edit />
      </Form>
    </Page>
  );
};

export default TemplateUpdate;
