import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import { AddProductDrawer } from "@/pages/sales/products/components/addProductDrawer";
import Schemas from "@heffl/server/src/schemas";
import { Form } from "@heffl/ui/components/primitives/form";
import ResponsivePrimaryButton from "@heffl/ui/components/ResponsivePrimaryButton";
import { zodResolver } from "@hookform/resolvers/zod";
import { Save } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import PurchaseOrderForm from "./purchase-order-form";
import usePermissions from "@/lib/hooks/usePermissions";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";

const PurchaseOrderEdit: React.FC = () => {
  const params = useParams();
  const purchaseOrderId = Number(params.id);
  const navigate = useNavigate();
  const permissions = usePermissions();

  const [showAddProductDialog, setShowAddProductDialog] = useState(false);
  const form = useForm<z.infer<typeof Schemas.purchases.purchaseOrder>>({
    resolver: zodResolver(Schemas.purchases.purchaseOrder),
  });

  const { data: purchaseOrderDetails } =
    trpc.purchases.purchaseOrders.details.useQuery(purchaseOrderId, {
      refetchOnWindowFocus: false,
    });

  const purchaseOrderUpdateMutation =
    trpc.purchases.purchaseOrders.update.useMutation({
      onSuccess: () => {
        form.reset();
        toast.success("Purchase Order updated successfully");
        navigate(-1);
      },
      onError(error) {
        toast.error(error.message);
      },
    });

  useEffect(() => {
    if (!form.getValues("vendorId") && purchaseOrderDetails) {
      form.reset({
        ...purchaseOrderDetails,
        purchaseOrderTags: purchaseOrderDetails.purchaseOrderTags.map(
          (tag) => tag.tagId
        ),
      });
    }
  }, [purchaseOrderDetails, form]);

  const onSubmit = (
    values: z.infer<typeof Schemas.purchases.purchaseOrder>
  ) => {
    purchaseOrderUpdateMutation.mutate({
      id: purchaseOrderId,
      purchaseOrder: values,
    });
  };

  if (!permissions) return <FullScreenSpinner />;

  return (
    <Page
      showBack
      title="Edit Purchase Order"
      description="Update purchase order details"
      className="max-w-screen-lg"
      suffix={
        <ResponsivePrimaryButton
          disabled={!permissions.UPDATE_PURCHASE_ORDERS.allowed}
          onClick={() => form.handleSubmit(onSubmit)()}
          loading={purchaseOrderUpdateMutation.isLoading}
          variant="primary"
          size="md"
          icon={Save}
        >
          Update Purchase Order
        </ResponsivePrimaryButton>
      }
    >
      {showAddProductDialog && (
        <AddProductDrawer
          open={showAddProductDialog}
          onClose={() => setShowAddProductDialog(false)}
        />
      )}
      <Form {...form} onSubmit={onSubmit}>
        <PurchaseOrderForm form={form} edit />
      </Form>
    </Page>
  );
};

export default PurchaseOrderEdit;
