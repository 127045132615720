import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Button } from "@heffl/ui/components/primitives/button";
import {
  ScrollArea,
  ScrollBar,
} from "@heffl/ui/components/primitives/scroll-area";
import { cn } from "@heffl/ui/lib/utils";
import { GripHorizontal, Plus } from "lucide-react";
import { RouterOutputs } from "@/helpers/trpc";
import SortableProjectCard from "./components/SortableProjectCard";

export type TProjectList = NonNullable<
  RouterOutputs["projects"]["pipelines"]["details"]
>["projectPipelineStages"][number];

export type ProjectBoardProps = {
  name: string;
  id: string;
  projects: RouterOutputs["projects"]["list"]["projects"];
  stageType: "OPEN" | "CLOSED";
  closedStageType?: "WON" | "LOST" | null;
};

const ProjectBoard: React.FC<{
  projectsData: ProjectBoardProps;
}> = ({ projectsData }) => {
  const {
    name,
    id,
    projects,
    stageType,
    closedStageType = "WON",
  } = projectsData;
  const { setNodeRef } = useDroppable({ id: id.toString() });

  const sectionColor = cn(
    "w-3 h-3 rounded-full",
    stageType === "OPEN" && "bg-secondary-500",
    stageType === "CLOSED" && closedStageType === "WON" && "bg-green-500",
    stageType === "CLOSED" && closedStageType === "LOST" && "bg-red-500"
  );

  return (
    <SortableContext
      items={projects}
      id={id.toString()}
      strategy={verticalListSortingStrategy}
    >
      <section
        className={cn("flex flex-col pt-0 rounded-sm min-w-[300px]")}
        ref={setNodeRef}
      >
        <div className="flex flex-col justify-between items-center pb-2 w-full border-b border-gray-200">
          <div className="flex gap-2 justify-between items-center w-full">
            <div className="flex gap-2 items-center">
              <div className={sectionColor} />
              <p className="text-sm font-semibold">{name}</p>
            </div>
            <div className="flex items-center pr-2">
              <Button variant="ghost" className="p-1">
                <Plus className="h-4 text-gray-400" />
              </Button>
              <Button variant="ghost" className="p-1">
                <GripHorizontal className="h-4 text-gray-400" />
              </Button>
            </div>
          </div>
        </div>
        <ScrollArea className="overflow-hidden hover:overflow-y-auto mt-1.5 pb-1.5 lg:overflow-y-auto">
          <ul className="flex flex-col gap-2 py-2">
            {projects.map((project) => (
              <SortableProjectCard
                key={project.id.toString()}
                project={project}
              />
            ))}
          </ul>
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
      </section>
    </SortableContext>
  );
};

export default ProjectBoard;
