import axios from "axios";

const telegramToken = "7266661501:AAHl9OFlKdVJwqC0Tv6nT5kSvugVu3NVH4w";

interface Props {
  to: string;
  message: string;
}

const sendTelegramMessage = async (props: Props) => {
  const requestURL = `https://api.telegram.org/bot${telegramToken}/sendMessage`;
  try {
    const messageResponse = await axios.post(
      requestURL,
      {
        chat_id: props.to,
        text: props.message,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    console.log(messageResponse.data);
    return messageResponse.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export default sendTelegramMessage;
