import Empty from "@/components/Empty";
import { SearchInput } from "@/components/FormComponents";
import Page from "@/components/page";
import Schemas from "@heffl/server/src/schemas";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import Select from "@heffl/ui/components/primitives/creatable-select";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import { Input } from "@heffl/ui/components/primitives/input";
import ResponsiveActionButton from "@heffl/ui/components/primitives/responsive-action-button";
import { zodResolver } from "@hookform/resolvers/zod";
import { Save, FileX, Trash2 } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useImmer } from "use-immer";
import { z } from "zod";
import { RouterOutputs, trpc } from "@/helpers/trpc";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Card } from "@heffl/ui/components/primitives/card";
import toast from "react-hot-toast";
import { Button } from "@heffl/ui/components/primitives/button";
import { capitalizeFirstLetter, cn } from "@heffl/ui/lib/utils";
import enums from "@heffl/server/src/schemas/enums";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";

const lostReasonTypes: {
  value: z.infer<typeof enums.lostReasonTypes>;
  label: string;
}[] = [
  {
    value: "LEAD",
    label: "Lead",
  },
  {
    value: "DEAL",
    label: "Deal",
  },
  {
    value: "QUOTATION",
    label: "Quotation",
  },
];

const LostReasonForm = ({ edit }: { edit?: boolean }) => (
  <div className="flex flex-col gap-3">
    <FormField name="type" label="Type">
      <Select
        disabled={edit}
        allowClear={false}
        options={lostReasonTypes}
        placeholder="Select lost reason type"
      />
    </FormField>

    <FormField name="reason" label="Reason">
      <Input placeholder="Lost reason" />
    </FormField>
  </div>
);

export const EditLostReasonDrawer = ({
  open,
  onClose,
  lostReasonId,
}: {
  open: boolean;
  onClose: () => void;
  lostReasonId: number;
}) => {
  const confirm = useConfirm();
  const form = useForm<z.infer<typeof Schemas.crm.lostReason>, unknown>({
    resolver: zodResolver(Schemas.crm.lostReason),
    defaultValues: {},
  });
  const { data: lostReason } = trpc.lostReasons.details.useQuery(lostReasonId);

  const deleteLostReasonMutation = trpc.lostReasons.delete.useMutation({
    onSuccess: () => {
      toast.success("Lost reason deleted successfully");
      onClose();
    },
  });

  const lostReasonUpdateMutation = trpc.lostReasons.update.useMutation({
    onSuccess: () => {
      toast.success("Lost reason updated successfully");
      onClose();
    },
  });

  useEffect(() => {
    if (lostReason) {
      form.reset({
        reason: lostReason.reason,
        type: lostReason.type as unknown as z.infer<
          typeof enums.lostReasonTypes
        >,
      });
    }
  }, [lostReason]);

  return (
    <ModalDrawer
      open={open}
      onClose={onClose}
      title="Edit lost reason"
      footer={
        <div className="flex gap-3 w-full">
          <Button
            variant="destructiveOutline"
            onClick={async () => {
              const confirmed = await confirm({
                title: "Are you sure you want to delete this lost reason?",
                body: "This action cannot be undone.",
              });
              if (confirmed) {
                deleteLostReasonMutation.mutate(lostReasonId);
              }
            }}
            icon={Trash2}
          >
            Delete
          </Button>
          <Button
            icon={Save}
            type="submit"
            variant="primary"
            className="w-full"
            loading={lostReasonUpdateMutation.isLoading}
          >
            Update lost reason
          </Button>
        </div>
      }
    >
      <Form
        {...form}
        onSubmit={(values) => {
          lostReasonUpdateMutation.mutate({
            id: lostReasonId,
            lostReason: values,
          });
        }}
      >
        <LostReasonForm edit />
      </Form>
    </ModalDrawer>
  );
};

export const AddLostReasonDrawer = ({
  open,
  onClose,
  type = "LEAD",
}: {
  open: boolean;
  onClose: (addedId?: number) => void;
  type?: "LEAD" | "DEAL" | "QUOTATION";
}) => {
  const form = useForm<z.infer<typeof Schemas.crm.lostReason>, unknown>({
    resolver: zodResolver(Schemas.crm.lostReason),
    defaultValues: {
      type,
    },
  });

  const onModalClose = (addedId?: number) => {
    onClose(addedId);
    form.reset();
  };

  const addLostReasonMutation = trpc.lostReasons.add.useMutation({
    onSuccess: (newLostReason) => {
      toast.success("Lost reason added successfully");
      onModalClose(newLostReason.id);
    },
  });

  const onSubmit = (values: z.infer<typeof Schemas.crm.lostReason>) => {
    addLostReasonMutation.mutate(values);
  };

  return (
    <ModalDrawer
      open={open}
      onClose={() => onModalClose()}
      title="Add lost reason"
      footer={
        <Button
          type="submit"
          variant="primary"
          className="w-full"
          loading={addLostReasonMutation.isLoading}
          onClick={() => form.handleSubmit(onSubmit)()}
        >
          Add lost reason
        </Button>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <LostReasonForm />
      </Form>
    </ModalDrawer>
  );
};

type TLostReason = RouterOutputs["lostReasons"]["list"][number];

const LostReasonCard = ({
  lostReason,
  onClick,
}: {
  lostReason: TLostReason;
  onClick?: () => void;
}) => {
  return (
    <Card className="flex gap-4 cursor-pointer" onClick={onClick}>
      <div className={cn(`p-4 bg-red-100 rounded-full`)}>
        <FileX className="h-6 text-red-500" />
      </div>
      <div className="flex flex-col gap-1 justify-center">
        <p className="text-sm font-medium">{lostReason.reason}</p>
        <p className="text-xs text-gray-500">
          {capitalizeFirstLetter(lostReason.type)}
        </p>
      </div>
    </Card>
  );
};

const LostReasons = () => {
  const [filters, setFilters] = useImmer({
    search: "",
  });
  const [addLostReason, setAddLostReason] = useState(false);
  const [editLostReason, setEditLostReason] = useState<number | undefined>(
    undefined
  );
  const { data } = trpc.lostReasons.list.useQuery();

  if (!data) return <FullScreenSpinner />;
  return (
    <Page title="Lost Reasons">
      <AddLostReasonDrawer
        open={addLostReason}
        onClose={() => setAddLostReason(false)}
      />
      {editLostReason && (
        <EditLostReasonDrawer
          open={!!editLostReason}
          onClose={() => setEditLostReason(undefined)}
          lostReasonId={editLostReason}
        />
      )}
      <div className="flex justify-between w-full">
        <SearchInput
          placeholder="Search lost reasons..."
          value={filters.search}
          onChange={(value) =>
            setFilters((f) => {
              f.search = value;
            })
          }
        />
        <div className="space-x-3">
          <ResponsiveActionButton
            onClick={() => setAddLostReason(true)}
            text="Lost Reason"
          />
        </div>
      </div>

      {!data?.length ? (
        <Empty
          title="No lost reasons added"
          icon={FileX}
          description="Please add a lost reason"
          className="mt-8"
        />
      ) : (
        <div className="grid grid-cols-2 gap-3 mt-4 sm:grid-cols-4">
          {data?.map((lostReason) => (
            <LostReasonCard
              key={lostReason.id}
              lostReason={lostReason}
              onClick={() => setEditLostReason(lostReason.id)}
            />
          ))}
        </div>
      )}
    </Page>
  );
};

export default LostReasons;
