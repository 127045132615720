import { Card } from "@heffl/ui/components/primitives/card";
import { RouterOutputs } from "@/helpers/trpc";
import { DATE_TIME_WORD_FORMAT } from "@/lib/constants";
import { formatName } from "@heffl/ui/lib/utils";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

type TContact = RouterOutputs["contacts"]["list"]["contacts"][number];

const MobileContactCard = ({ data }: { data: TContact }) => {
  const navigate = useNavigate();

  return (
    <Card onClick={() => navigate(`/crm/contacts/details/${data.id}`)}>
      <div className="flex flex-col gap-2">
        <div className="flex flex-row justify-between">
          <p className="text-sm font-medium">{formatName(data)}</p>
        </div>
        <div className="flex flex-row justify-between">
          <p className="text-xs">Phone number</p>
          <p className="text-xs text-gray-500"> {data.mobile || "No phone"}</p>
        </div>
        <div className="flex flex-row justify-between">
          <p className="text-xs">Email</p>
          <p className="text-xs text-gray-500">{data.email || "No email"}</p>
        </div>
        <div className="flex flex-row justify-between">
          <p className="text-xs">Created on</p>
          <p className="text-xs text-gray-500">
            {" "}
            {format(data.createdAt, DATE_TIME_WORD_FORMAT)}
          </p>
        </div>
      </div>
    </Card>
  );
};

export default MobileContactCard;
