/* eslint-disable react-refresh/only-export-components */
import ActivityTimeline from "@/pages/crm/components/activity-timeline";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import {
  Tabs,
  TabsList,
  TabsTrigger,
} from "@heffl/ui/components/primitives/tabs";
import { trpc } from "@/helpers/trpc";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@radix-ui/react-collapsible";
import {
  Activity,
  AlarmClock,
  CheckCircle,
  ChevronDown,
  ChevronUp,
  Clock,
  Mail,
  MessageCircle,
  MessageSquare,
  Phone,
  StickyNote,
  Users,
} from "lucide-react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Empty from "@/components/Empty";

type TType =
  | "todo"
  | "meeting"
  | "call"
  | "sms"
  | "email"
  | "whatsapp_message"
  | "event"
  | "note"
  | "task"
  | "comment";

export const historyIcons: Record<TType, React.ReactNode> = {
  todo: <AlarmClock className="h-4" />,
  event: <Activity className="h-4" />,
  email: <Mail className="h-4" />,
  call: <Phone className="h-4" />,
  meeting: <Users className="h-4" />,
  note: <StickyNote className="h-4" />,
  whatsapp_message: <MessageCircle className="h-4" />,
  sms: <MessageSquare className="h-4" />,
  task: <CheckCircle className="h-4" />,
  comment: <MessageCircle className="h-4" />,
};

const DealHistory = () => {
  const params = useParams();
  const dealId = Number(params.dealId);

  const [open, setOpen] = useState(true);
  const [selectedHistory, setSeletedHistory] = useState("all");

  const { data: dealHistory } = trpc.deals.history.useQuery({ dealId });

  if (!dealHistory) return <FullScreenSpinner />;

  const filterdDealHistory = dealHistory.filter(({ type }) => {
    if (selectedHistory === "all") return true;
    if (selectedHistory === type) return true;
    if (
      selectedHistory === "activity" &&
      ["email", "call", "meeting"].includes(type)
    )
      return true;
    return false;
  });

  return (
    <Collapsible className="mt-4" open={open} onOpenChange={setOpen}>
      <CollapsibleTrigger>
        <div className="flex flex-row gap-1 items-center">
          {open ? (
            <ChevronUp className="w-4 h-4" />
          ) : (
            <ChevronDown className="w-4 h-4" />
          )}
          <h1 className="text-sm font-semibold">History</h1>
        </div>
      </CollapsibleTrigger>
      <CollapsibleContent>
        <Tabs
          className="hidden mt-3 mb-4 sm:block"
          value={selectedHistory}
          onValueChange={setSeletedHistory}
        >
          <TabsList>
            <TabsTrigger value="all">All</TabsTrigger>
            <TabsTrigger value="task">Tasks</TabsTrigger>
            <TabsTrigger value="activity">Activities</TabsTrigger>
            <TabsTrigger value="note">Notes</TabsTrigger>
            <TabsTrigger value="file">Files</TabsTrigger>
          </TabsList>
        </Tabs>
        {!filterdDealHistory.length && (
          <Empty
            title="Nothing added here"
            icon={Clock}
            description="Please add some activities, tasks or notes to show up here."
          />
        )}
        <ActivityTimeline activities={filterdDealHistory} />
      </CollapsibleContent>
    </Collapsible>
  );
};

export default DealHistory;
