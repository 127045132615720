import SelectableDateicker from "@/components/selectable-date-picker";
import { trpc } from "@/helpers/trpc";
import InfoItemsHZ from "@/components/InfoItemHZ";
import { deformatReminderDate } from "@heffl/server/src/helpers/utils/formatReminderDate";
import Schemas from "@heffl/server/src/schemas";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import { DateTimePicker } from "@heffl/ui/components/primitives/datetime-picker";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import MiniRichTextEditor from "@heffl/ui/components/primitives/mini-rich-text-editor";
import { MultiSelect } from "@heffl/ui/components/primitives/multi-select";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import { zodResolver } from "@hookform/resolvers/zod";
import dayjs from "dayjs";
import { AlarmClock, Pencil, Trash2, UserCircle } from "lucide-react";
import { useEffect, useState } from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";
import { reminderDurations } from "./activities-modals";
import { Switch } from "@heffl/ui/components/primitives/switch";
import TagsInput from "@heffl/ui/components/TagInput";
import { dynamicDateFormatting } from "@heffl/ui/lib/utils";
import { cn } from "@heffl/ui/lib/utils";
import {
  TaskLinkBadge,
  remindersToPlainText,
} from "@/pages/personal-dashboard";

const TaskForm = ({
  form,
}: {
  form: UseFormReturn<z.infer<typeof Schemas.crm.task>>;
}) => {
  const dueDate = form.watch("date");

  const { data: users } = trpc.users.list.useQuery();

  return (
    <>
      <FormField name="title" label="Title">
        <Input placeholder="Title" />
      </FormField>
      <FormField label="Description" name="description">
        <MiniRichTextEditor
          placeholder="Describe the task details...."
          height={100}
        />
      </FormField>
      <FormField label="Assigned to" name="taskAssignees">
        <MultiSelect
          icon={UserCircle}
          placeholder="Assign team"
          options={
            users?.map((user) => ({
              label: user.firstName,
              value: user.id,
            })) || []
          }
        />
      </FormField>
      <div className="flex flex-wrap gap-1 pb-4 sm:pb-0">
        <FormField name="date" className="w-fit">
          <DateTimePicker />
        </FormField>
        <FormField name="reminders" className="w-fit">
          <SelectableDateicker
            renderClassName="text-purple-500"
            placeholder="Reminders"
            icon={AlarmClock}
            ogDate={dueDate || null}
            options={reminderDurations}
          />
        </FormField>
      </div>
    </>
  );
};

const AddTaskModal = ({
  open,
  onClose,
  dealId,
  contactId,
  clientId,
  leadId,
}: {
  open: boolean;
  onClose: () => void;
  dealId?: number;
  contactId?: number;
  clientId?: number;
  leadId?: number;
}) => {
  const form = useForm<z.infer<typeof Schemas.crm.task>>({
    resolver: zodResolver(Schemas.crm.task),
    defaultValues: {
      date: dayjs().toDate(),
    },
  });

  const onModalClose = () => {
    form.reset();
    onClose();
  };

  const taskAddMutation = trpc.tasks.add.useMutation({
    onSuccess: () => {
      toast.success("Task added successfully");
      onModalClose();
    },
  });

  const onSubmit = (values: z.infer<typeof Schemas.crm.task>) => {
    taskAddMutation.mutate({
      ...values,
      dealId,
      contactId,
      clientId,
      leadId,
    });
  };

  return (
    <ModalDrawer
      open={open}
      onClose={onModalClose}
      title="Add Task"
      footer={
        <Button
          loading={taskAddMutation.isLoading}
          onClick={() => form.handleSubmit(onSubmit)()}
          variant="primary"
          className="w-full"
          size="md"
        >
          Add Task
        </Button>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <TaskForm form={form} />
      </Form>
    </ModalDrawer>
  );
};

const EditTaskModal = ({
  open,
  onClose,
  taskId,
}: {
  open: boolean;
  onClose: ({
    deleted,
    updated,
  }: {
    deleted: boolean;
    updated: boolean;
  }) => void;
  taskId: number;
}) => {
  const confirm = useConfirm();
  const form = useForm<z.infer<typeof Schemas.crm.task>>({
    resolver: zodResolver(Schemas.crm.task),
  });

  const { data: task } = trpc.tasks.details.useQuery(taskId);

  const onModalClose = () => {
    form.reset();
    onClose({ deleted: false, updated: false });
  };

  const taskUpdateMutation = trpc.tasks.update.useMutation({
    onSuccess: () => {
      toast.success("Task updated successfully");
      onClose({ deleted: false, updated: true });
    },
  });

  const taskDeleteMutation = trpc.tasks.delete.useMutation({
    onSuccess: () => {
      toast.success("Task deleted successfully");
      onClose({ deleted: true, updated: false });
    },
  });

  const onSubmit = (values: z.infer<typeof Schemas.crm.task>) => {
    taskUpdateMutation.mutate({ id: taskId, task: values });
  };

  useEffect(() => {
    if (task) {
      form.reset({
        ...task,
        taskAssignees: task.taskAssignees.map((assignee) => assignee.userId),
        reminders: task.reminders.length
          ? deformatReminderDate({
              ogDate: task.date,
              date: task.reminders[0].date,
              availableUnits: reminderDurations,
            })
          : undefined,
      });
    }
  }, [task]);

  return (
    <ModalDrawer
      open={open}
      onClose={onModalClose}
      title="Edit Task"
      footer={
        <div className="flex gap-2 w-full">
          <Button
            icon={Trash2}
            size="md"
            variant="destructiveOutline"
            loading={taskDeleteMutation.isLoading}
            onClick={async () => {
              const confirmed = await confirm({
                title: "Are you sure you want to delete this task?",
              });
              if (confirmed) {
                taskDeleteMutation.mutate(taskId);
              }
            }}
          />
          <Button
            loading={taskUpdateMutation.isLoading}
            onClick={() => form.handleSubmit(onSubmit)()}
            variant="primary"
            className="w-full"
            size="md"
          >
            Update Task
          </Button>
        </div>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <TaskForm form={form} />
      </Form>
    </ModalDrawer>
  );
};

const TaskDetailsModal = ({
  open,
  onClose,
  taskId,
}: {
  open: boolean;
  onClose: () => void;
  taskId: number;
}) => {
  const [showEditTaskModal, setShowEditTaskModal] = useState(false);

  const { data: task } = trpc.tasks.details.useQuery(taskId);
  const { data: users } = trpc.users.list.useQuery();

  const taskUpdateMutation = trpc.tasks.update.useMutation();

  return (
    <ModalDrawer
      open={open}
      onClose={onClose}
      title={
        <div className="flex justify-between w-full sm:pr-12">
          <p className="w-4/5 text-lg font-medium">{task?.title}</p>
          <Button
            icon={Pencil}
            size="xs"
            variant="primaryOutline"
            onClick={() => setShowEditTaskModal(true)}
          >
            Edit
          </Button>
        </div>
      }
    >
      <EditTaskModal
        open={showEditTaskModal}
        onClose={({ deleted }) => {
          setShowEditTaskModal(false);
          if (deleted) {
            onClose();
          }
        }}
        taskId={taskId}
      />
      {task ? (
        <>
          <div
            className="text-xs text-gray-500"
            dangerouslySetInnerHTML={{
              __html: task.description || "No description",
            }}
          />

          <TaskLinkBadge
            task={task}
            className="mt-4 w-fit"
            tooltipSide="right"
            small={false}
          />
          <InfoItemsHZ
            className="mt-3"
            labelClassName="text-gray-500 font-normal"
            items={[
              {
                label: "Status",
                value: (
                  <Switch
                    checked={task.status === "COMPLETED"}
                    onChange={() => {
                      taskUpdateMutation.mutate({
                        id: taskId,
                        task: {
                          status: task.status === "OPEN" ? "COMPLETED" : "OPEN",
                        },
                      });
                    }}
                  />
                ),
              },
              {
                label: "Assigned to",
                value: (
                  <TagsInput
                    small={false}
                    emptyLabel="Not assigned"
                    className="flex-wrap mt-3"
                    value={task.taskAssignees.map((user) => user.userId)}
                    uniqueColor={true}
                    tags={users?.map((user) => ({
                      id: user.id,
                      name: user.firstName,
                    }))}
                    onChange={(value) => {
                      taskUpdateMutation.mutate({
                        id: taskId,
                        task: {
                          taskAssignees: value,
                        },
                      });
                    }}
                  />
                ),
              },
              {
                label: "Due Date",
                value: (
                  <span
                    className={cn(
                      dayjs(task.date).isBefore(dayjs()) &&
                        task.status !== "COMPLETED" &&
                        "text-red-500"
                    )}
                  >
                    {dynamicDateFormatting(task.date)}
                  </span>
                ),
              },
              {
                label: "Reminders",
                value: task.reminders.length
                  ? task.reminders
                      .map((reminder) =>
                        remindersToPlainText(reminder.date, reminder.dateType)
                      )
                      .join(", ")
                  : "No reminders",
              },
              {
                label: "Created By",
                value: task.createdBy.firstName,
              },
              {
                label: "Completed at",
                value: task.completedOn
                  ? dynamicDateFormatting(task.completedOn)
                  : "Not completed",
              },
            ]}
          />
        </>
      ) : (
        <FullScreenSpinner />
      )}
    </ModalDrawer>
  );
};

export { AddTaskModal, EditTaskModal, TaskDetailsModal };
