import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@heffl/ui/components/primitives/accordion";
import { Card } from "@heffl/ui/components/primitives/card";
import { copyToClipboard } from "@heffl/ui/components/primitives/copy-to-clipboard";
import { Input } from "@heffl/ui/components/primitives/input";
import { cn } from "@heffl/ui/lib/utils";
import {
  DynamicTags,
  dynamicTags,
} from "@heffl/server/src/helpers/templates/dynamicTags";
import Fuse from "fuse.js";
import { Braces, Copy, Search } from "lucide-react";
import { useState } from "react";
import { splitByCamelCase } from "@heffl/ui/lib/constants";

const HefflFields = ({ types }: { types: Array<keyof DynamicTags> }) => {
  const [copiedValue, setCopiedValue] = useState<string | undefined>(undefined);
  const [search, setSearch] = useState("");

  const typesArray = ["Common" as keyof DynamicTags, ...types].map(
    (t) => dynamicTags[t]
  );
  const fusedTypes = new Fuse(typesArray, {
    keys: ["title", ["menu", "title"], ["menu", "value"]],
    threshold: 0.3,
  });

  const filteredTypes = () => {
    if (search.length) {
      return fusedTypes.search(search).map((t) => t.item);
    }
    return typesArray;
  };

  return (
    <Card title="Heffl fields" icon={<Braces className="text-orange-500" />}>
      <Input
        placeholder="Search fields.."
        prefix={<Search className="h-5 text-gray-500" />}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />

      {/* @ts-ignore */}
      <Accordion
        type={search.length ? "multiple" : "single"}
        value={search.length ? filteredTypes().map((t) => t.title) : undefined}
      >
        {filteredTypes().map((item) => {
          const Icon = item.icon;
          return (
            <AccordionItem value={item.title} key={item.title}>
              <AccordionTrigger>
                <div className="flex gap-2 items-center">
                  <Icon className="h-5" />
                  <p className="text-medium">
                    {splitByCamelCase(item.title).join(" ")}
                  </p>
                </div>
              </AccordionTrigger>
              <AccordionContent>
                <div className="flex flex-col">
                  {item.menu.map((subItem) => (
                    <div
                      onClick={() => {
                        copyToClipboard(`{{${subItem.value}}}`);
                        setCopiedValue(subItem.value);
                        setTimeout(() => setCopiedValue(undefined), 1000);
                      }}
                      key={subItem.value}
                      className="box-border flex justify-between p-2 px-4 rounded-sm border border-white cursor-pointer text-medium hover:border-gray-200 hover:shadow-sm"
                    >
                      <p>{splitByCamelCase(subItem.title).join(" ")}</p>
                      <Copy
                        className={cn(
                          "h-4",
                          copiedValue === subItem.value && "text-primary"
                        )}
                      />
                    </div>
                  ))}
                </div>
              </AccordionContent>
            </AccordionItem>
          );
        })}
      </Accordion>
    </Card>
  );
};

export default HefflFields;
