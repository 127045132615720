/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@heffl/ui/components/primitives/alert-dialog";
import { Button } from "@heffl/ui/components/primitives/button";
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from "@heffl/ui/components/primitives/drawer";
import { isMobile } from "@heffl/ui/lib/utils";
import * as React from "react";

export const AlertDialogContext = React.createContext<
  <T extends AlertAction>(
    params: T
  ) => Promise<T["type"] extends "confirm" ? boolean : null | string>
>(() => null!);

export type AlertAction =
  | {
      type: "confirm";
      title: string;
      body?: string | React.ReactNode;
      cancelButton?: string;
      actionButton?: string;
      actionVariant?: "destructive" | "primary";
    }
  | { type: "close" };

interface AlertDialogState {
  open: boolean;
  title: string;
  body: string | React.ReactNode;
  actionVariant?: "destructive" | "primary";
  type: "confirm";
  cancelButton: string;
  actionButton: string;
  defaultValue?: string;
  inputProps?: React.PropsWithoutRef<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >
  >;
}

export function alertDialogReducer(
  state: AlertDialogState,
  action: AlertAction
): AlertDialogState {
  switch (action.type) {
    case "close":
      return { ...state, open: false, title: "", body: "" };
    case "confirm":
      return {
        ...state,
        open: true,
        ...action,
        cancelButton: action.cancelButton || "Cancel",
        actionButton:
          ("actionButton" in action && action.actionButton) || "Confirm",
      };
    default:
      return state;
  }
}

export function AlertDialogProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [state, dispatch] = React.useReducer(alertDialogReducer, {
    open: false,
    title: "",
    body: "",
    type: "confirm",
    cancelButton: "Cancel",
    actionButton: "Okay",
  });

  const resolveRef = React.useRef<(tf: any) => void>();

  function close() {
    dispatch({ type: "close" });
    resolveRef.current?.(false);
  }

  function confirm(value?: string) {
    dispatch({ type: "close" });
    resolveRef.current?.(value ?? true);
  }

  const dialog = React.useCallback(async <T extends AlertAction>(params: T) => {
    dispatch(params);

    return new Promise<T["type"] extends "confirm" ? boolean : null | string>(
      (resolve) => {
        resolveRef.current = resolve;
      }
    );
  }, []);

  return (
    <AlertDialogContext.Provider value={dialog}>
      {children}
      {!isMobile() ? (
        <AlertDialog
          open={state.open}
          onOpenChange={(open) => {
            if (!open) close();
            return;
          }}
        >
          <AlertDialogContent asChild>
            <form
              onSubmit={(event) => {
                event.preventDefault();
                confirm(event.currentTarget.prompt?.value);
              }}
            >
              <AlertDialogHeader>
                <AlertDialogTitle>{state.title}</AlertDialogTitle>
                {state.body ? (
                  <AlertDialogDescription>{state.body}</AlertDialogDescription>
                ) : null}
              </AlertDialogHeader>
              <AlertDialogFooter>
                <Button type="button" onClick={close}>
                  {state.cancelButton}
                </Button>
                <Button
                  variant={state.actionVariant || "destructive"}
                  type="submit"
                >
                  {state.actionButton}
                </Button>
              </AlertDialogFooter>
            </form>
          </AlertDialogContent>
        </AlertDialog>
      ) : (
        <Drawer
          open={state.open}
          onOpenChange={(open) => {
            if (!open) close();
            return;
          }}
        >
          <DrawerContent>
            <DrawerHeader>
              <DrawerTitle className="text-start">{state.title}</DrawerTitle>
              <DrawerDescription>{state.body}</DrawerDescription>
            </DrawerHeader>
            <DrawerFooter className="grid grid-cols-2 gap-3">
              <Button type="button" onClick={close}>
                {state.cancelButton}
              </Button>
              <Button
                variant="destructive"
                type="submit"
                onClick={() => {
                  confirm();
                  close();
                }}
              >
                {state.actionButton}
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      )}
    </AlertDialogContext.Provider>
  );
}
type Params<T extends "confirm"> =
  | Omit<Extract<AlertAction, { type: T }>, "type">
  | string;

export function useConfirm() {
  const dialog = React.useContext(AlertDialogContext);

  return React.useCallback(
    (params: Params<"confirm">) => {
      return dialog({
        ...(typeof params === "string" ? { title: params } : params),
        type: "confirm",
      });
    },
    [dialog]
  );
}
