import { LucideIcon, Plus } from "lucide-react";
import { Button } from "@heffl/ui/components/primitives/button";
import { cn } from "@heffl/ui/lib/utils";
import { useNavigate } from "react-router-dom";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";

export type EmptyProps = {
  icon: LucideIcon;
  iconSize?: number;
  title: string;
  description?: string;
  actionText?: string;
  onAction?: () => void;
  buttonSize?: "xs" | "sm" | "lg";
  className?: string;
  actionIcon?: LucideIcon;
  actionHref?: string;
  loading?: boolean;
  hideAction?: boolean;
};

const Empty = ({
  icon: Icon,
  title,
  description,
  onAction,
  className,
  iconSize,
  buttonSize,
  actionText,
  actionHref,
  actionIcon: ActionIcon = Plus,
  loading = false,
  hideAction = false,
}: EmptyProps) => {
  const navigate = useNavigate();

  return loading ? (
    <FullScreenSpinner className={className} />
  ) : (
    <div
      className={cn(
        "flex flex-col items-center p-3 w-full h-full text-center",
        className
      )}
    >
      <div>
        <div className="grid p-2 bg-[#22577A] rounded-lg shadow-md place-items-center">
          <Icon className="text-white" size={iconSize || 40} />
        </div>
      </div>
      <p className="mt-3 font-semibold text-medium">{title}</p>
      <p className="max-w-sm text-sm text-gray-400">{description}</p>
      {(onAction || actionHref) && !hideAction && (
        <Button
          onClick={() => {
            if (onAction) {
              onAction();
            } else if (actionHref) {
              navigate(actionHref);
            }
          }}
          size={buttonSize || "sm"}
          className="mt-3"
          variant="primary"
        >
          <ActionIcon size={20} /> {actionText || "Add"}
        </Button>
      )}
    </div>
  );
};
export default Empty;
