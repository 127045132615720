import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from "@heffl/ui/components/primitives/drawer";
import { cn, isMobile } from "@heffl/ui/lib/utils";
import Modal from "@heffl/ui/components/primitives/modal";

type Props = {
  children: React.ReactNode;
  title?: string | React.ReactElement;
  description?: string;
  open: boolean;
  onClose: (v: boolean) => void;
  className?: string;
  modalClassName?: string;
  drawerClassName?: string;
  footer?: React.ReactNode;
  closeOnOverlayClick?: boolean;
};

const ModalDrawer = ({
  title,
  description,
  open,
  onClose,
  children,
  modalClassName = "",
  footer,
  closeOnOverlayClick = false,
  drawerClassName = "",
}: Props) => {
  if (!isMobile()) {
    return (
      <Modal
        closeOnOverlayClick={closeOnOverlayClick}
        title={title}
        description={description}
        open={open}
        onClose={onClose}
        className={cn(modalClassName)}
        footer={footer}
      >
        {children}
      </Modal>
    );
  }

  return (
    <Drawer open={open} onOpenChange={(v) => !v && onClose(v)}>
      <DrawerContent
        className={cn("p-2 max-h-[90%] overflow-hidden px-0", drawerClassName)}
      >
        <DrawerHeader className="text-left">
          <DrawerTitle>{title}</DrawerTitle>
          <DrawerDescription>{description}</DrawerDescription>
        </DrawerHeader>
        <div className="overflow-y-auto flex-1 px-4 pt-2 pb-4">{children}</div>
        {footer && (
          <DrawerFooter className="py-0 pt-2 border-t">{footer}</DrawerFooter>
        )}
      </DrawerContent>
    </Drawer>
  );
};

export default ModalDrawer;
