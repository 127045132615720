import {
  CommandGroup,
  CommandItem,
  CommandList,
} from "@heffl/ui/components/primitives/command";
import { Command as CommandPrimitive } from "cmdk";
import {
  ReactNode,
  useCallback,
  useRef,
  useState,
  type KeyboardEvent,
} from "react";

import { Skeleton } from "@heffl/ui/components/primitives/skeleton";
import { cn } from "@heffl/ui/lib/utils";
import { Loader } from "lucide-react";
import { Input } from "./primitives/input";

interface Option {
  label: string;
  value: string | number;
}

type AutoCompleteProps<T extends Option> = {
  className?: string;
  render?: (record: T) => ReactNode;
  options: T[];
  emptyMessage?: string;
  itemName?: string;
  value?: string | null;
  onChange?: (v: string) => void;
  onSelect?: (v: T) => void;
  isLoading?: boolean;
  disabled?: boolean;
  placeholder?: string;
  allowCreate?: boolean;
  icon?: ReactNode;
  number?: boolean;
  allowClear?: boolean;
  onOptionSelected?: (v: number | string) => void;
  onSearch?: (v: string) => void;
  createButton?: {
    label: string;
    onClick: () => void;
  };
};

const InputWithDropDown = <T extends Option>({
  onOptionSelected,
  onSearch,
  options,
  placeholder,
  emptyMessage,
  value,
  disabled = false,
  isLoading = false,
  onChange,
  render,
  className,
}: AutoCompleteProps<T>) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [isOpen, setOpen] = useState(false);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      const input = inputRef.current;
      if (!input) {
        return;
      }
      // Keep the options displayed when the user is typing
      if (!isOpen) {
        setOpen(true);
      }
      // This is not a default behaviour of the <input /> field
      if (event.key === "Enter" && input.value !== "") {
        const optionToSelect = options.find(
          (option) => option.label === input.value
        );
        if (optionToSelect) {
          handleSelectOption(optionToSelect);
        }
      }

      if (event.key === "Escape") {
        input.blur();
      }
    },
    [isOpen, options]
  );

  const handleBlur = () => {
    setOpen(false);
  };

  const handleSelectOption = (selectedOption: Option) => {
    onOptionSelected && onOptionSelected(selectedOption.value);
    // This is a hack to prevent the input from being focused after the user selects an option
    setTimeout(() => {
      inputRef?.current?.blur();
    }, 0);
  };

  return (
    <CommandPrimitive onKeyDown={handleKeyDown} shouldFilter={false}>
      <div className="relative">
        <div
          className={cn(
            "flex items-center w-full rounded-md border",
            disabled && "bg-gray-100"
          )}
        >
          <Input
            className={cn(
              "w-full h-[2.3rem] p-2 !border-none !outline-none rounded-md",
              className
            )}
            // ref={inputRef}
            value={value || ""}
            onChange={(v) => {
              onChange && onChange(v.target.value);
              onSearch && onSearch(v.target.value);
            }}
            onBlur={handleBlur}
            onFocus={() => {
              // setOldInputValue(inputValue);
              // setInputValue("");
              setOpen(true);
            }}
            placeholder={placeholder}
            disabled={disabled}
          />
        </div>
      </div>
      <div className="relative mt-1">
        {isOpen ? (
          <div className="absolute top-0 z-10 w-full bg-white rounded-xl outline-none animate-in fade-in-0 zoom-in-95">
            <CommandList className="rounded-lg ring-1 ring-slate-200">
              {isLoading ? (
                <CommandPrimitive.Loading>
                  <div className="p-1">
                    <Skeleton className="w-full h-8" />
                  </div>
                </CommandPrimitive.Loading>
              ) : null}
              {!isLoading ? (
                <CommandGroup>
                  {options
                    .filter((option) =>
                      option.label
                        .toLowerCase()
                        .includes(value ? value.toLowerCase() : "")
                    )
                    .map((option) => {
                      return (
                        <CommandItem
                          key={option.value}
                          value={`${option.label} ${option.value}`}
                          onMouseDown={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                          }}
                          onSelect={() => {
                            handleSelectOption(option);
                          }}
                          className={cn("flex gap-2 items-center w-full")}
                        >
                          {render ? render(option) : option.label}
                        </CommandItem>
                      );
                    })}

                  {!isLoading ? (
                    <CommandPrimitive.Empty className="px-2 py-3 text-sm text-center rounded-sm select-none">
                      {emptyMessage}
                    </CommandPrimitive.Empty>
                  ) : null}
                </CommandGroup>
              ) : (
                <div className="flex justify-center items-center w-full">
                  <Loader className="h-4 animate-spin" />
                </div>
              )}
            </CommandList>
          </div>
        ) : null}
      </div>
    </CommandPrimitive>
  );
};

export default InputWithDropDown;
