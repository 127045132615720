import { cn, isMobile } from "@heffl/ui/lib/utils";
import { Button, ButtonProps } from "@heffl/ui/components/primitives/button";
import { styled } from "goober";
import { useEffect } from "react";

const FixedBottomContainer = styled("div")`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0.5rem 1rem;
  background-color: white;
  border-top: 1px solid #e5e7eb;
  box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.1),
    0 -2px 4px -1px rgba(0, 0, 0, 0.06);
  width: calc(100% + (100vw - 100%));
`;

const ResponsivePrimaryButton: React.FC<ButtonProps> = ({
  children,
  ...props
}) => {
  useEffect(() => {
    if (isMobile()) {
      document.body.style.paddingBottom = "60px";
      return () => {
        document.body.style.paddingBottom = "";
      };
    }
  }, []);

  if (isMobile()) {
    return (
      <FixedBottomContainer>
        <Button {...props} className={cn(props.className, "w-full")} size="md">
          {children}
        </Button>
      </FixedBottomContainer>
    );
  }

  return <Button {...props}>{children}</Button>;
};

export default ResponsivePrimaryButton;
