import parsePhoneNumber, { getCountryCallingCode } from "libphonenumber-js";

const parsePhone = (phoneNumber?: string | null): string | null => {
  if (!phoneNumber) return null;

  const guessedCountry = parsePhoneNumber(phoneNumber)?.country;
  const countryCode = guessedCountry
    ? getCountryCallingCode(guessedCountry)
    : "971"; // Default to UAE

  const parsed = parsePhoneNumber(phoneNumber, guessedCountry || "AE");

  if (parsed && parsed.isValid()) {
    return parsed.number;
  }

  // If parsing fails, try prepending the country code
  const withCountryCode = `+${countryCode}${phoneNumber.replace(/^\+/, "")}`;
  const parsedWithCode = parsePhoneNumber(withCountryCode);

  return parsedWithCode && parsedWithCode.isValid()
    ? parsedWithCode.number
    : null;
};

export default parsePhone;
