import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import { Form } from "@heffl/ui/components/primitives/form";
import { Switch } from "@heffl/ui/components/primitives/switch";
import ResponsivePrimaryButton from "@heffl/ui/components/ResponsivePrimaryButton";
import { zodResolver } from "@hookform/resolvers/zod";
import { AlertCircle, Save } from "lucide-react";
import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { useImmer } from "use-immer";
import { z } from "zod";
import JobForm, { jobSchema } from "./components/job-form";
import { defaultFilesInput } from "@/pages/purchases/expenses/list";
import { map } from "radash";
import axios from "axios";

const EditJob = () => {
  const navigate = useNavigate();
  const params = useParams();
  const jobId = Number(params.id);
  const scheduleEditAlertRef = useRef<HTMLDivElement>(null);

  const form = useForm<z.infer<typeof jobSchema>>({
    resolver: zodResolver(jobSchema),
  });

  const [schedulesEdit, setSchedulesEdit] = useImmer({
    isDefaultValuesSet: false,
    isEdited: false,
    editAcknowledged: false,
  });

  form.watch((_, { name }) => {
    console.log(name, "watch");
    if (
      name?.split(".")[0] === "fsSchedules" &&
      schedulesEdit.isDefaultValuesSet
    ) {
      setSchedulesEdit((draft) => {
        draft.isEdited = true;
      });
    }
  });

  const { data: job } = trpc.fieldService.jobs.details.useQuery(jobId, {
    refetchOnWindowFocus: false,
  });
  const { data: schedulesData } = trpc.fieldService.schedules.list.useQuery({
    fsJobId: jobId,
    status: ["SCHEDULED", "CONFIRMED", "IN_PROGRESS", "ON_MY_WAY", "ARRIVED"],
    orderBy: {
      startDate: "asc",
    },
  });

  const schedules = schedulesData?.schedules || [];

  const { mutateAsync: getPresignedUrls } =
    trpc.files.presignedUrls.useMutation();

  const jobUpdateMutation = trpc.fieldService.jobs.update.useMutation({
    onSuccess: ({ id }) => {
      form.reset();
      toast.success("Succesfully updated job.");
      navigate(`/field-service/jobs/details/${id}`);
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  useEffect(() => {
    if (job && schedules) {
      form.reset({
        ...job,
        fsSchedules: schedules.map((schedule) => ({
          ...schedule,
          fsScheduleAssignees: schedule.fsScheduleAssignees.map(
            (assignee) => assignee.userId
          ),
          fsScheduleTags: schedule.fsScheduleTags.map((tag) => tag.tagId),
        })),
        fsJobTags: job.fsJobTags.map((tag) => tag.tagId),
        fsJobQuotations: job.fsJobQuotations.map(
          (quotation) => quotation.quotations.id
        ),
        files: {
          fs_job_lpo: defaultFilesInput(
            job.files?.fields?.fs_job_lpo ? [job.files?.fields?.fs_job_lpo] : []
          ),
        },
      });
      setTimeout(() => {
        setSchedulesEdit((draft) => {
          draft.isDefaultValuesSet = true;
        });
      }, 2000);
    }
  }, [job, schedules]);

  const onSubmit = async (values: z.infer<typeof jobSchema>) => {
    if (schedulesEdit.isEdited && !schedulesEdit.editAcknowledged) {
      return window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }

    const presignedUrls = values?.files?.fs_job_lpo.new.length
      ? await getPresignedUrls({
          files: values?.files?.fs_job_lpo.new,
          section: "field-service/jobs",
        })
      : [];
    jobUpdateMutation.mutate({
      id: jobId,
      fsJob: {
        ...values,
        fsSchedules: schedulesEdit.isEdited ? values.fsSchedules : undefined,
        files: {
          fs_job_lpo: {
            deleted: [],
            ...values?.files?.fs_job_lpo,
            new: presignedUrls,
          },
        },
      },
    });

    await map(presignedUrls, async (file, index) => {
      const fileToUpload = values?.files?.fs_job_lpo.new[index].file;
      await axios.put(file.presignedUrl, fileToUpload, {
        headers: {
          "Content-Type": file.format,
        },
      });
    });
  };

  return (
    <Page
      showBack
      title="Edit job"
      description="Update job details"
      suffix={
        <div className="flex gap-2">
          <div className="flex gap-2">
            <ResponsivePrimaryButton
              onClick={() => form.handleSubmit(onSubmit)()}
              variant="primary"
              loading={jobUpdateMutation.isLoading}
              icon={Save}
            >
              Update Job
            </ResponsivePrimaryButton>
          </div>
        </div>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        {schedulesEdit.isEdited && (
          <div
            className="flex gap-4 p-4 w-full text-xs rounded-xl border border-yellow-500 sm:text-base"
            ref={scheduleEditAlertRef}
          >
            <p className="flex gap-2 items-center w-4/5 font-normal">
              <AlertCircle className="w-8 h-8 text-yellow-500" />
              Schedule changes will remove pending schedules and create new
              ones.
            </p>
            <div className="flex gap-2 items-center p-1 px-2 rounded-lg border border-green-500">
              <Switch
                checked={schedulesEdit.editAcknowledged}
                onCheckedChange={(v) => {
                  setSchedulesEdit((draft) => {
                    draft.editAcknowledged = v;
                  });
                }}
              />{" "}
              I Understand
            </div>
          </div>
        )}
        <JobForm
          form={form}
          edit
          isDefaultValuesSet={schedulesEdit.isDefaultValuesSet}
        />
      </Form>
    </Page>
  );
};

export default EditJob;
