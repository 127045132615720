import Page from "@/components/page";
import { UserAvatar } from "@/components/UserAvatar";
import { trpc } from "@/helpers/trpc";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import dayjs from "dayjs";

const NewHome = () => {
  const { data: currentUser } = trpc.users.currentUser.useQuery();

  if (!currentUser) {
    return <FullScreenSpinner />;
  }

  return (
    <Page title="" showHome={false} className="!p-0 bg-gray-100" fullWidth>
      <div className="relative h-screen">
        <div className="flex sticky top-0 z-10 gap-2 items-center p-4 w-full bg-white">
          <UserAvatar size="md" user={currentUser} />
          <div>
            <div className="text-xl font-medium">Home</div>
            <div className="text-sm text-gray-500">
              {dayjs().format("dddd, MMMM D")}
            </div>
          </div>
        </div>
        <div className="mx-auto max-w-screen-lg h-full bg-blue-200">
          New Home
        </div>
      </div>
    </Page>
  );
};

export default NewHome;
