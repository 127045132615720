import { RouterOutputs } from "@/helpers/trpc";
import { formatValue } from "@heffl/ui/lib/utils";
import { Card } from "@heffl/ui/components/primitives/card";
import { Building2, MapPin } from "lucide-react";
import { getJobName } from "../../schedules/calendar";
import { useNavigate } from "react-router-dom";

type Job = RouterOutputs["fieldService"]["jobs"]["list"]["jobs"][number];

const JobCard = ({ job }: { job: Job }) => {
  const navigate = useNavigate();
  return (
    <Card onClick={() => navigate(`/field-service/jobs/details/${job.id}`)}>
      <div className="flex flex-col gap-1">
        <p className="text-sm font-semibold">{getJobName(job.fsJobServices)}</p>
        <span className="flex items-center font-medium">
          <Building2 className="h-4 text-red-500" />
          <p className="text-sm text-gray-700">
            {job.fsProperties.clients.name}
          </p>
        </span>
        <span className="flex items-center font-medium">
          <MapPin className="h-4 text-blue-500" />
          <p className="text-sm text-gray-700">
            {formatValue.propertyName(job.fsProperties)}
          </p>
        </span>
      </div>
    </Card>
  );
};

export default JobCard;
