import type { ReactElement } from "react";
import { cloneElement } from "react";
import type { DataGridProps } from "react-data-grid";
import * as XLSX from "xlsx";

export const exportToCsv = async <R, SR>(
  gridElement: ReactElement<DataGridProps<R, SR>>,
  fileName: string
): Promise<void> => {
  const { head, body, foot } = await getGridContent(gridElement);
  const content = [...head, ...body, ...foot]
    .map((cells) => cells.map(serialiseCellValue).join(","))
    .join("\n");

  downloadFile(
    fileName,
    new Blob([content], { type: "text/csv;charset=utf-8;" })
  );
};

export const exportToExcel = async <R, SR>(
  gridElement: ReactElement<DataGridProps<R, SR>>,
  fileName: string
): Promise<void> => {
  const { head, body, foot } = await getGridContent(gridElement);
  const data = [...head, ...body, ...foot].map((row) =>
    row.map((cell) => {
      const cleanedCell = cell.replace(/,/g, "");
      const num = parseFloat(cleanedCell);
      return !isNaN(num) ? num : cell;
    })
  );

  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet(data);
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  XLSX.writeFile(wb, `${fileName}.xlsx`);
};

const getGridContent = async <R, SR>(
  gridElement: ReactElement<DataGridProps<R, SR>>
) => {
  const { renderToStaticMarkup } = await import("react-dom/server");
  const grid = document.createElement("div");
  grid.innerHTML = renderToStaticMarkup(
    cloneElement(gridElement, {
      enableVirtualization: false,
    })
  );

  return {
    head: getRows(".rdg-header-row"),
    body: getRows(".rdg-row:not(.rdg-summary-row)"),
    foot: getRows(".rdg-summary-row"),
  };

  function getRows(selector: string) {
    return Array.from(grid.querySelectorAll<HTMLDivElement>(selector)).map(
      (gridRow) => {
        return Array.from(
          gridRow.querySelectorAll<HTMLDivElement>(".rdg-cell")
        ).map((gridCell) => gridCell.innerText);
      }
    );
  }
};

const serialiseCellValue = (value: unknown): string => {
  if (typeof value === "string") {
    const formattedValue = value.replace(/"/g, '""');
    return formattedValue.includes(",")
      ? `"${formattedValue}"`
      : formattedValue;
  }
  return String(value);
};

const downloadFile = (fileName: string, data: Blob): void => {
  const downloadLink = document.createElement("a");
  downloadLink.download = fileName;
  const url = URL.createObjectURL(data);
  downloadLink.href = url;
  downloadLink.click();
  URL.revokeObjectURL(url);
};
