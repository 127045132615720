import axios from "axios";

const whatsappToken =
  "EAAyIQFoRY1cBALaIlyL4C0aHNRKBxSg24lDz1SLKsCZCRDUN887l5EfA6WJUsYM3LgOIZBV2hy3ZA3i5308MZCAhZBFlyFBbKa0aMpdh1u5GM72TcxZClfZB6lf3nadDZA7ZBZCG3Vpq0JkfG5c6vaZCvXO2S4rx1euZAdZCl0eAUH6ln4RDSAF6h1hxs";
const phoneNumberId = "118883897867608";

interface Props {
  to: string;
  template: "visa_reminder";
  variables: string[];
}
export default async function sendWhatsappMessage(props: Props) {
  const requestURL = `https://graph.facebook.com/v16.0/${phoneNumberId}/messages?access_token=${whatsappToken}`;
  try {
    const messageResponse = await axios.post(
      requestURL,
      {
        messaging_product: "whatsapp",
        recipient_type: "individual",
        to: props.to,
        type: "template",
        template: {
          name: props.template,
          language: {
            code: "en",
            policy: "deterministic",
          },
          components: [
            {
              type: "body",
              parameters: props.variables.map((v) => ({
                type: "text",
                text: v,
              })),
            },
          ],
        },
      },
      {
        headers: {
          Authorization: `Bearer ${whatsappToken}`,
          "Content-Type": "application/json",
        },
      }
    );
    return messageResponse.data;
  } catch (error) {
    return error;
  }
}
