import * as React from "react";
import { Watermark } from "antd";

import { cn } from "@heffl/ui/lib/utils";

const Card = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    title?: string | React.ReactNode;
    description?: string;
    icon?: React.ReactNode;
    suffix?: React.ReactNode;
    parentClassName?: string;
    titleClassName?: string;
    hideBorder?: boolean;
    watermark?: string;
  }
>(
  (
    {
      className,
      children,
      title,
      description,
      icon,
      suffix,
      parentClassName,
      hideBorder = false,
      watermark,
      titleClassName,
      ...props
    },
    ref
  ) => {
    const Icon = icon
      ? React.cloneElement(icon as React.ReactElement, { size: 20 })
      : undefined;

    const hasHeader = title || description;
    return (
      <div
        ref={ref}
        className={cn(
          "rounded-lg border bg-card text-card-foreground shadow-sm py-3",
          parentClassName,
          hideBorder && "!border-none",
          hideBorder && "!shadow-none"
        )}
        {...props}
      >
        {hasHeader && (
          <CardHeader>
            {title && (
              <CardTitle
                className={cn(
                  "flex flex-row gap-2 justify-between pb-2 text-base border-b border-gray-200",
                  titleClassName
                )}
              >
                <div className="flex gap-2 items-center">
                  {Icon && Icon}
                  <div>{title}</div>
                </div>
                {suffix && <div>{suffix}</div>}
              </CardTitle>
            )}
            {description && <CardDescription>{description}</CardDescription>}
          </CardHeader>
        )}
        <CardContent className={cn(hasHeader ? "pt-3" : "", className)}>
          {watermark ? (
            <Watermark content={watermark} gap={[30, 30]}>
              {children}
            </Watermark>
          ) : (
            children
          )}
        </CardContent>
      </div>
    );
  }
);
Card.displayName = "Card";

const CardHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("flex flex-col space-y-1.5 px-4  pb-2", className)}
    {...props}
  />
));
CardHeader.displayName = "CardHeader";

const CardTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h3
    ref={ref}
    className={cn("text-lg font-medium tracking-tight leading-none", className)}
    {...props}
  />
));
CardTitle.displayName = "CardTitle";

const CardDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn("text-sm text-muted-foreground", className)}
    {...props}
  />
));
CardDescription.displayName = "CardDescription";

const CardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn("px-4", className)} {...props} />
));
CardContent.displayName = "CardContent";

export { Card };
