import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import Schemas from "@heffl/server/src/schemas";
import { Button } from "@heffl/ui/components/primitives/button";
import { Form } from "@heffl/ui/components/primitives/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Plus } from "lucide-react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import PurchaseOrderForm from "./purchase-order-form";

const PurchaseOrderAdd = () => {
  const navigate = useNavigate();

  const form = useForm<
    z.infer<typeof Schemas.purchases.purchaseOrder>,
    unknown
  >({
    resolver: zodResolver(Schemas.purchases.purchaseOrder),
    defaultValues: {
      date: new Date(),
      purchaseOrderItems: [
        {
          productId: undefined,
          quantity: 1,
          price: 0,
          description: "",
          tax: 5,
          viewType: "LINE_ITEM",
        },
      ],
    },
  });

  const purchaseOrderAddMutation =
    trpc.purchases.purchaseOrders.add.useMutation({
      onSuccess: (response) => {
        navigate(`/purchases/purchase-orders/details/${response.id}`);
      },
    });

  const onSubmit = (
    values: z.infer<typeof Schemas.purchases.purchaseOrder>
  ) => {
    purchaseOrderAddMutation.mutate(values);
  };

  console.log(form.formState.errors);

  return (
    <Page
      title="Add Purchase Order"
      className="max-w-screen-lg"
      showBack
      breadcrumbs={[
        { label: "Purchase Orders", path: "/purchases/purchase-orders" },
        { label: "Add", path: "/purchases/purchase-orders/add" },
      ]}
      suffix={
        <>
          <Button
            onClick={() => form.handleSubmit(onSubmit)()}
            loading={purchaseOrderAddMutation.isLoading}
            variant="primary"
            icon={Plus}
          >
            Add purchase order
          </Button>
        </>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <div className="flex justify-center px-3 py-5 w-full sm:px-0">
          <PurchaseOrderForm form={form} />
        </div>
      </Form>
    </Page>
  );
};

export default PurchaseOrderAdd;
