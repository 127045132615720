import Page from "@/components/page";
import validateCustomFields from "@/helpers/customFields/validateCustomFields";
import { trpc } from "@/helpers/trpc";
import useQueryParams from "@/helpers/useQuery";
import Schemas from "@heffl/server/src/schemas";
import { Form } from "@heffl/ui/components/primitives/form";
import ResponsivePrimaryButton from "@heffl/ui/components/ResponsivePrimaryButton";
import { zodResolver } from "@hookform/resolvers/zod";
import { Plus } from "lucide-react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import QuotationForm from "./components/quotation-form";

const AddQuotation = () => {
  const queryParams = useQueryParams();
  const navigate = useNavigate();

  const form = useForm<z.infer<typeof Schemas.sales.quotation>, unknown>({
    resolver: zodResolver(Schemas.sales.quotation),
    defaultValues: {
      date: new Date(),
      // expiryDate 30 days from now
      expiryDate: new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000),
      currencyId: 1,
      quotationProducts: [
        {
          productId: undefined,
          quantity: 1,
          price: 0,
          description: "",
          tax: 5,
          viewType: "LINE_ITEM",
        },
      ],
    },
  });

  const dealIdParams = queryParams.get("dealId");
  const quotationIdParams = queryParams.get("quotationId");

  const templateId = form.watch("templateId");

  const { data: duplicateQuotation } = trpc.quotations.details.useQuery(
    Number(quotationIdParams),
    {
      enabled: !!quotationIdParams,
      refetchOnWindowFocus: false,
    }
  );
  const { data: customFields } = trpc.customizations.customFields.list.useQuery(
    {
      section: "QUOTATION_LINE_ITEM",
      documentTemplateId: templateId,
    },
    {
      enabled: !!templateId,
    }
  );

  const quotationAddMutation = trpc.quotations.add.useMutation({
    onSuccess: (response) => {
      navigate(`/sales/quotations/details/${response.id}`);
    },
  });

  useEffect(() => {
    if (dealIdParams) {
      form.setValue("dealId", Number(dealIdParams));
    }
  }, [dealIdParams, form]);

  useEffect(() => {
    if (duplicateQuotation) {
      form.setValue("dealId", duplicateQuotation.dealId);
      form.setValue("contactId", duplicateQuotation.contactId);
      form.setValue("clientId", duplicateQuotation.clients.id);
      form.setValue("quotationProducts", duplicateQuotation.quotationProducts);
      form.setValue("discount", duplicateQuotation.discount);
      form.setValue("salesPersonId", duplicateQuotation.salesPersonId);
    }
  }, [duplicateQuotation]);

  const onSubmit = (values: z.infer<typeof Schemas.sales.quotation>) => {
    const isValid = values.quotationProducts.every((product, index) => {
      if (product.viewType === "LINE_ITEM") {
        return validateCustomFields({
          customFields,
          customFieldsValues: product.customFields,
          form,
          name: `quotationProducts.${index}`,
        });
      }
      return true;
    });
    if (!isValid) return;
    quotationAddMutation.mutate(values);
  };

  return (
    <Page
      title="Add Quotation"
      className="max-w-screen-lg"
      showBack
      breadcrumbs={[
        { label: "Quotations", path: "/sales/quotations" },
        { label: "Add", path: "/sales/quotations/add" },
      ]}
      suffix={
        <>
          <ResponsivePrimaryButton
            onClick={() => form.handleSubmit(onSubmit)()}
            loading={quotationAddMutation.isLoading}
            variant="primary"
            icon={Plus}
          >
            Add quotation
          </ResponsivePrimaryButton>
        </>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <div className="flex justify-center px-3 py-5 w-full sm:px-0">
          <QuotationForm form={form} />
        </div>
      </Form>
    </Page>
  );
};

export default AddQuotation;
