const ComingSoon = () => {
  return (
    <div className="flex min-h-[100dvh] w-full flex-col items-center justify-center bg-gray-100 px-4 py-12 dark:bg-gray-800">
      <div className="mx-auto space-y-4 max-w-md text-center">
        <h1 className="text-4xl font-bold tracking-tighter sm:text-5xl">
          Not found!
        </h1>
        <p className="text-gray-500 dark:text-gray-400">
          We are working hard to bring you this exciting new feature. Stay tuned
          for updates!
        </p>
        <a
          className="inline-flex justify-center items-center px-6 h-10 text-sm font-medium text-gray-50 rounded-md shadow transition-colors bg-primary hover:bg-gray-900/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 dark:bg-gray-50 dark:text-gray-900 dark:hover:bg-gray-50/90 dark:focus-visible:ring-gray-300"
          href="/"
        >
          Go Back Home
        </a>
      </div>
    </div>
  );
};
export default ComingSoon;
