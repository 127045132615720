/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from "@heffl/ui/components/primitives/button";
import { Card } from "@heffl/ui/components/primitives/card";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FormList from "@heffl/ui/components/primitives/form-list";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import {
  DndContext,
  MouseSensor,
  TouchSensor,
  rectIntersection,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import Schemas from "@heffl/server/src/schemas";
import { zodResolver } from "@hookform/resolvers/zod";
import { PlusCircle, Save, Tally2, Trash2, TrashIcon } from "lucide-react";
import { nanoid } from "nanoid";
import { useEffect, useRef } from "react";
import { UseFieldArrayRemove, UseFormReturn, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import Select from "@heffl/ui/components/primitives/creatable-select";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";

export const pipelineSchema = z.object({
  name: z.string(),
  dealPipelineStages: Schemas.crm.dealPiplineStage
    .extend({
      id: z.union([z.string(), z.number()]),
    })
    .array(),
});

// Horizontal sortable board
export const DraggableBoard = ({
  _name,
  index,
  form,
  remove,
}: {
  remove: UseFieldArrayRemove;
  index: number;
  _name: (index: number, fieldName: string) => string;
  form: UseFormReturn<z.infer<typeof pipelineSchema>, unknown>;
}) => {
  // @ts-ignore
  const stageId = form.watch(_name(index, "id"));
  const newDealStages = form.watch("dealPipelineStages");
  const { attributes, listeners, setNodeRef, transform, transition, isOver } =
    useSortable({ id: String(stageId) });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  // @ts-ignore
  const boardName: string = form.watch(_name(index, "name")) as string;

  const ref = useRef<HTMLDivElement>(null);

  return (
    <div
      className={`min-w-[250px] flex flex-col px-4 rounded-lg py-3 bg-gray-100 relative h-fit ${
        isOver && "bg-gray-200"
      }`}
      ref={(node) => {
        setNodeRef(node);
        //@ts-ignore
        ref.current = node;
      }}
      style={style}
      {...attributes}
      {...listeners}
    >
      <div className="flex flex-row justify-between px-1 py-3 border-b border-gray-300">
        <p className="text-sm font-semibold">{boardName}</p>
        {/* header */}
        <div className="flex flex-row">
          <Tally2 className="h-4" />
        </div>
        {/* header */}
      </div>
      <div className="flex flex-col gap-3 py-4">
        <FormField name={_name(index, "name")} label="Name">
          <Input />
        </FormField>
        <FormField name={_name(index, "probability")} label="Probability">
          <Input />
        </FormField>
        <FormField name={_name(index, "rottingInDays")} label="Rotting in days">
          <Input />
        </FormField>
        <FormField name={_name(index, "stageType")} label="Stage type">
          <Select
            options={["ACTIVE", "WON", "LOST"].map((stageType) => ({
              label: stageType,
              value: stageType,
            }))}
            hideSearch
          />
        </FormField>
        <Button
          onClick={() => remove(index)}
          variant="outline"
          disabled={newDealStages.length < 2}
        >
          <TrashIcon className="mr-2 w-4" />
          Delete stage
        </Button>
      </div>
    </div>
  );
};

const PipelineEdit = () => {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const boardContainerRef = useRef<HTMLDivElement>(null);
  const params = useParams();
  const pipelineId = Number(params.pipelineId);
  const form = useForm<z.infer<typeof pipelineSchema>>({
    resolver: zodResolver(pipelineSchema.partial()),
  });
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });
  const touchSensor = useSensor(TouchSensor);
  const sensors = useSensors(mouseSensor, touchSensor);
  const trpcUtils = trpc.useUtils();

  const dealStagesNew = form.watch("dealPipelineStages");

  const { data: pipelineDetails } = trpc.deals.pipelines.details.useQuery({
    id: pipelineId,
  });
  const deletePipelineMutation = trpc.deals.pipelines.delete.useMutation({
    onSuccess: () => {
      toast.success("Pipeline deleted");
      navigate("/crm/deals/board");
    },
    onError() {
      toast.error(
        "Please clear all deals in this pipeline before deleting it."
      );
    },
  });

  useEffect(() => {
    if (pipelineDetails) !form.getValues("name") && form.reset(pipelineDetails);
  }, [pipelineDetails, form]);

  const updatePiplelineMutation = trpc.deals.pipelines.update.useMutation({
    onSuccess: () => {
      toast.success("Pipeline updated");
      if (navigate.length > 1) {
        navigate(-1);
      } else {
        navigate("/crm/deals/board");
      }
    },
    onError(error) {
      toast.error(error.message);
      form.reset();
      trpcUtils.deals.pipelines.details.invalidate();
    },
  });

  const handleDragEnd = (event: any) => {
    if (!pipelineDetails) return;
    const { active, over } = event;
    if (!over) return;
    if (String(active.id) !== String(over.id)) {
      const oldPipelines = form.getValues().dealPipelineStages;
      if (!oldPipelines) return;
      const oldIndex = oldPipelines.findIndex(
        (item) => String(item.id) === String(active.id)
      );
      const newIndex = oldPipelines.findIndex(
        (item) => String(item.id) === String(over.id)
      );
      const updatedPipelins = arrayMove(oldPipelines, oldIndex, newIndex);
      form.setValue("dealPipelineStages", updatedPipelins);
    }
  };

  if (!pipelineDetails || !dealStagesNew) return <FullScreenSpinner />;
  return (
    <Page
      title="Edit pipeline"
      showBack
      fullWidth
      description="Add and rearrange stages"
      suffix={
        <div className="flex gap-2">
          <Button
            icon={Trash2}
            variant="destructiveOutline"
            onClick={async () => {
              const confirmed = await confirm({
                title: "Are you sure you want to delete this pipeline?",
                body: "This action cannot be undone, and data will be lost.",
              });
              if (confirmed) {
                deletePipelineMutation.mutate(pipelineId);
              }
            }}
          >
            Delete
          </Button>
          <Button
            icon={Save}
            onClick={() =>
              form.handleSubmit((values) =>
                updatePiplelineMutation.mutate({
                  id: pipelineId,
                  pipeline: {
                    ...values,
                    dealPipelineStages: values.dealPipelineStages.map(
                      (stage, index) => ({
                        ...stage,
                        position: index + 1,
                        id: isNaN(stage.id as any) ? 0 : Number(stage.id),
                      })
                    ),
                  },
                })
              )()
            }
            variant="primary"
            loading={updatePiplelineMutation.isLoading}
          >
            Save changes
          </Button>
        </div>
      }
    >
      <Form
        {...form}
        onSubmit={(values) => {
          updatePiplelineMutation.mutate({
            id: pipelineId,
            pipeline: {
              ...values,
              dealPipelineStages: values.dealPipelineStages.map(
                (stage, index) => ({
                  ...stage,
                  position: index + 1,
                  id: isNaN(stage.id as any) ? 0 : Number(stage.id),
                })
              ),
            },
          });
        }}
      >
        <div className="w-full">
          {/* Header actions */}
          <div className="flex flex-col gap-3 justify-between sm:flex-row">
            <div className="flex flex-row gap-2">
              <FormField
                name="name"
                label="Pipeline name"
                className="space-y-0 w-64"
              >
                <Input className="mt-2" placeholder="Sales pipeline.." />
              </FormField>
            </div>
          </div>
          <div className="flex gap-2">
            <Card
              parentClassName="h-[365px] rounded-md w-[250px] mt-7 text-center"
              className="flex flex-col gap-2 justify-center items-center h-full"
            >
              <p className="text-xl font-medium">Add new stage</p>
              <p className="font-light tet-gray-600">
                Add stages and arrange them in the order you want.
              </p>
              <Button
                className="mt-4"
                size="md"
                onClick={() => {
                  const currentStages = form.getValues("dealPipelineStages");
                  form.setValue("dealPipelineStages", [
                    ...currentStages,
                    {
                      id: nanoid(),
                      name: "New stage",
                      stageType: "ACTIVE",
                      probability: 0,
                      rottingInDays: 0,
                      position: 0,
                    },
                  ]);
                  setTimeout(() => {
                    if (boardContainerRef.current) {
                      boardContainerRef.current.scrollTo({
                        left: boardContainerRef.current.scrollWidth,
                        behavior: "smooth",
                      });
                    }
                  }, 100);
                }}
                variant="outline"
              >
                <PlusCircle className="h-5" /> Add new stage
              </Button>
            </Card>

            <DndContext
              sensors={sensors}
              onDragEnd={handleDragEnd}
              collisionDetection={rectIntersection}
            >
              <SortableContext
                items={dealStagesNew.map((s) => String(s.id))}
                strategy={horizontalListSortingStrategy}
              >
                <FormList name="dealPipelineStages">
                  {({ fields, _name, remove }) => {
                    return (
                      <div
                        ref={boardContainerRef}
                        className="flex overflow-x-auto flex-row gap-4 p-1 py-3 mt-4 w-full rounded-lg"
                      >
                        {fields.map((field, index) => (
                          <DraggableBoard
                            key={field.id}
                            index={index}
                            _name={_name}
                            form={form}
                            remove={remove}
                          />
                        ))}
                      </div>
                    );
                  }}
                </FormList>
              </SortableContext>
            </DndContext>
          </div>
        </div>
      </Form>
    </Page>
  );
};

export default PipelineEdit;
