import { Button } from "@heffl/ui/components/primitives/button";
import { Checkbox } from "@heffl/ui/components/primitives/checkbox";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Separator } from "@heffl/ui/components/primitives/separator";
import {
  Tabs,
  TabsList,
  TabsTrigger,
} from "@heffl/ui/components/primitives/tabs";
import { trpc } from "@/helpers/trpc";
import FullCalendar from "@fullcalendar/react";
import dayjs from "dayjs";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { Suspense, lazy, useEffect, useRef, useState } from "react";
const FullCalendarWrapper = lazy(() => import("./full-calendar"));

type View =
  | "dayGridMonth"
  | "timeGridWeek"
  | "timeGridDay"
  | "listMonth"
  | "listDay"
  | "listWeek";

const viewsArray: {
  key: View;
  label: string;
}[] = [
  { key: "dayGridMonth", label: "Month" },
  { key: "timeGridWeek", label: "Week" },
  { key: "timeGridDay", label: "Day" },
  { key: "listMonth", label: "List" },
];

type CalendarProps = {
  categoryId?: string[];
  onClickEvent?: (event: Event) => void;
};

const viewMap = {
  dayGridMonth: "month" as const,
  timeGridWeek: "week" as const,
  timeGridDay: "day" as const,
  listMonth: "month" as const,
  listDay: "day" as const,
  listWeek: "week" as const,
};
export const Calendar: React.FC<CalendarProps> = () => {
  const calendarRef = useRef<FullCalendar>(null);

  const [calendarView, setCalendarView] = useState<View>("dayGridMonth");
  const [title, setTitle] = useState(calendarRef.current?.getApi().view.title);

  const { data: events } = trpc.calendar.list.useQuery();

  const updateCrmActivity = trpc.activities.update.useMutation({
    onSuccess() {
      console.log("Activity updated");
    },
  });

  useEffect(() => {
    calendarRef.current?.getApi().changeView(calendarView);
  }, [calendarView]);

  if (!events) return <FullScreenSpinner />;

  return (
    <div className="relative p-4 min-h-screen">
      <div className="flex gap-2 h-full">
        <div
          className="hidden flex-col gap-3 p-0 w-2/12 border-r border-gray-200 sm:flex"
          title="Upcoming events"
        >
          <Tabs defaultValue={viewsArray[0].key}>
            <TabsList>
              {viewsArray.map((view) => (
                <TabsTrigger
                  key={view.key}
                  value={view.key}
                  onClick={() => setCalendarView(view.key)}
                >
                  {view.label}
                </TabsTrigger>
              ))}
            </TabsList>
          </Tabs>
          <Separator className="w-full" />
          <p className="text-lg font-medium text-gray-800">Filters</p>
          {/* Check boxes to select events */}
          <div className="flex flex-col gap-4">
            <div className="flex flex-row gap-2">
              <Checkbox className="w-5 h-5 rounded-sm" />
              <p className="text-sm">🎯 Todo</p>
            </div>
            <div className="flex flex-row gap-2">
              <Checkbox className="w-5 h-5 rounded-sm" />
              <p className="text-sm">📞 Calls</p>
            </div>
            <div className="flex flex-row gap-2">
              <Checkbox className="w-5 h-5 rounded-sm" />
              <p className="text-sm">✉️ Email</p>
            </div>
            <div className="flex flex-row gap-2">
              <Checkbox className="w-5 h-5 rounded-sm" />
              <p className="text-sm">🤝 Meetings</p>
            </div>
            <div className="flex flex-row gap-2">
              <Checkbox className="w-5 h-5 rounded-sm" />
              <p className="text-sm">📒 Notes</p>
            </div>
            <div className="flex flex-row gap-2">
              <Checkbox className="w-5 h-5 rounded-sm" />
              <p className="text-sm">🗓️ Schedules</p>
            </div>
          </div>
          <Separator className="w-full" />
        </div>
        <div className="sm:w-10/12 w-full p-4 !h-full">
          <div className="flex justify-between items-center w-full">
            <p className="text-3xl">
              <span className="font-bold"> {title?.split(" ")[0]}</span>{" "}
              {title?.split(" ")[1]}
            </p>
            <div className="flex gap-2">
              <Button
                onClick={() => calendarRef.current?.getApi().prev()}
                size="sm"
                variant="outline"
                className="p-0 w-7"
              >
                <ChevronLeft className="h-4" />
              </Button>
              <Button
                onClick={() => calendarRef.current?.getApi().today()}
                size="sm"
                variant={
                  dayjs(calendarRef.current?.getApi().getDate()).isSame(
                    dayjs(),
                    viewMap[calendarView]
                  )
                    ? "primaryOutline"
                    : "outline"
                }
              >
                Today
              </Button>
              <Button
                onClick={() => calendarRef.current?.getApi().next()}
                className="p-0 w-7"
                size="sm"
                variant="outline"
              >
                <ChevronRight className="h-4" />
              </Button>
            </div>
          </div>
          <div className="relative mt-2 h-screen">
            <Suspense fallback={<div>Loading...</div>}>
              <FullCalendarWrapper
                calendarRef={calendarRef}
                events={events.map((event) => ({
                  id: event.id.toString(),
                  title: event.title,
                  start: event.startDate,
                  end: event.endDate,
                  allDay: false,
                }))}
                onClickEvent={() => {
                  console.log("clicked");
                }}
                setTitle={setTitle}
                eventChange={(event) => {
                  const activityId = event.event.id;
                  const updatedDate = event.event.start;
                  const updatedEndDate = event.event.end;
                  if (activityId && updatedDate) {
                    updateCrmActivity.mutate({
                      id: Number(activityId),
                      activity: {
                        startDate: dayjs(updatedDate).toDate(),
                        endDate: updatedEndDate
                          ? {
                              date: dayjs(updatedEndDate).toDate(),
                              dateType: "CUSTOM_DATE",
                            }
                          : undefined,
                      },
                    });
                  }
                }}
              />
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  );
};
