"use client";

import { format } from "date-fns";
import { Calendar as CalendarIcon, XCircle } from "lucide-react";

import { Button } from "@heffl/ui/components/primitives/button";
import { Calendar } from "@heffl/ui/components/primitives/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@heffl/ui/components/primitives/popover";
import { cn } from "@heffl/ui/lib/utils";
import { useState } from "react";

type TProps = {
  disabled?: boolean;
  displayFormat?: string;
  value?: Date;
  onChange?: (v: Date | undefined) => void;
  presets?: { label: string; value: Date }[];
  presetsPosition?: "top" | "bottom";
  allowClear?: boolean;
  className?: string;
  placeholder?: string;
  fromDate?: Date;
  toDate?: Date;
};

const Presets = ({
  presets,
  onClick,
}: {
  presets: { label: string; value: Date }[];
  onClick: (preset: { label: string; value: Date }) => void;
}) => {
  return (
    <div className="flex flex-wrap gap-2 p-2 bg-green-50">
      {presets.map((preset) => (
        <div
          key={preset.label}
          className="px-2 py-1 text-xs bg-white rounded-sm border shadow-sm cursor-pointer hover:bg-gray-50"
          onClick={() => onClick(preset)}
        >
          {preset.label}
        </div>
      ))}
    </div>
  );
};

export const DatePicker = ({
  value,
  onChange = () => {},
  displayFormat = "dd/MM/yyyy",
  presets = [],
  presetsPosition = "bottom",
  allowClear = false,
  disabled = false,
  className = "",
  placeholder = "Pick a date",
  fromDate,
  toDate,
}: TProps) => {
  const [showCalendar, setShowCalendar] = useState(false);

  return (
    <Popover open={showCalendar} onOpenChange={setShowCalendar}>
      <PopoverTrigger asChild>
        <Button
          onClick={() => setShowCalendar(true)}
          variant={"outline"}
          className={cn(
            "relative justify-start px-2 w-full font-normal text-left",
            className
          )}
          disabled={disabled}
          size="md"
        >
          <CalendarIcon className="mr-2 w-4 h-4" />
          {value ? (
            format(value, displayFormat)
          ) : (
            <span className="font-normal text-gray-500">{placeholder}</span>
          )}
          {allowClear && value && (
            <XCircle
              onClick={(e) => {
                onChange(undefined);
                setShowCalendar(false);
                e.stopPropagation();
              }}
              className="absolute right-2 h-4 text-gray-400 cursor-pointer"
            />
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0 w-auto">
        {!!presets.length && presetsPosition === "top" && (
          <Presets
            presets={presets}
            onClick={(preset) => {
              onChange(preset.value);
              setShowCalendar(false);
            }}
          />
        )}
        <Calendar
          defaultMonth={value}
          mode="single"
          selected={value}
          fromDate={fromDate}
          toDate={toDate}
          onSelect={(value) => {
            onChange(value);
            setShowCalendar(false);
          }}
          initialFocus
        />
        {!!presets.length && presetsPosition === "bottom" && (
          <Presets
            presets={presets}
            onClick={(preset) => {
              onChange(preset.value);
              setShowCalendar(false);
            }}
          />
        )}
      </PopoverContent>
    </Popover>
  );
};
