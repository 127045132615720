import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@heffl/ui/components/primitives/dialog";
import { cn } from "@heffl/ui/lib/utils";

const Modal = ({
  children,
  title,
  description,
  open,
  onClose,
  className,
  footer,
  closeOnOverlayClick = false,
}: {
  closeOnOverlayClick?: boolean;
  children: React.ReactNode;
  title?: string | React.ReactElement;
  description?: string;
  open: boolean;
  onClose: (v: boolean) => void;
  className?: string;
  footer?: React.ReactNode;
}) => {
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent
        className={cn("max-h-[90%]  overflow-hidden px-0 ", className)}
        onInteractOutside={(e) => {
          !closeOnOverlayClick && e.preventDefault();
        }}
      >
        <DialogHeader className="px-4">
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>{description}</DialogDescription>
        </DialogHeader>
        <div className="overflow-y-auto flex-1 px-4 py-1">{children}</div>
        {footer && <DialogFooter className="px-4">{footer}</DialogFooter>}
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
