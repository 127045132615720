import Calendar from "@event-calendar/core";
import "@event-calendar/core/index.css";
import Interaction from "@event-calendar/interaction";
import Resource from "@event-calendar/resource-time-grid";
import TimeGrid from "@event-calendar/time-grid";
import { cn, makeEllipsis } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import { styled } from "goober";
import React, { useEffect, useRef } from "react";
import { renderToString } from "react-dom/server";
import { ScheduleEvent } from "../schedules/calendar";

type DayEventCalendarProps = {
  view?:
    | "timeGridDay"
    | "timeGridWeek"
    | "resourceTimeGridDay"
    | "resourceTimeGridWeek";
  resources?: {
    id: string;
    title: string;
  }[];
  events: {
    id: number;
    resourceIds?: string[];
    start: Date | undefined;
    end: Date | undefined;
    title: string;
    allDay: boolean;
    backgroundColor?: string;
  }[];
  date: Date;
  height?: string;
  allDayContent?: React.ReactNode;
  className?: string;
  hiddenDays?: number[];
  onEventClick?: (event: ScheduleEvent) => void;
  onEventUpdate?: (event: ScheduleEvent) => void;
  onEventCreate?: (event: ScheduleEvent) => void;
};

const CalendarWrapper = styled("div")`
  background-color: white;
  .ec-toolbar {
    display: none;
  }
  .ec-body {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .ec-header {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
`;

const DayEventCalendar: React.FC<DayEventCalendarProps> = ({
  events,
  date,
  allDayContent,
  onEventClick,
  onEventUpdate,
  view = "timeGridDay",
  resources = [],
  onEventCreate,
  hiddenDays = [],
  className,
  height = "100%",
}) => {
  const calendarRef = useRef<typeof Calendar | null>(null);

  useEffect(() => {
    const ecElement = document.getElementById("day-event-calendar");
    if (ecElement && !calendarRef.current) {
      calendarRef.current = new Calendar({
        target: ecElement,
        props: {
          plugins: [TimeGrid, Interaction, Resource],
          options: {
            eventContent: ({ event }: { event: ScheduleEvent }) => {
              return {
                html: renderToString(
                  <div
                    className={cn("pl-1 text-xs")}
                    style={{
                      background: event.backgroundColor,
                    }}
                  >
                    {makeEllipsis(event.title, 30)} -{" "}
                    <b>{dayjs(event.start).format("hh:mm A")}</b>
                  </div>
                ),
              };
            },
            dayHeaderFormat: (date: Date) => dayjs(date).format("dddd"),
            slotEventOverlap: false,
            backgroundColor: "white",
            datesAboveResources: true,
            headerToolbar: {
              start: "",
              center: "",
              end: "",
            },
            height: height,
            allDaySlot: true,
            allDayContent: allDayContent
              ? () => ({ html: renderToString(allDayContent) })
              : undefined,
            date,
            selectable: true,
            nowIndicator: true,
            view,
            views: {
              timeGridWeek: {
                hiddenDays: hiddenDays,
              },
            },
            events,
            resources,
            eventStartEditable: true,
            eventClick: function ({ event }: { event: ScheduleEvent }) {
              onEventClick?.(event);
            },
            eventResize: function ({ event }: { event: ScheduleEvent }) {
              onEventUpdate?.(event);
            },
            eventDrop: function ({ event }: { event: ScheduleEvent }) {
              onEventUpdate?.(event);
            },
            select: function (event: ScheduleEvent) {
              onEventCreate?.(event);
            },
          },
        },
      });
    } else if (calendarRef.current) {
      calendarRef.current.setOption("date", date);
      calendarRef.current.setOption("events", events);
      calendarRef.current.setOption("resources", resources);
      calendarRef.current.setOption("view", view);
    }
  }, [date, events, resources, view]);

  return <CalendarWrapper id="day-event-calendar" className={className} />;
};

export default DayEventCalendar;
