import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import { capitalize } from "@heffl/ui/lib/utils";
import {
  PermissionItem,
  Permissions,
} from "@heffl/server/src/helpers/permissions/permissionItems";
import { Card } from "@heffl/ui/components/primitives/card";
import { Checkbox } from "@heffl/ui/components/primitives/checkbox";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

const PermissionCheckbox = ({
  name,
  label,
  allowed,
  onClick,
}: {
  name: string;
  label: string;
  allowed: boolean;
  onClick?: () => void;
}) => {
  return (
    <div className="flex gap-2 items-center">
      <Checkbox id={name} checked={allowed} onChange={onClick} />
      <label className="text-sm font-medium">{label}</label>
    </div>
  );
};

const PermissionSetEdit = () => {
  const params = useParams();
  const permissionSetId = Number(params.id);

  const { data: permissionSet } =
    trpc.permissionSets.details.useQuery(permissionSetId);

  const updatePermissionSetMutation =
    trpc.permissionSets.updatePermission.useMutation({
      onSuccess() {
        toast.success("Successfully updated permission set");
      },
    });

  if (!permissionSet) {
    return <FullScreenSpinner />;
  }

  const permissionsArray = Object.keys(permissionSet.permissionsSetItems).map(
    (app) => ({
      appName: app as keyof Permissions,
      sections: Object.keys(
        permissionSet.permissionsSetItems[app as keyof Permissions]
      ).map((section) => ({
        sectionName: section as keyof Permissions[keyof Permissions],
        permissions: permissionSet.permissionsSetItems[
          app as keyof Permissions
        ][section as keyof Permissions[keyof Permissions]] as PermissionItem[],
      })),
    })
  );

  return (
    <Page
      title="Edit permission set"
      showBack
      breadcrumbs={[
        { label: "Permission sets", path: "/employees/permission-sets" },
        {
          label: "Edit permission set",
        },
      ]}
    >
      <div className="flex flex-col gap-4">
        {permissionsArray
          .filter((permission) => permission.sections.length > 0)
          .map((permission) => (
            <div key={permission.appName as string}>
              <p className="text-lg font-medium">
                {/* @ts-ignore */}
                {capitalize(permission.appName.split("_").join(" "))}
              </p>
              <div className="grid grid-cols-4 gap-4">
                {permission.sections
                  .filter((section) => section.permissions.length > 0)
                  .map((section) => (
                    <Card
                      key={section.sectionName}
                      title={capitalize(
                        // @ts-ignore
                        section.sectionName.split("_").join(" ")
                      )}
                    >
                      <div className="flex flex-col gap-2 pt-1">
                        {section.permissions.map((permission) => (
                          <PermissionCheckbox
                            name={permission.name}
                            label={permission.label}
                            allowed={permission.allowed}
                            onClick={() =>
                              updatePermissionSetMutation.mutate({
                                permissionSetId: permissionSet.id,
                                permissionName: permission.name,
                                allowed: !permission.allowed,
                              })
                            }
                            key={permission.name}
                          />
                        ))}
                      </div>
                    </Card>
                  ))}
              </div>
            </div>
          ))}
      </div>
    </Page>
  );
};

export default PermissionSetEdit;
