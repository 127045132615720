import { useState, useEffect } from "react";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  ZoomControl,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { trpc } from "@/helpers/trpc";
import { Card } from "@heffl/ui/components/primitives/card";
import { Badge } from "@heffl/ui/components/primitives/badge";
import {
  Calendar,
  CircleDollarSign,
  Filter,
  Globe,
  HeartHandshake,
  Lightbulb,
  Phone,
  Search,
  Wand2,
} from "lucide-react";
import { Button } from "@heffl/ui/components/primitives/button";
import { Input } from "@heffl/ui/components/primitives/input";

const LeadDiscovery = () => {
  const [location, setLocation] = useState<{
    label: string;
    value: { place_id: string };
  } | null>(null);
  const [markerPosition, setMarkerPosition] = useState<[number, number] | null>(
    null
  );

  const { data: clients } = trpc.clients.list.useQuery({
    pageNo: 1,
    pageSize: 100,
  });

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;
      setLocation({
        label: "Current Location",
        value: { place_id: `${latitude},${longitude}` },
      });
      setMarkerPosition([latitude, longitude]);
    });
  }, []);

  const handleSelect = async (
    value: {
      label: string;
      value: { place_id: string };
    } | null
  ) => {
    if (!value) return; // handle null value
    const results = await geocodeByPlaceId(value.value.place_id);
    const latLng = results[0].geometry.location;
    setLocation(value);
    setMarkerPosition([latLng.lat(), latLng.lng()]);
  };

  return (
    <div className="relative">
      <div className="absolute top-1 left-1 w-[300px] h-full  z-[2000] border-r border-gray-200 shadow-lg bg-gray-50 p-4 flex flex-col gap-3 overflow-y-scroll">
        <div className="flex sticky top-0 flex-col gap-2 pb-3 w-full bg-gray-50">
          <div className="flex flex-col gap-1">
            <p className="text-sm font-semibold">Location</p>
            <GooglePlacesAutocomplete
              apiKey="AIzaSyCQdBepgPBP4-a66aYLTR65TsYSkFN-KHs"
              selectProps={{
                value: location,
                onChange: handleSelect,
              }}
              apiOptions={{
                language: "en",
                region: "ae",
              }}
            />
          </div>
          <Button>
            <Wand2 className="h-4" />
            Get new Leads
          </Button>

          <Button variant={"outline"}>
            <Lightbulb className="h-4" />
            Market insights
          </Button>
          <Button variant={"outline"}>
            <Search className="h-4" />
            Competitor analysis
          </Button>
          <Card>
            <div className="flex flex-col gap-2">
              <p className="text-sm font-medium">Experimental Features</p>
              <p className="text-xs text-gray-400">
                These features are currently in the experimental stage. Click
                below to learn more about how to use them.
              </p>
              <Button size={"xs"} variant={"outline"} className="w-fit">
                Learn How to Use
              </Button>
            </div>
          </Card>
        </div>
        {clients ? (
          <>
            <div className="flex flex-row justify-between items-center mt-4">
              <p className="text-sm font-semibold">Companies</p>
              <Button size={"sm"} variant={"outline"}>
                <Filter className="h-3" />
                Filter
              </Button>
            </div>
            <Input className="mt-2" placeholder="Search company" />
            <div className="flex flex-col gap-2 !h-[600px] mt-3">
              {clients.clients?.map((company) => (
                <Card
                  key={company.id}
                  className="pb-0 cursor-pointer hover:bg-blue-50"
                >
                  <div className="flex flex-col gap-1">
                    <div className="flex flex-row justify-between">
                      <p className="text-sm font-semibold">{company.name}</p>
                      <p className="text-xs font-medium text-red-500">
                        10km distance
                      </p>
                    </div>
                    <div className="flex flex-row gap-1">
                      <Badge
                        className="font-medium text-violet-700 bg-violet-100"
                        icon={Globe}
                      >
                        {company.website || ""}
                      </Badge>
                    </div>
                    <div className="grid gap-1">
                      {company.contacts.map((contact) => (
                        <div
                          className="flex flex-row items-center"
                          key={contact.id}
                        >
                          <Phone className="h-3" />
                          <p className="text-sm">{contact.jobTitle}</p>
                        </div>
                      ))}
                    </div>
                    <div className="flex flex-row gap-1">
                      <div className="flex items-center px-3 py-1 text-green-600 bg-green-100 rounded-lg w-fit">
                        <HeartHandshake className="h-3" />
                        <p className="text-xs font-medium">
                          {company.deals?.length} deals
                        </p>
                      </div>
                      <div className="flex items-center px-3 py-1 text-blue-600 bg-blue-100 rounded-lg w-fit">
                        <CircleDollarSign className="h-3" />
                        <p className="text-xs font-medium">
                          {company.deals?.reduce(
                            (total, deal) => total + (deal.price || 0),
                            0
                          )}{" "}
                          AED
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-row gap-1 pt-3 border-t border-gray-200">
                      <Button size={"sm"} variant={"secondary"}>
                        <Phone className="h-3" />
                      </Button>
                      <Button size={"sm"} variant={"secondary"}>
                        <Calendar className="h-3" />
                        Schedule visit
                      </Button>
                    </div>
                  </div>
                </Card>
              ))}
            </div>
          </>
        ) : null}
      </div>
      {markerPosition && (
        <MapContainer
          preferCanvas
          center={markerPosition}
          zoom={13}
          scrollWheelZoom={true}
          touchZoom={true}
          style={{ height: "calc(100vh - 100px)", width: "100%" }}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <Marker position={markerPosition}>
            <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup>
          </Marker>
          <ZoomControl position="bottomright" />
        </MapContainer>
      )}
    </div>
  );
};

export default LeadDiscovery;
