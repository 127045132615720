import { RouterOutputs, trpc } from "@/helpers/trpc";

import { AvatarsGroup } from "@/components/UserAvatar";
import DataGrid from "@/components/dataGrid/DataGrid";
import {
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
} from "@heffl/ui/components/primitives/popover";
import { cn } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getProjectTaskStatus,
  taskStatuses,
} from "../details/components/kanban";
import { ProjectTaskDetailsModal } from "./components/project-task-modals";

const ProjectTaskList = ({
  tasks,
}: {
  tasks: RouterOutputs["projects"]["tasks"]["list"];
}) => {
  const navigate = useNavigate();

  const [editTaskId, setEditTaskId] = useState<number | undefined>();

  const taskUpdateMutation = trpc.projects.tasks.update.useMutation();

  return (
    <>
      {editTaskId && (
        <ProjectTaskDetailsModal
          open={!!editTaskId}
          onClose={() => setEditTaskId(undefined)}
          taskId={editTaskId}
        />
      )}

      <DataGrid
        rowKey="id"
        className="h-[calc(100vh-111px-var(--header-height))]"
        name="tasksListMain"
        label="Tasks"
        rows={tasks}
        columns={[
          { key: "number", name: "Id", width: 80 },
          {
            key: "title",
            name: "Subject",
            width: 300,
            renderCell: ({ row }) => (
              <div
                onClick={() => setEditTaskId(row.id)}
                className="truncate rounded-md cursor-pointer hover:text-primary-600"
              >
                {row.title}
              </div>
            ),
          },
          {
            key: "client",
            name: "Client",
            width: 120,
            renderCell(props) {
              return (
                <div
                  onClick={() =>
                    navigate(
                      `/crm/clients/details/${props.row.projects.clients?.id}`
                    )
                  }
                  className="truncate cursor-pointer hover:text-primary-600 hover:underline"
                >
                  {props.row.projects.clients?.name}
                </div>
              );
            },
          },
          {
            key: "project",
            name: "Project",
            width: 120,
            renderCell(props) {
              return (
                <div
                  onClick={() =>
                    navigate(`/projects/details/${props.row.projectId}`)
                  }
                  className="truncate cursor-pointer hover:text-primary-600 hover:underline"
                >
                  {props.row.projects.title}
                </div>
              );
            },
          },
          {
            key: "status",
            name: "Status",
            width: 140,
            editable: true,
            cellClass: "px-0",
            renderCell: ({ row: task }) => (
              <Popover>
                <PopoverTrigger className="w-full h-full">
                  <div
                    className={cn(
                      "w-full h-full flex items-center justify-center text-white font-medium",
                      getProjectTaskStatus(task.status).color
                    )}
                  >
                    {getProjectTaskStatus(task.status).label}
                  </div>
                </PopoverTrigger>
                <PopoverContent className="space-y-2 w-56" sideOffset={0}>
                  <PopoverArrow className="fill-white" />
                  {taskStatuses.map((status) => (
                    <div
                      key={status.value}
                      onClick={() => {
                        taskUpdateMutation.mutate({
                          id: task.id,
                          task: {
                            status: status.value,
                          },
                        });
                      }}
                      className={cn(
                        "p-2 cursor-pointer w-full flex items-center justify-center rounded-sm text-white font-medium",
                        status.color
                      )}
                      style={{
                        backgroundColor: status.hex,
                      }}
                    >
                      {status.label}
                    </div>
                  ))}
                </PopoverContent>
              </Popover>
            ),
          },
          {
            key: "assignee",
            name: "Assignee",
            width: 120,
            renderCell: ({ row }) => (
              <AvatarsGroup
                size="xs"
                users={row.projectTaskAssignees.map(
                  (assignee) => assignee.users
                )}
              />
            ),
          },
          {
            key: "dueDate",
            name: "Due Date",
            width: 120,
            renderCell: ({ row }) => (
              <div>{row.date ? dayjs(row.date).format("DD MMM YYYY") : ""}</div>
            ),
          },
        ]}
      />
    </>
  );
};

export default ProjectTaskList;
