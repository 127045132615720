import { z } from "zod";
import { reminder, selectableDatePicker } from "./crm";
import enums from "./enums";
import { filesInput } from "./files";

const project = z.object({
  projectTemplateId: z.number().nullish(),
  title: z.string().min(1, "Please add a title to project"),
  startDate: z.date(),
  endDate: z.date(),
  pipelineId: z.number(),
  archived: z.boolean().default(false),
  projectStageId: z.number(),
  dealId: z.number().nullish(),
  contactId: z.number().nullish(),
  clientId: z.number().nullish(),
  description: z.string().nullish(),
  status: z.enum(["ACTIVE", "COMPLETED", "CANCELLED"]).default("ACTIVE"),
  projectAssignees: z.coerce.number().array().nullish(),
  projectTags: z.coerce.number().array().nullish(),
  customFields: z.any().nullish(),
});

const timesheet = z.object({
  userId: z.number().optional(),
  durationType: enums.timeSheetDurationTypes.default("MINUTES"),
  minutesWorked: z.coerce.number().optional().default(0),
  projectTaskId: z.number().nullish(),
  startTime: z.date(),
  endTime: z.date().nullish(),
  timerRunning: z.boolean().default(false),
  notes: z.string().default(""),
  approvalStatus: enums.timeSheetStatusTypes.default("OPEN"),
});

const section = z.object({
  title: z.string(),
  projectId: z.number(),
});

const projectTemplateSubTask = z.object({
  title: z.string().min(1, "Please add a title to task"),
  parentProjectTemplateTaskId: z.number(),
});
const projectTemplateSection = z.object({
  title: z.string(),
  projectTemplateId: z.number(),
});

const projectTemplateTask = z.object({
  position: z.number().default(1),
  title: z.string(),
  description: z.string().nullish(),
  dueDateDays: z.coerce.number().nullish(),
  projectTemplateSectionId: z.number().nullish(),
  projectTemplateTaskAssignees: z.coerce.number().array().optional(),
  projectTemplateId: z.number(),
  projectTemplateSubTasks: projectTemplateSubTask
    .omit({
      parentProjectTemplateTaskId: true,
    })
    .array()
    .optional(),
});

const projectTemplate = z.object({
  name: z.string(),
  description: z.string().nullish(),
  projectPipelineId: z.number(),
});

const projectNote = z.object({
  projectId: z.number(),
  note: z.string(),
});

const projectPipelineStage = z.object({
  stageType: z.enum(["OPEN", "CLOSED"]),
  closedStageType: z.enum(["WON", "LOST"]).nullish(),
  name: z.string(),
  position: z.coerce.number(),
});

const projectPipeline = z.object({
  name: z.string(),
  singularName: z.string(),
  pluralName: z.string(),
  projectPipelineStages: projectPipelineStage.array(),
});

const projectSubTask = z.object({
  title: z.string().min(1, "Please add a title to task"),
  status: enums.projectTaskStatusTypes.default("OPEN"),
  parentTaskId: z.number(),
});

const projectTask = z.object({
  title: z.string().min(1, "Please add a title to task"),
  description: z.string().nullish(),
  status: enums.projectTaskStatusTypes.default("OPEN"),
  date: z.date().nullish(),
  projectTaskAssignees: z.coerce.number().array().optional(),
  projectId: z.number(),
  projectSectionId: z.number().nullish(),
  projectSubTasks: projectSubTask
    .omit({
      parentTaskId: true,
    })
    .array()
    .optional(),
  reminders: reminder.nullish(),
  projectTaskTags: z.coerce.number().array().nullish(),
  files: filesInput.nullish(),
});

const projectActivity = z.object({
  type: enums.activityTypes,
  priority: z.enum(["LOW", "MEDIUM", "HIGH"]).nullish(),
  title: z.string(),
  description: z.string().nullish(),
  projectActivityAssignees: z.coerce.number().array().nullish(),
  startDate: z.date(),
  endDate: selectableDatePicker,
  projectActivityAttendees: z.coerce.number().array().nullish(),
  pipelineStageId: z.number().nullish(),
  projectId: z.number(),
  completed: z.boolean().default(false),
  completedOn: z.date().nullish(),
  reminders: reminder.nullish(),
});

const projectTemplateActivity = z.object({
  type: enums.activityTypes,
  priority: z.enum(["LOW", "MEDIUM", "HIGH"]).nullish(),
  title: z.string(),
  description: z.string().nullish(),
  projectTemplateActivityAssignees: z.coerce.number().array().optional(),
  startDateDays: z.coerce.number(),
  projectPipelineStageId: z.number().nullish(),
  projectTemplateId: z.number(),
  duration: z.string(),
});

export default {
  timesheet,
  project,
  projectPipeline,
  projectPipelineStage,
  projectTask,
  projectActivity,
  projectSubTask,
  projectNote,
  projectTemplate,
  projectTemplateTask,
  projectTemplateActivity,
  section,
  projectTemplateSection,
};
