import { LucideIcon } from "lucide-react";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "../components/primitives/context-menu";

type RightClickMenuProps = {
  className?: string;
  children: React.ReactNode;
  items: {
    icon?: LucideIcon;
    label: string;
    onClick: () => void;
  }[];
};

export default function RightClickMenu({
  children,
  items,
  className,
}: RightClickMenuProps) {
  return (
    <ContextMenu>
      <ContextMenuTrigger className={className}>{children}</ContextMenuTrigger>
      <ContextMenuContent className="w-32">
        {items.map((item) => (
          <ContextMenuItem inset onClick={item.onClick} key={item.label}>
            {item.icon && <item.icon className="mr-2 w-4 h-4" />}
            {item.label}
          </ContextMenuItem>
        ))}
      </ContextMenuContent>
    </ContextMenu>
  );
}
