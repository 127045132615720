import InfoItemsVT from "@/components/InfoItemVT";
import ResponsiveActions from "@/components/ResponsiveActions";
import Page from "@/components/page";
import { RouterOutputs, trpc } from "@/helpers/trpc";
import { getInvoiceStatus, getQuoteStatus } from "@/lib/constants";
import { EditClientDrawer } from "@/pages/crm/clients/list";
import { CompanyLogo } from "@/pages/crm/deals/components/dealCard";
import { ContactCard } from "@/pages/crm/deals/details";
import { Card } from "@heffl/ui/components/primitives/card";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import { cn, formatAddress, formatCurrency } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import {
  Building,
  Calendar,
  DollarSign,
  Eye,
  InfoIcon,
  PencilIcon,
  ScrollText,
  Trash2,
  UserCheck,
} from "lucide-react";
import { useState } from "react";
import SimpleTable from "@heffl/ui/components/simple-table";

import DataTable from "@/components/DataTable";
import { calculateQuotationLineItems } from "@heffl/server/src/helpers/lineItems/calculateLineItems";
import StripeTabs from "@heffl/ui/components/primitives/stripe-tabs";
import { useNavigate, useParams } from "react-router-dom";

import { renderCustomFieldValue } from "@heffl/server/src/helpers/customFields";
import calculateInvoice from "@heffl/server/src/helpers/lineItems/calculateInvoice";
import enums from "@heffl/server/src/schemas/enums";
import toast from "react-hot-toast";
import { z } from "zod";
import { InvoiceStatusBadge, getProductName } from "../invoices/list";
import { QuotationStatusBadge } from "../quotations/list";
import StatementOfAccounts from "./StatementOfAccounts";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { sum } from "radash";
import { Button } from "@heffl/ui/components/primitives/button";
import { EditPaymentModal } from "../invoices/components/payment-modals";

type InvoiceStatus = z.infer<typeof enums.invoiceStatus>;
type QuoteStatus = z.infer<typeof enums.quotationStatus>;

const SalesClientDetails = () => {
  const params = useParams();
  const clientId = Number(params.id);
  const confirm = useConfirm();
  const navigate = useNavigate();

  const [showEdit, setShowEdit] = useState(false);
  const [showCredits, setShowCredits] = useState(false);
  const [showEditPaymentModal, setShowEditPaymentModal] = useState<
    number | null
  >(null);

  const { data: client } = trpc.clients.details.useQuery(clientId);
  const { data: invoices } = trpc.invoices.list.useQuery({
    clients: [clientId],
  });
  const { data: quotations } = trpc.quotations.list.useQuery({
    clients: [clientId],
  });

  const clientDeleteMutation = trpc.clients.delete.useMutation({
    onSuccess() {
      toast.success("Client deleted successfully");
      navigate("/sales/clients");
    },
    onError(er) {
      toast.error(er.message);
    },
  });

  if (!client || !invoices || !quotations) return <FullScreenSpinner />;

  const billingAddress = client.clientAddresses.length
    ? client.clientAddresses.find((address) => address.type === "BILLING")
    : client.clientAddresses[0];

  const invoiceStatusSummary = invoices.invoices.reduce(
    (acc, invoice) => {
      const { status } = invoice;
      if (acc[status]) {
        acc[status].push(invoice);
      } else {
        acc[status] = [invoice];
      }
      return acc;
    },
    {
      CANCELLED: [],
      DRAFT: [],
      SENT: [],
      PARTIALLY_PAID: [],
      PAID: [],
      OVERDUE: [],
    } as Record<InvoiceStatus, RouterOutputs["invoices"]["list"]["invoices"]>
  );

  const clientTotalPendingAmount = () => {
    const pendingInvoices = invoices.invoices.filter(
      (invoice) =>
        invoice.status === "SENT" || invoice.status === "PARTIALLY_PAID"
    );
    const totalPendingAmount = pendingInvoices.reduce((total, invoice) => {
      return total + calculateInvoice(invoice).pendingTotal;
    }, 0);
    return totalPendingAmount + client.openingBalance;
  };

  const invoiceTotalAmount = () => {
    const sentInvoices = invoices.invoices.filter(
      (invoice) => invoice.status !== "DRAFT"
    );
    const totalSentAmount = sentInvoices.reduce((total, invoice) => {
      return total + calculateInvoice(invoice).invoiceTotal;
    }, 0);
    return totalSentAmount;
  };

  const quoteStatusSummary = quotations.quotations.reduce(
    (acc, quote) => {
      const { status } = quote;
      if (acc[status]) {
        acc[status].push(quote);
      } else {
        acc[status] = [quote];
      }
      return acc;
    },
    {
      CANCELLED: [],
      DRAFT: [],
      SENT: [],
      ACCEPTED: [],
      REJECTED: [],
    } as Record<QuoteStatus, RouterOutputs["quotations"]["list"]["quotations"]>
  );

  const paymentsWithCredits = client.paymentsRecieved.filter((payment) => {
    const totalAllocated = sum(
      payment.paymentAllocations,
      (allocation) => allocation.amount
    );
    return totalAllocated < payment.amount;
  });

  return (
    <Page
      className="w-full h-full bg-gray-100 sm:min-h-screen"
      title="Client Details"
      showBack
      fullWidth
      description={`Created on ${dayjs(client.createdAt).format(
        "DD MMM YYYY hh:mm A"
      )}`}
      suffix={
        <div className="flex flex-col gap-2 sm:flex-row">
          <ResponsiveActions
            actions={[
              {
                label: "Delete",
                buttonVariant: "destructive",
                loading: clientDeleteMutation.isLoading,
                icon: Trash2,
                onClick: async () => {
                  const confirmed = await confirm({
                    title: "Are you sure to delete this client?",
                    body: `This will delete the client "${client.name}" and its history from the system.`,
                    actionButton: "Delete",
                  });
                  if (confirmed) {
                    clientDeleteMutation.mutate(clientId);
                  }
                },
              },
              {
                label: "Edit",
                icon: PencilIcon,
                onClick: () => setShowEdit(true),
              },
            ]}
          />
        </div>
      }
    >
      {showEdit && (
        <EditClientDrawer id={clientId} onClose={() => setShowEdit(false)} />
      )}

      <ModalDrawer
        open={showCredits}
        onClose={() => setShowCredits(false)}
        title="Unused Credits"
        modalClassName="min-w-[600px]"
      >
        <SimpleTable
          columns={[
            { label: "Info", key: "info" },
            { label: "Date", key: "date" },
            { label: "Amount", key: "amount" },
            { label: "Action", key: "action" },
          ]}
          rows={paymentsWithCredits.map((payment) => ({
            info: "Excess payment",
            date: dayjs(payment.date).format("DD/MM/YYYY"),
            amount: formatCurrency(
              payment.amount - sum(payment.paymentAllocations, (a) => a.amount),
              "AED"
            ),
            action: (
              <Button
                onClick={() => {
                  setShowEditPaymentModal(payment.id);
                  setShowCredits(false);
                }}
                variant="primaryOutline"
                size="xs"
              >
                Apply
              </Button>
            ),
          }))}
        />
      </ModalDrawer>

      {showEditPaymentModal && (
        <EditPaymentModal
          id={showEditPaymentModal}
          open={!!showEditPaymentModal}
          onClose={() => setShowEditPaymentModal(null)}
        />
      )}

      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-3 sm:flex-row">
          <div className="flex flex-col gap-2 w-full sm:w-4/6">
            <Card parentClassName="w-full pt-4 h-fit">
              <div className="flex gap-3">
                <CompanyLogo company={client} className="w-16 h-16" />
                <div>
                  <h2 className="text-xl font-semibold sm:text-3xl">
                    {client.name}
                  </h2>
                  <p className="text-gray-500">
                    {` Customer since ${dayjs(client.createdAt).format(
                      "DD MMM YYYY"
                    )}`}
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-2 mt-6 sm:grid-cols-5">
                <Card>
                  <p className="text-sm font-medium text-gray-500">
                    Total Invoiced
                  </p>
                  <p className="text-xl font-semibold sm:text-base">
                    {formatCurrency(invoiceTotalAmount(), "AED")}
                  </p>
                </Card>
                <Card>
                  <p className="text-sm font-medium text-gray-500">
                    Total Pending
                  </p>
                  <p className="text-xl font-semibold sm:text-base">
                    {formatCurrency(clientTotalPendingAmount(), "AED")}
                  </p>
                </Card>
                <Card onClick={() => setShowCredits(true)}>
                  <p className="text-sm font-medium text-gray-500">
                    Unused Credits
                  </p>
                  <p className="text-xl font-semibold cursor-pointer sm:text-base text-primary-700">
                    {formatCurrency(client.unUsedCredits, "AED")}
                  </p>
                </Card>
                <Card>
                  <p className="text-sm font-medium text-gray-500">
                    Total Invoices
                  </p>
                  <p className="text-xl font-semibold sm:text-base">
                    {invoices.invoices.length}
                  </p>
                  <div className="flex gap-2">
                    {Object.keys(invoiceStatusSummary)
                      .filter(
                        (status) =>
                          invoiceStatusSummary[status as InvoiceStatus].length >
                          0
                      )
                      .map((status) => (
                        <div
                          key={status}
                          className="flex gap-1 items-center text-sm text-gray-400 capitalize sm:text-xs"
                        >
                          <div
                            className={cn(
                              "w-2 h-2 rounded-full",
                              getInvoiceStatus(status as InvoiceStatus).color
                            )}
                          />
                          {status.toLowerCase()}:{" "}
                          {invoiceStatusSummary[status as InvoiceStatus].length}
                        </div>
                      ))}
                  </div>
                </Card>
                <Card>
                  <p className="text-sm font-medium text-gray-500">
                    Total Quotes
                  </p>
                  <p className="text-xl font-semibold sm:text-base">
                    {quotations.quotations.length}
                  </p>
                  <div className="flex gap-2">
                    {Object.keys(quoteStatusSummary)
                      .filter(
                        (status) =>
                          quoteStatusSummary[status as QuoteStatus].length > 0
                      )
                      .map((status) => (
                        <div
                          key={status}
                          className="flex gap-1 items-center text-sm text-gray-400 capitalize sm:text-xs"
                        >
                          <div
                            className={cn(
                              "w-2 h-2 rounded-full",
                              getQuoteStatus(status as QuoteStatus).color
                            )}
                          />
                          {status.toLowerCase()}:{" "}
                          {quoteStatusSummary[status as QuoteStatus].length}
                        </div>
                      ))}
                  </div>
                </Card>
              </div>
            </Card>
            <Card title="Overview">
              <StripeTabs
                defaultValue="invoices"
                items={[
                  {
                    key: "invoices",
                    label: "Invoices",
                    children: (
                      <DataTable
                        data={invoices.invoices || []}
                        mobileColumns={[0, 1, 2]}
                        rowKey="id"
                        onRowClick={(invoice) =>
                          navigate(`/sales/invoices/details/${invoice.id}`)
                        }
                        loading={!invoices.invoices}
                        columns={[
                          {
                            title: "#",
                            render: (invoice) => `#${invoice.number}`,
                            className: "!w-[60px]",
                          },
                          {
                            icon: Building,
                            title: "Client",
                            className: "!w-[500px]",
                            render: (invoice) => (
                              <div className="flex gap-2 items-center py-1">
                                <CompanyLogo
                                  company={invoice.clients}
                                  className="w-10 h-10 rounded-full"
                                />
                                <div className="flex flex-col">
                                  <p className="text-sm font-medium">
                                    {invoice.clients?.name ?? "No company"}
                                  </p>
                                  <p className="text-xs font-medium text-gray-500">
                                    {getProductName(invoice.invoiceProducts)}
                                  </p>
                                </div>
                              </div>
                            ),
                          },
                          {
                            icon: Eye,
                            title: "Status",
                            render: (invoice) => (
                              <InvoiceStatusBadge invoice={invoice} />
                            ),
                            className: "!w-[100px]",
                          },
                          {
                            icon: Calendar,
                            title: "Date",
                            render: (invoice) =>
                              dayjs(invoice.date).format("DD/MM/YYYY"),
                            className: "!w-[160px]",
                          },
                          {
                            icon: DollarSign,
                            title: "Amount",
                            className: "!w-[160px]",
                            textAlign: "right",
                            render: (invoice) =>
                              formatCurrency(
                                calculateInvoice(invoice).invoiceTotal,
                                "AED"
                              ),
                          },
                        ]}
                        empty={{
                          title: "No invoices found",
                          icon: DollarSign,
                          description: "No invoices available to display.",
                          actionText: "Add invoice",
                          onAction: () =>
                            navigate(
                              `/sales/invoices/add?clientId=${clientId}`
                            ),
                          buttonSize: "sm",
                        }}
                      />
                    ),
                  },
                  {
                    key: "quotations",
                    label: "Quotations",
                    children: (
                      <DataTable
                        rowKey="id"
                        mobileColumns={[0, 1, 2]}
                        onRowClick={(quotation) =>
                          navigate(`/sales/quotations/details/${quotation.id}`)
                        }
                        loading={!quotations.quotations}
                        columns={[
                          {
                            title: "#",
                            render: (quotation) => `#${quotation.number}`,
                            className: "!w-[60px]",
                          },
                          {
                            icon: Building,
                            title: "Client",
                            className: "!w-[500px]",
                            render: (quotation) => (
                              <div className="flex gap-2 items-center py-1">
                                <CompanyLogo
                                  company={quotation.clients}
                                  className="w-10 h-10 rounded-full"
                                />
                                <div className="flex flex-col">
                                  <p className="text-sm font-medium">
                                    {quotation.clients?.name ?? "No company"}
                                  </p>
                                  <p className="text-xs font-medium text-gray-500">
                                    {quotation.subject || "No subject"}
                                  </p>
                                </div>
                              </div>
                            ),
                          },
                          {
                            icon: Eye,
                            title: "Status",
                            render: (quotation) => (
                              <QuotationStatusBadge status={quotation.status} />
                            ),
                            className: "!w-[100px]",
                          },
                          {
                            icon: Calendar,
                            title: "Date",
                            render: (quotation) =>
                              dayjs(quotation.date).format("DD/MM/YY"),
                            className: "!w-[160px]",
                          },
                          {
                            icon: DollarSign,
                            title: "Amount",
                            className: "!w-[160px]",
                            textAlign: "right",
                            render: (quotation) =>
                              formatCurrency(
                                calculateQuotationLineItems({
                                  lineItems: quotation.quotationProducts,
                                  discount: quotation.discount,
                                  customFields:
                                    quotation.documentTemplates.customFields,
                                }).total,
                                quotation.currencies.symbol
                              ),
                          },
                        ]}
                        empty={{
                          title: "No quotations added",
                          icon: ScrollText,
                          description: "Please add a quotation to the deal",
                          actionText: "Add quotation",
                          onAction: () =>
                            navigate(
                              `/sales/quotations/add?clientId=${clientId}`
                            ),
                          buttonSize: "sm",
                        }}
                        data={quotations?.quotations || []}
                      />
                    ),
                  },
                  {
                    key: "statement",
                    label: "Statement of Accounts",
                    children: <StatementOfAccounts clientId={clientId} />,
                  },
                ]}
              />
            </Card>
          </div>
          <div className="flex flex-col gap-2 w-full sm:w-2/6">
            <Card title="Details" icon={<InfoIcon className="text-blue-500" />}>
              <InfoItemsVT
                items={[
                  {
                    label: "Tax No",
                    value: client.taxNumber ?? "No tax number added",
                    allowCopy: true,
                  },
                  {
                    label: "Billing Address",
                    value: billingAddress
                      ? formatAddress(billingAddress)
                      : "No address added",
                  },
                  {
                    label: "Website",
                    value: client.website ?? "No website added",
                  },
                  ...(client.customFieldFields &&
                    client.customFieldFields.map((field) => ({
                      label: field.label,
                      value: renderCustomFieldValue(
                        field,
                        client.customFields[field.name]
                      ),
                    }))),
                ]}
              />
            </Card>
            {client.contacts
              .sort((a) => (a.isPrimary ? -1 : 1))
              .map((contact, index) => (
                <ContactCard
                  key={contact.id}
                  contact={contact}
                  title={contact.isPrimary ? "Primary contact" : ""}
                  icon={<UserCheck className="text-green-600" />}
                  showAdd={index === 0}
                />
              ))}
          </div>
        </div>
      </div>
    </Page>
  );
};

export default SalesClientDetails;
