import { sendMessageAction } from "./actions/send-message";
import { WorkFlowApp } from "../../types";

export const whatsapp: WorkFlowApp = {
  name: "whatsapp",
  label: "WhatsApp",
  description: "WhatsApp is a messaging app",
  logo: "whatsapp",
  actions: [sendMessageAction],
  triggers: [],
};
