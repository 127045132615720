import { ClientInput } from "@/components/FormComponents";
import { trpc } from "@/helpers/trpc";
import Schemas from "@heffl/server/src/schemas";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import Select from "@heffl/ui/components/primitives/creatable-select";
import { DatePicker } from "@heffl/ui/components/primitives/datepicker";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FormList from "@heffl/ui/components/primitives/form-list";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import { Textarea } from "@heffl/ui/components/primitives/textarea";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import { cn, formatCurrency, formatName } from "@heffl/ui/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { Trash2 } from "lucide-react";
import { sum } from "radash";
import { useEffect } from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";

const PaymentForm = ({
  form,
  edit = false,
  paymentAllocationsDetails,
}: {
  edit?: boolean;
  form: UseFormReturn<z.infer<typeof Schemas.sales.invoicePayment>>;
  paymentAllocationsDetails: {
    type: "INVOICE" | "OPENING_BALANCE";
    invoiceId?: number | null;
    amountTotal: number;
    amountDue: number;
    title: string;
  }[];
}) => {
  const clientId = form.watch("clientId");

  const { data: paymentMethods } =
    trpc.invoices.payments.methods.list.useQuery();
  const { data: users } = trpc.users.list.useQuery();

  // const paymentAllocations = useMemo(() => {
  //   if (!paymentAllocationsRaw || !invoices) return [];

  //   return paymentAllocationsRaw.map((pa) => {
  //     let date: Date | undefined;
  //     let title = "";
  //     let amount = 0;
  //     let amountDue = 0;

  //     if (pa.type === "INVOICE") {
  //       const invoice = invoices.invoices.find((i) => i.id === pa.invoiceId);
  //       if (invoice) {
  //         const invoiceTotal = calculateInvoice({
  //           discount: invoice.discount,
  //           invoiceProducts: invoice.invoiceProducts,
  //           paymentAllocations: invoice.paymentAllocations,
  //         });
  //         amount = invoiceTotal.invoiceTotal;
  //         amountDue = invoiceTotal.pendingTotal;
  //         title = `#${invoice.number}`;
  //         date = invoice.date;
  //       }
  //     } else if (pa.type === "OPENING_BALANCE" && client) {
  //       amount = client.openingBalance;
  //       amountDue = client.openingBalance;
  //       title = "Opening balance";
  //     }

  //     return {
  //       ...pa,
  //       date,
  //       amount,
  //       amountDue,
  //       title,
  //     };
  //   });
  // }, [paymentAllocationsRaw, invoices, client]);

  useEffect(() => {
    if (paymentMethods?.length && !edit) {
      const defaultPaymentMethod =
        paymentMethods.find((method) => method.isDefault) || paymentMethods[0];
      form.setValue("paymentMethodId", defaultPaymentMethod.id);
    }
  }, [paymentMethods]);

  // // Setting allocations
  // useEffect(() => {
  //   if (amount <= 0) return;
  //   let remainingAmount = amount;
  //   paymentAllocations.map((pa, index) => {
  //     if (remainingAmount <= 0) return;
  //     const allocationAmount = Math.min(remainingAmount, pa.amountDue);
  //     remainingAmount -= allocationAmount;
  //     form.setValue(`paymentAllocations.${index}.amount`, allocationAmount);
  //   });
  // }, [amount, paymentAllocations, form]);

  // const totalAllocatedAmount = sum(paymentAllocationsRaw, (pa) => pa.amount);

  // const amountInExcess = useMemo(() => {
  //   const excess = amount - totalAllocatedAmount;
  //   return excess > 0 ? excess : 0;
  // }, [amount, totalAllocatedAmount]);

  return (
    <>
      <ClientInput name="clientId" label="Client" />
      <div
        className={cn(
          "space-y-2",
          !clientId ? "opacity-50 pointer-events-none" : ""
        )}
      >
        <div className="grid grid-cols-2 gap-2">
          <FormField name="date" label="Date">
            <DatePicker />
          </FormField>
          <FormField name="paymentMethodId" label="Payment method">
            <Select
              allowClear={false}
              placeholder="Select payment method"
              options={
                paymentMethods?.map((method) => ({
                  label: method.name,
                  value: method.id,
                })) || []
              }
            />
          </FormField>
        </div>
        <div className="grid grid-cols-3 gap-2">
          <FormField name="amount" label="Amount">
            <Input placeholder="Amount" prefix="AED" prefixFilled />
          </FormField>
          <FormField name="collectedByUserId" label="Collected by">
            <Select
              placeholder="Select collected by"
              options={
                users?.map((user) => ({
                  label: formatName(user),
                  value: user.id,
                })) || []
              }
            />
          </FormField>
          <FormField name="refNo" label="Ref No">
            <Input placeholder="Ref No" />
          </FormField>
        </div>

        <FormField name="notes" label="Notes">
          <Textarea placeholder="Notes" />
        </FormField>

        <FormList name="paymentAllocations">
          {({ _name }) => (
            <div className="space-y-2">
              <table className="w-full border-collapse">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="p-2 font-semibold text-left">Invoice</th>
                    <th className="p-2 font-semibold text-right">Amount</th>
                    <th className="p-2 font-semibold text-right">Pending</th>
                    <th className="p-2 font-semibold text-right">Payment</th>
                  </tr>
                </thead>
                <tbody>
                  {paymentAllocationsDetails?.map((pa, index) => {
                    return (
                      <tr key={index} className="border-b hover:bg-gray-50">
                        <td className="p-2">{pa.title}</td>
                        <td className="p-2 text-right">
                          {formatCurrency(pa.amountTotal, "AED")}
                        </td>
                        <td className="p-2 text-right">
                          {formatCurrency(pa.amountDue, "AED")}
                        </td>
                        <td className="flex justify-end p-2 text-right">
                          <FormField
                            name={_name(index, "amount")}
                            className="w-20"
                          >
                            <Input placeholder="Amount" />
                          </FormField>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </FormList>
        {/* <div className="p-4 mt-4 bg-gray-50 rounded-lg">
          <div className="space-y-2">
            <div className="flex justify-between">
              <span className="font-medium">Amount Received:</span>
              <span>{formatCurrency(amount, "AED")}</span>
            </div>
            <div className="flex justify-between">
              <span className="font-medium">Amount used for Payments:</span>
              <span>{formatCurrency(totalAllocatedAmount, "AED")}</span>
            </div>
            <div className="flex justify-between items-center">
              <span className="flex gap-1 items-center font-medium">
                <AlertTriangle className="w-4 h-4 text-yellow-500" />
                Amount in Excess:
              </span>
              <span className="font-semibold">
                {formatCurrency(amountInExcess, "AED")}
              </span>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

type paymentModalProps = {
  open: boolean;
  onClose: () => void;
  defaultValues?: {
    clientId?: number;
    invoiceId?: number;
  };
};

export const AddPaymentModal = ({
  open,
  onClose,
  defaultValues,
}: paymentModalProps) => {
  const form = useForm<z.infer<typeof Schemas.sales.invoicePayment>>({
    defaultValues: {
      date: new Date(),
      paymentAllocations: [],
    },
    resolver: zodResolver(Schemas.sales.invoicePayment),
  });

  const clientId = form.watch("clientId");

  const { data: pendingList } = trpc.invoices.payments.pendingList.useQuery(
    {
      clientId: clientId!,
    },
    {
      enabled: !!clientId,
    }
  );

  useEffect(() => {
    if (defaultValues?.clientId) {
      form.setValue("clientId", defaultValues.clientId);
    }
  }, [defaultValues]);

  const paymentAddMutation = trpc.sales.paymentsRecieved.add.useMutation({
    onSuccess() {
      toast.success("Payment added successfully");
      onClose();
      form.reset();
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  const onSubmit = (values: z.infer<typeof Schemas.sales.invoicePayment>) => {
    console.log(values);
    const totalAllocatedAmount = Number(
      sum(values.paymentAllocations, (pa) => Number(pa.amount))
    );
    if (totalAllocatedAmount > values.amount) {
      return toast.error("Payment amount is less than the allocated amount");
    }
    paymentAddMutation.mutate(values);
    form.reset();
    onClose();
  };

  useEffect(() => {
    if (defaultValues?.invoiceId) {
      const invoice = pendingList?.find(
        (pa) => pa.invoiceId === defaultValues.invoiceId
      );
      if (invoice) {
        form.setValue("amount", invoice.amountDue < 0 ? 0 : invoice.amountDue);
      }
    }
  }, [defaultValues, pendingList]);

  useEffect(() => {
    if (pendingList) {
      form.setValue(
        "paymentAllocations",
        pendingList.map((pa) => ({
          type: pa.type,
          amount: defaultValues?.invoiceId === pa.invoiceId ? pa.amountDue : 0,
          invoiceId: pa.invoiceId,
        }))
      );
    }
  }, [pendingList]);

  return (
    <ModalDrawer
      modalClassName="min-w-[600px]"
      open={open}
      onClose={() => {
        onClose();
        form.reset();
      }}
      title="Add payment"
      footer={
        <Button
          className="w-full"
          variant="primary"
          type="submit"
          loading={paymentAddMutation.isLoading}
          onClick={() => form.handleSubmit(onSubmit)()}
        >
          Add payment
        </Button>
      }
    >
      <Form
        {...form}
        onSubmit={async (values) => paymentAddMutation.mutate(values)}
      >
        <PaymentForm
          form={form}
          paymentAllocationsDetails={pendingList || []}
        />
      </Form>
    </ModalDrawer>
  );
};

export const EditPaymentModal = ({
  open,
  onClose,
  id,
}: {
  id: number;
  open: boolean;
  onClose: () => void;
}) => {
  const form = useForm<z.infer<typeof Schemas.sales.invoicePayment>>();
  const confirm = useConfirm();

  const { data: paymentDetails } = trpc.invoices.payments.details.useQuery(id);

  const paymentUpdateMutation = trpc.sales.paymentsRecieved.update.useMutation({
    onSuccess() {
      toast.success("Payment updated successfully");
      onClose();
      form.reset();
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  const deletePaymentMutation = trpc.sales.paymentsRecieved.delete.useMutation({
    onSuccess() {
      toast.success("Payment deleted successfully");
      onClose();
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  useEffect(() => {
    if (paymentDetails) {
      form.reset(paymentDetails);
    }
  }, [paymentDetails]);

  useEffect(() => {
    if (paymentDetails) {
      form.setValue(
        "paymentAllocations",
        paymentDetails.paymentAllocations.map((pa) => ({
          type: pa.type,
          amount: pa.amountDue,
          invoiceId: pa.invoiceId,
        }))
      );
    }
  }, [paymentDetails]);

  const onSubmit = (values: z.infer<typeof Schemas.sales.invoicePayment>) => {
    paymentUpdateMutation.mutate({
      id,
      payment: values,
    });
    form.reset();
    onClose();
  };

  return (
    <ModalDrawer
      open={open}
      onClose={() => {
        onClose();
        form.reset();
      }}
      title="Update payment"
      footer={
        <div className="flex gap-2 items-center w-full">
          <Button
            variant="destructiveOutline"
            icon={Trash2}
            onClick={async () => {
              const confirmed = await confirm({
                title: "Are you sure you want to delete this payment?",
                body: "This action cannot be undone.",
              });
              if (confirmed) {
                deletePaymentMutation.mutate(id);
              }
            }}
          />
          <Button
            className="!w-full"
            variant="primary"
            type="submit"
            loading={paymentUpdateMutation.isLoading}
            onClick={() => form.handleSubmit(onSubmit)()}
          >
            Update payment
          </Button>
        </div>
      }
    >
      {!paymentDetails && <FullScreenSpinner className="h-60" />}
      {paymentDetails && (
        <Form {...form} onSubmit={onSubmit}>
          <PaymentForm
            form={form}
            edit
            paymentAllocationsDetails={paymentDetails.paymentAllocations}
          />
        </Form>
      )}
    </ModalDrawer>
  );
};
