import Page from "@/components/page";
import validateCustomFields from "@/helpers/customFields/validateCustomFields";
import { trpc } from "@/helpers/trpc";
import Schemas from "@heffl/server/src/schemas";
import { Form } from "@heffl/ui/components/primitives/form";
import ResponsivePrimaryButton from "@heffl/ui/components/ResponsivePrimaryButton";
import { zodResolver } from "@hookform/resolvers/zod";
import { Save } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import { AddProductDrawer } from "../products/components/addProductDrawer";
import QuotationForm from "./components/quotation-form";

const EditQuotation = () => {
  const params = useParams();
  const quotationId = Number(params.id);
  const navigate = useNavigate();

  const [showAddProductDialog, setShowAddProductDialog] = useState(false);
  const form = useForm<z.infer<typeof Schemas.sales.quotation>, unknown>({
    resolver: zodResolver(Schemas.sales.quotation),
  });

  const { data: quotationDetails } = trpc.quotations.details.useQuery(
    quotationId,
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: customFields } = trpc.customizations.customFields.list.useQuery(
    {
      section: "QUOTATION_LINE_ITEM",
      documentTemplateId: quotationDetails?.templateId,
    },
    {
      enabled: !!quotationDetails?.templateId,
    }
  );

  const quotationUpdateMutation = trpc.quotations.update.useMutation({
    onSuccess: () => {
      form.reset();
      toast.success("Quotation updated successfully");
      navigate(-1);
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  useEffect(() => {
    // check if default values is already set
    if (!form.getValues("clientId") && quotationDetails) {
      form.reset({
        ...quotationDetails,
        quotationTags: quotationDetails.quotationTags.map((tag) => tag.tagId),
      });
    }
  }, [quotationDetails, form]);

  const onSubmit = (values: z.infer<typeof Schemas.sales.quotation>) => {
    const isValid = values.quotationProducts.every((product, index) => {
      if (product.viewType === "LINE_ITEM") {
        return validateCustomFields({
          customFields,
          customFieldsValues: product.customFields,
          form,
          name: `quotationProducts.${index}`,
        });
      }
      return true;
    });
    if (!isValid) return;
    quotationUpdateMutation.mutate({
      id: quotationId,
      quotation: values,
    });
  };

  return (
    <Page
      showBack
      title="Edit Quotation"
      description="Update quotation details"
      className="max-w-screen-lg"
      suffix={
        <ResponsivePrimaryButton
          onClick={() => form.handleSubmit(onSubmit)()}
          loading={quotationUpdateMutation.isLoading}
          variant="primary"
          size="md"
          icon={Save}
        >
          Update quotation
        </ResponsivePrimaryButton>
      }
    >
      {showAddProductDialog && (
        <AddProductDrawer
          open={showAddProductDialog}
          onClose={() => setShowAddProductDialog(false)}
        />
      )}
      <Form {...form} onSubmit={onSubmit}>
        <QuotationForm form={form} edit />
      </Form>
    </Page>
  );
};

export default EditQuotation;
