import RichTextEditor from "@heffl/ui/components/primitives/RichTextEditor";
import { FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { trpc } from "@/helpers/trpc";

type Props = {
  templateId: number;
  contentHtml: string;
  className?: string;
};

const TemplateRenderEditable = ({ templateId }: Props) => {
  const { data: template } = trpc.templates.details.useQuery(templateId);

  if (!template) return <FullScreenSpinner />;

  const getPageMargin = () => {
    return `@page {
      margin-top:${template.templateProperties.pageMarginTop}in;
      margin-bottom:${template.templateProperties.pageMarginBottom}in;
      margin-left:${template.templateProperties.pageMarginLeft}in;
      margin-right:${template.templateProperties.pageMarginRight}in;
    }`;
  };

  return (
    <>
      <div className="flex flex-col gap-3 p-1 mt-4 w-full bg-white rounded-md shadow-xl">
        <table className="w-full">
          <style>{getPageMargin()}</style>
          <tbody>
            <tr>
              <td>
                <FormField name="contentHtml">
                  <RichTextEditor height="calc(100vh - 150px)" />
                </FormField>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TemplateRenderEditable;
