import { RouterOutputs } from "@/helpers/trpc";
import { Avatar, AvatarFallback } from "@heffl/ui/components/primitives/avatar";
import { Badge } from "@heffl/ui/components/primitives/badge";
import {
  cn,
  formatCurrency,
  formatName,
  websiteFavicon,
} from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { User } from "lucide-react";
import { useNavigate } from "react-router-dom";

dayjs.extend(relativeTime);

interface Props {
  deal: RouterOutputs["deals"]["list"][number];
  dragOverlay?: boolean;
  isListingCard?: boolean;
}

export const CompanyLogo = ({
  company,
  avatarClassName,
  className,
}: {
  company?: { website?: string | null; name: string } | null;
  avatarClassName?: string;
  className?: string;
}) => {
  return company && company.website ? (
    <img
      src={websiteFavicon(company.website, 64)}
      alt="company"
      className={cn("w-12 h-12 rounded-sm", className)}
    />
  ) : (
    <Avatar className={cn("w-12 h-12 uppercase rounded-sm", className)}>
      <AvatarFallback
        className={cn(
          "text-base text-gray-600 bg-gray-200 rounded-sm",
          avatarClassName
        )}
      >
        {company ? (
          `${company.name[0]}${company.name[1]}`
        ) : (
          <User className="text-gray-700" />
        )}
      </AvatarFallback>
    </Avatar>
  );
};

const DealCard = ({ deal, dragOverlay, isListingCard = false }: Props) => {
  const navigate = useNavigate();
  return (
    <div
      className={cn(
        "bg-white shadow-sm border border-gray-200 p-2 !rounded-sm",
        dragOverlay ? "cursor-grabbing" : "cursor-grab",
        isListingCard ? "w-full" : "max-width-[300px]"
      )}
      onClick={() => isListingCard && navigate(`/crm/deals/details/${deal.id}`)}
    >
      <div className="flex gap-3 items-center">
        <CompanyLogo company={deal.clients} />
        <div className="flex flex-col line-clamp-2">
          <p className="text-sm font-normal text-gray-500">
            {deal.clients
              ? deal.clients.name
              : deal.dealContacts.length
              ? formatName(deal.dealContacts[0].contacts)
              : ""}
          </p>
          <p className="font-semibold w-[200px]">{deal.title}</p>
          {isListingCard && (
            <p className="text-sm text-gray-500">
              {deal.dealPipelineStages.dealPipelines.name}
            </p>
          )}
          {isListingCard && (
            <p className="text-sm text-gray-500">
              {deal.dealPipelineStages.name}
            </p>
          )}
          {deal.status === "WON" && (
            <Badge variant="success" className="w-fit">
              Won
            </Badge>
          )}{" "}
          {deal.status === "LOST" && (
            <Badge variant="error" className="w-fit">
              Lost
            </Badge>
          )}
        </div>
      </div>
      <hr className="-mx-2 mt-3 border-gray-100" />
      <div className="flex justify-between items-center">
        <div className="flex gap-2 items-center pt-1">
          {deal.ownedBy && (
            <div className="flex flex-row gap-2 items-center">
              <Avatar className="w-6 h-6">
                <AvatarFallback className="text-xs uppercase">
                  {`${deal.ownedBy.firstName[0]}${deal.ownedBy.firstName[1]}`}
                </AvatarFallback>
              </Avatar>
            </div>
          )}
          <p className="text-xs font-normal text-gray-600">
            {dayjs().to(deal.createdAt)}
          </p>
        </div>
        <span className="text-sm font-medium text-black">
          {deal.price ? formatCurrency(deal.price, deal.currencies.symbol) : ""}
        </span>
      </div>
    </div>
  );
};
export default DealCard;
