import { Card } from "@heffl/ui/components/primitives/card";
import { Building } from "lucide-react";

const CompanyCard = ({
  company,
  onClick,
}: {
  company: {
    id: number;
    name: string;
    website?: string | null;
    taxNumber?: string | null;
  };
  onClick?: () => void;
}) => {
  return (
    <Card
      onClick={() => {
        if (onClick) return onClick();
      }}
      className="cursor-pointer"
    >
      <div className="flex flex-col gap-2">
        <div className="flex gap-2 text-sm font-medium">
          {company.website ? (
            <img
              className="w-5 h-5 rounded-sm"
              src={`https://www.google.com/s2/favicons?domain=${company.website}&sz=32`}
            />
          ) : (
            <Building className="object-cover w-6 h-6 p-1 rounded-full bg-blue-50" />
          )}
          {company.name}
        </div>
        <p className="px-2 font-medium text-blue-700 border rounded-lg cursor-pointer w-fit">
          {company.website}
        </p>
        <p className="text-xs">{company.taxNumber || "No TRN added"}</p>
      </div>
    </Card>
  );
};

export default CompanyCard;
