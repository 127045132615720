import { RouterOutputs, trpc } from "@/helpers/trpc";
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MeasuringStrategy,
  PointerSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
  type DragEndEvent,
  type DragOverEvent,
  type DragStartEvent,
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { isMobile } from "@heffl/ui/lib/utils";
import { produce } from "immer";
import { useState } from "react";
import ProjectBoard, { ProjectBoardProps } from "../ProjectBoard";
import { TProject } from "./SortableProjectCard";
import Projectcard from "./projectCard";

const ProjectsKanban = ({
  projects,
  pipelineId,
  onChange,
}: {
  projects: RouterOutputs["projects"]["list"]["projects"];
  pipelineId?: number | null;
  onChange: (projects: RouterOutputs["projects"]["list"]["projects"]) => void;
}) => {
  const [activeCard, setActiveCard] = useState<TProject | null>(null);

  const { data: pipelineDetails } = trpc.projects.pipelines.details.useQuery(
    pipelineId || 0,
    {
      enabled: !!pipelineId,
    }
  );

  const sensors = useSensors(
    useSensor(isMobile() ? TouchSensor : PointerSensor, {
      activationConstraint: isMobile()
        ? {
            delay: 300,
            tolerance: 5,
          }
        : {
            distance: 10,
          },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const projectsBulkUpdate = trpc.projects.bulkPositionUpdate.useMutation();

  const handleDragStart = (e: DragStartEvent) => {
    setActiveCard((e.active.data.current?.project as TProject) || null);
  };

  // Fires if a drag operation is cancelled (eg: if user preses 'esc' while dragging)
  const handleDragCancel = () => setActiveCard(null);
  const handleDragOver = ({ active, over }: DragOverEvent) => {
    if (!pipelineDetails || !pipelineId) return;
    const activeListId = active.data.current?.sortable.containerId;
    const overListId = over?.data.current?.sortable.containerId || over?.id;

    if (activeListId === overListId || !overListId) return;

    const updatedProjects = produce(projects, (draft) => {
      const activeProject = draft.find((project) => project.id === active.id);
      if (activeProject) {
        activeProject.projectStageId = Number(overListId);
      }
    });
    onChange(updatedProjects);
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    setActiveCard(null);
    if (!over || !pipelineId) return;

    const activeListId = active.data.current?.sortable.containerId;
    const overListId = over.data.current?.sortable.containerId || over.id;

    if (activeListId === overListId) {
      const updatedData = produce(projects, (draft) => {
        const activeProject = draft.find((project) => project.id === active.id);
        if (activeProject) {
          activeProject.projectStageId = Number(overListId);
        }
      });
      onChange(updatedData);
      const overListProjects = updatedData.filter(
        (project) => project.projectStageId === Number(overListId)
      );
      const updateOverProjects = overListProjects.map((project, i) => ({
        id: project.id,
        projectStageId: Number(overListId),
        position: i + 1,
      }));
      projectsBulkUpdate.mutate(updateOverProjects);
    }
  };

  const pipelineStages: ProjectBoardProps[] =
    pipelineId && pipelineDetails?.projectPipelineStages
      ? pipelineDetails.projectPipelineStages.map((stage) => ({
          name: stage.name,
          id: stage.id.toString(),
          stageType: stage.stageType,
          closedStageType: stage.closedStageType,
          projects: projects.filter(
            (project) => project.projectStageId === stage.id
          ),
        }))
      : [
          {
            id: "OPEN",
            name: "Open",
            stageType: "OPEN",
            projects: projects.filter(
              (project) => project.projectPipelineStages.stageType === "OPEN"
            ),
            closedStageType: undefined,
          },
          {
            id: "CLOSED",
            name: "Closed",
            stageType: "CLOSED",
            projects: projects.filter(
              (project) => project.projectPipelineStages.stageType === "CLOSED"
            ),
            closedStageType: undefined,
          },
        ];

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      measuring={{ droppable: { strategy: MeasuringStrategy.Always } }}
      id="2"
    >
      <main className="bg-transition-body flex gap-3 h-[calc(100vh-120px)] px-3 sm:h-[calc(100vh-152px)] overflow-x-auto overflow-y-hidden">
        {pipelineStages.map((stage) => (
          <ProjectBoard projectsData={stage} key={stage.id} />
        ))}
      </main>
      <DragOverlay>
        {activeCard ? <Projectcard project={activeCard} dragOverlay /> : null}
      </DragOverlay>
    </DndContext>
  );
};

export default ProjectsKanban;
