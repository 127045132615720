import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import DropMenu from "@/components/DropMenu";
import Empty from "@/components/Empty";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import Select from "@heffl/ui/components/primitives/creatable-select";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import Page from "@/components/page";
import { Textarea } from "@heffl/ui/components/primitives/textarea";
import { trpc } from "@/helpers/trpc";
import Schemas from "@heffl/server/src/schemas";
import { zodResolver } from "@hookform/resolvers/zod";
import { FileStack, MoreHorizontal, Plus, Search } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

export const ProjectTemplateForm = ({ edit }: { edit?: boolean }) => {
  const { data: projectPipelines } = trpc.projects.pipelines.list.useQuery();

  return (
    <>
      <FormField name="name" label="Name">
        <Input placeholder="Template name" />
      </FormField>
      <FormField name="projectPipelineId" label="Pipeline">
        <Select
          disabled={!!edit}
          placeholder="Select pipeline"
          options={
            projectPipelines?.map((pipeline) => ({
              label: pipeline.name,
              value: pipeline.id,
            })) || []
          }
        />
      </FormField>
      <FormField name="description" label="Description">
        <Textarea placeholder="Template description" />
      </FormField>
    </>
  );
};

const AddTemplateModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const navigate = useNavigate();

  const form = useForm<z.infer<typeof Schemas.project.projectTemplate>>({
    resolver: zodResolver(Schemas.project.projectTemplate),
  });

  const { data: projectPipelines } = trpc.projects.pipelines.list.useQuery();

  const addTemplateMutation = trpc.projects.templates.add.useMutation({
    onSuccess(newTemplate) {
      onClose();
      navigate(`/projects/templates/edit/${newTemplate.id}`);
    },
  });

  if (!projectPipelines) return <FullScreenSpinner />;

  return (
    <ModalDrawer title="Add template" open={open} onClose={onClose}>
      <Form {...form} onSubmit={(values) => addTemplateMutation.mutate(values)}>
        <ProjectTemplateForm />
        <div className="self-end space-x-2">
          <Button>Cancel</Button>
          <Button
            loading={addTemplateMutation.isLoading}
            type="submit"
            variant="primary"
          >
            Add template
          </Button>
        </div>
      </Form>
    </ModalDrawer>
  );
};

const Templates = () => {
  const navigate = useNavigate();
  const confirm = useConfirm();

  const [addTemplate, setAddTemplate] = useState(false);

  const { data: templates } = trpc.projects.templates.list.useQuery();
  const deleteTemplateMutation = trpc.projects.templates.delete.useMutation({
    onSuccess() {
      toast.success("Template deleted successfully");
    },
    onError(error) {
      toast.error(error.message);
    },
  });
  const duplicateTemplateMutation =
    trpc.projects.templates.duplicate.useMutation({
      onSuccess(newTemplate) {
        toast.dismiss();
        toast.success("Template duplicated successfully");
        navigate(`/projects/templates/edit/${newTemplate.id}`);
      },
    });

  if (!templates) return <FullScreenSpinner />;

  return (
    <Page title="Templates" fullWidth>
      <AddTemplateModal
        open={addTemplate}
        onClose={() => setAddTemplate(false)}
      />
      <div className="flex flex-row justify-between border-gray-100 w-fullborder-b">
        <Input
          prefix={<Search className="h-5 text-gray-400" />}
          className="w-full sm:w-60"
          placeholder="Search templates.."
        />
        <Button variant="primary" onClick={() => setAddTemplate(true)}>
          <Plus className="w-4 h-4" /> Template
        </Button>
      </div>
      {!templates.length ? (
        <Empty
          title="No templates added yet"
          icon={FileStack}
          description="Add project templates to get started with your projects."
          actionText="Add template"
          onAction={() => setAddTemplate(true)}
          buttonSize="sm"
        />
      ) : (
        <div className="flex flex-col gap-3 py-6">
          {/* TEMPLATE */}
          {templates.map((template) => (
            <div
              className="flex flex-row justify-between p-3 border-b border-gray-200 cursor-pointer"
              key={template.id}
              onClick={() =>
                navigate(`/projects/templates/edit/${template.id}`)
              }
            >
              <p className="text-sm font-medium">{template.name}</p>
              <div className="flex flex-row gap-3">
                <div className="flex flex-col gap-2 items-center">
                  <p className="text-sm">
                    {template._count.projectTemplateTasks}
                  </p>
                  <p className="text-sm text-gray-500">Tasks</p>
                </div>
                <div className="flex flex-col gap-2 items-center">
                  <p className="text-sm">0</p>
                  <p className="text-sm text-gray-500">Activities</p>
                </div>

                <DropMenu
                  items={[
                    {
                      label: "Edit",
                      onClick: () =>
                        navigate(`/projects/templates/edit/${template.id}`),
                    },
                    {
                      label: "Duplicate",
                      onClick: () => {
                        toast.loading("Duplicating template...");
                        duplicateTemplateMutation.mutate(template.id);
                      },
                    },
                    {
                      label: "Delete",
                      className: "text-red-500",
                      onClick: async () => {
                        const confirmed = await confirm({
                          title: "Delete template",
                          body: "Are you sure you want to delete this template?",
                          actionButton: "Confirm",
                        });
                        confirmed && deleteTemplateMutation.mutate(template.id);
                      },
                    },
                  ]}
                >
                  <Button variant={"outline"}>
                    <MoreHorizontal className="w-4 h-4" />
                  </Button>
                </DropMenu>
              </div>
            </div>
          ))}
        </div>
      )}
    </Page>
  );
};

export default Templates;
