import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { trpc } from "@/helpers/trpc";
import { Input } from "@heffl/ui/components/primitives/input";
import { Archive, Search } from "lucide-react";
import Page from "@/components/page";
import Empty from "@/components/Empty";
import { P, match } from "ts-pattern";
import { isMobile } from "@heffl/ui/lib/utils";
import Projectcard from "../list/components/projectCard";
import ProjectTable from "../list/table";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
const ProjectArchives = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");

  const { data: projects } = trpc.projects.list.useQuery({
    archived: true,
    search,
  });

  return (
    <Page title="Archived projects" fullWidth className="sm:!p-0">
      <div className="p-3">
        <Input
          prefix={<Search className="h-5 text-gray-400" />}
          className="w-full sm:w-60"
          placeholder="Search archived projects..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      {match(projects)
        .with({ projects: [] }, () => (
          <Empty
            title="No archived projects"
            icon={Archive}
            description="Archive your projects to keep them safe and accessible."
          />
        ))
        .with({ projects: P.array() }, ({ projects }) =>
          isMobile() ? (
            <div className="flex flex-col gap-2 mt-4 mb-[100px] w-full">
              {projects.map((project) => (
                <Projectcard
                  onProjectClick={() => {
                    navigate(`/projects/details/${project.id}`);
                  }}
                  key={project.id}
                  project={project}
                  className="!w-full max-w-none min-w-full"
                />
              ))}
            </div>
          ) : (
            <ProjectTable projects={projects} />
          )
        )
        .otherwise(() => (
          <FullScreenSpinner />
        ))}
    </Page>
  );
};

export default ProjectArchives;
