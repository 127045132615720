import { z } from "zod";
import enums from "./enums";
import * as files from "./files";

export const expense = z.object({
  expenseCategoryId: z.number(),
  userId: z.number(),
  projectId: z.number().nullish(),
  fsJobId: z.number().nullish(),
  date: z.date(),
  amount: z.coerce.number(),
  notes: z.string().nullish(),
  paidBy: enums.expenses.paidBy.schema,
  files: z
    .object({
      expense_reciept: files.filesInput,
    })
    .optional(),
});

export const expenseCategories = z.object({
  name: z.string(),
  systemGenerated: z.boolean().optional().default(false),
});
