import { CustomFieldInput } from "@heffl/server/src/helpers/workflows/types";

type ValidateCustomFieldsProps = {
  fields: CustomFieldInput[];
  data: Record<string, string>;
  form: unknown;
};
const validateActionFields = ({
  fields,
  data,
  form,
}: ValidateCustomFieldsProps) => {
  if (!fields) return true;

  const customFieldErrors = fields
    .filter((field) => field.required && !data[field.name])
    .map((field) => ({
      name: `data.${field.name}`,
      message: `${field.label} is required`,
    }));
  if (customFieldErrors.length) {
    customFieldErrors.forEach((error) =>
      // @ts-ignore
      form.setError(error.name, { message: error.message })
    );
    return false;
  }
  return true;
};

export default validateActionFields;
