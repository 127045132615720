import DataTable from "@/components/DataTable";
import DropMenu from "@/components/DropMenu";
import { SearchInput } from "@/components/FormComponents";
import Pagination from "@/components/Pagination";
import FilterBar from "@/components/filters";
import Page from "@/components/page";
import { RouterOutputs, trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import usePermissions from "@/lib/hooks/usePermissions";
import { CompanyLogo } from "@/pages/crm/deals/components/dealCard";
import { calculateQuotationLineItems } from "@heffl/server/src/helpers/lineItems/calculateLineItems";
import enums from "@heffl/server/src/schemas/enums";
import TagsInput from "@heffl/ui/components/TagInput";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import ResponsiveActionButton from "@heffl/ui/components/primitives/responsive-action-button";
import { formatCurrency, formatName, isMobile } from "@heffl/ui/lib/utils";
import { format } from "date-fns";
import dayjs from "dayjs";
import {
  ArrowDownToLine,
  Building,
  Calendar,
  CalendarDays,
  Copy,
  DollarSign,
  Eye,
  MoreHorizontal,
  Pencil,
  ScrollText,
  Tags,
  Trash,
  UserCircle,
  Zap,
} from "lucide-react";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { downloadPdf } from "../invoices/details";
import QuotationCard from "./QuotationCard";
import { generateQuotationName } from "./details";

type QuotationStatus = z.infer<typeof enums.quotationStatus>;

export const QuotationStatusBadge: React.FC<{
  status: QuotationStatus;
  small?: boolean;
}> = ({ status, small }) => {
  const badgeProps = {
    small,
    variant: {
      DRAFT: "neutral",
      SENT: "default",
      ACCEPTED: "success",
      REJECTED: "error",
    }[status] as "neutral" | "default" | "success" | "error",
  };

  return (
    <Badge {...badgeProps}>
      {status.charAt(0) + status.slice(1).toLowerCase()}
    </Badge>
  );
};

type Quote = RouterOutputs["quotations"]["list"]["quotations"][number];

const getQuoteExpiryHTML = (quote: Quote): React.ReactNode => {
  const today = dayjs();
  const quoteExpiry = quote.expiryDate ? dayjs(quote.expiryDate) : null;
  const daysRemaining = quoteExpiry ? quoteExpiry.diff(today, "day") : null;

  if (daysRemaining !== null) {
    const statusClass = daysRemaining >= 0 ? "text-green-500" : "text-red-500";
    const expiryText =
      daysRemaining >= 0
        ? `Expires in ${daysRemaining} day${daysRemaining !== 1 ? "s" : ""}`
        : `Expired on ${dayjs(quoteExpiry).format("MMM, DD")}`;
    return <span className={`font-normal ${statusClass}`}>{expiryText}</span>;
  }

  return (
    <span className="font-normal text-gray-500">
      {quote.expiryDate
        ? dayjs(quote.expiryDate).format("DD-MM-YYYY")
        : "No Expiry Date"}
    </span>
  );
};

type PageFilters = {
  statuses: QuotationStatus[];
  dates: [Date, Date] | undefined;
  search: string;
  pageNo: number;
  clients: number[];
  tags: number[];
  salesPersons: number[];
};

const Quotations = () => {
  const navigate = useNavigate();

  const [filters, setFilters] = useParamsState<PageFilters>({
    search: "",
    pageNo: 1,
    statuses: [],
    dates: undefined,
    clients: [],
    tags: [],
    salesPersons: [],
  });

  const [clientSearch, setClientSearch] = useState("");

  const { data: tags } = trpc.tags.list.useQuery({
    type: "QUOTATION",
  });
  const { data: clients } = trpc.clients.list.useQuery({
    search: clientSearch,
    pageNo: 1,
    pageSize: 10,
  });

  const { data: salesPersons } = trpc.users.list.useQuery({
    excludeType: ["FIELD_STAFF"],
  });
  const { data: quotations, isLoading } = trpc.quotations.list.useQuery({
    ...filters,
    statuses: filters.statuses,
    startDate: filters.dates?.[0],
    endDate: filters.dates?.[1],
    pageNo: filters.pageNo,
    pageSize: 30,
    clients: filters.clients,
    tags: filters.tags,
    salesPersons: filters.salesPersons,
  });
  const permissions = usePermissions();

  const updateQuotationMutation = trpc.quotations.update.useMutation({
    onError(error) {
      toast.error(error.message);
    },
  });

  const deleteQuotationMutation = trpc.quotations.delete.useMutation({
    onSuccess() {
      toast.success("Quotation deleted successfully");
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  if (!tags || !permissions) return <FullScreenSpinner />;
  if (!permissions.VIEW_QUOTATIONS.allowed) {
    return (
      <Page fullWidth title="Quotations">
        <div className="flex flex-col justify-center items-center h-screen">
          <div className="flex flex-col gap-2 justify-center items-center p-3">
            <h1 className="text-3xl font-bold">
              You don&apos;t have permission to view quotations
            </h1>
            <p className="text-base font-medium">
              Please contact the admin to request access.
            </p>
          </div>
        </div>
      </Page>
    );
  }
  return (
    <Page fullWidth title="Quotations">
      <div className="flex flex-col gap-2 justify-between sm:gap-0 sm:flex-row">
        <SearchInput
          value={filters.search}
          onChange={(e) => setFilters({ search: e })}
        />
        {permissions.CREATE_QUOTATIONS.allowed && (
          <ResponsiveActionButton
            onClick={() => navigate("add")}
            text="Quotation"
          />
        )}
      </div>

      <FilterBar
        onChange={() => {
          setFilters({
            pageNo: 1,
          });
        }}
        className="mt-3"
        filters={[
          {
            key: "date",
            type: "date-range",
            label: "Date",
            value: filters.dates,
            onChange: (value) => setFilters({ dates: value }),
          },
          {
            key: "status",
            type: "checkbox",
            label: "Status",
            value: filters.statuses,
            onChange: (value) =>
              setFilters({ statuses: value as QuotationStatus[] }),
            options: [
              { label: "Draft", value: "DRAFT" },
              { label: "Sent", value: "SENT" },
              { label: "Accepted", value: "ACCEPTED" },
              { label: "Rejected", value: "REJECTED" },
            ],
          },
          {
            key: "clients",
            type: "checkbox",
            label: "Clients",
            showSearch: true,
            value: filters.clients,
            onChange: (value) => {
              setFilters({ clients: value as number[] });
            },
            options: clients?.clients.map((client) => ({
              label: client.name,
              value: client.id,
            })),
            onSearch: (e) => setClientSearch(e),
          },
          {
            key: "salesPersons",
            label: "Sales Person",
            type: "checkbox",
            value: filters.salesPersons,
            options: salesPersons?.map((user) => ({
              label: formatName(user),
              value: user.id,
            })),
            onChange: (value) => {
              setFilters({ salesPersons: value as number[] });
            },
          },
          {
            key: "tags",
            label: "Tags",
            type: "checkbox",
            value: filters.tags,
            options:
              tags?.map((tag) => ({
                label: tag.name,
                value: tag.id,
              })) ?? [],
            onChange: (e) => {
              setFilters({ tags: e as number[] });
            },
          },
        ]}
      />

      {isMobile() ? (
        <div className="flex flex-col gap-2 mt-4 mb-[100px]">
          {quotations?.quotations.map((quotation) => (
            <QuotationCard key={quotation.id} data={quotation} />
          ))}
          <Pagination
            pageNo={filters.pageNo}
            totalPages={quotations?.totalPages}
            setPageNo={(pageNo) => setFilters({ pageNo })}
            className="justify-start"
          />
        </div>
      ) : (
        <DataTable
          rowKey="id"
          className="mt-4"
          onRowClick={(quotation) =>
            navigate(`/sales/quotations/details/${quotation.id}`)
          }
          loading={isLoading}
          columns={[
            {
              title: "#",
              render: (quotation) => quotation.number,
              className: "!w-[60px]",
            },
            {
              icon: Building,
              title: "Client",
              className: "!w-[500px]",
              render: (quotation) => (
                <div className="flex gap-2 items-center py-1">
                  <CompanyLogo
                    company={quotation.clients}
                    className="w-10 h-10 rounded-full"
                  />
                  <div className="flex flex-col">
                    <p className="text-sm font-medium">
                      {quotation.clients?.name ?? "No company"}
                    </p>
                    <p className="text-xs font-medium text-gray-500">
                      {quotation.subject || "No subject"}
                    </p>
                  </div>
                </div>
              ),
            },

            {
              icon: Eye,
              title: "Status",
              render: (quotation) => (
                <QuotationStatusBadge status={quotation.status} />
              ),
              className: "!w-[100px]",
            },
            {
              icon: Tags,
              title: "Tags",
              render: (quotation) => (
                <TagsInput
                  value={quotation.quotationTags.map((tag) => tag.tagId)}
                  onChange={(tags) => {
                    updateQuotationMutation.mutate({
                      id: quotation.id,
                      quotation: { quotationTags: tags },
                    });
                  }}
                  tags={tags}
                />
              ),
              className: "!w-[150px]",
            },
            {
              icon: Calendar,
              title: "Date",
              render: (quotation) => format(quotation.date, "dd/MM/yy"),
              className: "!w-[160px]",
            },
            {
              icon: CalendarDays,
              title: "Expiry",
              render: (quotation) => getQuoteExpiryHTML(quotation),
              className: "!w-[180px]",
            },
            {
              icon: DollarSign,
              title: "Amount",
              className: "!w-[160px]",
              textAlign: "right",
              render: (quotation) =>
                formatCurrency(
                  calculateQuotationLineItems({
                    lineItems: quotation.quotationProducts,
                    discount: quotation.discount,
                    customFields: quotation.documentTemplates.customFields,
                  }).total,
                  quotation.currencies.symbol
                ),
            },
            {
              icon: UserCircle,
              title: "Sales Person",
              render: (quotation) => (
                <div className="flex gap-2 items-center">
                  {quotation.salesPerson ? (
                    <span className="text-sm">
                      {formatName(quotation.salesPerson)}
                    </span>
                  ) : (
                    <span className="text-sm text-gray-500">Not assigned</span>
                  )}
                </div>
              ),
              className: "!w-[200px]",
            },
            {
              icon: Zap,
              title: "Actions",
              textAlign: "right",
              className: "!w-[50px]",
              render: (quotation) => (
                <DropMenu
                  items={[
                    {
                      label: "Edit",
                      icon: Pencil,
                      onClick: () =>
                        navigate(`/sales/quotations/edit/${quotation.id}`),
                    },
                    {
                      label: "Duplicate",
                      icon: Copy,
                      onClick: () =>
                        navigate(
                          `/sales/quotations/add?quotationId=${quotation.id}`
                        ),
                    },
                    {
                      label: "Download",
                      icon: ArrowDownToLine,
                      onClick: async () => {
                        await downloadPdf({
                          name: generateQuotationName(quotation),
                          url: `print?quotation=${quotation.uuid}`,
                        });
                      },
                    },
                    {
                      icon: Trash,
                      className: "text-red-500",
                      label: "Delete",
                      onClick: async () => {
                        const confirmed = await confirm(
                          "Are you sure to delete this quotation?"
                        );
                        if (confirmed) {
                          deleteQuotationMutation.mutate(quotation.id);
                        }
                      },
                    },
                  ]}
                >
                  <Button
                    onClick={(e) => e.stopPropagation()}
                    variant={"ghost"}
                  >
                    <MoreHorizontal className="h-4 text-gray-700" />
                  </Button>
                </DropMenu>
              ),
            },
          ]}
          empty={{
            title: "No quotations added",
            icon: ScrollText,
            description: "Please add a quotation to the deal",
            actionText: "Add quotation",
            actionHref: "/sales/quotations/add",
            buttonSize: "sm",
            hideAction: !permissions.CREATE_QUOTATIONS.allowed,
          }}
          pagination={{
            pageNo: filters.pageNo,
            totalPages: quotations?.totalPages,
            setPageNo: (pageNo) => setFilters({ pageNo }),
          }}
          data={quotations?.quotations || []}
        />
      )}
    </Page>
  );
};

export default Quotations;
