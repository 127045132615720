import { z } from "zod";
import enums from "./enums";

export const accountingExpenses = z.object({
  categoryId: z.number(),
  paidByUserId: z.number(),
  projectId: z.number().nullish(),
  date: z.date(),
  category: z.string(),
  amount: z.number(),
  notes: z.string().optional(),
  paidBy: enums.accounting.accountingExpensePaidBy,
});

export const accountingCategories = z.object({
  type: enums.accounting.accountingExpenseType,
  name: z.string(),
  teamId: z.number(),
});
