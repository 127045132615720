import RenderHtml from "../components/render-html";
import { getS3URL } from "../helpers/s3Helpers";
import useStaticElementDimensions from "../hooks/useElementDimensions";
import { cn, isMobile } from "../lib/utils";
import { styled } from "goober";
import { RouterOutputs } from "@heffl/server/src/trpc/trpc";
import dayjs from "dayjs";
import injectDynamicTags from "@heffl/server/src/helpers/templates/injectDynamicTags";

const PageSizes = {
  A4: {
    width: "8.27in",
    height: "11.7in",
  },
  A5: {
    width: "5.83in",
    height: "8.27in",
  },
  Letter: {
    width: "8.5in",
    height: "11in",
  },
};

const PrintWrapper = styled("div")<{
  previewMode: boolean;
  properties: {
    pageSize: keyof typeof PageSizes;
    pageMarginTop: number;
    pageMarginBottom: number;
    pageMarginLeft: number;
    pageMarginRight: number;
    showHeader: boolean;
    showFooter: boolean;
  };
}>`
  padding-top: ${({ previewMode }) => (previewMode ? "20px" : 0)};
  padding-bottom: ${({ previewMode }) => (previewMode ? "20px" : 0)};
  .page-header {
    position: fixed;
    top: 0mm;
    width: 100%;
    visibility: ${({ previewMode }) => (previewMode ? "hidden" : "visible")};
  }
  .page-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    visibility: ${({ previewMode }) => (previewMode ? "hidden" : "visible")};
  }
  width: ${({ properties }) => PageSizes[properties.pageSize].width};
  @media print {
    thead {
      display: table-header-group;
    }
    tfoot {
      display: table-footer-group;
    }
  }
`;

export type TemplateRenderSettings = {
  headerHtml?: RouterOutputs["templates"]["details"]["headerHtml"];
  footerHtml?: RouterOutputs["templates"]["details"]["footerHtml"];
  templateProperties: RouterOutputs["templates"]["details"]["templateProperties"];
};

type Props = {
  template: TemplateRenderSettings;
  contentHtml: string;
  className?: string;
  previewMode?: boolean;
};

const injectCommonData = (contentHtml: string) => {
  const injected = injectDynamicTags({
    contentHtml,
    dataToInject: {
      Common: {
        "Common.PageNumber": "<div class='page-number'></div>",
        "Common.TodaysDate": dayjs().format("DD/MM/YYYY"),
      },
    },
  });
  return injected;
};

const TemplateRender = ({
  contentHtml,
  template,
  className,
  previewMode,
}: Props) => {
  const { dimensions: headerDimensions, ref: headerHeightRef } =
    useStaticElementDimensions();
  const { height: headerHeight } = headerDimensions ?? {};
  const { dimensions: footerDimensions, ref: footerHeightRef } =
    useStaticElementDimensions();
  const { height: footerHeight } = footerDimensions ?? {};

  const getZoomLevel = () => {
    const zoomWidth = window.innerWidth / (23 * 37.795276);
    const zoomHeight = window.innerHeight / (31.7 * 37.795276);
    return Math.min(zoomWidth, zoomHeight, 1);
  };

  const getPageMargin = () => {
    const {
      showHeader,
      showFooter,
      pageMarginTop,
      pageMarginBottom,
      headerFirstPageOnly,
    } = template.templateProperties;
    const margins = {
      top: showHeader ? 0 : pageMarginTop,
      bottom: showFooter ? 0 : pageMarginBottom,
    };

    const firstPageMarginTop = headerFirstPageOnly ? "0%" : `${margins.top}in`;
    const restPageMarginTop = headerFirstPageOnly
      ? pageMarginTop
      : `${margins.top}in`;

    return `
    ol li {
      list-style-position: outside !important;
      margin-left: 14px !important;
    }
    ul li {
      list-style-position: outside !important;
      margin-left: 14px !important;
    }
    @page {
      margin-top:${restPageMarginTop}in !important;
      margin-bottom:${margins.bottom}in !important;
      margin-left:0 !important;
      margin-right:0 !important;
    }
    @page :first {
      margin-top:${firstPageMarginTop} !important;
    }
    `;
  };

  const headerBackgroundImage = template.templateProperties
    .headerBackgroundImage
    ? `url('${getS3URL(
        "templates",
        template.templateProperties.headerBackgroundImage
      )}')`
    : "";

  const Header = template.templateProperties.showHeader && (
    <div
      className="page-header"
      ref={headerHeightRef}
      style={{
        paddingBottom: template.templateProperties.pageMarginTop + "in",
        paddingLeft: template.templateProperties.pageMarginLeft + "in",
        paddingRight: template.templateProperties.pageMarginRight + "in",
        backgroundSize: "contain",
        backgroundImage: headerBackgroundImage,
        backgroundRepeat: "no-repeat",
        backgroundPosition:
          template.templateProperties.headerBackgroundImagePosition,
      }}
    >
      <RenderHtml>{injectCommonData(template.headerHtml || "")}</RenderHtml>
    </div>
  );

  const footerBackgroundImage = template.templateProperties
    .footerBackgroundImage
    ? `url('${getS3URL(
        "templates",
        template.templateProperties.footerBackgroundImage
      )}')`
    : "";

  const Footer = template.templateProperties.showFooter && (
    <div
      className="page-footer"
      ref={footerHeightRef}
      style={{
        paddingTop: template.templateProperties.pageMarginBottom + "in",
        paddingLeft: template.templateProperties.pageMarginLeft + "in",
        paddingRight: template.templateProperties.pageMarginRight + "in",
        backgroundSize: "contain",
        backgroundImage: footerBackgroundImage,
        backgroundRepeat: "no-repeat",
        backgroundPosition:
          template.templateProperties.footerBackgroundImagePosition,
      }}
    >
      {<RenderHtml>{injectCommonData(template.footerHtml || "")}</RenderHtml>}
    </div>
  );

  const HeaderSpace = template.templateProperties.showHeader &&
    !template.templateProperties.headerFirstPageOnly && (
      <thead>
        <tr>
          <td>
            <div
              style={{
                height: previewMode ? 0 : headerHeight || 0,
              }}
            ></div>
          </td>
        </tr>
      </thead>
    );

  const FooterSpace = template.templateProperties.showFooter && (
    <tfoot>
      <tr>
        <td>
          <div
            style={{
              height: previewMode ? 0 : footerHeight || 0,
            }}
          ></div>
        </td>
      </tr>
    </tfoot>
  );

  const FirstPageHeader = template.templateProperties.headerFirstPageOnly &&
    !previewMode && (
      <tr>
        <td>
          <div
            style={{
              paddingBottom: template.templateProperties.pageMarginTop + "in",
              backgroundSize: "contain",
              paddingLeft: template.templateProperties.pageMarginLeft + "in",
              paddingRight: template.templateProperties.pageMarginRight + "in",
              backgroundImage: headerBackgroundImage,
              backgroundRepeat: "no-repeat",
              backgroundPosition:
                template.templateProperties.headerBackgroundImagePosition,
            }}
          >
            <RenderHtml>{template.headerHtml || ""}</RenderHtml>
          </div>
        </td>
      </tr>
    );

  return (
    <PrintWrapper
      id="print-wrapper"
      className={cn(className)}
      properties={template.templateProperties}
      previewMode={previewMode || false}
      style={{ zoom: isMobile() ? getZoomLevel() : 1 }}
    >
      {!template.templateProperties.headerFirstPageOnly && Header}
      {Footer}
      <style>{getPageMargin()}</style>
      <table className="w-full">
        {HeaderSpace}
        <tbody>
          {FirstPageHeader}
          <tr>
            <td
              style={{
                paddingLeft: template.templateProperties.pageMarginLeft + "in",
                paddingRight:
                  template.templateProperties.pageMarginRight + "in",
              }}
            >
              <RenderHtml>{contentHtml}</RenderHtml>
            </td>
          </tr>
        </tbody>
        {FooterSpace}
      </table>
    </PrintWrapper>
  );
};

export default TemplateRender;
