import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { trpc } from "@/helpers/trpc";
import ActivityTimeline from "@/pages/crm/components/activity-timeline";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@radix-ui/react-collapsible";
import { ChevronDown, ChevronUp } from "lucide-react";
import { useState } from "react";
import { useParams } from "react-router-dom";

const ClientFocus = () => {
  const params = useParams();
  const clientId = Number(params.clientId);

  const [open, setOpen] = useState(true);

  const { data: focusActivities } = trpc.activities.focus.useQuery({
    clientId: clientId,
  });

  if (!focusActivities) return <FullScreenSpinner />;

  return (
    <Collapsible className="mt-4" open={open} onOpenChange={setOpen}>
      <CollapsibleTrigger>
        <div className="flex flex-row gap-1 items-center">
          {open ? (
            <ChevronUp className="w-4 h-4" />
          ) : (
            <ChevronDown className="w-4 h-4" />
          )}
          <h1 className="text-sm font-semibold">Focus</h1>
        </div>
      </CollapsibleTrigger>
      <CollapsibleContent>
        <div className="flex flex-col mt-4">
          {!focusActivities.length && (
            <div className="flex flex-col gap-2 justify-center items-center p-3">
              <p className="text-sm">No focus items yet</p>
              <p className="w-1/2 text-sm text-center text-gray-500">
                Scheduled activities, pinned notes, email drafts and scheduled
                emails will appear here.
              </p>
            </div>
          )}
          <ActivityTimeline activities={focusActivities} />
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
};

export default ClientFocus;
