import Empty from "@/components/Empty";
import { SearchInput } from "@/components/FormComponents";
import Page from "@/components/page";
import { RouterOutputs, trpc } from "@/helpers/trpc";
import Schemas from "@heffl/server/src/schemas";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import { Card } from "@heffl/ui/components/primitives/card";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import ResponsiveActionButton from "@heffl/ui/components/primitives/responsive-action-button";
import { Switch } from "@heffl/ui/components/primitives/switch";
import { zodResolver } from "@hookform/resolvers/zod";
import { CreditCard } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useImmer } from "use-immer";
import { z } from "zod";

export const PaymentMethodForm = () => (
  <div className="flex flex-col gap-3 w-full">
    <FormField name="name" label="Name">
      <Input placeholder="Name" />
    </FormField>
    <FormField name="isDefault" label="Default">
      <Switch />
    </FormField>
  </div>
);

const AddPaymentMethodDrawer = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const form = useForm<z.infer<typeof Schemas.sales.paymentMethod>, unknown>({
    resolver: zodResolver(Schemas.sales.paymentMethod),
    defaultValues: {
      isDefault: false,
    },
  });

  const addPaymentMethodMutation =
    trpc.invoices.payments.methods.add.useMutation({
      onSuccess: () => {
        form.reset();
        toast.success("Successfully added payment method.");
        onClose();
      },
      onError: () => {
        form.reset();
        toast.error("Failed to add payment method.");
      },
    });

  return (
    <ModalDrawer
      open={open}
      onClose={onClose}
      className="w-full sm:max-w-sm"
      title="Add Payment Method"
    >
      <div className="flex flex-col gap-3 items-center w-full">
        <Form
          {...form}
          onSubmit={(values) => {
            addPaymentMethodMutation.mutate(values);
          }}
          className="w-full"
        >
          <PaymentMethodForm />
          <Button
            type="submit"
            variant="primary"
            className="w-full"
            loading={addPaymentMethodMutation.isLoading}
          >
            Add Payment Method
          </Button>
        </Form>
      </div>
    </ModalDrawer>
  );
};

const EditPaymentMethodDrawer = ({
  open,
  onClose,
  paymentMethod,
}: {
  open: boolean;
  onClose: () => void;
  paymentMethod: TPaymentMethod;
}) => {
  const form = useForm<z.infer<typeof Schemas.sales.paymentMethod>, unknown>({
    resolver: zodResolver(Schemas.sales.paymentMethod),
    defaultValues: {
      name: paymentMethod.name,
      isDefault: paymentMethod.isDefault,
    },
  });

  const editPaymentMethodMutation =
    trpc.invoices.payments.methods.update.useMutation({
      onSuccess: () => {
        form.reset();
        toast.success("Successfully updated payment method.");
        onClose();
      },
      onError: () => {
        form.reset();
        toast.error("Failed to update payment method.");
      },
    });

  return (
    <ModalDrawer
      open={open}
      onClose={onClose}
      className="w-full sm:max-w-sm"
      title="Edit Payment Method"
    >
      <div className="flex flex-col gap-3 items-center w-full">
        <Form
          {...form}
          onSubmit={(values) => {
            editPaymentMethodMutation.mutate({
              id: paymentMethod.id,
              paymentMethod: values,
            });
          }}
          className="w-full"
        >
          <PaymentMethodForm />

          <Button
            type="submit"
            variant="primary"
            className="w-full"
            loading={editPaymentMethodMutation.isLoading}
          >
            Update Payment Method
          </Button>
        </Form>
      </div>
    </ModalDrawer>
  );
};

type TPaymentMethod =
  RouterOutputs["invoices"]["payments"]["methods"]["list"][number];
const PaymentMethodCard = ({
  paymentMethod,
  onEdit,
}: {
  paymentMethod: TPaymentMethod;
  onEdit: () => void;
}) => {
  return (
    <Card className="flex gap-4 cursor-pointer" onClick={onEdit}>
      <div className="p-3 bg-blue-100 rounded-full">
        <CreditCard className="h-6 text-primary" />
      </div>
      <div className="flex flex-col gap-1 justify-center">
        <p className="text-sm font-medium">{paymentMethod.name}</p>
        <p className="text-xs text-gray-500">
          {paymentMethod.isDefault ? "Default" : "Not Default"}
        </p>
      </div>
    </Card>
  );
};

const PaymentMethods = () => {
  const [filters, setFilters] = useImmer({
    search: "",
  });
  const [addPaymentMethod, setAddPaymentMethod] = useState(false);
  const [editPaymentMethod, setEditPaymentMethod] =
    useState<TPaymentMethod | null>(null);

  const { data } = trpc.invoices.payments.methods.list.useQuery();

  if (!data) return <FullScreenSpinner />;
  return (
    <Page title="Payment Methods">
      <AddPaymentMethodDrawer
        open={addPaymentMethod}
        onClose={() => setAddPaymentMethod(false)}
      />
      {editPaymentMethod && (
        <EditPaymentMethodDrawer
          open={!!editPaymentMethod}
          onClose={() => setEditPaymentMethod(null)}
          paymentMethod={editPaymentMethod}
        />
      )}
      <div className="flex justify-between w-full">
        <SearchInput
          placeholder="Search payment methods..."
          value={filters.search}
          onChange={(value) =>
            setFilters((f) => {
              f.search = value;
            })
          }
        />
        <div className="space-x-3">
          <ResponsiveActionButton
            onClick={() => setAddPaymentMethod(true)}
            text="Payment Method"
          />
        </div>
      </div>
      {!data?.length ? (
        <Empty
          title="No payment methods added"
          icon={CreditCard}
          description="Please add a payment method"
          className="mt-8"
        />
      ) : (
        <div className="grid grid-cols-2 gap-3 mt-4 sm:grid-cols-4">
          {data?.map((paymentMethod) => (
            <PaymentMethodCard
              key={paymentMethod.id}
              paymentMethod={paymentMethod}
              onEdit={() => setEditPaymentMethod(paymentMethod)}
            />
          ))}
        </div>
      )}
    </Page>
  );
};

export default PaymentMethods;
