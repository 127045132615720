import LogoSmall from "@/assets/images/logo_small.png";
import { Button } from "@heffl/ui/components/primitives/button";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import { Input } from "@heffl/ui/components/primitives/input";
import { doLogin } from "@/helpers/auth";
import { trpc } from "@/helpers/trpc";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

const loginSchema = z.object({
  email: z.string().email(),
  password: z.string(),
});

const Login = () => {
  const navigate = useNavigate();

  const loginMutation = trpc.auth.login.useMutation({
    onSuccess(data) {
      if (data.success) {
        doLogin(data);
        toast.success("Logged in successfully, redirecting...");
        navigate("/");
      }
    },
    onError(error) {
      console.log(error);
      toast.error("Invalid credentials");
    },
  });

  const loginForm = useForm<z.infer<typeof loginSchema>>({
    resolver: zodResolver(loginSchema),
  });

  useEffect(() => {
    if (localStorage.getItem("hefflToken")) {
      navigate("/");
    }
  }, []);

  return (
    <div className="relative w-full h-full">
      <div className="flex top-4 left-4 gap-3 justify-center items-center mt-8 sm:absolute sm:left-8 sm:mt-0">
        <img src={LogoSmall} alt="Heffl" className="w-8" />
        <p className="text-3xl font-bold">Heffl</p>
      </div>

      <div className="w-full lg:grid lg:min-h-[600px] lg:grid-cols-2 xl:min-h-screen">
        <div className="flex justify-center items-center py-12">
          <div className="mx-auto grid w-[350px] gap-6">
            <div className="grid gap-2 text-center">
              <h1 className="text-3xl font-bold">Login to heffl</h1>
              <p className="text-balance text-muted-foreground">
                Enter your email below to login to your account
              </p>
            </div>
            <Form
              {...loginForm}
              onSubmit={(values) => {
                loginMutation.mutate(values);
              }}
              className="flex-col gap-6 mt-8 w-full"
            >
              <div className="grid gap-4">
                <FormField name="email" label="Email">
                  <Input placeholder="Email" type="email" className="w-full" />
                </FormField>
                <FormField name="password" label="Password">
                  <Input
                    placeholder="Password"
                    type="password"
                    className="w-full"
                  />
                </FormField>
                <Button
                  type="submit"
                  className="w-full"
                  variant="primary"
                  loading={loginMutation.isLoading}
                  size="md"
                >
                  Login
                </Button>
              </div>
            </Form>
          </div>
        </div>
        <div className="hidden bg-muted lg:block">
          {/* <img
          src="https://file.notion.so/f/f/8e170db7-3d1b-4708-add1-fee93ba56658/bc302c74-d49c-4c88-aad6-c8b4be6e5e18/1_VvfGqf0wmuV14BTbP-hNhw.webp?id=68d2996f-892a-4c05-9a3a-1bba4cb06c5d&table=block&spaceId=8e170db7-3d1b-4708-add1-fee93ba56658&expirationTimestamp=1711497600000&signature=km3l1yTJuxgHQtbds0xU0BwH_Tgi5m_DcEkqv27Cq0o&downloadName=1_VvfGqf0wmuV14BTbP-hNhw.webp"
          alt="Image"
          width="1920"
          height="1080"
          className="h-full w-full object-cover dark:brightness-[0.2] dark:grayscale"
        /> */}
        </div>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col p-4">
      <div className="flex flex-row justify-between p-2 w-full sm:p-7">
        <h1 className="text-lg font-bold">Heffl</h1>
      </div>
      <div className="flex justify-center items-center">
        <div className="flex flex-col items-center justify-center gap-2 px-7 sm:w-1/3 sm:h-[500px] h-auto w-full sm:rounded-lg shadow-sm sm:border border-gray-200 mt-16">
          <h1 className="text-3xl font-bold">Login</h1>
          <div className="flex flex-col gap-6 mt-4 w-full">
            <Form
              {...loginForm}
              onSubmit={(values) => {
                loginMutation.mutate(values);
              }}
              className="flex-col gap-6 w-full"
            >
              <FormField name="email" label="Email">
                <Input placeholder="Email" type="email" className="w-full" />
              </FormField>
              <FormField name="password" label="Password">
                <Input
                  placeholder="Password"
                  type="password"
                  className="w-full"
                />
              </FormField>
              <Button
                type="submit"
                variant={"primary"}
                size={"lg"}
                loading={loginMutation.isLoading}
              >
                Login
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
