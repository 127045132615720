import DataTable from "@/components/DataTable";
import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import { Card } from "@heffl/ui/components/primitives/card";
import React from "react";
import { useNavigate } from "react-router-dom";

interface Report {
  title: string;
  description: string;
  slug: string;
}

const reports: Report[] = [
  {
    title: "Project Summary Report",
    description: "Overview of project status and key metrics.	",
    slug: "project-summary-report",
  },
];

const ProjectReports: React.FC = () => {
  const navigate = useNavigate();
  const { data: currentUser } = trpc.users.currentUser.useQuery();

  if (currentUser?.type !== "ADMIN" && currentUser?.type !== "SUPER_ADMIN")
    return <div>You are not authorized to view this page.</div>;
  return (
    <Page title="Project Reports">
      <div className="container mx-auto py-8">
        <h1 className="text-2xl font-bold mb-6">All Reports</h1>
        <Card>
          <DataTable
            data={reports}
            columns={[
              {
                title: "Report Title",
                render: (report: Report) => (
                  <span
                    className="text-blue-400 font-medium cursor-pointer"
                    onClick={() => {
                      navigate(`/projects/reports/${report.slug}`);
                    }}
                  >
                    {report.title}
                  </span>
                ),
              },
              {
                title: "Description",
                render: (report: Report) => (
                  <span className="text-sm text-gray-500">
                    {report.description}
                  </span>
                ),
              },
              {
                title: "Created By",

                render: () => (
                  <span className="text-sm text-gray-500">
                    System generated
                  </span>
                ),
              },
            ]}
            rowKey="slug"
          />
        </Card>
      </div>
    </Page>
  );
};

export default ProjectReports;
